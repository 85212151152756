.product-attributes {
  .attri {
    display: flex;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #36c626;
      border-color: #36c626;
    }
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
      color: #585858 !important;
      font-weight: 400;
      font-size: 14px;
      padding: 7px 10px;
      text-align: center;
      height: 48px;
      display: flex;
      align-items: center;
      span {
        font-size: 10px;
      }
      .anticon {
        font-size: 20px;
      }
    }
    .tag-class {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 10px !important;
        margin-bottom: 0px;
        margin-left: 8px;
        width: 100%;
      }
    }
    p {
      width: 33%;
      margin-bottom: 10px;
      .ant-checkbox {
        margin-left: 20px;
      }
    }
  }
}
