.sourcing-order-card {
  .ant-card-body {
    padding: 0px;
  }

  .rto-status {
    padding: 5px;
    padding-left: 15px;
    position: absolute;
    left: -10px;
    top: 8px;
    color: white;
    background: #15dd24;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    padding-right: 20px;
  }

  .rto-status:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 100%;
    left: 0px;
    border-bottom: 9px solid #15dd24;
    border-left: 9px solid transparent;
    transform: rotate(270deg);
  }

  .sourcing-card-header {
    padding: 24px;
    .sourcing-order-image-container {
      width: 15%;
    }
    .sourcing-order-details {
      padding-left: 24px;
      flex: 1;
      h4 {
        margin-bottom: 0px;
        font-size: 14px;
        color: black;
        font-weight: 500;
      }
      p {
        margin-bottom: 0px;
        font-size: 16px;
        line-height: 22px;
        color: #585858;
        font-weight: 400;
      }
    }
    .soucing-order-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .sourcing-partner-info {
      display: flex;
      justify-content: space-around;
      background: #eeeeee;
      padding: 8px;
      margin-top: 12px;
      p {
        margin-bottom: 0px;
        font-size: 14px;
        color: black;
        line-height: 22px;
        font-weight: 500;
        span {
          font-size: 16px;
          font-weight: 400;
          color: #585858;
        }
      }
    }
  }

  .view-more-button {
    text-align: center;
    margin-top: 13px;
    button {
      font-size: 14px;
      height: 36px;
    }
  }
}

.new-inventory-card-logs {
  .head {
    width: auto !important;
  }
}
.ant-collapse.ant-collapse-icon-position-right.collapse-item-logs {
  width: auto;
}
