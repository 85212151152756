.progressbar_data {
  display: flex;
  justify-content: center;
  align-items: center;
  .progressbar_data2 {
    .line-container {
      margin-top: 10px;
        display: flex;
        width: 256px;
        margin-bottom: 16px;
      .progress-line {
        position: relative;
        border-radius: 10px;
        width: 100%;
        background: #cecece;
        height: 8px;
        z-index: 90;
        display: flex;
        justify-content: space-between;

        .progress {
          position: absolute;
          width: 75%;
          height: 100%;
          background: #2bc822;
          transition: width 1s ease-in-out;
        }
      }

      .status {
        position: relative;
        top: -4px;
        // left: -0.7px;
        .dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #cecece;
          cursor: pointer;
          margin-left: -1px;
          .current {
            background: white;
            border: 5px solid orange;
          }

          &.completed {
            position: relative;
            background: #2bc822;
            border: 5px solid #2bc822;
          }
        }

        .dot.dot-active {
          outline: 4px solid #b8f0b3;
          animation: pulse-black 2s infinite;
        }
      }
    }
  }
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes load-10 {
  0% {
    width: 0;
  }
  100% {
    width: 10%;
  }
}

@keyframes load-58 {
  0% {
    width: 0;
  }
  100% {
    width: 58%;
  }
}

@keyframes load-100 {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
