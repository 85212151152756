.open-rfq-nav {
  .roq-btb-container {
    position: relative;
    top: -8px;
  }
  .rfq-left {
    text-align: center;
    line-height: 1.32;
    font-size: 12px;
   
    color: #fd104d;

    .value {
      font-weight: 600;
    }
  }
  .rfq-left-dis {
    text-align: center;
    line-height: 1.32;
    font-size: 12px;
    color: #fd104d;
    position: relative;
    top: 5px;
  }
  .open-rfq-nav-upper {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .private-product-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .filter {
          .ant-select-selection {
            padding: 0 !important;
            background: none;
            border: none;
            margin-right: 0;
          }
          svg {
            font-size: 20px;
          }
          .ant-select-arrow {
            margin-top: -10px;
          }
          .ant-select-selection-selected-value {
            padding-right: 26px;
          }
        }
        .show_data_from {
          font-size: 12px;
        }
        .dispute-status {
          font-size: 12px;
        }
      }
      .left {
        margin-left: "7px";
      }
    }
    .secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 104px;
      .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        .tab {
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;
          color: #898989;
          .active {
            color: #1268fb;
          }
          button {
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 500;
          }
        }
        .circle {
          // margin-left: 8px !important;
          position: relative;
          background: #f5f5f5;
          font-size: 12px;
          padding: 2px 10px;
          color: #898989;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #898989;
        }
        .backgroundcircle {
          // margin-left: 8px !important;
          position: relative;
          // background: #1161fb;
          font-size: 12px;
          padding: 2px 10px;
          color: #898989;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #898989;
        }

        .hover-ndr-underline-animation {
          position: relative;
          .private-products-txt {
            a {
              color: #898989;
            }
          }

          .store-product-txt {
            a {
              color: #898989;
            }
          }
          .wishlist-product-txt {
            a {
              color: #898989;
            }
          }
          .import-list-txt {
            a {
              color: #898989;
            }
          }
        }
        .hover-ndr-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-ndr-underline-animation:hover {
          color: #4398ed !important;
          a {
            color: #4398ed;
          }
          .backgroundcircle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-ndr-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left;
        }

        .hover-ndr-underline-animation-click {
          position: relative;

          button {
            color: #1268fb;
          }

          .backgroundcircle {
            background-color: #1268fb;
            color: #fff;
            border: 1px solid #1268fb;
          }
        }
        .hover-ndr-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left;
        }
      }
      .download_csv {
        margin-bottom: 10px;
        line-height: 30px;
        height: 32px;
        line-height: 30px;
        margin-right: 100px;
      }
    }
    .ant-input {
      width: 80%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px;
    }
    .ant-calendar-range-picker-separator {
      color: #1268fb !important;
    }
    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em;
    }
    .ant-calendar-range-picker-input {
      width: 35% !important;
      letter-spacing: 0.032em;
      line-height: 24px;
    }
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      top: 50%;
      right: 47px;
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
    .breadcurmb-txt {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 24px;
      > span {
        margin: 0px 4px;
      }
      .breadcurmb-underline {
        text-decoration: underline;
      }
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-top: 92px;
    margin-bottom: -32px;
  }
  .ant-select-selection__placeholder {
    color: #1268fb;
  }

  .rfq-status {
    width: 210px;
    height: 40px;

    font-size: 12px !important;

    svg {
      transition: 0.3s all;
      fill: none;

      path:last-of-type {
        fill: #898989;
      }
    }

    .ant-select-selection.ant-select-selection--single {
      height: 40px;
      border: none;
      border-radius: 2px;
      background-color: #f8f8f8;
      box-shadow: 0px 2px 8px #00000016;

      .ant-select-selection__rendered {
        height: 40px;
      }
    }
    .ant-select-selection-selected-value {
      height: 40px;
      margin-top: 4px;
      color: #1268fb;
      span {
        color: #898989;
        margin-right: 2px;
      }
    }
  }

  .rfq-status.ant-select-open {
    svg {
      transform: rotate(-180deg);
      transition: 0.3s all;
      fill: #212121;

      path {
        fill: #212121;
      }
    }
  }

  .nav-rfq-btn {
    border-color: green;
    width: 160px;
    box-shadow: 0px 2px 3px #00000022;
    font-weight: 500;
    background-color: green;
    height: auto;
    padding: 5px;
  }
  .ant-btn-primary[disabled] {
    color: #898989;
    background-color: #d4cece82;
    font-weight: 400;
    border-color: transparent;
  }
}

@media screen and (min-width: 1440px) {
  .open-rfq-nav-upper {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
}

// media query
@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .open-rfq-nav-upper {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}

.custom-select {
  span {
    display: none;
  }
}
