.user-modal {
  z-index: 99;
  .ant-modal-content {
    border-radius: 4px;
    border: 1px solid #707070;
    box-shadow: none;
    padding: 1rem;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      border: none;
      padding: 8px 16px;
      .ant-modal-title.custom {
        font-size: 16px;
        color: #020429;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 0.032em;
        line-height: 16px;
        svg {
          width: 1.71rem;
          height: 1.71rem;
          cursor: pointer;

          * {
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }

    .ant-modal-body {
      padding: 16px;
      .ant-form {
        label {
          font-size: 12px;
        }
        .ant-form-item-label > label {
          color: #464646;
          line-height: 16px !important;
        }
        .ant-input-group {
          .label {
            font-size: 12px;
            color: #464646;
            font-weight: 400;
            margin-bottom: 2px;
            letter-spacing: 0.032em;
          }

          .ant-input {
            border-radius: 0px;
            border: 0px solid rgba(0, 0, 0, 0.15);
            background: #f5f5f5;
          }

          .ant-select {
            width: 100%;
          }

          .ant-select-dropdown-menu-item {
            border-bottom: 2px solid;
          }
        }
        .form-footer {
          text-align: center;
          a {
            color: #010101;
            text-decoration: underline !important;
          }
        }

        .submit-button {
          width: 100%;
          background: #1bca00;
          color: #fff !important;
          border: none !important;
          outline: none !important;
          font-size: 16px;
          font-weight: 500;
          border-radius: 2px;
          margin-top: 16px;
        }
        .submit-button:hover,
        .submit-button:active,
        .submit-button:focus,
        .submit-button:focus-within {
          background: #1bca00;
        }
        .anticon {
          vertical-align: -0.2em;
        }
      }
      .ant-form-item {
        margin-bottom: 8px !important;
      }
      .ant-input::placeholder {
        font-size: 12px !important;
      }
      .ant-form-item-label {
        color: #464646;
        line-height: 16px !important;
        letter-spacing: 0.032em;
      }
      .ant-select-selection {
        background-color: #f5f5f5;
        border: 0px solid #d9d9d9;
        border-top-width: 0px;
        border-radius: 0px;
      }
    }
  }
}

.option-span {
  margin-bottom: 0;
}
.role-select {
  font-size: 10px !important;
  font-style: italic !important;
  color: #d6d6d6 !important;
  margin-left: 20px !important;
}

.role-permissions-data {
  .title {
    font-size: 12px;
    color: #595959;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;

    svg {
      width: 0.93rem;
      height: 0.93rem;
      margin-right: 0.5rem;
    }
  }
}
