.import-product-search {
  width: 400px !important;
  .ant-input-group {
    .ant-input {
      height: 40px !important;
      box-shadow: 0px 2px 8px #66656514;
      border-radius: 2px 0 0 2px;

      border: 1px solid #e3e3e3 !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
      // transition: all 0.5s ease-in-out !important;
    }
    .ant-input::placeholder {
      // text-transform: capitalize;
    }
    .ant-input:hover {
      // height: 40px !important;
      // box-shadow: 0px 2px 8px #66656514;
      border: 1px solid #b2b2b2 !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
    }
    .ant-input:focus {
      // height: 40px !important;
      // box-shadow: 0px 2px 8px #66656514;
      border: 2px solid #fd104d !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
    }
  }
  button {
    // width: 176px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    // transition: all 0.5s ease-in-out !important;
    border: none;
  }
  button:hover {
    // transition: all 0.5s ease-in-out !important;
  }
}

.ant-btn {
  border-radius: 2px !important;
}

//
.pick {
  height: 40px !important;
  .ant-calendar-picker-input {
    height: 40px;
  }
}

// clickable button global classes
.clickable {
  background-color: #0068ff !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #0068ff !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important;
  cursor: pointer;
}

.clickable:hover {
  background-color: #0063f2 !important;
  border: 2px solid #0058d6 !important;
  color: #fff !important;
}

.clickable:active {
  background-color: #0058d6 !important;
  color: #fff !important;
  box-shadow: none !important;
}

// clickable-dropdown
.clickable-dropdown {
  transition: 0.5s all;
  background-color: #2a9c27 !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #2a9c27 !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important;
}

.clickable-dropdown:hover {
  transition: 0.5s all;
  background-color: #2bc822 !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
  box-shadow: none !important;
}

.clickable-dropdown:active {
  background-color: #229d1b !important;
  color: #fff !important;
}

.capitalize-text {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}
// global classes

.border-orange {
  position: relative;

  z-index: 9;

  border-radius: 5px;

  .ant-input:hover {
    border: none;
  }

  .ant-calendar-picker:hover {
    border: none;
  }

  .ant-calendar-picker:hover .ant-calendar-picker-input {
    border: none;
  }

  .ant-calendar-picker-input.ant-input {
    border: 1px solid red !important;
    animation: border-pulse 2s infinite;
  }
}

.small-tooltip-text {
  font-size: 11px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.032em !important;
}

@keyframes border-pulse {
  0% {
    box-shadow: 0 0 0 0 red;
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

// glbal toottip background
.ant-tooltip-inner {
  background-color: #000000e6 !important;
  padding: 12px !important;
  font-size: 11px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.032em !important;

  .float-tooltip {
    h1 {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #fff;
    }
    p {
      text-align: left;
    }
  }
}

// line animation

.underline-animation {
  display: inline-block;
  position: relative;
  color: #0068ff;
}

.underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  color: #0068ff;
  background-color: #0068ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

// onboarding flags css

.ant-modal-body {
  .input-number-txt {
    width: 100% !important;
  }
}

// breadcrumbs
.breadcurmb-txt {
  display: flex !important;
  align-items: center;
  > span {
    margin: 0 4px !important;
  }
}

.flag-dropdown {
  background-color: transparent !important;
  .selected-flag {
  }

  .selected-flag:hover {
    background-color: transparent !important;
  }

  .selected-flag:focus {
    background-color: transparent !important;
  }
}

.react-tel-input {
  .form-control {
    border-radius: 0px !important;
  }
}

// Ant Modal background-color:

.ant-modal-mask {
  background-color: #000000e6 !important;
  z-index: 999 !important;
}
.logoRightContainer {
  display: none;
}
@media screen and (max-width: 967px) {
  .left-card-containerS {
    display: none;
  }
  .mainCard-signup {
    max-width: 98%;
  }
  .right-card-signincontainer .flex-from {
    padding: 0 8% !important;
  }
  .right-card-signincontainer .flex-from input {
    width: 100% !important;
  }
  .right-card-signincontainer .flex-from .submitbutton {
    width: 100% !important;
  }
  .right-card-signincontainer .flex-from .signup-line .login-form-signup-here {
    margin-left: unset !important;
  }
  .right-card-signincontainer .flex-from .signup-line {
    display: flex;
    justify-content: space-between;
  }
  .right-card-signincontainer .sub-heading {
    margin-bottom: 20px;
  }
  .logoRightContainer {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  .otpVerifyContaner .heading-bar,
  .otpVerifyContaner .otp-section {
    padding: 20px 10% !important;
  }

  .otpVerifyContaner .main-heading {
    font-size: 22px !important;
  }

  .otpVerifyContaner .email-section {
    padding: 0 10% !important;
  }

  .otpVerifyContaner .email-section .input-email {
    width: 100% !important;
  }

  .otpVerifyContaner .otp-section .submit-section {
    margin-top: 10px;
    padding-top: unset !important;
  }

  .otpVerifyContaner {
    min-height: unset !important;
  }

  .otpVerifyContaner .otp-section .otp-verify .otp-number {
    width: 40px !important;
  }

  .otpVerifyContaner .otp-section .submit-section button {
    width: 100% !important;
  }
}
@media screen and (max-width: 530px) {
  .forgot-remember-parent{
    width:100% !important;
  }
  .mainCard-signup {
    max-width: 98%;
  }
  .right-card-signupcontainer .flex-from .flex-form-inner .option-group {
    flex-direction: column;
    width: auto !important;
  }
  .right-card-signupcontainer .heading-bar {
    padding: 15px !important;
  }
  .right-card-signupcontainer .main-heading {
    font-size: 22px !important;
  }
  .right-card-signupcontainer .flex-from {
    padding: 15px !important;
  }
  .right-card-signupcontainer .flex-form2 {
    padding: 15px !important;
  }
  .right-card-signupcontainer .flex-form2 .submitbutton {
    width: 100% !important;
  }
  .right-card-signupcontainer .signup-line {
    display: flex;
    justify-content: space-between;
  }
  .right-card-signupcontainer .signup-line .login-form-signup-here {
    margin-left: 0px !important;
  }

  .right-card-signincontainer .heading-bar {
    padding: 15px !important;
  }

  .right-card-signincontainer .main-heading {
    font-size: 22px !important;
    text-align: center;
    padding-left: unset !important;
  }
  .right-card-signincontainer .sub-heading {
    text-align: center;
    padding-left: unset !important;
  }
  .right-card-signincontainer .flex-from {
    padding: 0 8% !important;
  }
  .right-card-signincontainer .flex-from input {
    width: 100% !important;
  }
  .right-card-signincontainer .flex-from .submitbutton {
    width: 100% !important;
  }
  .right-card-signincontainer .flex-from .signup-line .login-form-signup-here {
    margin-left: unset !important;
  }
  .right-card-signincontainer .flex-from {
    padding: 0 8% !important;
  }
}
