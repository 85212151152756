.link-product-bundle-modal {
  .ant-modal-body {
    .ant-select-lg .ant-select-selection--multiple {
      min-height: 47px;
      border: none;
      background: #f5f5f5;
      border-radius: unset;
    }
    .ant-select-selection--multiple .ant-select-selection__placeholder {
      margin-left: 6px;
      font-size: 12px;
      line-height: 35px;
      height: 47px;
    }
    .ant-radio-group {
      display: block;

      .ant-radio-wrapper {
        border: 1.5px solid #979797;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        display: block;
        margin: 1rem auto;

        .label-text {
          font-weight: 700;
        }
      }
    }
    p.input-label {
      margin-bottom: 0;
      font-weight: 600;
    }
    input:first-of-type {
      margin-bottom: 15px;
    }
    .bulk-import {
      .icon-container {
        margin-top: 10px;
        padding: 10px;
        border: 2px solid #979797;
        border-radius: 4px;
        color: #979797;
        text-align: center;
        cursor: pointer;

        i {
          svg {
            width: 5rem;
            height: 5rem;
          }
        }
      }
      .icon-container:hover {
        border-color: #5e73ff;
        color: #5e73ff;
      }
    }
  }
  .next-button {
    background-color: #2bc822;
    outline: none;
    border: none;
    width: 161px;
    border-radius: 2px;
    height: 40px;
  }
  .cancle-button {
    width: 161px;
    border-radius: 2px;
    height: 40px;
    color: #898989 !important;
    border-color: #898989 !important;
    &:hover {
      color: #898989 !important;
      border-color: #898989 !important;
    }
  }

  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #8bd987;
    border-color: #8bd987;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    width: 161px;
  }
  .ant-select-selection__rendered {
    line-height: 29px !important;
    li {
      height: 38px !important;
      line-height: 32px !important;
      margin-top: 5px !important;
      width: 381px !important;
    }
  }
  .input-label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400 !important;
    margin-bottom: 4px !important;
  }
}
