.storlistingnav {
  .stornav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 255px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .ant-select-selection__placeholder {
    color: #1268fb;
  }
}

@media screen and (min-width: 1440px) {
  .stornav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 344px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .stornav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}
