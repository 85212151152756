.quote-modal {
  // width: 850px !important;
  top: 32px !important;

  .gst {
    font-size: 12px;
  }

  div[role="tablist"] {
    display: none !important;
  }

  .files {
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    flex-wrap: wrap;

    > div,
    > img,
    > video {
      object-fit: cover;
      height: 72px;
      width: 72px;
      border: 1px solid #c4c4c4;
      cursor: pointer;
    }
  }

  * {
    letter-spacing: 0.032em;
    font-size: 12px;
    margin: 0;
  }

  .global-tooltip {
    .anticon.global-tooltip-icon {
      margin-right: 0px !important;
    }

    span {
      i {
        margin-right: 0px !important;
      }
    }
  }

  .scroller {
    max-height: 469px !important;
  }

  .ant-modal-header {
    padding: 24px 0px 8px 0px !important;
    margin: 0px 24px;

    .ant-modal-title {
      h1 {
        font-size: 16px;
        font-weight: 600;
        color: #212121;
        margin-bottom: 6px;
      }

      h2 {
        font-size: 12px;
        font-weight: 400;
        color: #898989;
      }
    }
  }

  .ant-modal-body {
    padding-top: 16px !important;
    padding-bottom: 16px !important;

    .moq-range-wraper {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e8e8e8;

      .view-media {
        margin-top: 6px;
        border: 1px solid #0068ff !important;
        color: #0068ff !important;

        span {
          color: #0068ff !important;
        }
      }
      .moq-heading,
      .moq-days,
      .loop-line {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;

        > div {
          width: 220px;
          color: #212121;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .moq {
          width: 140px !important;
          align-items: flex-start;
        }
        .days {
          display: flex;
          align-items: center;
        }
      }

      .moq-heading {
        padding: 0px 24px;
        margin-bottom: 4px;

        > div {
          color: #898989 !important;
          font-weight: 400;
        }
      }

      .moq-days {
        padding: 0px 24px;
        margin-bottom: 8px;
      }

      .moq {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400 !important;
      }
      .loop-line:nth-of-type(odd) {
        background-color: #f5f5f5;
      }
    }

    .remark-wraper {
      p {
        margin-bottom: 8px;
        color: #898989;
      }

      textarea {
        border-radius: 0px;
        border: none;
        background-color: #f5f5f5;
        resize: none;
      }

      margin-bottom: 40px;
    }
  }

  .ant-modal-footer {
    margin-left: 24px !important;
    margin-right: 24px !important;
    padding: 12px 0 !important;
    .grp {
      button:first-of-type {
        background-color: #f5f5f5;
      }

      .ant-btn-primary {
        font-weight: 500;
      }
    }
    .footer-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        padding-left: 56px;
        padding-right: 56px;
      }

      a {
        padding-left: 32px;
        padding-right: 32px;
      }

      .green {
        background-color: #2bc822;
        border: #2bc822;
        span {
          color: #fff;
          font-weight: 600;
        }
      }

      .green:hover {
        background-color: #198313;
      }
    }
  }
}

.media-modal {
  .ant-modal-body {
    position: relative;
  }

  .left,
  .right {
    position: absolute;

    svg {
      transform: scale(2);
      cursor: pointer;
    }
  }

  .left {
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  .right {
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  .img-slider {
    > div,
    > img,
    > video {
      object-fit: cover;
      min-height: fit-content;
      max-height: 500px;
      width: 100%;
      border: 1px solid #c4c4c4;
      cursor: pointer;
    }
  }

  .ant-modal-body {
    padding: 24px 4rem;
  }

  .img-slider {
    display: flex;
    align-items: center;
  }
}
