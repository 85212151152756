.orders-to-vf-graph-wraper.pie {
  * {
    letter-spacing: 0.032em;
  }

  .circle-color-wraper {
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      height: 20px;
      //   background-color: red;
      width: 20px;
      border-radius: 50%;
    }
  }

  position: relative;

  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;

  background-color: #fff;

  .graph-head {
    height: 56px;
    background-color: #f0f2f659;
    padding: 16px 24px 12px 24px;

    font-size: 12px;
    color: #898989;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px;
    }
    .name {
      margin-left: 0px;
    }

    span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px;
    }
    .abs-div {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: -48px;
      left: 48px;
      justify-content: flex-start;

      > div {
        margin-right: 34px;
      }

      svg {
        margin-right: 2px;
      }
      span {
        font-size: 12px;
        color: #212121;
        font-weight: 500;
      }
    }

    .compare {
      display: flex;
      align-items: center;
    }
  }
  .recharts-wrapper {
    padding-left: 6px;
    padding-right: 6px;
    z-index: 9 !important;
    margin-top: 0px !important;
    right: 32px !important;

    .recharts-surface {
      // padding-bottom: 14px !important;
      width: 100% !important;
    }

    .yAxis {
      font-size: 10px;
    }
    .xAxis {
      g {
        font-size: 14px;
        // text-transform: capitalize;
      }
    }
  }
}

.graph-custom-tooltip {
  border: 1px solid #000;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 1;
  padding: 11px 16px;
  transition: top 200ms, left 200ms;
  font-size: 12px;
  min-width: 250px;

  .light-bold {
    font-weight: 500;
  }
  .date {
    margin-bottom: 7px;
  }

  .details {
    display: flex;
    .icon {
      display: flex;
      align-items: flex-start;
      margin-top: 6px;
      margin-right: 6px;
      svg {
        font-size: 6px;
      }
    }

    .text {
      text-transform: capitalize;
      p {
        font-weight: 500;
      }

      p:first-of-type {
        font-weight: 400;
      }
    }
  }

  .details.last {
    margin-top: 14px;
  }
}

// table css

.map-table-wraper.pie {
  * {
    letter-spacing: 0.032em;
  }

  position: absolute;
  right: 0;
  top: 50%;
  padding: 0px 10px;
  transform: translateY(-45%);
  overflow-x: scroll;
  overflow-y: scroll;
  float: right;
  min-height: 400px;
  width: 46%;

  .row-wraper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6px 2px;
    border: 1px solid transparent;
    transition: 0.3s all linear;
    > .row1 {
      padding-left: 6px;
    }
  }

  .row-wraper.head {
    // background-color: #f0f2f659;
    div {
      font-weight: 500;
      // background-color: #f0f2f659;
    }
  }

  .activeTooltip {
    transition: 0.3s all linear;
    background-color: #e6f4ff !important;
    border: 1px solid #91d5ff;

    .row {
      font-weight: 500 !important;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #212121;
    font-weight: 400;
    // padding-left: 8px;
  }

  .row1 {
    width: 40%;
    justify-content: flex-start;
  }

  .row2 {
    width: 20%;
  }

  .row3 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
  }

  .row-wraper:nth-child(even) {
    background: #f5f5f5;
  }
}
