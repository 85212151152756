.my-products-card {
  margin-bottom: 1rem;
  letter-spacing: 0.032em;
  font-size: 12px;
  margin-bottom: 24px;
  .img-container {
    border: 2px solid #f5f5f5;
    background: white;
    padding: 0;
    text-align: right;
    width: 100%;
    height: 160px;
    background-size: cover !important;
    background-position: center !important;
  }
  .flex-on{
    color:rgb(43, 200, 34) ;
    position: relative;
    margin-left: 5px;
    span{
       color: rgb(206, 206, 206);
       display: inline-block;
       margin-left: 3px;
       margin-right: 3px;
       
    }
    svg{
      position: relative;
      border-radius: 8px;
      top:3px
    }
  }
  
  // span.copied-id svg g path {
  //     fill: #5e73ff !important;
  // }
  // span.copied-id:hover svg g path {
  //     fill: blue !important;
  // }
  .my-products-card-dropdown-menu li {
    text-align: left;
  }
  .switch {
    margin-right: 8px;
  }
  // .my-product-card-dropdown-menu {
  //   .switch .ant-switch {
  //     min-width: 36px !important;
  //     height: 16px !important;
  //     margin-left: ;
  //   }
  //   .switch .ant-switch::after {
  //     top: 0px;
  //     width: 14px !important;
  //     height: 14px !important;
  //   }
  // }
  .text-container {
    padding-left: 32px;
    color: #000;
    margin-left: -13px;
    p {
      font-size: 0.9rem;
    }
    b {
      font-weight: 500;
    }
    .product-name {
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px;
    }
    .supplier-name {
      color: #5e73ff;
    }
    .price-details {
      margin-bottom: 0;
      font-weight: 300;
      b {
        font-size: 0.95rem;
      }
    }
  }
  .next-img {
    right: 0;
  }
  .prev-img {
    left: 0;
  }
  .img-container button {
    position: absolute;
    top: 0%;
    height: 100%;
    background: #212121;
    cursor: pointer;
    display: none;
    border: none;
    opacity: 0.4;
  }
  .img-container:hover button {
    display: block;
  }

  .mapping-product {
    text-align: center;
    margin-top: 8px;
    .link-product {
      background-color: #fd4726;
      color: #fff;
      padding: 8px 16px;
      margin: 16px;
      display: inline-block;
      border-radius: 2px;
      border: 2px solid #fd4726;
      cursor: pointer;
      &:hover {
        background: #e61047;
        border: 2px solid #c70c3c;
        color: #fff;
        padding: 8px 16px;
        margin: 16px;
        display: inline-block;
        border-radius: 2px;
        cursor: pointer;
      }
    }
    .bundle-product {
      border: none;
      background: transparent;
      cursor: pointer;
      color: red;
      padding: 8px 16px;
      letter-spacing: 0.032em;
      line-height: 16px;
      border: 1px solid #fd4726;
      cursor: pointer;
    }
  }
}
.not-available-txt {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 400;
}

.my-products-card.not-linked {
  // border: 2px solid Red !important;
  // box-shadow: 0px 3px 10px rgba(255, 146, 0, 0.15) !important;
  border: none !important;
  box-shadow: none !important;
}

.my-products-card.provisional {
  // border: 2px solid #ff9300 !important;
  // box-shadow: 0px 3px 10px rgba(255, 146, 0, 0.15) !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 12px;
  letter-spacing: 0.032em;
  .ant-card-body {
    padding-bottom: 18px;
    padding: 32px 32px 32px !important;
    .ribbon span {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: capitalize;
      text-align: center;
      line-height: 34px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 198px;
      display: block;
      background: #ff9400;
      position: absolute;
      top: 35px;
      left: -50px;
      z-index: 999;
    }
  }
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: #ff9500;
  }
  .escalated-now-alert {
    padding: 16px 16px 16px 56px;
    .ant-alert-icon {
      font-size: 22px;
      top: 14.5px;
    }
    .ant-btn {
      background: #ff9500;
      color: white;
      margin-left: 15px;
      font-size: 14px;
      font-weight: 400;
      box-shadow: none;
      border: none;
    }
  }
  .ant-alert.ant-alert-warning.escalated-now-alert {
    // border: 1px solid #ff9200;
    border: 1px solid #0068ff;
    background-color: #f5faff;
  }
  .ant-alert-warning .ant-alert-icon {
    color: #0068ff;
  }
  .escalated-alert {
    padding: 16px 16px 16px 56px;
    // background-color: #f5fff4;
    background-color: #f5faff;
    border: 1px solid #2bc822;
    .ant-alert-icon {
      font-size: 22px;
      top: 14.5px;
      color: #70d35c;
    }
    .ant-btn {
      background: #70d35c;
      color: white;
      margin-left: 15px;
      font-size: 14px;
      font-weight: 400;
      box-shadow: none;
      border: none;
    }
  }
}

.cost-breakup-button {
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
  height: 18px;
  line-height: 19px;
  border-radius: 2px;
  box-shadow: none;
  border: none;
  padding: 0px 7px;
  margin-left: 14px;
}

.shopify-sell-tooltip {
  display: none;
}

.shopify-sell:hover .shopify-sell-tooltip {
  display: block;
}

.shopify-sell .shopify-sell-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 1%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #ff9500 transparent;
}

.my-product-card-dropdown-menu .ant-dropdown-menu-item {
  border-bottom: 1px solid #cecece !important;
  padding: 12px 16px !important;
  color: #898989 !important;
  line-height: 16px !important;
  margin: 0 !important;
  font-size: 12px !important;
  letter-spacing: 0.032em;
  text-align: left;
  &:hover {
    background-color: #f5faff !important;
    color: #212121 !important;
    font-weight: 500;
    // text-align: center;
  }
  span.switch .ant-switch {
    min-width: 36px !important;
    height: 16px !important;
    margin-left: 16px;
  }
  span.switch .ant-switch::after {
    top: 0px;
    width: 14px !important;
    height: 14px !important;
  }
}
.my-product-card-dropdown-menu .ant-dropdown-menu-item-active {
  box-shadow: 0px -1px 0px #b5dafe inset;
  border-bottom: none !important;
}

.action_btn {
  .dropdownaction:hover {
    color: #212121 !important;
    font-weight: 400 !important;
  }
}

.ant-card {
  border: 0px !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05) !important;
}

.notlinked {
  .ribbon span {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    line-height: 34px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 198px;
    display: block;
    background: #e14643;
    position: absolute;
    top: 35px;
    left: -50px;
    z-index: 999;
  }
}

.sv3 {
  pointer-events: none;
  color: rgb(137, 137, 137);
  text-decoration: none !important;
}
.product-name-id a {
  text-decoration: underline !important;
}

.blue-store-product-title a:hover {
  text-decoration: underline !important;
}
.green-dot{
  position: relative;
  right: 5px;
 svg{
  
  circle{
    fill:rgb(43, 200, 34);
  }
 }
}