.options-container34 {
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  .option {
    a {
      color: #0068ff;
      .d-container {
        position: relative;
        top: 2px;
      }
    }

    .bottom-line {
        font-size: 10px;
        margin-top: 2px;
      .date {
        color: #212121;
        font-weight: 500;
      }
    }
  }
}
