.inventry {
  .inventry-count {
    height: 32px;
    width: 100%;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .second {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .inventry-right {
        h2 {
          font-size: 14px;
          color: #212121;
          font-weight: medium;
          margin: 0;
          padding: 0;
          margin-left: 4px;
        }
      }
    }
  }

  .inventry-filling-day {
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.032em;
    line-height: 24px;
    .restocking-time-txt {
      margin-right: 4px;
    }
  }

  .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 0.4rem;
    .inventry-available-txt {
      color: #898989;
      margin-right: 8px;
    }
  }
  .inventry-card-count {
    margin-bottom: 4px;
    align-items: center;
    display: flex;
  }

  .sv:hover {
    fill: #0068ff;
  }
  .anticon:hover {
    svg {
      #Ellipse_44 {
        stroke: #1268fb;
        fill: #1268fb;
      }
      #Ellipse_47 {
        stroke: #fff;
        fill: #ffff;
      }
      #Rectangle_2462 {
        stroke: #fff;
        fill: #ffff;
      }
    }
  }
  .inventry-tooltip svg {
    width: 16px;
    height: 16px;
  }
  .inventry-tooltip-txt {
    font-size: 10px;
  }
  .inventry-date {
    color: #212121;
    letter-spacing: 0.032em;
  }
}
