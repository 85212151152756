.sourcing-requests-list {
  // * {
  //   letter-spacing: 0.032em;
  // }
  .ant-select-arrow {
    margin: 0;
  }
  .ant-input-search-button {
    background: #fd104d;
    border-color: #fd104d;
    height: 40px;
    font-size: 12px;
    line-height: 0;
    border-radius: 0 2px 2px 0;
    text-shadow: none;
    box-shadow: none;
  }
  .requests-filters {
    .ant-select {
      .ant-select-selection__rendered {
        margin-left: 0;
        width: 71px;
      }
      .ant-select-selection-selected-value {
        margin-right: 8px;
      }
    }
  }
  .ant-input-search {
    input {
      background-color: white !important;
      border: none;
      border-radius: 2px 0 0 2px !important;
      height: 40px;
      letter-spacing: 0.032em;
      font-size: 12px;
    }
  }
  .heading {
    .content-page-title {
      margin-bottom: 0;
      span {
        float: right;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }
    p {
      font-size: 16px;
      color: black;
    }
  }
  .requests-filters {
    display: flex;
    justify-content: space-between;
    .ant-input {
      background-color: "white" !important;
    }
    .store-filters {
      background-color: white !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        margin-bottom: 0px;
        font-size: 12px;
        color: black;
        margin-right: 5px;
      }
      .filter_select {
        .ant-select-selection__placeholder {
          display: block !important;
        }
        .ant-select-selection__choice {
          display: none;
        }
      }
      .filter.ant-select {
        width: auto;
        color: #004eff;
        font-size: 14px;
        font-weight: 600;
        .ant-select-selection {
          background-color: transparent;
          border: none;
          .ant-select-selection__rendered {
            line-height: 31px;
            .ant-select-selection__placeholder {
              color: #004eff;
            }
            ul {
              .ant-select-selection__choice {
                color: #004eff;
                .ant-select-selection__choice__remove:hover,
                .ant-select-selection__choice__remove {
                  color: rgb(2, 103, 251);
                }
              }
            }
          }
        }
        .ant-select-selection--single {
          height: 25px;
        }
        .ant-select-arrow {
          margin-top: -2px;
          color: #004eff;
        }
      }
      .ant-select-focused .ant-select-selection,
      .ant-select-selection:focus,
      .ant-select-selection:active {
        border-color: none;
        border-right-width: 0px !important;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .store-filters:hover {
    background-color: #fff !important;
  }
}
.request-not-found {
  padding: 16px;
  text-align: center;
  background: #fff;
  color: #000;
  font-weight: 500;
}

.ant-pagination-options-size-changer {
  .ant-select-arrow {
    margin-top: -4px !important;
  }
}
