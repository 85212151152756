.basic-details-container {
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #898989;
  line-height: 16px;
  padding: 48px;
  p {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px;
  }
  .ant-card-body {
    padding: 0px !important;
    background-color: #f6f6f6 !important;
  }
  .ant-tabs-tab {
    margin: 0px !important;
    text-align: left !important;
    padding: 12px 32px !important;
    font-size: 12px;
  }
  .tabs-nav-wrap {
    height: 100vh !important;
  }
  .ant-tabs-left-content {
    background-color: #fff;
    border-left: none;
    padding: 40px 40px 40px 40px;
  }
  .ant-tabs .ant-tabs-left-bar {
    border-right: none;
    margin-right: 0px;
    padding-top: 8px;
    width: 200px;
  }
  .ant-tabs-ink-bar {
    width: 0px !important;
    background-color: none !important;
    height: 0px !important;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #212121;
    font-weight: 400;
    background: #fff;
  }
  .ant-form-item-required::before {
    display: none;
  }
  .fname {
    background: #f5f5f5;
    display: block;
    margin-bottom: 12px;
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 5px;
    color: #212121;
    height: 32px;
    text-transform: capitalize;
  }

  .basic-detail-field {
    background: #f5f5f5;
    display: block;
    margin-bottom: 12px;
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 5px;
    color: #212121;
    height: 32px;
  }
  .ant-divider-horizontal {
    margin: 16px 0;
  }
  .ant-input-lg {
    height: unset;
    padding: 7px 16px;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    border-radius: 0px;
  }
  .ant-btn-lg {
    height: unset;
    padding: 8px 16px;
    border-radius: 2px;
    margin-top: 24px;
    font-size: 12px;
  }
  .ant-input .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #f5f5f5;
  }
  .ant-input .has-error .ant-input-affix-wrapper .ant-input-lg {
    border: 1px solid #f5222d;
  }
  .has-error .ant-form-explain {
    font-size: 12px;
  }
  .ant-form-item-label {
    line-height: 16px;
  }
  .ant-form-item label {
    font-size: 12px;
    color: #898989;
    line-height: 16px;
  }
  .ant-form-item {
    margin: 0px;
  }
  .ant-btn-primary {
    background-color: #2bc822 !important;
    border-color: #2bc822 !important;
  }
  .clickablebtn2 {
    background-color: #94e38f !important;
    border-color: #94e38f !important;
  }
}
