.opops {
  .dragger-packing{
    border-radius: 0px;

  }
  .io-container {
    width: 110px;
    margin-right: 10px;
    height: 110px;
    margin-bottom: 12px;
    display: inline-block;
    background-color: lavender;
    svg{
      display: flex;
      margin: auto;
      height: 110px;
    }
  }
  .cointainer {
    display: flex;
    margin-top: 12px;
  }
  .heading {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .drage-image {
    padding: 24px 16px;
    background-color: #f5f5f5;
    margin-right: 8px;
    width: 110px;
  }
  .drage-image:hover {
    background-color: #1370fc;
    color: #fff;
    p {
      color: #fff;
    }
  }
  .drage-image:hover svg g path {
    fill: #fff;
  }
  .drage-image svg {
    position: relative;
    left: 33%;
  }
  .image-cointainer {
    position: relative;
    .icon-cointainer-pdf{
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: 2px;
      right: 12px;
      display:none;
      svg {
        height: 100%;
        width: 100%;
      }
    }
    .icon-cointainer {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: -1px;
      right: 10px;
      display: none;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  .image-cointainer:hover {
    .icon-cointainer {
      display: block;
    }
    
  }
  .image-cointainer:hover {
    .icon-cointainer-pdf {
      display: block;
    }
    
  }
}
