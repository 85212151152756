.colorContainer{
    .cointainer-input {
        display: flex;
        .container-main {
          width: 30%;
          .title-input{
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.032em;
            color: #898989;
            margin-bottom: 5px;
            margin-top: 4px;
          }
          .input {
            width: 80%;
            background: #f5f5f5;
            border: 1px solid #212121;
            border-radius: 0px;
            color: #212121;
            letter-spacing: 0.032em;
            line-height: 20px;
            font-size: 12px;
            height: 37px;
            padding: 4px 11px;
          }
          .colour-text{
            margin-left: 12px;
            font-weight: 400;
            
          }
        }
      }
}