.cost-brakup-modal {
  .ant-modal-content {
    padding: 8px;
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0px;
      padding-top: 24px;
      .ant-modal-title {
        font-weight: 600;
        font-size: 24px;
      }
    }
    .ant-modal-body {
      padding-top: 0px;
      .cost-breakeup-heading {
        margin-bottom: 5px;
        font-size: 16px !important;
        font-weight: 500;
        color: #000000d9;
      }
    }
  }

  .ant-modal-close {
    top: 20px;
    right: 15px;
    .anticon {
      font-size: 24px;
    }
  }
  .margin-class {
    margin-bottom: 8px;
    margin-top: 37px;
  }
  .ant-select-selection {
    width: 100%;
    height: 36px;
    padding: 4px 11px;
    color: #000000d9;
    font-size: 16px;
    font-weight: 500;
    background-color: #f8f8f8;
    border: 1px solid #707070;
    border-radius: 0px;
  }
  .cost-breakup-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cost-breakup-inputs {
      flex: 1;
      margin: 5px;
      .ant-input {
        width: 100%;
        height: 36px;
        padding: 4px 11px;
        color: #000000d9;
        font-size: 16px;
        font-weight: 500;
        background-color: #f8f8f8;
        border: 1px solid #707070;
        border-radius: 0px;
      }
    }
    .cost-breakup-inputs:first-child {
      margin-left: 0;
    }
    .cost-breakup-inputs:last-child {
      margin-right: 0;
    }

    .ali-pricing {
      padding: 12px 20px;
      background: #fdfeff;
      width: 50%;
      p {
        font-size: 14px;
        color: #000000d9;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span {
        color: #005cff;
        font-weight: 500;
      }
    }
    .vf-pricing {
      padding: 12px 20px;
      background: #f4f8ff;
      width: 50%;
      p {
        font-size: 14px;
        color: #000000d9;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span {
        color: #005cff;
        font-weight: 500;
      }
    }
    .total-ali-pricing {
      padding: 12px 20px;
      background: #fdfeff;
      width: 50%;
      p {
        font-size: 14px;
        color: #000000d9;
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span {
        color: #cf230a;
        font-weight: 500;
      }
    }
    .total-vf-pricing {
      padding: 12px 20px;
      background: #f4f8ff;
      width: 50%;
      p {
        font-size: 14px;
        color: #000000d9;
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span {
        color: #00cb00;
        font-weight: 500;
      }
    }
  }

  .cost-brakup-footer {
    padding: 24px 24px 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn {
      font-size: 16px;
      color: #707070;
      border-color: #707070;
      border-radius: 3px;
      height: 36px;
    }
    .ant-btn-primary {
      text-shadow: none;
      box-shadow: none;
      color: white;
      border: none;
      margin-right: 20px;
    }
  }
  .vf-buyback-price-container {
    .vf-buyback-price {
      width: 48%;

      .buyback-value {
        background: #f8f8f8;
        color: #005cff;
        padding: 10px;
      }
    }
  }
  .italic-subheading {
    font-size: 12px;
    margin-bottom: 0px;
  }
  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
