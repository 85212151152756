.Sold_Products {
    background: #fafbfc;
    border-radius: 4px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    .Sold_Products_container {
        display: flex;
        justify-content: space-between;
        padding: 20px 32px;
        margin-bottom: -24px;
        .Selected_Period_text {
            font-size: var(--medium-font-size);
            font-weight: 500;
            color: #000000;
            line-height: 22px;
            letter-spacing: 0.032em;
        }
        .right_text span {
            font-size: var(--small-font-size);
            margin-right: 8px;
            color: #000000;
            line-height: 22px;
            letter-spacing: 0.032em;
            .Via_Shopify_Name {
                cursor: pointer;
                background: no-repeat;
                border: none;
                color: #1162fb;
                letter-spacing: 0.032em;
                line-height: 22px;
                text-decoration: underline;
            }
            .color_change {
                cursor: pointer;
                background: no-repeat;
                border: none;
                color: #000000;
                letter-spacing: 0.032em;
                line-height: 22px;
            }
            .color_change:hover {
                text-decoration: underline;
            }
            .Via_Catalog_Name {
                cursor: pointer;
                background: no-repeat;
                border: none;
                color: #1162fb;
                letter-spacing: 0.032em;
                line-height: 22px;
                text-decoration: underline;
            }
        }
    }
    .Sold_Products_table {
        width: 100%;
        font-size: var(--small-font-size);
        display: inline-block;
        // tr:second-child{
        //     background: #F5F5F5;
        // padding: 8px 8px;
        // }
        // tr {
        //   display: table;
        // }
        th {
            color: #262626;
            font-weight: 500;
            padding: 0.5rem;
            white-space: normal;
            letter-spacing: 0.032em;
            line-height: 22px;
            width: 186px;
        }
        th:first-child {
            padding-left: 0px !important;
            width: 351px;
        }
        td {
            padding: 8px !important;
            width: 180px;
            span img {
                width: 40px;
                height: 40px;
                border: 1px solid #f5f5f5;
                border-radius: 4px;
                padding: 2px;
                margin-right: 1rem;
            }
            span a {
                white-space: nowrap;
                line-height: 22px;
                font-weight: 400;
                letter-spacing: 0.032em;
            }
        }
        td:first-child {
            padding-left: 0px !important;
            width: 300px;
        }
        .table_parrent {
            tbody tr:nth-child(even) {
                background: #f5f5f5;
            }
            table {
                border-radius: 4px;
            }
            tbody {
                border-radius: 4px;
            }
        }
        .table_parrent>div {
            border-radius: 4px;
        }
        tbody {
            padding: 20px 32px;
            background: #ffffff;
            width: 100%;
            display: inline-grid;
            margin-top: -16px;
            border-radius: 4px;
        }
    }
    .ant-empty-description {
        display: none !important;
    }
    .svI {
        opacity: 0;
        transition: opacity 0.5s;
        margin-left: 5px;
    }
    .spn:hover {
        .svI {
            opacity: 1;
            margin-left: 5px;
            transition: opacity 0.5s;
        }
    }
}

@media screen and (min-width: 1360px) {
    .Sold_Products_table {
        width: 100%;
        font-size: var(--small-font-size);
        display: inline-block;
        th {
            color: #262626;
            font-weight: 500;
            padding: 0.5rem;
            white-space: normal;
            letter-spacing: 0.032em;
            line-height: 22px;
        }
        th:first-child {
            padding-left: 0px !important;
            width: 300px;
        }
        .table_parrent {
            tbody tr:nth-child(2) {
                background: #f5f5f5;
            }
        }
        tbody {
            padding: 20px 32px;
            background: #ffffff;
            background: #ffffff;
            width: 100%;
            display: inline-grid;
        }
    }
}