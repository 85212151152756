.floating-widget-wraper-new {
  .pricing-container {
    padding: 12px 12px;
    background-color: #f5f5f5;

    .horizental-line {
      height: 1px;
      background-color: #b2b2b2;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    i {
      margin: 0 8px;
    }

    p {
      font-size: 12px;
      color: #898989;
    }

    span {
      font-size: 12px;
      color: #898989;
      font-weight: 500;
    }

    .float-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 8px;
    }

    .float-heading:nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 8px;
      p {
        font-size: 12px;
        margin-right: 4px;
      }
      span {
        font-size: 12px;
        color: #0068ff;
        margin-left: 0px !important;
      }
    }

    .float-heading:nth-of-type(2) {
      i {
        margin: 0px !important;
        margin-left: 4px !important;
      }
    }

    .price-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 8px;
      span {
        font-size: 16px;
        font-weight: 600;
        color: #212121;
      }
    }
    .gst-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      p {
        font-style: italic;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        color: #898989;
      }

      i {
        font-size: 16px;
      }
    }

    // infos
    .info-container {
      padding-top: 4px;
      padding-bottom: 6px;

      p {
        font-style: italic;
      }

      i {
        font-size: 16px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 6px;
      }
    }

    .total-cost-container {
      border-top: 1px solid #b2b2b2;
      padding-top: 12px;

      .cost-wraper {
        display: flex;
        align-items: center;
      }

      .total-cost {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 12px;
        > p:first-of-type {
          text-decoration: underline;
        }
        .f16 {
          font-size: 16px;
          color: #212121;
          font-weight: 600;
          text-decoration: none !important;
        }
      }
      .gst-desc {
        font-size: 12px;
        color: #898989;
        text-align: center;
        font-size: 10px;
        font-style: italic;
      }
    }

    .raise_booking_request {
      button {
        border-radius: 2px;
        width: 100%;
        margin: 12px 0;
        font-size: 16px;
        color: #fff;
        height: 40px;
        background-color: #1370fc;
        box-shadow: #00000033 0px 2px 3px;
        span {
          color: #fff;
          font-size: 16px;
        }
      }
      p {
        font-size: 12px;
        color: #898989;
        text-align: center;
        font-size: 10px;
        font-style: italic;
      }
      .ant-btn.ant-btn-cancel {
        background-color: #f5f5f5 !important;
        span {
          color: #898989 !important;
        }
      }
    }
  }

  .gst-heading-bottom {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    i {
      margin-left: 4px;
      margin-right: 0px !important;
    }
  }
}

.ant-message {
  z-index: 9999999999999 !important;
}
