.deductedFromWallet-summary {
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff;
  height: fit-content;

  .not-found-wraper {
    margin-bottom: 0px !important;
  }

  .topPerformingProductHead {
    height: 48px;
    background-color: #f0f2f659;
    padding: 16px 24px;
    font-size: 12px;
    color: #898989;
    position: relative;
    .name {
      margin-left: 0px !important;
    }

    span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px;
    }

    span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px;
    }
  }

  .product-heads {
    height: 24px;
    padding: 0 24px;
    background-color: #f0f2f659;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    div {
      font-size: 12px;
      font-weight: 500;
      color: #898989;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name-head {
      justify-content: flex-start;
      width: 260px;
    }

    .delivered-order-head {
      width: 180px;
      justify-content: flex-end;
      padding-right: 8px;
    }
  }

  .product-row-wraper {
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    .product-row {
      height: 40px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #898989;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .name-head {
        justify-content: flex-start;
        width: 256px;
        color: #212121;
      }

      .name-head.total {
        font-weight: 500;
      }

      .delivered-order-head {
        width: 180px;
        justify-content: flex-end;
        font-weight: 500;
      }

      .delivered-order-head.debit {
        color: #c70c3c;
      }
      .delivered-order-head.credit {
        color: #229d1b;
      }
      .delivered-order-head.yellow {
        color: #ff9800;
      }
    }

    .product-row:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
}

.wallet-container {
  display: flex;
  gap: 24px;
  margin-top: 60px;

  > div {
    width: 48.8%;
  }
}
