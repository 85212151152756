.media {
  .no-media {
    background-image: url("../../../../../../assets/images/backgrounds/no-media.svg");
    padding: 24px 20px;
    background-repeat: no-repeat;
    width: 120px;
    p {
      margin-bottom: 0px;
      text-align: center;
      color: black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .product-images {
    img {
      width: 120px;
      height: 120px;
      margin-right: 15px;
      margin-bottom: 15px;
      border: 1px solid black;
    }
    .anticon {
      bottom: 24px;
      position: absolute;
      right: 22px;
      background: #0068ff;
      color: white;
      padding: 5px;
      border-radius: 18px;
      font-size: 15px;
    }
  }
  .product-video {
    video {
      width: 120px;
      height: 120px;
      margin-right: 15px;
      margin-bottom: 15px;
      border: 1px solid black;
    }
    .anticon {
      bottom: 24px;
      position: absolute;
      right: 22px;
      background: #0068ff;
      color: white;
      padding: 5px;
      border-radius: 18px;
      font-size: 15px;
    }
  }
}
