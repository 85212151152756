.fullfillment-card {
  display: flex;
  padding:2px 5px;
  
    .left-varaint-image-data {
      flex-basis: 50%;
      background-color: #f5f5f5;
      padding: 8px;
    padding-left: 16px;
        img {
            height: 48px;
            width: 48px;
            // margin-right: 16px;
            border: 1px solid #979797;
            padding: 2px;
          }
      .image-name {
        margin-left: 16px;
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #212121;
        line-height: 16px;
      }
    }
    .switch-cointainer{
      background-color: #f5f5f5;
      padding-right: 64px;
      padding-left: 68px;
    .switchFullfillment{
        margin-top: 18px;
       
    }
  }
}
