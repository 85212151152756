.modal-data-transtion {
    .delail-CONTAINER{
        // background-color: red;
        // display: flex;
        font-size: 12px;
        color: #212121;
        text-transform: capitalize;
        span{
            display: inline-block;
           margin-left: 14px;
        }
    }
    .id-cointainer{
        display: flex;
        justify-content: space-between;
        .transtionid{
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            margin-right: 5px;
        }

    }
    .table-heading{
        color: #898989;
        font-weight: 400;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-size: 12px;

    }
    .footer-table{
        display: flex;
        justify-content: space-between;
        .item{
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #212121;
        text-transform: capitalize;
        }
        .total{
            
            font-weight: 600;
            margin-right: 30px;
            color: #e14643;
            font-weight: 600;
        }
    }
    .data{
        color:#212121;
        font-size: 16px;
        font-weight: 500;
    }
    .divider{
        border-bottom: 1px solid #e8e8e8 ;
        margin-bottom: 10px;
    }
    .top-divider{
        border-top: 10px;
    }
    .amount{
        font-weight:600 ;
        color: #e14643;
    }
    .ant-table-thead > tr > th{
        background: white;
        border: none !important;
    }
    .ant-table-thead > tr > th:first-of-type{
        background: white;
        border: none !important;
        padding-left: 0px;
    }
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 8px 8px;
        overflow-wrap: break-word;
        border: 2px solid rgb(227, 227, 227);
    }
    .ant-table table {
        
        border-collapse: collapse;
      
    }
    .ant-table-tbody > tr >td:first-of-type{
   background-color: #f5f5f5;
    }
    .heading-modal{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 5px;
        padding-left: 0px;
    }
  .heading-vfd {
    padding: 5px;
    padding-left: 8px;
    background: #3385ff;
    color: white;
    border-radius: 2px;
    font-size: var(--small-font-size);
    font-weight: 500;
    letter-spacing: 0.032em;
    position: relative;
    top: -7px;
   
  }
  .footer-cointainer{
    margin-top: 10px;
    text-align: right;

  }
  .sku-img{
    height: 32px !important;
    width: 32px !important;
  }

}
