.request-tooltip {
    svg {
        animation: spinend 0.2s ease-out !important;
    }
}

.request-tooltip:hover {
    svg {
        animation: spinStart 0.2s ease-out !important;
        fill: #0068FF;
    }
}

.request-tooltip:active {
    opacity: 0;
}

@keyframes spinend {
    to {
        transform: rotate(-180deg);
    }
}

@keyframes spinStart {
    to {
        transform: rotate(180deg);
    }
}

@keyframes spinStart {
    to {
        -webkit-transform: rotate(180deg);
    }
}