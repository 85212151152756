.specialTags {
    li {
        svg:hover {
            rect {
                filter: brightness(0.8);
            }
            circle {
                filter: brightness(0.8);
            }
        }
    }
}