// bucket filter
.mav-filter-wraper {
  .lock-container {
    svg {
      fill: #6e6b6b;
    }
  }
  overflow: hidden;
  * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer !important;
  }
  box-shadow: 0px 2px 8px #00000014;
  margin-bottom: 48px;
  position: relative;

  .ant-select-search.ant-select-search--inline {
    width: 30px;
    margin-top: 5px;
    color: #1268fb;
  }

  .ant-select-selection__choice__content {
    max-width: 30px;
  }

  .ant-select-selection__rendered {
    height: 40px;
    margin-bottom: 0px;
    padding-left: 116px !important;
    .ant-select-selection__choice {
      max-width: 50px;
      position: relative;

      margin-top: 8px;
      color: #1268fb;
      font-weight: 500;
      font-size: 12px;
      background: none;
      border: 1px solid transparent;
      z-index: 10;
      margin-right: 2px;
      padding: 0 2px !important;
      span {
        display: none;
      }
    }

    li:nth-last-child(2) {
      position: relative;
      ::after {
        content: "";
        position: absolute;
        right: -1px;
        top: 1px;
      }
    }

    .ant-select-search__field {
      margin-top: 3.4px;
      font-size: 12px;
    }
  }

  .ant-select-selection__choice {
    position: relative;
    ::after {
      content: ",";
      position: absolute;
      right: -1px;
      top: 1px;
    }
  }

  .ant-select-selection__choice:last-of-type {
    position: relative;
    display: none !important;
    ::after {
      content: "--";
      position: absolute;
      right: -1px;
      top: 1px;
    }
  }

  .ant-select-selection-item {
    display: none !important;
  }

  .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none;

    span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px;
    }
  }

  .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s;
    pointer-events: none;
  }

  .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg);

    g {
      fill: #898989;
    }
  }

  .ant-select.ant-select-enabled {
    height: 40px;
  }

  .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px;
    .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      padding-left: 130px !important;

      // .ant-select-selection__placeholder {
      //   display: block !important;
      // }
      .ant-select-selection__choice {
        max-width: 100px;
        position: relative;

        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        padding: 0 2px !important;
        span {
          display: none;
        }
      }

      .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px;
      }
    }
  }

  .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb;
  }

  .ant-checkbox.ant-checkbox-checked {
    display: none;
  }
}
.lock-mav {
  opacity: 0.5;
  border: 2px solid transparent;
}
.lock-mav:hover {
  border: 2px solid #898989b3;
}
.mav-filter-wraper.custom-padding {
  .ant-select-search.ant-select-search--inline {
    width: 30px;
    margin-top: 5px;
    color: #1268fb;
  }

  .ant-select-selection__rendered {
    height: 40px;
    margin-bottom: 0px;
    padding-left: 164px !important;
    .ant-select-selection__choice {
      max-width: 100px;
      position: relative;

      margin-top: 8px;
      color: #1268fb;
      font-weight: 500;
      font-size: 12px;
      background: none;
      border: 1px solid transparent;
      z-index: 10;
      margin-right: 2px;
      padding: 0 2px !important;
      span {
        display: none;
      }
    }

    li:nth-last-child(2) {
      position: relative;
      ::after {
        content: "";
        position: absolute;
        right: -1px;
        top: 1px;
      }
    }

    .ant-select-search__field {
      margin-top: 3.4px;
      font-size: 12px;
    }
  }

  .ant-select-selection__choice {
    position: relative;
    ::after {
      content: ",";
      position: absolute;
      right: -1px;
      top: 1px;
    }
  }

  .ant-select-selection__choice:last-of-type {
    position: relative;
    display: none !important;
    ::after {
      content: "--";
      position: absolute;
      right: -1px;
      top: 1px;
    }
  }
}

//
.mav-filter-overlay {
  * {
    letter-spacing: 0.032em;
  }

  .ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item {
    color: #898989;
    min-height: 40px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #cecece;
    padding-left: 40px;

    i {
      position: absolute;
      left: 16px !important;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 12px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: #212121;
    font-weight: 500;
    i {
      background-color: #2bc822;
      border-color: #2bc822 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        stroke: 2px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item:hover {
    background-color: #f5faff;
    border-bottom: 1px solid #b5dafe;
    svg {
      color: #898989;
    }
  }

  .select-divider {
    // display: none !important;
    background-color: #898989 !important;
    min-height: 1px !important;
    padding: 0px !important;
    pointer-events: none !important;
    i {
      display: none !important;
    }
  }
}

.overlay-footer {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 26px !important;
    padding: 2px 8px !important;
  }

  span {
    font-size: 12px;
  }
}
