.extra-stats {
  box-shadow: 0 1px 2px #ff3c3c29;
  cursor: pointer;
  padding-left: 1rem !important;
  padding-right: 1.5rem !important;
  .ant-card-body {
    // padding: 0.625rem 1.5rem;
    display: flex;
    align-items: center;
    padding-left: 1rem !important;
    padding: 0 !important;
    height: 100%;
    span {
      vertical-align: middle;
    }
    .icon {
      font-size: 1.312rem;
    }
    .title {
      margin-left: 12px;
      font-size: var(--medium-font-size);
      color: #585858;
      font-weight: 500;
    }
    .number {
      // float: right;
      display: block;
      width: 30%;
      display: flex;
      justify-content: flex-end;
      font-size: var(--extra-medium-font-size);
      color: #585858;
      font-weight: 500;
    }
  }
}

.sc {
  margin: 1rem 0 2rem 0rem !important;
  padding: 0 !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .scwrap {
    width: 70%;
  }
  .ant-col {
    // width: 304px;
    padding: 0 !important;
    // padding-left: 2rem !important;
    height: 3.5rem;
    .ant-card {
      border: 1px solid #d6d6d6 !important;
      height: 100%;
      box-shadow: 0 1px 2px #00000016 !important;
      .ant-card-body {
        height: 100%;
        width: 100%;
      }
    }
    .ant-card:nth-of-type(2) {
      padding: 0 1.5rem !important;
    }
  }
}

.sc > div {
  width: 31.5%;
}

.extra-stats.is-zero {
  opacity: 0.8;
  box-shadow: none;
}

.statistic-card .ant-card-body .header {
  line-height: 20px;
  font-size: 12px !important;
  letter-spacing: 0.032em;
  font-weight: 500;
}
