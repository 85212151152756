.shopify-connect {
  .content-container {
    width: 100%;
    max-width: 450px;
    margin: auto;
    text-align: center;
  }

  .skip {
    cursor: pointer;
    color: #4b4b4b;
    width: 510px;
    border: 1px solid black;
    border-radius: 2px;
    background: none;
    padding: 3px;
    margin-bottom: 30px;
    font-weight: 400;
  }
  // .skip:hover {
  //     background: #0031FF;
  //     color: white;
  //    border: none;
  // }

  .shopify-connect-title {
    font-size: 16px;
    color: #ff005e;
    margin: 0;
    letter-spacing: 0.032em;
    line-height: 22px;
  }

  .shopify-connect-subtitle {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 20px;
    // margin-top: 8px;
  }
  .shopify-connect-img {
    margin: 48px 0;
  }

  .shopify-connect-label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 20px;
  }
  .onboarding-field {
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    margin-bottom: 10px;
  }

  .onboarding-field .input-addon {
    position: absolute;
    right: 1px;
    bottom: 1px;
    background: none;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 12px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
  }

  .onboarding-field label {
    margin-bottom: 4px;
    margin-left: 4px;
    color: #898989;
    font-weight: 400;
    transform: none !important;
  }

  .shopify-connect-btn {
    background: #36c626;
    border-color: #36c626;
    color: white;
    width: 510px;
    border-radius: 2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 16px;
    margin-bottom: 8px;
    height: 40px;
  }
}
