.ndr-card {
  margin-bottom: 3%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 10px;
  position: relative;
  .vforderid_tag {
    // padding: 3px;
    // background: #1161fb;
    // color: #fff;
    // border-bottom-left-radius: 0;
    // font-size: 12px;
    // margin-right: 8px;
    // border-top-right-radius: 2px;
    // border-bottom-right-radius: 2px;
    width: 225px;
    height: 24px;
    // text-align: center;
    margin-left: -32px;

    .click-id-badge {
      min-width: 200px;
      max-width: 225px;
    }
  }

  .bucket-badge {
    position: absolute;
    top: 8px;
    right: 32px;

    color: #fff;
    padding: 1px 6px;
    font-size: 12px;
    font-weight: 500 !important;
    border-radius: 5px;
    background-color: #3587ff;
  }

  .ant-dropdown-button.ant-btn-group
    > .ant-btn:last-child:not(:first-child):not(:disabled) {
    background: #3587ff;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.15) !important;
  }

  .hidden {
    display: none;
  }
  .ant-card-body {
    padding: 0;
  }
  .blue-text {
    color: #0068ff;
  }
  .mr-3 {
    margin-right: 10px;
  }
  .header-row {
    display: flex;
    align-items: center;
    padding: 24px 32px 0px 32px;
    .header-top-row {
      width: 100%;
      display: flex;
      justify-content: space-between;

      p {
        color: #fff;
        border-bottom-left-radius: 0;
        font-size: 12px;
        width: 115px;
        text-align: center;
        height: 26px;
        margin-top: 0px;
        border-radius: 2px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      .action_taken {
        border-radius: 2px;
        font-size: 12px;
        font-weight: 500;
        padding-left: 12px;
        padding-right: 12px;
        color: black;
        width: fit-content;
        height: 24px;
        text-align: center;
        letter-spacing: 0.032em;
        align-items: center;
        line-height: 16px;
      }
    }

    // .ndr-action {
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: center;
    //   height: 36px;
    //   .span-button {
    //     color: white;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     height: 36px;
    //     width: 148px;
    //     border-radius: 4px;
    //   }
    // }
  }

  .upper_middle_data {
    display: flex;
    align-items: center;
    padding: 24px 32px 0px 32px;
    .middle_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      letter-spacing: 0.032em;
      font-size: 12px;
      .waybill_number {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-checkbox-wrapper {
          display: flex;
          align-items: flex-end;
          .ant-checkbox-inner {
            background-color: #ededed;
          }
        }
        .ant-checkbox-wrapper-checked {
          .ant-checkbox-inner {
            background-color: #36c626;
          }
        }
        p {
          color: #898989;
          margin-left: 16px;
        }
      }
      .order_no {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .middledata {
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.032em;
        }
        .shipment-date {
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.032em;
        }
      }

      .ndr-action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.032em;
        .span-button {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 128px;
          border-radius: 2px;
          letter-spacing: 0.032em;
          line-height: 16px;
          font-weight: 500;
        }
        .span-button2 {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 128px;
          border-radius: 2px;
          letter-spacing: 0.032em;
          line-height: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .tabs-row-card {
    background: #16273b;
    padding: 0 20px 0 28px;
    display: flex;
    align-items: center;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.032em;
      font-weight: 400;
      li {
        display: inline-block;
        padding: 8px 24px;
        color: white;
        cursor: pointer;
      }
      li.active,
      li:hover {
        background: #244060;
      }
    }
  }
  .tab-container {
    background: #f2f2f2;
    padding: 0 27px 12px 27px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .flex {
    margin-bottom: 5px;
    margin: 0 0px 8px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    font-size: 12px;
    color: #898989;
    .flex-description {
      width: 45%;
    }
    .flex-added-on {
      width: 27%;
    }
    .flex-addedby {
      width: 25%;
    }
  }
  .flex-2 {
    width: 432px;
  }
  .flex-3 {
    width: 304px;
  }
  .flex-4 {
    width: 95px;
  }

  .ant-avatar {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }

  .thead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    padding-left: 16px;
    .flex-description {
      width: 45%;
    }
    .flex-added-on {
      width: 27%;
    }
    .flex-addedby {
      width: 25%;
    }
  }
  .anticon {
    font-size: 17px;
    margin-right: 10px;
  }
  .dropdownmenu4 {
    width: 128px;
    height: 32px;
    background: #1161fb;
    color: #fff;
    position: relative;
    border: none;
    border-radius: 2px;
  }

  .action-taken-drop {
    width: 136px;
    height: 32px;
    background: #229d1b;
    color: #fff;
    position: relative;
    border: 1px solid #229d1b;
    border-radius: 2px;
    padding: 0px 8px;
  }
  .action-taken-drop-btn {
    .right {
      font-size: 8px;
      margin-right: 0px;
    }
  }
}

.drop-menu {
  ul {
    border-radius: 0px;
    li {
      height: 40px;
      width: 176px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 14px;

      i {
        margin-right: 0px !important;
      }

      svg {
        font-size: 16px;
        margin-left: 8px;
        opacity: 0;
        transition: opacity 0.3s linear;
      }
    }

    li:hover {
      font-weight: 500;
      svg {
        transition: opacity 0.4s linear;
        opacity: 1;
      }
    }
  }
}
.expired-tooltip {
  font-size: 12px;
}

.dropdowndropmenustyle {
  width: 180px !important;
  border-radius: 2px !important;
  ul {
    li {
      margin: 0 !important;
      border-bottom: 1px solid #cecece !important;
      text-align: left !important;
      font-size: 12px !important;
    }
  }
}

.dropdowndropmenustyle {
  li:hover {
    background: #f5faff;
    color: #212121;
    border-bottom: 2px solid #b5dafe !important;
  }
}

.span-button2:hover {
  background: #c70c3c !important;
}

// .product-tooltip-icon:hover svg {
//   background: #1268fb;
//   border-radius: 50%;
//   cursor: pointer;
// }
// .product-tooltip-icon:hover svg g text {
//   fill: #fff;
// }
// .product-tooltip-icon:hover svg g > g {
//   stroke: #1268fb;
// }

// .tooltip_icon_svg {
//  svg:hover
// }
