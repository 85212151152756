.free-plan-card-wraper {
  background-color: #fff;
  border: 1px solid #3b3b3b;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 56px;
  margin-left: 8rem;
  margin-right: 8rem;

  #payment-disable {
    background-color: #2bc822 !important;
    border-color: #2bc822 !important;
    span {
      color: #fff;
    }
  }

  .price-card {
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 16px 12px 24px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    flex: 1;

    h1 {
      font-size: 24px;
      color: #4a4be4;
      font-weight: 600;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      span {
        color: #fff;
        font-weight: 400;
      }
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700;
      }
      span {
        color: #898989;
        font-size: 16px !important;
      }
    }

    span {
      font-size: 12px;
      color: #898989;
    }

    button {
      background-color: #fff !important;
      border-radius: 2px;
      border: 1px solid #536be8;
      padding-left: 68px !important;
      padding-right: 68px !important;
      margin-top: 12px;
      width: 100%;

      span {
        font-size: 14px;
        color: #536be8;
        font-weight: 500;
      }
    }
  }

  .right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 24px;
    flex: 2;

    .illustration-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 22px;
      // width: 320px;

      svg {
        display: block;
        min-width: 72px;
      }

      p {
        color: #212121;
        font-size: 12px;
        width: 194px;
        font-family: "Helvetica", "Arial", sans-serif !important;
        line-height: 18px;
        span {
          text-decoration: underline;
        }
      }
    }

    .features-card {
      .features {
        border-radius: 2px;
        display: flex;
        flex-wrap: wrap;
        width: 75%;
        column-gap: 36px;
        row-gap: 15px;
        font-size: 12px;
        color: #212121;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .free-plan-card-wraper {
    .illustration-wraper {
      p {
        width: 100% !important;
      }
    }

    .features {
      width: 100% !important;
    }
  }
}
