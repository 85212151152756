.import-list-product-basic {
  color: black;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-size: 0.75rem;
  .id-copy {
    margin-right: 8px;
    color: rgb(137, 137, 137);
    font-size: 12px;
  }
  b {
    font-weight: 400;
  }
  .selling-price {
    position: relative;
    .abs {
      position: absolute;
      right: 5px;
      top: -1px;
      color: rgb(137, 137, 137);
    }
    .special-cost-product-tooltip {
      position: absolute;
      left: -32px;
      top: -1px;
    }
    a {
      // color: #67c72b;
      // font-size: 0.625rem !important;
    }
  }
  .product-basic-title-label {
    margin-bottom: 10px;
  }
  .product-img-container {
    min-width: 173px !important;
    max-width: 173px !important;
    img {
      border: 2px solid #f5f5f5 !important;
      min-width: 173px;
      min-height: 173px;
      max-width: 173px;
      max-height: 173px;
    }
    // .product-img-container {
    //     width: 170px;
    //     img {
    //         border: 2px solid #f5f5f5 !important;
    //         max-width: 173px;
    //         min-height: 173px;
    //     }
    //     a {
    //         color: #ff005e;
    //     }
    // }
  }
  .product-supplier-link {
    .external-link-icon {
      display: none;
      // stroke: #5E73FF;
      fill: #5e73ff;
    }
  }
  .product-supplier-link:hover {
    .external-link-icon {
      display: inline-block;
    }
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }
  .ant-select-selection {
    border: none;
    height: 24px;
    min-height: 24px;
    background-color: #f5f5f5;
    border-radius: 0px;
    .ant-select-selection__rendered {
      line-height: 24px;
    }
    ul > li,
    .ant-select-selection__rendered > ul > li {
      height: 22px;
      margin-top: 1px;
      line-height: 18px;
    }
    .ant-select-selection__choice__content {
      font-size: 0.75rem;
      line-height: 20px;
    }
    .ant-select-selection__rendered {
      margin-left: 1px;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #898989;
      font-size: 12px;
    }
  }
  .next-img {
    right: 0;
  }
  .prev-img {
    left: 0;
  }
  .product-img-container button {
    position: absolute;
    top: 0%;
    height: 100%;
    background: #212121;
    cursor: pointer;
    display: none;
    border: none;
    opacity: 0.4;
    z-index: 99;
  }
  .product-img-container:hover button {
    display: block;
  }
  .rejected {
    outline: none !important;
    border: none !important;
  }
  .rejected:hover {
    outline: none;
    border: none;
  }
}

.collection-select,
.tag-select,
.type-select {
  font-size: 0.75rem;
}

.req-latest-price-import {
  text-decoration: underline !important;
  color: #1268fb;
  cursor: pointer;
}

.badge[contenteditable]:empty::before {
  content: "Make a new tag";
  display: inline-block;
}

.editable-text {
  span[contenteditable]:empty::before {
    content: "Enter product name" !important;
    display: inline-block !important;
    color: #898989;
  }
}

.special-cost-product-tooltip:hover svg.product-tooltip-icon {
  background: #1268fb;
  border-radius: 50%;
  cursor: pointer;
}

.special-cost-product-tooltip:hover svg.product-tooltip-icon g text {
  fill: #fff;
}

.special-cost-product-tooltip:hover svg.product-tooltip-icon g > g {
  stroke: #1268fb;
}

.ff3 {
  padding: 0 !important;
}
