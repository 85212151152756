.cancel-modal234 {
  .reason-container {
    background-color: #f5f5f5;
    padding: 12px 8px;
    font-size: 14px;
    color: #212121;
    font-weight: 500;
    span {
      color: #898989;
      font-weight: 400;
    }
  }
  .by {
    margin-top: 6px;
    text-align: right;
    font-size: 12px;
    color: #212121;
    font-weight: 500;
    span {
      color: #898989;
      font-weight: 400;
    }
  }
}
