.categories-bar {
  @import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

  background: white;
  // border-top: 1px solid #d0d0d0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  .categories-bar-content {
    width: 1200px;
    margin: auto;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.032em;
    color: #212121;
    a {
      color: #212121;
    }
    .filters-list {
      list-style-type: none;
      padding: 0;
      height: 56px;
      margin: 0;
      li:first-child {
        width: 240px;
      }
      li {
        display: inline-block;
        position: relative;
        margin-right: 16px;
        padding: 16px 8px;
        display: inline-block;
        cursor: pointer;
        span {
          color: #212121 !important;
        }
        ul.dropdown,
        ul.dropdown-right {
          background: white;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          transition: all 0.5s ease;
          left: 0;
          display: none;
          z-index: 2;
          padding: 0px 0;
          min-width: 300px;
          border: 1px solid #f1f1f2;
          z-index: 999;
          li {
            width: 100%;
            padding: 11px 24px;
            line-height: 16px;
            margin: 0;
            letter-spacing: 0.032em;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            :hover {
              // border-top: 1px solid #f1f1f2;
              border-bottom: 1px solid #f1f1f2;
              background-color: #f5faff;
            }
          }
        }
        ul.dropdown-right {
          left: 100%;
          top: 0;
        }
      }
      li:hover>ul.dropdown, // li:focus-within > ul.dropdown,
              li ul.dropdown:hover {
        visibility: visible;
        opacity: 1;
        display: block;
      }
    }
  }
  .hov {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      // display: block;
      vertical-align: middle;
      margin-right: 8px;
      svg {
        font-size: 16px;
      }
    }

    span {
      // display: block;
    }

    .beta {
      text-transform: uppercase;
      display: inline-block;
      font-family: "Archivo Black", sans-serif !important;
      position: relative;
      right: 0px;
      top: -9px;
      font-size: 10px;
    }
  }

  .hov {
    // border-bottom: 2px solid transparent;
  }
  .hov:hover {
    background-color: rgb(249, 249, 249);
    // border-bottom: 2px solid #1268fb;
  }
  .hovercursor-pointer {
    pointer-events: none;
  }
  .filled-heart-ican {
    margin-right: 6px;
  }

  #active {
    background-color: #f5f5f5;
    box-shadow: inset 0 -1px 0 #0068ff;

    span {
      color: #212121 !important;
      font-weight: 600;
      // text-decoration: underline;
    }
  }
}

.niche-hov.ant-dropdown-open {
  svg {
    transform: rotateX(180deg);
    transition: all 0.2s;
    color: black;
    g {
      fill: #212121;
    }
  }
}

.niche-overlay {
  width: 220px !important;

  ul {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  li {
    padding: 0 !important;

    .ant-checkbox-wrapper-checked {
      span {
        font-weight: 500;
      }
    }
    .ant-checkbox {
      display: none;
    }
  }
  label {
    width: 100% !important;
    padding: 5px 12px !important;
  }
}

@media screen and (min-width: 1360px) {
  .categories-bar {
    .categories-bar-content {
      width: 1277px;
      margin: auto;
    }
  }
}

li:first-of-type svg {
  transition: all 0.4s;
}

.dd {
  cursor: pointer;
}

.dd:hover svg {
  transform: rotateX(180deg);
  transition: all 0.2s;
  color: black;
  g {
    fill: #212121;
  }
}
