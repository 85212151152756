.tab-container {
  background: #f5f5f5;
  padding: 0 24px 24px 24px;
  .notes-tab {
    padding: 16px 8px;
    background: #fff;
    .note {
      background: #f2f2f2;
      margin-bottom: 8px;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      .flex-note {
        width: 45%;
      }
      .flex-date {
        width: 27%;
      }
      .flex-addedby {
        width: 25%;
      }
    }

    .header_upper_data {
      font-size: 12px;
      padding: 0 16px;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      .flex-note {
        width: 45%;
      }
      .flex-date {
        width: 27%;
      }
      .flex-addedby {
        width: 25%;
      }
    }
  }
 .flex-note{
  svg {
    height: 18px;
    width: 18px;
    transform: rotate(29deg);
    position: relative;
    top: 5px;
    margin-right: 3px;
  }
}
}
