.setting-cointainer {
  position: relative;

  .custom-pop-up {
    position: fixed;
    z-index: 9;
    /* width: 200px; */
    background-color: #fff;

    top: 50%;
    left: 58%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 1008px;

    border-radius: 12px;

    .wr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 32px 32px;
    }

    * {
      letter-spacing: 0.032em;
    }

    .left {
      width: 62%;
      border: 4px solid #f5f5f5;
      padding: 24px;
      margin-right: 24px;
      position: relative;

      h1 {
        font-size: 20px;
        margin: 0px;
        font-weight: 600;
      }

      h2 {
        font-size: 14px;
        margin-bottom: 24px;
      }

      h3 {
        font-size: 12px;

        font-weight: 500;
        color: #0068ff;
      }
      h4 {
        font-size: 12px;

        font-weight: 400;
        margin-bottom: 16px;
      }

      .btn-wraper {
        display: flex;

        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 12px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 12px;

          svg {
            height: 24px;
            width: 24px;
            margin-right: 12px;
            color: #fff;
            fill: #fff;
          }
        }

        a:first-of-type {
          svg {
            fill: #898989 !important;
          }
        }
        a:first-of-type:hover {
          svg {
            fill: #0068ff !important;
          }
        }
      }
    }

    .right {
      width: 38%;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        // scale: 1.5;
      }
    }
  }

  .italic-text {
    font-style: italic;
  }
  .heading {
    border-bottom: 1px solid #cecece;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #212121;
    display: flex;
    justify-content: space-between;
  }
  .sub-heading {
    border-bottom: 1px solid #cecece;
    margin-bottom: 32px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #212121;
  }
  .brand_logo {
    margin-top: 32px;
    .logo-heading-cointainer {
      display: flex;
      font-size: 12px;
      margin-bottom: 5px;
      .brand_name_label {
        width: 50%;
      }
    }
    .uploader-cointainer {
      display: flex;
      .brand_logo_uploder {
        display: flex;
        justify-content: space-between;
        width: 50%;
        .brand_logo_ican {
          width: 32%;
          height: 120px;
          .drage-image {
            padding: 24px 16px;
            background-color: #f5f5f5;
            margin-right: 8px;
          }
          .drage-image:hover {
            background-color: #1370fc;
            color: #fff;
          }
          .drage-image:hover svg g path {
            fill: #fff;
          }
          .drage-image svg {
            position: relative;
            left: 33%;
          }
        }
        .images_show {
          width: 65%;
        }
      }
    }
  }
  .title-select {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 5px;
    margin-top: 4px;
  }
  .select-theme {
    margin-bottom: 10px;
  }
  .radio-group-domain {
    margin-bottom: 10px;
  }
  .self-input{
    .title-input {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.032em;
      color: #898989;
      margin-bottom: 5px;
      margin-top: 4px;
    }
   
    .ant-input-group {
      width: 80%;
    }
    .ant-input-group-addon {
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
      border-right: none;
    }
    .ant-input {
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
    }

    .inputdd {
      .ant-input-group-addon {
        border: none;
      }
      // .ant-input {
      //   border: none;
      // }
      .ant-input:hover {
        border: none;
        border-right-width: 0px !important;
      }
    }
    .input {
      width: 80%;
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
    }
    .disable {
      opacity: 0.5;
    }
  }
  .container-mainDomain {
    .title-input {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.032em;
      color: #898989;
      margin-bottom: 5px;
      margin-top: 4px;
    }
    .ant-input-group-wrapper{
      width: 80%;
      .ant-input-group {
        width: 100%;
      }
    }
   
    .ant-input-group-addon {
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
      border-left: 0;
    }
    .ant-input {
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
    }

    .inputdd {
      .ant-input-group-addon {
        border: none;
      }
      // .ant-input {
      //   border: none;
      // }
      .ant-input:hover {
        border: none;
        border-right-width: 0px !important;
      }
    }
    .input {
      width: 80%;
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
    }
    .colour-text {
      margin-left: 12px;
      font-weight: 400;
    }
    .disable {
      opacity: 0.5;
    }
    .verified {
      color: rgb(54, 198, 38);
      font-weight: 500;
      position: relative;
      right: -8px;
      .icon-container {
        position: relative;
        right: -1px;
        top: 1px;
        svg {
          transform: scale(1.2);
          * {
            fill: #ffffff !important;
            stroke: rgb(54, 198, 38) !important;
          }
        }
      }
    }
  }
  .cointainer-input {
    display: flex;

    .container-main {
      width: 50%;
      .title-input-cointainer {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      .title-input {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989;
        margin-bottom: 5px;
        margin-top: 4px;
      }
      .input {
        width: 80%;
        background: #f5f5f5;
        border: 1px solid #212121;
        border-radius: 0px;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 20px;
        font-size: 12px;
        height: 37px;
        padding: 4px 11px;
      }
      .colour-text {
        margin-left: 12px;
        font-weight: 400;
      }
    }
    .container-2 {
      width: 50%;
      .container-main {
        width: 100%;
      }
    }
  }
  .btnpre {
    margin-top: 16px;
    height: 32px;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #fff !important;
    text-shadow: none !important;
    background-color: #0068ff !important;
    box-shadow: 0px 2px 8px #002f7225 !important;
    border: 2px solid #0068ff !important;
    border-radius: 2px !important;
    background-color: #fff !important;
    color: #0068ff !important;
    border: 2px solid #0068ff !important;
    box-shadow: none !important;
    margin-right: 5px;
  }
  .icon-container {
    position: relative;
    right: 4px;
    top: 2px;
    svg {
      * {
        fill: #0068ff;
        stroke: #0068ff;
      }
    }
  }
  .btn {
    margin-top: 16px;
    height: 32px;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #fff !important;
    text-shadow: none !important;
    background-color: #0068ff !important;
    box-shadow: 0px 2px 8px #002f7225 !important;
    border: 2px solid #0068ff !important;
    border-radius: 2px !important;
  }
  .cancel-btn {
    background-color: #fff !important;
    color: #212121 !important;
    border: 2px solid #212121 !important;
    box-shadow: none !important;
    margin-left: 5px;
  }
  .edit-btn {
  }
  .divider {
    border-bottom: 1px solid #cecece;
    margin-bottom: 6px;
    margin-top: 32px;
  }
  .footer-cointainer {
    text-align: right;
  }
  .heading-right {
    position: relative;
    top: -16px;
  }
  input:focus-visible,
  textarea {
    outline: none !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:disabled {
    cursor: not-allowed;
    border: 0px solid black !important;
  }
  textarea:disabled {
    cursor: not-allowed;
    border: 0px solid black !important;
  }
  .domain-cointainer-main {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}
.icon-i-ontracking:hover {
  svg {
    g {
      fill: blue;
    }
    #i_Button_Circle {
      stroke: none;
    }
    #icomponent1 {
      stroke: white;
    }
    #icomponent2 {
      stroke: white;
    }
  }
}

.setting-cointainer.locked::after {
  content: "";
  height: 133%;
  width: 128%;
  position: absolute;
  top: -162px;
  left: -129px;
  backdrop-filter: blur(3px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(20, 20, 20, 0.55);
}

// ribbon
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 9;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #d4c980;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background: linear-gradient(to right, #a47e30, #d4c980);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Poppins", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: #bf953f;
  border-left-color: #bf953f;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
