.transactionFailedModal {
  .transaction-failed-content {
    h1 {
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: bold;
      margin-top: 10px;
      color: #242424;
    }
    p {
      font-size: 16px;
      color: #242424;
      font-weight: 500;
    }
    .anticon {
      font-size: 55px;
    }
  }
  .footer-button {
    margin-top: 30px;
    span {
      display: inline-block;
      vertical-align: middle;
      color: #a3a3a3;
      font-size: 13px;
      margin-right: 15px;
      cursor: pointer;
    }
    .ant-btn {
      padding: 0px 16px;
      text-align: center;
      background: #d75a4a;
      font-size: 18px;
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      height: 40px;
      border-radius: 0px;
      border: none;
      width: 125px;
      span {
        color: white;
        margin: 0;
      }
    }
  }
}
