.delivery-address-component {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  background: #fff;
  padding: 8px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .item {
    width: 32%;
    .label {
      font-size: 12px;
      color: #898898;
      margin-bottom: 8px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
    .value {
      background: #f5f5f5;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      padding: 8px;
      color: #898989;
      
      width: 100%;
    }
    .address-name{
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
      font-weight: 600;
    }
  }
  .width-49{
    width: 49%;
  }
  .width-100{
    width: 100%;
  }
}
