.channel_branding_card {
  .brand-setting-txt {
    border-bottom: 1px solid #cecece;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #212121;
  }
  .brand_input {
    border-radius: 2px;
    width: 44%;
    background: #f5f5f5;
    border: none;
  }
  .brand_input2 {
    border-radius: 2px;
    width: 44%;
    background: #f5f5f5;
    border: 1px solid #212121;
  }

  .brand_input_name {
    border-radius: 2px;
    width: 44%;
    background: #f5f5f5;
    border: none;
    padding: 8px;
    color: #212121;
    letter-spacing: 0.032em;
    font-size: 12px;
  }
  .brand_name_label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 4px;
  }

  .brand_logo {
    margin-top: 32px;
    .drage-image {
      padding: 24px 16px;
      background-color: #f5f5f5;
      margin-right: 8px;
    }
    .drage-image:hover {
      background-color: #1370fc;
      color: #fff;
    }
    .drage-image:hover svg g path {
      fill: #fff;
    }
    .drage-image svg {
      position: relative;
      left: 33%;
    }

    .brand_logo_uploder {
      display: flex;
      justify-content: space-between;
      width: 48%;
      .brand_logo_ican {
        width: 32%;
        height: 120px;
      }
      .images_show {
        width: 65%;
      }
    }
  }

  .bottom_data {
    margin-top: 32px;
    .footer_data {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: -56px;
      .change_email {
        width: 48%;
        .save_chnage {
          display: flex;
          justify-content: space-between;
          margin-bottom: 3px;
          font-size: 12px;
          letter-spacing: 0.032em;
          color: #898989;
          margin-top: 56px;
          .change-number-txt {
            color: #fc125e;
            border: none;
            background: none;
            text-decoration: underline !important;
            cursor: pointer;
          }
          .save-number-txt {
            color: #28af21;
            border: none;
            background: none;
            text-decoration: underline !important;
            cursor: pointer;
          }
        }
      }
      .change_phone_number {
        width: 48%;
        .save_chnage {
          display: flex;
          justify-content: space-between;
          margin-bottom: 3px;
          font-size: 12px;
          letter-spacing: 0.032em;
          color: #898989;
          margin-top: 56px;
          .change-number-txt {
            color: #fc125e;
            border: none;
            background: none;
            text-decoration: underline !important;
            cursor: pointer;
          }
          .save-number-txt {
            color: #28af21;
            border: none;
            background: none;
            text-decoration: underline !important;
            cursor: pointer;
          }
        }
      }
      .input-number-data {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .ind-91 {
          width: 20%;
          background: #f5f5f5;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 12px;
          .ant-select-selection {
            background: none;
            border: none;
            font-size: 12px;
            display: flex;
            justify-content: center;
            border-radius: 2px;
            color: #212121;
          }
          .ant-select-selection__rendered {
            line-height: 23px !important;
          }
          .ant-select-arrow {
            margin-top: -7px !important;
          }
        }

        .input-email-txt {
          width: 100%;
          input {
            background: #f5f5f5;
            border: none;
            border-radius: 0px;
            color: #212121;
            letter-spacing: 0.032em;
            line-height: 20px;
            font-size: 12px;
          }
        }

        .input-email-txt2 {
          width: 100%;
          input {
            background: #f5f5f5;
            border: 1px solid #212121;
            border-radius: 0px;
            color: #212121;
            letter-spacing: 0.032em;
            line-height: 20px;
            font-size: 12px;
            height: 37px;
          }
        }

        .input-number-txt {
          width: 78%;
          input {
            background: #f5f5f5;
            border: none;
            border-radius: 0px;
            color: #212121;
            letter-spacing: 0.032em;
            line-height: 20px;
            font-size: 12px;
          }
        }

        .input-number-txt2 {
          width: 100%;
          input {
            background: #f5f5f5;
            border: 1px solid #212121;
            border-radius: 0px;
            color: #212121;
            letter-spacing: 0.032em;
            line-height: 20px;
            font-size: 12px;
            margin-left: 8px;
          }
        }
      }

      .disable-phonenumber {
        background: #f5f5f5;
        font-size: 12px;
        height: 35px;
        display: flex;
        align-items: center;
        letter-spacing: 0.032em;
        color: #212121;
        padding-left: 16px;
      }

      .verify-email {
        background: transparent;
        position: absolute;
        right: 58%;
        border: none;
        font-size: 12px;
        box-shadow: none;
        letter-spacing: 0.032em;
        cursor: pointer;
        margin-top: 0px;
        z-index: 99;
        color: #92bcf9;
        text-decoration: underline !important;
        pointer-events: none;
      }
      .verify-email2 {
        background: transparent;
        position: absolute;
        right: 58%;
        border: none;
        font-size: 12px;
        box-shadow: none;
        letter-spacing: 0.032em;
        cursor: pointer;
        margin-top: 0px;
        z-index: 99;
        color: #0063f2;
        text-decoration: underline !important;
      }

      .verify-phone {
        background: transparent;
        position: absolute;
        right: 10%;
        border: none;
        font-size: 12px;
        box-shadow: none;
        letter-spacing: 0.032em;
        cursor: pointer;
        margin-top: 0px;
        z-index: 99;
        color: #92bcf9;
        text-decoration: underline !important;
        pointer-events: none;
      }
      .verify-phone2 {
        background: transparent;
        position: absolute;
        right: 10%;
        border: none;
        font-size: 12px;
        box-shadow: none;
        letter-spacing: 0.032em;
        cursor: pointer;
        margin-top: 0px;
        z-index: 99;
        color: #0063f2;
        text-decoration: underline !important;
      }

      .cancel-verify-email {
        background: transparent;
        position: absolute;
        right: 52%;
        border: none;
        font-size: 12px;
        box-shadow: none;
        letter-spacing: 0.032em;
        cursor: pointer;
        margin-top: 0px;
        z-index: 99;
        color: #898989;
        text-decoration: underline !important;
      }
      .cancel-verify-Phone {
        background: transparent;
        position: absolute;
        right: 4%;
        border: none;
        font-size: 12px;
        box-shadow: none;
        letter-spacing: 0.032em;
        cursor: pointer;
        margin-top: 0px;
        z-index: 99;
        color: #898989;
        text-decoration: underline !important;
      }
    }
    .support_url {
      margin-top: -40px;
    }
    .already-verify-email {
      background: transparent;
      position: absolute;
      right: 52%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #28af21;
      text-decoration: underline !important;
      pointer-events: none;
      .save-ican {
        margin-right: 3px;
        svg {
          path {
            fill: #28af21;
          }
        }
      }
    }

    .already-verify-phone {
      background: transparent;
      position: absolute;
      right: 5%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #28af21;
      text-decoration: underline !important;
      pointer-events: none;
      .save-ican {
        margin-right: 3px;
        svg {
          path {
            fill: #28af21;
          }
        }
      }
    }
  }

  .brand_Edit {
    width: 168px;
    margin-top: 16px;
  }
  .cancel_btn {
    width: 168px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #212121;
  }
  .brand_save {
    background: #2bc822;
    width: 168px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
  }
}
.edit-ican {
  margin-right: 3px !important;
  .anticon {
    position: relative;
    top: 1px;
  }
}
.cancel-url {
  color: #898989;
  border: none;
  background: none;
  text-decoration: underline !important;
  cursor: pointer;
}

.support-email-phone-modal {
  .ant-modal-content {
    .ant-modal-close {
      display: none !important;
    }
    .support-phone-number-txt {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0.032em;
      line-height: 22px;
      margin-bottom: 0px;
      color: #fc125e;
    }
    .number-verify-txt {
      font-size: 12px;
      color: #4b4b4b;
      line-height: 22px;
      letter-spacing: 0.032em;
      text-align: center;
    }
    .ant-modal-header {
      border-bottom: none !important;
      padding: 32px 24px !important;
    }
    .ant-modal-footer {
      border-top: none !important;
    }
  }
  .otp-verify {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    .otp-number {
      width: 15%;
      border: none;
      background: #f5f5f5;
      height: 32px;
      text-align: center;
    }
  }
  .otp-txt {
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 5px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
  }
  .resend-code {
    float: right;
    margin-top: -16px;
    font-size: 12px;
    letter-spacing: 0.032em;
    .resend-code-txt {
      color: #3273cc;
      text-decoration: underline !important;
      margin-right: 4px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    .resend-code-txt2 {
      color: #98b9e5;
      text-decoration: underline !important;
      margin-right: -3px;
      background: transparent;
      border: none;
      pointer-events: none;
    }
  }
  .header-support-txt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    font-size: 12px;
    letter-spacing: 0.032em;
    margin-top: -24px;
    color: #898989;
    .change-number-txt {
      color: #fc125e;
      border: none;
      background: none;
      text-decoration: underline !important;
      cursor: pointer;
    }
    .save-number-txt {
      color: #28af21;
      border: none;
      background: none;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }

  .save-ican {
    margin-right: 3px;
    svg {
      path {
        fill: #28af21;
      }
    }
  }
  .input-number-data {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .ind-91 {
      width: 20%;
      background: #f5f5f5;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 12px;
      .ant-select-selection {
        background: none;
        border: none;
        font-size: 12px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        color: #212121;
      }
      .ant-select-selection__rendered {
        line-height: 23px !important;
      }
      .ant-select-arrow {
        margin-top: -7px !important;
      }
    }
    .input-email-txt {
      width: 100%;
      input {
        background: #f5f5f5;
        border: none;
        border-radius: 0px;
      }
    }

    .input-email-txt2 {
      width: 100%;
      input {
        background: #f5f5f5;
        border: 1px solid #212121;
        border-radius: 0px;
        height: 37px;
      }
    }

    .input-number-txt {
      width: 78%;
      input {
        background: #f5f5f5;
        border: none;
        border-radius: 0px;
      }
    }

    .input-number-txt2 {
      width: 100%;
      input {
        background: #f5f5f5;
        border: 1px solid #212121;
        border-radius: 0px;
        margin-left: 8px;
      }
    }
  }

  .verify-continue-btn {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #2bc822 !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    margin-top: 16px;
  }
  .verify-continue-btn2 {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #94e38f !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    pointer-events: none;
  }
  .cancel-btn {
    width: 408px;
    margin-top: 8px;
    border: 1px solid #898989;
    box-shadow: none;
    // background: #2bc822;
    border-radius: 2px;
    color: #898989;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
  }
  .otp-number-txt {
    font-size: 12px;
    font-style: italic;
    color: #898989;
    margin-top: 4px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .ant-input {
    font-size: 12px !important;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .disable-phonenumber {
    background: #f5f5f5;
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.032em;
    color: #212121;
    padding-left: 16px;
  }
}
