.view-content {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
      padding: 16px 60px;
      h1 {
        text-align: center;
        font-size: 28px;
        color: #01002b;
        font-weight: 500;
        margin-bottom: 0px;
        line-height: 42px;
      }
    }
    .ant-modal-body {
      padding: 0px 24px 14px 24px;
      height: auto;
      p {
        font-size: 14px;
        color: #484848;
        margin-bottom: 0px;
        border: 1px solid #eeeeee;
        padding: 10px;
        background: #f9f9f9;
        border-radius: 6px;
      }
    }
    .ant-btn {
      font-size: 12px;
      font-weight: 600;

      border-radius: 2px;
    }
  }
}
