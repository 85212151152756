.view-product-bundle-modal {
  .ant-modal-content {
    border-radius: 2px;
    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 24px;
    font-size: 12px;
  }

  .bundel-variant-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
    padding-bottom: 8px;
    .variant-detail-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      width: 225px;
    }
    .specifications-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      width: 130px;
    }
    .quantity-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      width: 56px;
    }
  }

  .ant-modal-header {
    padding: 32px 0px;
    border-bottom: 1px solid #cecece;
    display: flex;
    margin: 0 auto;
    width: 512px;
  }
  .ant-modal-footer {
    padding: 8px 0px;
    text-align: right;
    border-top: 1px solid #cecece;
    width: 512px;
    margin: 0 auto;
  }
  .ant-modal-title {
    color: #212121;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-bottom: -16px;
  }
  .bundle-variant-bottom-data {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px;
    background: #f5f5f5;
    margin-bottom: 16px;
    width: 512px;
    margin: 8px auto;

    align-items: center;
    .variant-detail-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      width: 225px;
      display: flex;
      align-items: center;
      img {
        border: 1.5px solid #b2b2b2;
        margin-right: 8px;
        padding: 2px;
        width: 56px;
        height: 56px;
        object-fit: cover;
      }
    }
    .specifications-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
      width: 130px;
    }
    .quantity-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
      width: 56px;
    }
  }

  .bundle-variant-bottom-data:first-of-type {
    margin-top: 0px !important;
  }

  .bundle-cancel-modal {
    border: 1px solid #898989;
    width: 144px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
    color: #898989;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .bundleitem-vfsku {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }

  .side p a:hover {
    text-decoration: underline !important;
  }
  .not-available {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 59px;
  }
  .bundle-bottom-variant-data {
    max-height: 232px;
    overflow: auto;
  }
  .no-bundle-data {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
}
