.packaging-card {
  * {
    letter-spacing: 0.032em;
  }
  background: white;
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    padding: 16px;
    padding-right: 24px;
    padding-bottom: 0px;
    display: flex;

    .product-img {
      height: 164px;
      position: relative;
      overflow: hidden;
      border-radius: 2px;
      margin-right: 24px;
      margin-bottom: 0px;
      img {
        width: 164px;
        height: 164px;
        object-fit: cover;
        border-radius: 2px;
        border: 2px solid #f5f5f5;
        transition: all 0.5s ease;
      }
      .prev-img,
      .next-img {
        position: absolute;
        transition: all 0.5s ease;
        height: 100%;
        width: 32px;
        background: rgba(33, 33, 33, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        display: none;
      }
      .prev-img {
        top: 0;
        left: 0;
      }
      .next-img {
        top: 0;
        right: 0;
      }
    }
    .product-img:hover {
      img {
        transition: all 0.5s ease;
        transform: scale(1.05);
      }
      .prev-img,
      .next-img {
        transition: all 0.5s ease;
        opacity: 1;
        transform: scale(1.05);
      }
    }
    .product-img:hover {
      .prev-img,
      .next-img {
        display: block;
      }
    }
    .details {
      flex: 1;
      // a:hover {
      //   text-decoration: underline !important;
      // }

      .packaging-name {
        color: #1268fb;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .price-title {
        .packaging-with-tooltip {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
              color: #898989;
              font-size: 12px;
            }
          }
          margin-bottom: 4px;
        }
      }

      .cost-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        background-color: #f5f5f5;
        height: 32px;
        margin-bottom: 6px;

        .price-range {
          color: #1c6513;
          font-weight: 500;
          font-size: 12px;
        }

        .gst {
          color: #212121;
          font-style: italic;
          font-size: 12px;
        }
      }

      .last-updated-pricing {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
          font-size: 10px;
          color: #898989;
          margin-right: 4px;
        }

        span {
          font-size: 10px;
          color: #212121;
        }
      }
    }
  }

  .view-packaging-details {
    height: 48px;
    background-color: #f5faff;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: center;

    p {
      color: #0068ff;
      font-weight: 600;
      font-size: 12px;
      margin-right: 8px;
    }
  }

  //   request
  .request-latest-price.disabled {
    color: #898989;
    svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .request-latest-price {
    display: flex;
    color: #212121 !important;
    font-size: 10px !important;
    svg {
      color: #212121;
      font-size: 14px;
      margin-right: 5px;
      transition: all 0.3s;
      transform: rotate(0deg);
    }
  }

  .request-latest-price:hover {
    display: flex;
    color: #212121 !important;
    transition: all 0.3s;

    svg {
      transform: rotate(220deg) !important;

      path {
        fill: #0058d6 !important;
      }
    }
  }

  .request-latest-price.disabled {
    color: #898989 !important;
    font-size: 10px !important;
    transition: none;
    svg {
      font-size: 14px !important;
      margin-right: 5px !important;
      path {
        fill: #898989 !important;
      }
    }
  }

  .request-latest-price.disabled:hover {
    color: #898989;
    transform: none;

    svg {
      transform: rotate(0deg) !important;
      path {
        fill: #898989 !important;
      }
    }
  }

  //   animation
  .hover-underline-animation {
    position: relative;
    color: #0087ca;

    i {
      transition: all 0.5s;
    }
  }
  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 25.9%;
    transform: scaleX(0);
    height: 1px;
    left: 7px;
    top: 32px;
    margin-left: 33.2%;
    background-color: #2986ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: top left;
  }

  .hover-underline-animation:hover {
    i {
      transition: all 0.5s;
      transform: translateX(8px);
    }
  }

  .parent {
    height: 32px;
    width: 100%;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 16px;
      color: #1c6513;
      font-weight: medium;
      margin: 0;
      padding: 0;
      line-height: 0;
      margin-right: 16px;
    }
  }
}
