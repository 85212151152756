.add-to-store {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin-top: 2px;
  cursor: pointer;
  p {
    a {
      height: 100%;
      svg {
        margin-right: 4px;
      }
    }
  }
  .add-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fd9526;
    height: 100%;
    cursor: pointer;
  }
  .add-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 67%;
    transform: scaleX(0);
    height: 1px;
    bottom: 4px;
    left: 20px;
    background-color: #dd8222;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .add-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.add-store-hover-underline-animation:hover {
  a {
    span {
      // text-decoration: underline;
      color: #dd8222 !important;
    }
  }
  svg:first-of-type {
    path {
      fill: #dd8222 !important;
    }
  }
  svg:last-of-type {
    path {
      stroke: #dd8222 !important;
    }
  }
}
