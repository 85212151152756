.left-card-container1 {
  width: 42%;
  background-color: red;
  border-radius: 8px 0 0 8px;
  .Tabs-container {
    height: 100%;
  }
  .ant-carousel {
    background-color: green;
    height: 100%;
    border-radius: 8px 0 0 8px;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
        }
        .slick-slide:first-child {
          height: 100%;
        }
      }
    }
    ul {
      bottom: 40px;
      li {
        button {
            border-radius: 100%;
            border: 0.5px solid black;   
            height: 15px;
            background-color: #FFFFFF;
            width: 15px;
            opacity: 1;
        }
        button:hover{
            opacity: 1;
        }
      }
      li.slick-active button {
        border-radius: 100%;
        border: 0.5px solid black;
        height: 15px;
        background-color: black;
        width: 15px;
      }
    }
  }
  .ant-carousel .slick-slide {
    border-radius: 8px 0 0 8px;
    text-align: center;

    background-color: yellow;
    overflow: hidden;
  }
  .ant-carousel div.slick-slide > div:first-child {
    height: 100%;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
}
