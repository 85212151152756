.product-title {
  .clamp1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  a {
    color: #1268fb !important;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .f12.mb4.hover-underline-animation {
    font-size: 14px !important;
  }
}
