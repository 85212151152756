.data-sorter {
  display: flex;
  align-items: center;

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 12px;

    color: #6e6e6e;
  }

  .ant-select-selection {
    background-color: transparent;
    border: none;
    border-radius: 0px;
  }

  .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em;
  }

  a {
    font-size: 12px !important;
    color: #1268fb !important;
    letter-spacing: 0.032em !important;
    font-weight: 500 !important;
  }
  .anticon {
    font-size: 16px;
    margin-left: 4px;
    transition: all 0.5s;
  }
  .arrow-down {
    transform: rotateX(0deg);
  }
  .arrow-up {
    transform: rotateX(180deg);
    g {
      fill: #898989;
    }
  }
}

.sorter-dropdwon-item {
  .active {
    font-weight: 500;
    color: #000000a6;
  }
}
