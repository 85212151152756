.pr-viewvf {
  padding: 0;
  display: flex;
  a,
  button {
    flex: 1;
    text-align: center;
    padding: 16px 0;
    font-weight: 500;
  }
  a {
    background: #f5faff;
    color: #0068ff;
  }
  button {
    background: #36c626;
    color: white;
    border: none;
  }
  .add-to-import-list-button {
    transition-duration: 0.5s;
    flex: unset;
    padding: 0;
    width: 48px;
    height: 48px;
    .text {
      display: none;
      // transition-delay: 0.5s;
      overflow: hidden;
      text-overflow: hidden;
      font-size: 12px;
      white-space: nowrap;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .add-to-import-list-button:hover {
    width: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .text {
      display: block;
      // transition-delay: 0.5s;
    }
    .icon {
      // display: none;
      padding-left: 24px;
      padding-right: 8px;
    }
  }
  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
  }
  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 28.9%;
    transform: scaleX(0);
    height: 1px;
    left: -3px;
    top: 32px;
    margin-left: 33.2%;
    background-color: #2986ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: top left;
  }
  .ml8:nth-of-type(1) {
    transition: all linear 0.4s;
    transform: translate(0px);
  }
  .heartblank-ican {
    width: 48px;
    height: 48px;
    .heartblank-ican-btn {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      width: 24px;
      .anticon {
        margin-left: 24px;
      }

      .heartblank.anticon svg {
        height: 48px;
        width: 24px;
        margin-top: 6px;
      }

      .heartfill.anticon svg {
        height: 48px;
        width: 48px;
        margin-top: 6px;
      }
    }
    .wishlisttabheart {
      height: 42px;
    }
  }

  .heartfill-ican {
    width: 48px;
    height: 48px;
    .heartfill-ican-btn {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 24px;
      .anticon {
        margin-left: 24px;
      }
      .heartfill.anticon svg {
        height: 48px;
        width: 48px;
        margin-top: 6px;
      }
    }
  }
}

.pr-viewvf:hover {
  .ml8:nth-of-type(1) {
    transition: all linear 0.4s;
    transform: translate(4px);
  }
}
