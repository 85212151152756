.open-rfq-wraper {
  .external-case {
    display: flex;
    flex-direction: column;
    .progress-bar {
      border: 1px solid #898989;
      width: 160px;
      padding: 5px;
      border-radius: 2px;
      background-color: #d4cece82;
      color: #898989;
      align-self: center;
    }
  }
  .no-rfq {
    margin-top: 52px;
    background-color: #fff;
    padding: 40px;
    padding-right: 80px;
    padding-bottom: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 150px;
    .nav-rfq-btn {
      border-color: green;
      width: 160px;
      box-shadow: 0px 2px 3px #00000022;
      font-weight: 500;
      background-color: green;
      height: auto;
      padding: 5px;
    }
    .ant-btn-primary[disabled] {
      color: #898989;
      background-color: #d4cece82;
      font-weight: 400;
      border-color: transparent;
    }
    .rfq-left-dis {
      line-height: 1.32;
      font-size: 12px;
      color: #fd104d;
      position: relative;
      top: 5px;
      left: 25px;
    }
    * {
      margin: 0;
      letter-spacing: 0.032em;
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 14px;
    }
    h2 {
      font-size: 14px;
      margin-bottom: 14px;
      font-weight: 500;
    }

    h3 {
      font-size: 14px;
      margin-bottom: 24px;
      font-weight: 400;
    }
  }

  .rfq-table {
    margin-top: 52px;

    .variants {
      margin-top: 1px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      p::after {
        content: ", ";
        margin-right: 4px;
      }
      p:last-of-type::after {
        content: "";
      }
    }

    .valid {
      display: flex;
      flex-direction: column;

      span {
        color: #898989;
      }
    }
    .reject-reason {
      margin-top: 6px;
      .btn {
        font-size: 12px;
        color: #898989;
        .icon-container {
          position: relative;
          top: 2px;
          left: -4px;
        }
        .text {
          position: relative;
          top: -6px;
        }
      }
      .btn:hover {
        border-color: #212121 !important;
      }
      * {
        fill: #212121;
      }
    }
    .table-img-details {
      display: flex;
      align-items: center;

      .text-wraper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
      }

      p {
        line-height: 13px;
        display: block;
        font-weight: 400 !important;
      }

      .name-link {
        line-height: 13px;
        display: flex;
        align-items: center;
        font-weight: 400 !important;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // white-space: normal;

        svg {
          margin-left: 4px;
          opacity: 0;
        }
      }

      .name-link:hover {
        color: #0068ff;
        svg {
          opacity: 1;
        }
      }

      img {
        height: 52px;
        width: 52px;
        object-fit: contain;
        margin-right: 16px;
        border: 1px solid #c7c7c7;
        flex: none;
      }

      .vf {
        display: inline-flex !important;
        color: #898989;
        margin-top: 4px;
        align-items: center;
        svg {
          margin-left: 4px;
        }
      }

      .vf:hover {
        color: #0068ff;
      }
    }
    .link-modal {
      cursor: pointer;
    }
    .action-btn1 {
      display: flex;

      gap: 8px;
      flex-direction: column;
      .takeAction {
        svg {
          fill: white;
        }
      }
      .re-open-btn {
        .icon-container {
          svg {
            fill: #fff;
            position: relative;
            left: -6px;
            top: -1px;
          }
        }
        .text {
          position: relative;
          top: -9px;
        }
        span {
          color: #ffff !important;
        }
      }
      .ant-btn {
        height: 24px;
        border-radius: 2px;
        font-size: 12px;

        border: 1px solid #898989;

        span {
          color: #898989;
        }
      }
      .ant-btn.ant-btn-primary {
        border: 1px solid #1370fc;

        background-color: #1370fc;

        span {
          color: #fff !important;
        }
      }
      .media-btn {
        display: flex;
        background-color: #fff;
        border: 1px solid #d21b5c;
        transition: all 0.2s ease-in;
        justify-content: center;
        svg {
          height: 18px;
          width: 18px;
          fill: #d21b5c;
          align-self: center;
          transition: all 0.2s ease-in;
        }
        span {
          align-self: center;
          color: #d21b5c;
          padding-left: 2px;
          transition: all 0.2s ease-in;
        }
      }
      .media-btn:hover {
        background-color: #d21b5c;
        border: 1px solid transparent;
        transition: all 0.2s;
        svg {
          fill: #fff;
          transition: all 0.2s ease-in;
        }
        span {
          color: #fff;
          transition: all 0.2s ease-in;
        }
      }
      .df {
        align-self: baseline;

        .image-continer {
          position: relative;
          top: 5px;
          svg {
            width: 20px;
            margin-right: -2px;
            height: 20px;
            fill: #d21b5c;
          }
        }
      }

      .underline-animatio {
        display: inline-block;
        position: relative;
        color: #d21b5c;
        top: -3px;
      }

      .underline-animatio:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        color: #d21b5c;
        background-color: #d21b5c;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      .underline-animatio:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    .blue-tick-container {
      display: inline-block;
      padding-right: 4px;
      padding-left: 2px;
      position: relative;
      top: 2px;
    }
    .red-cross-container {
      position: relative;
      top: 1px;
      padding-left: 4px;
      padding-right: 4px;
      svg {
        height: 12px;
        width: 12px;
      }
    }
    .action-btn {
      display: flex;

      gap: 8px;
      a {
        color: #fff;
        padding: 0px;
        margin: 0px;
        width: 126px;
        margin: auto;
      }

      .ant-btn {
        height: 24px;
        border-radius: 2px;
        font-size: 12px;

        border: 1px solid #898989;

        span {
          color: #898989;
        }
      }
      .ant-btn.ant-btn-primary {
        border: 1px solid #1370fc;

        background-color: #1370fc;

        span {
          color: #fff !important;
        }
      }
    }

    .ant-table-thead {
      th {
        padding: 12px !important;
        background-color: #16273b !important;
        color: #fff !important;
        font-size: 12px;
      }
    }

    .ant-table-tbody {
      * {
        font-weight: 500;
        color: #212121;
      }
      td {
        padding: 12px !important;
        background-color: #fff !important;
        color: #212121 !important;
        font-size: 12px;
      }
      tr:nth-child(even) {
        td {
          background-color: #f5f5f5 !important;
        }
      }
    }
  }
}

.show-data-from {
  font-size: 12px;
  margin-left: 8px;
}

.media-modal {
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;

    img {
      object-fit: contain;
    }
  }
}

#test {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rtrty {
  color: #fff !important;
  * {
    color: #ffffff !important;
  }
}
.view-btn-rfq {
  color: #fff !important;
  * {
    color: #ffffff !important;
  }
}
.view-btn-rfq:hover {
  background-color: rgb(5, 72, 5) !important;
  border: 2px solid rgb(5, 72, 5) !important;
  color: #fff !important;
}
