.next-steps-card {
  margin-bottom: 2rem;

  .notification-app-reinstall {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h3 {
      font-size: 16px;
      letter-spacing: 0.032em !important;
    }

    .ic-wr {
      margin-left: 16px;
      letter-spacing: 0.032em !important;
    }

    height: 88px;
    border: 2px solid #ff005f;
    // height: 85px;
    padding: 1rem;
    background: white;

    font-size: var(--extra-small-font-size);
    left: 0px;
    top: 0px;

    p {
      font-size: 14px;

      a {
        padding: 0 !important;
        margin: 0 4px !important;
        text-decoration: underline !important;
      }
    }
  }

  .quick-start {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    .left-quick-start {
      display: flex;
      align-items: flex-end;
      p {
        font-size: var(--medium-font-size);
        font-weight: 400;
        margin-bottom: 1rem;
      }
    }
    .right-quick-start {
      // display: flex;
      margin-bottom: -21px;
      margin-right: 15px;
      img {
        max-width: 220px;
      }

      .quick-start-jenny {
        h3 {
          font-size: var(--extra-small-font-size);
        }
        width: 226px;
        height: 88px;
        border: 2px solid #ff005f;
        // height: 85px;
        padding: 0.75rem;
        background: white;
        position: absolute;
        font-size: var(--extra-small-font-size);
        right: 185px;
        top: 20px;
      }

      h3 {
        font-size: var(--small-font-size);
        font-weight: 600;
        color: #222222;
        margin-bottom: 3px;
      }
      // p {
      //     font-size: var(--small-font-size);
      //     color: #222222;
      // }
    }
  }
  .ant-collapse {
    background: #f0f2f5;
    position: relative;
    z-index: 3;
    .ant-collapse-content-box {
      padding: 0px;
      padding-top: 0px !important;
    }
    .ant-collapse-item {
      background: white;
      border-radius: 4px;
      margin-bottom: 0.5rem;
      border: 0px;
      overflow: hidden;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      .ant-collapse-content {
        background-color: white;
      }
      .ant-collapse-header {
        padding: 0 4rem 0 2rem;
        height: 4rem;
        color: #222222;
        font-size: var(--medium-font-size);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .anticon {
          font-size: 22px;
          margin-right: 10px;
        }
        .ant-collapse-extra {
          display: flex;
          align-items: center;
          div {
            padding: 0px;
            margin: 0px;
          }
          span {
            font-size: var(--extra-medium-font-size);
          }
          span:last-child {
            font-size: var(--extra-medium-small-font-size) !important;
          }
        }
        .step-complete {
          color: #3794f4;
          font-size: var(--small-font-size);
          font-weight: 500;
          margin-right: 0.25rem;
        }
      }
      .panel-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 6.5rem;
        padding: 0rem 4rem 1rem 4rem;
        border-bottom: 2px solid #f8f8f8;
        .left-content {
          width: 60%;
          h3 {
            color: #ff005b;
            font-size: var(--medium-font-size);
            font-weight: 400;
            line-height: 1.5rem;
            margin-bottom: 0px;
          }
          p {
            color: #595959;
            font-size: var(--small-font-size);
            font-weight: 400;
            line-height: 1.25rem;
            margin-bottom: 0px;
          }
        }
        .right-content {
          font-size: var(--small-font-size);
          display: flex;
          align-items: center;
          .ant-btn {
            background: #67d367;
            padding: 8px;
            color: white;
            font-size: var(--small-font-size);
            font-weight: 500;
            line-height: 12px;
            border: none;
            letter-spacing: 0.032em;
          }
          .anticon {
            font-size: 1rem;
            margin-right: 0.25rem;
          }
        }

        .india-txt {
          position: relative;
          right: 74%;
        }
        .global-txt {
          position: relative;
          right: 52%;
        }
      }
    }
    .ant-collapse-item-active {
      background: #0d192a;
      .ant-collapse-header {
        color: white;
        .anticon {
          font-size: 22px;
          margin-right: 10px;
          path {
            fill: white;
          }
        }
      }
    }
  }
}

.contact-cen {
  width: 430px !important;
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close-x {
    display: none !important;
  }
  .contact-center-modal {
    padding: 32px 32px 20px 32px;
    background-color: #ffffff;
    width: 430px;
    border-radius: 2px;
    box-shadow: 0 3px 10px transparent;
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    h1 {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #212121;
      margin-bottom: 4px;
    }
    h2 {
      color: #898989;
      font-size: 12px;
      text-align: center;
      margin-bottom: 34px;
    }
    .logo {
      height: 122px;
      width: 100%;
      background-color: #f5f5f5;
      border-radius: 2px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        // display: none;
        margin-left: 24px;
      }
      .vc {
        height: 71px;
        width: 67px;
      }
    }
    p {
      margin-bottom: 12px;
      color: #212121;
      a {
        color: #1370fc;
        margin-left: 4px;
        text-decoration: none;
      }
      svg {
        margin-left: 4px;
      }
    }
    button {
      display: block;
      margin: auto;
      height: 32px;
      width: 160px;
      color: #898989;
      font-size: 12px;
      border: 1px solid #898989;
      border-radius: 2px;
      background-color: transparent;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
