.error-card-wraper {
  border-radius: 3px;
  background-color: #fff7f5;
  border: 1px solid #fd4726;
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  .error-message-with-ic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .icon {
      svg {
        font-size: 24px;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
      color: #212121;
      letter-spacing: 0.032em;
    }
  }

  .error-description {
    font-size: 12px;
    color: #212121;
    letter-spacing: 0.032em;
    margin-bottom: 12px;
  }
  a {
    letter-spacing: 0.032em;
    border-radius: 2px;
    background-color: #fd4726;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 8px 18px;
  }
}
