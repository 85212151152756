.ant-drawer {
  z-index: 99999;

  .ant-drawer-header {
    padding-left: 0px !important;
    margin-left: 24px !important;

    .drawer-title {
      font-size: 16px !important;
      font-weight: 500 !important;
    }

    p {
      font-size: 12px !important;
      color: #898989 !important;
      font-weight: 400 !important;
    }
  }

  .ant-drawer-body {
    padding-top: 14px !important;
    margin-bottom: 70px;
  }

  .action-btns-drawer {
    position: absolute;
    border-top: 1px solid #e8e8e8;
    width: 100%;
    background-color: #fff;
    padding: 16px 22px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .ant-btn-primary {
      background-color: #21a819;
      border: 1px solid #21a819;
      font-size: 16px;
    }
  }
}

.place-order-drawer {
  .step-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 8px;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #1370fc;
      position: relative;
    }

    span::before {
      content: "";
      height: 16px;
      width: 2px;
      background-color: #1370fc;
      position: absolute;
      left: -13px;

      transform: translateY(-50%);
      top: 50%;
    }
  }

  .booking-qty {
    button {
      cursor: pointer;
    }
  }

  .new-moq-table {
    border-bottom: 2px solid #f5f5f5;
    // border: 1px solid #26b99a;
    .main-heading {
      background-color: #1a67d9;
      height: 24px;
      > div {
        color: #fff !important;
        font-size: 12px;
      }
    }

    .main-heading,
    .new-moq-table-row {
      cursor: pointer;
      display: flex;

      padding: 12px 0;
      gap: 12px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        color: #212121;
      }

      .select {
        flex: 0.7;
      }

      .type {
        justify-content: flex-start;
      }
    }

    .new-moq-table-row {
      border: 2px solid #fff;
      border-left-color: #f5f5f5;
      border-right-color: #f5f5f5;
    }

    .new-moq-table-row:nth-child(even) {
      background-color: #f5f5f5 !important;
      border: 2px solid #f5f5f5 !important;
    }

    .new-moq-table-row.selected {
      border: 2px solid #20a819 !important;
      background-color: #f4fff4 !important;

      .ant-radio-inner {
        border-color: #20a819;
      }
      .ant-radio-inner::after {
        background-color: #20a819 !important;
      }

      .type {
        color: #20a819;
      }
    }
  }

  .pricing-wraper {
    border: none !important;
    .table-sub-headings {
      background-color: #fff;
      padding: 8px 12px !important;
      gap: 8px;

      .variants {
        flex: 2 !important;
        width: auto !important;
        padding-left: 0px;
      }

      .air-days,
      .booking-qty {
        flex: 1 !important;
        width: auto !important;
      }
    }

    .table-row-data {
      background-color: #fff !important;
      height: auto !important;
      padding: 8px 12px !important;
      gap: 8px;

      .variants-details {
        flex: 2 !important;
        width: auto !important;
        padding-left: 0px;

        .img {
          height: 50px;
          width: 50px;
        }
      }

      .air-days {
        font-size: 12px;
        color: #212121;
        font-weight: 400;
      }

      .air-days,
      .booking-qty-parent-wraper {
        flex: 1 !important;
        width: auto !important;
      }
    }
    .table-row-data:nth-child(even) {
      background-color: #f5f5f5 !important;
    }
  }

  .price-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 10px 25px 10px 12px;
    margin-bottom: 4px;

    p {
      font-size: 14px;
      color: #898989;
    }

    span {
      font-size: 16px;
      font-weight: 600;
      color: #212121;
    }
  }
}
