.escalation-card {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px;
  .header-top-row {
    // width: 100%;
    // display: flex;
    // justify-content: space-between;

    p {
      color: #fff;
      border-bottom-left-radius: 0;
      font-size: 12px;
      width: 115px;
      text-align: center;
      height: 26px;
      margin-top: 0px;
      border-radius: 2px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
    .action_taken {
      border-radius: 2px;
      font-size: 12px;
      font-weight: 500;
      padding-left: 12px;
      padding-right: 12px;
      color: black;
      width: fit-content;
      height: 24px;
      text-align: center;
      letter-spacing: 0.032em;
      align-items: center;
      line-height: 16px;
    }
  }
  .vforderid_tag {
    padding: 5px;
    padding-left: 15px;
    position: absolute;
    left: -10px;
    top: 8px;
    background: #ed7741;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .vforderid_tag:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 100%;
    left: 0px;
    border-bottom: 9px solid #ed7741;
    border-left: 9px solid transparent;
    transform: rotate(270deg);
  }
  .ant-card-body {
    padding: 0px;
    .blue-text {
      color: #1268fb;
      font-weight: 500;
    }
    .header-row {
      display: flex;
      align-items: center;
      padding: 46px 52px 20px 52px;
      h3 {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 400;
      }
      .dispute-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        min-width: 66px;
        .anticon {
          font-size: 17px;
          margin-right: 10px;
        }
      }
      .dispute-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px;
      }
    }
    .circle-ican {
      font-size: 10px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: "white";
      padding: 4px 8px;
      padding-right: 5px;
    }
    .circle-ican-status {
      font-size: 12px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px 10px;
      padding-left: 0;
      font-weight: 500;
    }

    .tabs-row {
      background: #16273b;
      padding: 0 32px;
      display: flex;
      align-items: center;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          padding: 12px 15px;
          color: white;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em;
        }
        li.active,
        li:hover {
          background: #244060;
        }
      }
    }
    .tab-container {
      background: #f5f5f5;
      padding: 0 32px 32px 32px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .dispute-info-tab {
        background: white;
        padding: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .dispute-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          p {
            font-size: 14px;
            font-weight: 500;
            color: black;
            margin-bottom: 0px;
            span {
              color: #5a5a5a;
              font-weight: 400;
            }
          }
        }
        .dispute-reason {
          width: 100%;
          margin-right: 8px;
          h6 {
            font-size: 12px;
            line-height: 16px;
            color: #898989;
            margin-bottom: 4px;
            font-weight: 400;
          }
          p {
            font-size: 12px;
            padding: 8px 16px;
            background: #f5f5f5;
            color: #252525;
          }
        }
      }
      .escalation-log {
        padding: 16px 0px 7px 0px;
      }
      .line-items-tab {
        padding: 16px 13px 7px 13px;
        background: #fff;
        .line-item {
          background: #f2f2f2;
          margin-bottom: 10px;
          padding: 14px 12px;
          display: flex;
          align-items: center;
          border: 1px solid transparent;
        }
        .anticon {
          font-size: 17px;
        }
        .line-item-product-detail {
          display: flex;
          align-items: center;
        }
        .line-item-img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: inline-block;
          background-size: cover !important;
          background-position: center !important;
          border: 1px solid #c8c8c8;
        }
        .line-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          font-size: 12px;
        }
        .line-item-sku {
          font-size: 12px;
          color: #676666;
          font-weight: 400;
          margin: 0;
          margin-top: -2px;
          b {
            color: #898989;
            font-weight: 400;
          }
          .detailcommon {
            color: #000;
            letter-spacing: 0.032em;
            font-size: 12px;
            line-height: 16px;
          }
        }
        .delivered-date {
          margin-bottom: 0px;
          text-decoration: none;
        }
      }
      .media-attachments {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        background-color: #ffffff;
        padding-top: 28px;

        .download-image-data {
          position: absolute;
          bottom: 25px;
          padding: 6px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 93px;
        }
      }
    }

    .audio-container {
      padding: 0 16px;
      p {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin-bottom: 0px;
      }

      .audio-wraper-all {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .audio-wraper {
          margin-right: 24px;
          margin-top: 12px;
        }
      }
    }

    .images-container {
      padding: 0 16px;
      p {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin-bottom: 0px;
      }

      .image-div-wraper {
        border: none !important;
        margin-top: 12px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .img-div {
          height: 98px !important;
          width: 98px !important;
          margin-bottom: 20px;
          margin-right: 24px;
          position: relative;
          img {
            height: 100% !important;
            width: 100% !important;
            padding: 0px !important;
            object-fit: cover;
          }

          .download-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0px;
            right: 8px;
            cursor: pointer;
            i {
              font-size: 24px;
            }
          }

          .eye-icon {
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            position: absolute;
            opacity: 0;
            font-size: 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }

        .img-div:hover {
          .eye-icon {
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            opacity: 1;
          }
        }
      }
    }

    .videos-container {
      padding: 0 16px;

      p {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin-bottom: 12px;
      }

      .video-div-wraper {
        border: none !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .video-div {
          height: 98px !important;
          width: 98px !important;
          border: 1px solid #c9c9c9;
          margin-bottom: 20px;
          margin-right: 24px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .video-icon {
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            position: absolute;
            opacity: 0;
            font-size: 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            opacity: 1;
          }

          .download-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0px;
            right: 8px;
            cursor: pointer;
            i {
              font-size: 24px;
            }
          }
        }
      }

      .video-div:hover {
        .video-icon {
          transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          opacity: 1;
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    .flex-note-log {
      width: 45%;
    }
    .flex-date {
      width: 27%;
    }
    .flex-addedby {
      width: 25%;
    }
  }

  .flex-5 {
    flex: 4;

    .line-item-product-detail {
      width: 100%;
    }
  }
  .flex-6 {
    flex: 3;
  }
  .flex-7 {
    flex: 2;
    font-size: 12px;
  }
  .flex-8 {
    flex: 1;
  }

  .note-logs {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px;
  }

  .notes-logs-txt {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
  }
  .notes-logs-data {
    background: white;
    padding: 12px 32px;
    font-size: 12px;
  }

  .tab-count {
    display: inline-block;
    background: #ff9400;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    margin-left: 12px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
  }
  .upper_data {
    display: flex;
    justify-content: space-between;
  }

  .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 20px;
    color: "#000";
    font-weight: 500;
  }
  .video-data {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
  .shipment-status {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .returned-on {
    margin-left: -16px;
  }
  .delivered-on {
    margin-left: -16px;
  }
  .delivered {
    margin-left: -38px;
  }
  .returned {
    margin-left: -38px;
  }
  .shipment-status {
    margin-left: 24px;
  }
  .header-varaint-status {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    font-size: 12px;
  }
  .header-varaint-status-table {
    margin-bottom: 10px;
    padding: 0 0px;
    color: #898989;
    font-size: 12px;
  }
  .delivered-on-date {
    color: #73d13d;
    margin-bottom: 0;
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: left;
  }
  .top-header {
    display: flex;
    width: 97%;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    .vf {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      p {
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .fields {
    display: flex;
    width: 100%;
    padding: 0 32px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    .field {
      flex: 1;
      margin-right: 24px;
      .field-label {
        font-size: 12px;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px;
      }
      .field-value {
        font-size: 12px;
        padding: 8px 12px;
        background: #f5f5f5;
        color: black;
      }
    }
    .field:last-of-type {
      margin-right: 0;
    }
  }
  .transaction-stats-tabledc {
    table {
      thead {
        height: 40px;
        padding-left: 24px;
        tr {
          th {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.3px;
            background: #5c89e4 !important;
            border-radius: 0 !important;
            color: white !important;
            padding: 10px;
          }

          th:nth-of-type(1) {
            padding-left: 24px;
          }
        }
      }
      tbody {
        background: white;
        tr {
          td {
            font-size: 12px;
            border-bottom-color: #f2f2f2 !important;
            text-transform: capitalize;
            padding: 10px;
            padding-top: 8px;
            padding-bottom: 8px;

            p {
              margin-bottom: 0px;
            }
          }

          td:nth-of-type(1) {
            padding-left: 24px;
          }

          td:nth-of-type(6) {
            color: #212121;
          }

          td:nth-of-type(4) {
            button {
              width: 104px;
              font-size: 12px;
              font-weight: 500;
              background-color: #1370fc;
              color: #fff;
              border-radius: 2px;
              border: none;
              box-shadow: 0px 2px 3px #00000020;
            }
          }

          td:nth-of-type(7) {
            .status {
              padding: 0 7px;
              display: flex;
              align-items: center;
              height: 26px;
              border-radius: 2px;
              width: auto;
              color: #fff;
              font-size: 12px;
              font-weight: 500;
              .circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #fff;
                margin-right: 8px;
              }
            }
          }

          td:nth-of-type(8) {
            .action {
              a {
                display: flex;
                align-items: center;
              }
            }
          }

          td:nth-of-type(9) {
            color: #212121;
          }
          td:nth-of-type(10) {
            color: #212121;
          }

          td:nth-of-type(11) {
            color: #212121;
          }
          td:nth-of-type(12) {
            color: #212121;
          }
          // select geo overlay scss
        }
      }
    }
  }
  .log-heading-cointainer {
    display: flex;

    padding: 16px 16px 0px;

    div {
      font-size: 12px;
      width: 50%;
    }
  }
  .logsnotes-container:last-of-type {
    border-bottom: none;
  }
  .logsnotes-container {
    border-bottom: 1px solid gray;
    display: flex;
    padding: 16px 16px;

    .right-log {
      .right-icon {
        position: absolute;
        left: -8px;
        bottom: -5px;
      }
    }
    .log-contiainer {
      font-size: 12px;
      width: 50%;

      .divider-bar {
        padding-bottom: 24px;
        border-bottom: 1px solid gray;
      }
      .Escalation--log-container {
        width: 90%;
        background-color: #eaf0fe;
        padding: 8px 16px;
        position: relative;
        .line-1 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .esc {
            font-weight: 500;
            color: #212121;
          }
          .divi {
            display: inline-block;
            margin: 0px 3px;
          }
          .view-btn {
            text-decoration: underline;
            color: #1268fb;
            cursor: pointer;
          }
          .view-btn:hover {
            color: #1268fb;
            cursor: pointer;
            font-weight: 500;
          }
        }

        .remark-partner-text {
          font-weight: 500;
          color: #212121;
        }
      }
      .ece-status {
        span {
          font-weight: 500;
          color: #212121;
        }
      }
      .reattampt-container.scroller {
        max-height: 120px !important;

        h2 {
          font-size: 12px;
        }
      }
      .reattampt-container {
        background-color: #fef5e9;
        padding: 8px 16px;
        position: relative;

        .esc {
          font-weight: 500;
          color: #212121;
        }
        .divi {
          display: inline-block;
          margin: 0px 3px;
        }
        .remark-partner-text {
          font-weight: 500;
          color: #212121;
        }
        .remark-parnter {
          margin-top: 10px;
        }
        .left-icon {
          position: absolute;
          bottom: -5px;
          right: -8px;
        }
      }
      .Escalation-close {
        background-color: #fef5e9;
        padding: 8px 8px;
        position: relative;
        margin-top: 10px;
        .esc {
          font-weight: 500;
          color: #212121;
        }
        .divi {
          display: inline-block;
          margin: 0px 3px;
        }
        .left-icon {
          position: absolute;
          bottom: -5px;
          right: -8px;
        }
      }
      .escalation-processing {
        background-color: #fef5e9;
        padding: 8px 8px;
        position: relative;
        color: #212121;
        font-weight: 500;
        .left-icon {
          position: absolute;
          bottom: -5px;
          right: -8px;
        }
      }
    }
  }
}

.lists {
  li {
    font-size: 12px;
  }
}

.clickable.ar {
  svg {
    transform: rotate(90deg);
    margin-right: 6px !important;
    path {
      fill: #fff;
    }
  }
}
