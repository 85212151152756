.important-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.9rem;
  padding-right: 3.9rem;
  background-color: #fbc52d;
  height: 56px;
  position: absolute;
  top: 0;
  z-index: 1000;
  .imp-div {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .ic {
      margin-right: 12px;
      display: flex;
    }
    h5 {
      margin: 0;
      padding: 0;
      text-decoration: underline;
      margin-right: 8px;
      font-size: 12px;
      font-weight: 500;
    }

    p {
      margin: 0;
      padding: 0;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 500;
    }

    button {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 147px;
    }
  }
}

.update-store-modal {
  width: 424px !important;
}
.imp-modal {
  padding: 26px 27px 24px 27px;

  width: 424px !important;

  border-radius: 2px;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .icon {
    margin: auto;
    width: 80px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #212121;
    margin-bottom: 4px;
    letter-spacing: 0.03em;
  }

  h2 {
    color: #898989;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.03em;
  }

  h3 {
    color: #898989;
    font-size: 12px;
    text-align: center;
    margin-bottom: 18px;
    font-weight: 400;
    letter-spacing: 0.03em;
  }

  .logo {
    height: 122px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      // display: none;
      margin-left: 24px;
    }

    .vc {
      height: 71px;
      width: 67px;
      background: gray;
    }
  }

  p {
    margin-bottom: 14px;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #212121;

    // span {
    //   text-decoration: underline;
    // }

    a {
      color: #1370fc;
      text-decoration: underline !important;
    }
    svg {
      margin-left: 4px;
    }
  }

  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    button:first-of-type {
      width: 155px;
      margin-right: 6;
      height: 32px;
    }

    button:last-of-type {
      display: block;
      margin-left: 6px;
      height: 32px;

      width: 160px;
      color: #898989;
      font-size: 12px;
      border: 1px solid #898989;
      border-radius: 2px;
      background-color: transparent;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.update-store-modal {
  .update-store-modal {
    padding: 0;
    margin: 0;
  }

  .ant-modal-body {
    padding: 0;
    margin: 0;
  }

  .ant-modal-close-x {
    display: none;
  }
}

.arrow-image {
  height: 12px;
  position: absolute;
  animation: myfirst 0.5s infinite linear;
  animation-direction: alternate;

  margin: auto;
  margin-top: 7% !important;
  margin-left: -30% !important;

  // top: 50% !important;
  // left: -20% !important;
}

@keyframes myfirst {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    left: 20px;
    top: 0px;
  }
}
