.tab-container {
  background: #f5f5f5;
  padding: 0 24px 24px 24px;
  .notes-tab {
    padding: 16px 8px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .note {
      background: #f2f2f2;
      margin-bottom: 8px;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      .flex-action {
        width: 45%;
      }
      .flex-date {
        width: 27%;
      }
      .flex-addedby {
        width: 25%;
      }
    }

    .note.repitiveOrders {
      div {
        text-transform: capitalize;
      }
    }

    .header_upper_data {
      font-size: 12px;
      padding: 0 16px;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      .flex-action {
        width: 45%;
      }
      .flex-date {
        width: 27%;
      }
      .flex-addedby {
        width: 25%;
      }
    }
  }
}

.tab-container.repitiveOrders {
  .header_upper_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    > p {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    p:first-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    p:last-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .note.repitiveOrders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:first-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
