.time-control {
  display: flex;
  width: 250px;
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: #f5f5f5;

  .play-pause {
    padding: 0px 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .download-btn {
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: 100%;
    height: 4px;
    position: absolute;
    // padding: 1rem 0rem;
    -webkit-appearance: none;
    background: none;
    cursor: pointer;
    // transition: all 0.1s linear;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 1rem;
      width: 1rem;
    }
  }

  p {
    padding: 1rem;
    text-align: center;
  }
}

.play-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem;
  width: 30%;
  height: 4px;

  svg {
    cursor: pointer;
  }
}

.track {
  width: 100%;
  background-color: #1370fb;
  position: relative;
  overflow: hidden;

  height: 4px;
}

.animate-track {
  position: absolute;
  height: 16px;
  width: 100%;
  background-color: lightgrey;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  pointer-events: none;
  transition: all 0.3s linear;
}

@media screen and (max-width: 768px) {
  .time-control {
    width: 90%;
  }
  .play-control {
    width: 40%;
  }
}
