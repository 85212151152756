.preview-broadcast1 {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    .preview {
        font-family: 'Poppins', sans-serif !important;
        background-color: white;
        padding: 35px 42px;
        margin-top: 24px;
        .imgdiv {
            height: 360px;
            margin-bottom: 22px;
            img {
                height: 100%;
                width: 100%;
                background-attachment: fixed;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
        .we {
            h1 {
                color: #2E2E2E;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            p {
                color: #2E2E2E;
                font-size: 13px;
                margin-bottom: 20px;
                font-weight: 500;
                span {
                    color: #2E2E2E;
                    font-weight: bold;
                }
            }
            h4 {
                color: #2E2E2E;
                font-size: 12px;
            }
        }
        .how {
            span {
                font-weight: 600;
            }
            p {
                font-size: 13px;
                color: #2E2E2E;
            }
        }
        .back {
            margin: 20px 0;
            h2 {
                color: #7E5BA6;
                font-size: 18px;
                font-weight: 600;
            }
            h3 {
                color: #7E5BA6;
                font-size: 18px;
                font-weight: 700;
            }
        }
        .happy {
            margin-top: 24px;
            h3 {
                color: #2E2E2E;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
    .footer {
        height: 320px;
        background-color: #3A3A3C;
        text-align: center;
        padding-top: 22px;
        .footerimg {
            height: 40px;
            width: 106px;
            margin: auto;
            img {
                height: 100%;
                width: 100%;
            }
        }
        h4 {
            color: #fff;
            font-size: 21px;
            font-weight: 500;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .follow {
            margin-bottom: 16px;
            font-size: 12px;
            color: #fff;
        }
        .address {
            padding: 24px 0;
            color: white;
        }
    }
    .ant-modal-footer {
        display: none !important;
    }
    .ref {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        // align-items: center;
    }
}

.showOpacity {
    opacity: 1;
    transition: all 0.5s ease-in-out;
}