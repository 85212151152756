.welcome-modal {
  .ant-btn {
    background: #2bc80a;
    color: white;
    border: #36c626;
    height: 38px;
    width: 148px;
    font-size: var(--small-font-size);
    font-weight: 500;
    letter-spacing: 1px;
  }
  h3 {
    color: #ff005e;
    font-size: 21px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 0px;
    margin-top: 12px;
  }
  p {
    font-size: var(--extra-medium-small-font-size);
    font-weight: 400;
    line-height: 22px;
    color: #4b4b4b;
  }
}

.snapshot-page {
  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .Onboarding_Steps button {
      height: 47px;
      background: #fff;
      border: none;
      font-size: var(--small-font-size);
      color: #000000;
      line-height: 22px;
      letter-spacing: 0.032em;
      padding: 8px 16px 8px 16px;
      cursor: pointer;
      .green_text {
        color: #3ace36;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
      }
      .red_text {
        color: #dd2525;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
      }
      .ant-dropdown-link span {
        .up-arrow {
          display: none;
        }
        .down-arrow {
          display: none;
        }
        .up-arrow {
          display: inline-block;
        }
      }
      .ant-dropdown-link span .up-arrow {
        display: none;
      }
    }
  }
  .content-page-title {
    font-size: var(--extra-medium-font-size);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .global-tooltip-video {
      margin-left: 8px !important;
    }

    svg {
      margin-bottom: 4px !important;
    }
  }
  .content-page-subtitle {
    font-size: var(--small-font-size);
  }
  .gr {
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 4px;
  }
}

.snapshot-picker {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Your_Snapshot {
    display: flex;
  }
  .Your_Snapshot p {
    color: #000000;
    font-size: var(--medium-font-size);
    line-height: 22px;
    letter-spacing: 0.032em;
  }
  .right_datepicker span {
    color: #000000;
    font-size: var(--extra-medium-small-font-size);
    line-height: 22px;
    letter-spacing: 0.032em;
  }
  // position: sticky;
  // top: -16px;
  // z-index: 999;
  // padding: 16px 0px;
  // background: #f0f2f5;
}
