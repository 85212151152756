.contact-tab-container {
  p,
  h2,
  span {
    font-size: 12px;
    letter-spacing: 0.032em;
  }
  .save-setting {
    font-size: 12px;
    background-color: #0068ff;
    border: none;
    padding: 11px 16px;
    color: rgb(255, 255, 255);
    border-radius: 2px;
    cursor: pointer;
    height: 40px;
    font-weight: 600;
  }
  .ant-tabs {
    background: #f6f6f6;
    border-radius: 0px 0px 4px 4px;
  }
  .ant-tabs-nav-container {
    width: 200px;
  }
  .ant-tabs .ant-tabs-left-bar,
  .ant-tabs .ant-tabs-right-bar {
    background: #f6f6f6;
    height: 100% !important;
    border-right: 0px;
    padding-top: 8px;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left;
    letter-spacing: 0.032em;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #212121;
    background: #ffff;
    font-weight: 400;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
  }
  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    background: #fff;
  }
  .ant-tabs .ant-tabs-left-content {
    padding: 16px 32px;
    background: #fff;
    min-height: 350px;
    border-left: 0px;
  }

  .enable-order-content {
    padding-left: 32px;
    padding-bottom: 8px;
    padding-right: 24px;
  }
  .calling-services {
    padding: 16px;
    background: #f5faff;
  }

  .contact-tab-list {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 10px;
    border-radius: 0px 0px 4px 4px;
  }

  .ant-tabs-tab {
    margin: 0 0 1px 0 !important;
    padding: 12px 32px !important;
    font-size: 12px;
    line-height: 16px;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #212121;
  }
  .enable-order-text p {
    padding: 15px 0px 8px;
  }

  label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    color: #000;
  }
  .ant-checkbox {
    margin-right: 8px;
  }
  .ant-radio-wrapper {
    padding-bottom: 5px;
  }
  span.ant-checkbox-inner,
  span.ant-radio-inner {
    background: #ededed;
  }
  .ant-radio.ant-radio-checked span.ant-radio-inner {
    background-color: #2bc822;
  }
  span.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2bc822;
  }

  .contact-tab-header {
    padding: 8px 32px;
    background-color: #16273b;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
  }
  .colling-services {
    h2 {
      font-size: 14px;
      letter-spacing: 0.032em;
      color: #ffffff;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
