@import url("https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap");
.resetSection {
  color: #212121;
  min-height: 550px;

  .heading-bar {
    padding-top: 58px;
    padding-left: 56px;
  }
  .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121;
    padding-left: 90px;
  }
  .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041;
    padding-left: 84px;
  }
  .heading-reset {
    color: #0068ff;
    font-weight: 500;
    padding-top: 60px;
    padding-left: 175px;
  }
  .line-blue {
    background-color: #0068ff;
    height: 2px;
    width: 178px;
    margin-left: 159px;
    margin-top: 10px;
  }
  .flex-from {
    padding-left: 56px;
    padding-top: 24px;
    .input-signup23 {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px;
    }
    .input-signup22 {
      input {
        height: 40px !important;
        border-radius: 4px !important;
        border: 1px solid #898989;
        background-color: #f5f5f5;
        font-size: 14px;
        padding: 10px 0 10px 16px;
        width: 390px;
      }
      .ant-input-suffix {
        right: 68px;
      }
    }
  }
  .alert {
    width: 390px;
    margin-bottom: 12px;
  }
  .change-emailbtn {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent;
  }
  .login-form-signup-here {
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .login-page-footer {
    font-weight: 500;
    margin-top: 50px;
    .copy-line {
      padding-left: 175px;
    }
    p {
      margin-bottom: 0px;
      color: #1a1a1a;
    }
    .footer-links {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0px;

      padding-left: 138px;

      li {
        display: inline-block;
        margin: 0 20px;
        a {
          color: #1a1a1a;
        }
        a:hover {
          color: rgb(0, 0, 0);
        }
      }
    }
  }
}
