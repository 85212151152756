.product-summary {
  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    color: #585858 !important;
    font-weight: 400;
    font-size: 12px;
  }
  .descri {
    p {
      font-size: 16px;
      font-weight: 400;
      color: #585858;
      margin-bottom: 8px;
      // height: 73px;
    }
    .ant-btn {
      font-size: 10px;
      color: white;
      background: #ff005e;
      height: 22px;
      width: 125px;
      font-weight: 500;
      border: none;
    }
  }
  .category {
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
      color: #585858 !important;
      font-weight: 400;
      font-size: 12px;
    }
  }
  .feature {
    ul {
      padding-left: 17px;
      li {
        font-size: 16px;
        width: 50%;
        float: left;
        margin-bottom: 8px;
      }
    }
  }
  .attri {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #36c626;
      border-color: #36c626;
    }
    .ant-tag,
    .ant-tag a,
    .ant-tag a:hover {
      color: #585858 !important;
      font-weight: 400;
      font-size: 14px;
      padding: 7px 10px;
      text-align: center;
      height: 48px;
      span {
        font-size: 10px;
      }
      .anticon {
        font-size: 20px;
      }
    }
    p {
      width: 33%;
      margin-bottom: 10px;
      .ant-checkbox {
        margin-left: 20px;
      }
    }
  }
}
