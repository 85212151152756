.ViewInVFCatalog_Button {
  width: 100%;
  button {
    position: relative;
    width: 80%;
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .ml8 {
        transition: all linear 0.4s;
        transform: translate(0px);
      }
    }
  }
  .hover-underline-animation {
    position: relative;
    color: #0087ca;
  }
  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 47%;
    transform: scaleX(0);
    height: 1px;
    left: -30px;
    top: 32px;
    margin-left: 33.2%;
    background-color: #2986ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: top left;
  }
}

.ViewInVFCatalog_Button:hover {
  a {
    .ml8 {
      transition: all linear 0.4s;
      transform: translate(4px);
    }
  }
}

.dropdown_link:hover {
  .ml8 {
    transition: all linear 0.4s;
    transform: translate(0px) !important;
  }
  .ant-dropdown-open {
    color: #fff !important;
  }
}

.dropdown_link {
  .ant-dropdown-open {
    color: #fff !important;
  }
}
