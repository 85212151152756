.productOverviewDrawer {
  .go-back {
    background-color: #000000;
    font-weight: 500;
    padding: 14px 24px;
    font-size: 1rem;
    margin-bottom: 15px;
    a {
      color: white;
    }
  }
  .ant-drawer-body {
    hr {
      margin-bottom: 24px;
      margin-top: 24px;
    }
    padding: 0px;
    .drawer-main-section {
      display: flex;
      .drawer-sidebar {
        width: 255px;
        background: #0e1d34;
        padding: 0 0 24px 0px;
        height: 100vh;
        position: fixed;
        h1 {
          font-size: 21px;
          font-weight: 500;
          color: white;
          padding: 0px 34px;
        }
        .ant-menu {
          background: #0e1d34;
          .ant-menu-item {
            padding: 0 34px;
            font-size: 16px;
            font-weight: 400;
            color: white;
            margin-top: 0px;
            margin-bottom: 0px;
            height: 48px;
            a {
              color: white;
            }
          }
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background-color: #000000;
          border-right: 5px solid #0068ff;
          font-weight: 500;
        }
        .ant-menu-item:active {
          background-color: #000000;
        }
      }
      .drawer-main-body {
        margin-left: 18.2em;
        width: calc(100% - 18.2em);
        height: 100vh;
        overflow: scroll;
        scroll-behavior: smooth;
        .modalTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 9px 43px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .product-info {
            width: 100%;
            display: flex;
            // align-items: center;
            .product-image {
              margin-right: 30px;
              width: 240px;
              img {
                border: 1px solid black;
              }
              .blackbox {
                display: flex;
                overflow: scroll;
                margin-bottom: 15px;
                img {
                  width: 40px;
                  height: 40px;
                  margin-top: 10px;
                  margin-right: 10px;
                  cursor: pointer;
                }
              }
              .overview-dates {
                font-size: 12px;
                text-decoration: underline;
                color: #838383;
                font-weight: 500;
                text-align: center;
                margin: 0 0 5px 0;
              }
            }
            .product-details {
              display: flex;
              flex: 1;
              justify-content: space-between;
              .product-internal-info {
                flex: 1;
              }
            }
            .ant-tag,
            .ant-tag a,
            .ant-tag a:hover {
              color: #585858 !important;
              font-weight: 400;
              font-size: 12px;
              padding: 7px 10px;
            }
            .bulk-price {
              margin-top: 10px;
              p {
                color: #585858 !important;
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 0px;
                text-align: center;
                padding: 0px 25px;
                span {
                  color: #2b2a2a !important;
                  font-weight: 500;
                  font-size: 12px;
                }
              }
            }
            .explore-pricing {
              margin-top: 24px;
              margin-bottom: 16px;
              .ant-btn {
                width: 260px;
                height: 36px;
                margin-right: 24px;
                border-radius: 2px;
              }
              .add-product {
                background: #36c626;
                color: white;
              }
            }
          }
          .anticon {
            color: rgb(68, 66, 66);
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #585858;
          }
        }
        h4 {
          font-size: 14px;
          font-weight: 500;
          color: black;
          margin-bottom: 10px;
          span {
            font-weight: 500;
            text-decoration: underline;
            margin-left: 10px;
            .anticon {
              margin-left: 10px;
            }
          }
        }

        .overview-section {
          margin: 43px 60px;
          h1 {
            font-size: 24px;
            color: black;
            font-weight: bold;
            border-bottom: 2px solid black;
            line-height: 20px;
            margin-bottom: 24px;
          }
          .media-container {
            padding-top: 20px;
          }
          .data-container {
            padding-top: 20px;
            margin-bottom: 60px;
            .advertising-data-not-available {
              background-image: url("../../../../../assets/images/backgrounds/advertising-disable.svg");
              padding: 36px 43px;
              text-align: center;
              h2 {
                color: #040404;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 0px;
              }
              p {
                color: #040404;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0px;
              }
            }
          }
          .pricing-data {
            .calculate-est-vf {
              padding: 24px;
              background: #f0f0f0;
              .calculation {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                p {
                  margin-bottom: 0px;
                }
                .ant-input {
                  width: 104px;
                  margin-left: 20px;
                }
              }
              .cal-button {
                margin-top: 24px;
                text-align: center;
                .ant-btn {
                  width: 250px;
                }
              }
            }
            .shopify-metrics {
              display: flex;
              margin-bottom: 24px;
              p {
                background: #eeeeee;
                padding: 10px;
                font-size: 14px;
                margin-bottom: 0px;
                color: #040404;
                font-weight: 400;
                margin-right: 15px;
                width: 204px;
                text-align: center;
                span {
                  font-size: 12px;
                  margin-bottom: 0px;
                  font-weight: 500;
                }
              }
            }
            .report-graph {
              margin-top: 24px;
              .graph-stat {
                display: flex;
                align-items: center;
                .stat-name {
                  margin-right: 20px;
                  p {
                    font-size: 12px;
                    color: #000000d9;
                    font-weight: 500;
                    .anticon {
                      font-size: 10px;
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.bulk-pricing-modal {
  width: 500px;
  .ant-modal-body {
    padding: 0;
    overflow: hidden;
    .ant-modal-confirm-content {
      margin: 0;
      .title {
        background: #1268fb;
        color: white;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .body {
        padding: 10px 16px 0 16px;
        color: black;
        font-size: 12px;
      }
    }
    .ant-modal-confirm-btns {
      float: unset;
      padding: 10px 0 16px 0;
      text-align: center;
      .ant-btn {
        border: 2px solid #707070;
        border-radius: 0;
      }
    }
  }
}
