.profile-settings {
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #898989;
  line-height: 16px;
  p {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px;
  }
  .ant-card-body {
    padding: 0px !important;
    background-color: #fff;
    margin-top: 40px;
    border-radius: 4px !important;
  }
  .ant-tabs-tab {
    margin: 0px !important;
    text-align: left !important;
    padding: 12px 32px !important;
    font-size: 12px;
  }
  .tabs-nav-wrap {
    height: 100vh !important;
  }
  .ant-tabs-left-content {
    background-color: #fff;
    border-left: none;
    padding: 35px 40px;
    border-radius: 0px 0px 4px 0px;
  }
  .ant-tabs .ant-tabs-left-bar {
    border-right: none;
    margin-right: 0px;
    padding-top: 8px;
    width: 200px;
  }
  .ant-tabs-ink-bar {
    width: 0px !important;
    background-color: none !important;
    height: 0px !important;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #212121;
    font-weight: 400;
    background: #fff;
  }

  .ant-card {
    border-radius: 4px !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05) !important;
  }

  .ant-card .ant-card-body {
    border-radius: 0px 0px 4px 4px;
  }
}
