.link-product-ali-express-modal {
  .ant-modal-footer {
    text-align: left;
  }
  .ant-input {
    background-color: #f5f5f5;
    border: none;
  }
  .ant-modal-content {
    border-radius: 0px;
  }
  .ant-modal-body {
    p.input-label {
      margin-bottom: 0;
      font-weight: 400;
      color: #898989;
      font-size: 12px;
    }

    input:first-of-type {
      margin-bottom: 15px;
    }
    .bulk-import {
      .icon-container {
        margin-top: 10px;
        padding: 10px;
        border: 2px solid #979797;
        border-radius: 4px;
        color: #979797;
        text-align: center;
        cursor: pointer;

        i {
          svg {
            width: 5rem;
            height: 5rem;
          }
        }
      }
      .icon-container:hover {
        border-color: #5e73ff;
        color: #5e73ff;
      }
    }
  }
  .next-button {
    background-color: #2bc822;
    outline: none;
    border: none;
    width: 161px;
    border-radius: 2px;
  }
  .cancle-button {
    width: 161px;
    border-radius: 2px;
  }
}
