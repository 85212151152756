.fullorder_data {
  margin-bottom: 16px;
  .custom-lineheight {
    line-height: 35px !important;
  }

  .ordernav {
    .Globalsearch-order2 {
      .drop {
        height: 40px !important;
      }
      .ant-input-group {
        width: inherit !important;
      }
    }
    .data-sorter {
      .ant-select-selection-selected-value {
        margin-right: 8px !important;
      }
    }

    .store-filters {
      .ant-select-selection-selected-value {
        margin-right: 8px !important;
      }
    }
  }
  .opennav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .filter {
          .ant-select-selection {
            padding: 0 !important;
            background: none;
            border: none;
            margin-right: 0;
          }
          svg {
            font-size: 20px;
          }
          .ant-select-arrow {
            margin-top: -10px;
          }
          .ant-select-selection-selected-value {
            padding-right: 26px;
          }
        }
        .show_data_from {
          font-size: 12px;
          color: #6e6e6e;
        }
        .dispute-status {
          font-size: 12px;
        }
      }
    }
    .secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        .tab {
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 20px;
          color: #898989;
          .active {
            color: #1268fb;
          }
          button {
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 500;
          }
        }
        .circle {
          // margin-left: 8px !important;
          position: relative;
          background: #f5f5f5;
          font-size: 12px;
          padding: 2px 10px;
          color: #898989;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #898989;
        }
        .backgroundcircle {
          // margin-left: 8px !important;
          position: relative;
          background: #1268fb;
          font-size: 12px;
          padding: 2px 10px;
          color: #fff;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #1268fb;
        }

        .hover-order-underline-animation {
          position: relative;
        }
        .hover-order-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-order-underline-animation:hover {
          color: #4398ed !important;
          .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-order-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left;
        }

        .hover-order-underline-animation-click {
          position: relative;
        }
        .hover-order-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left;
        }
      }
      .download_csv {
        margin-right: 100px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 32px;
        line-height: 30px;
      }
    }
    .ant-input {
      width: 82%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px;
    }
    .ant-calendar-range-picker-separator {
      color: #1268fb !important;
    }
    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em;
    }
    .ant-calendar-range-picker-input {
      width: 36% !important;
      letter-spacing: 0.032em;
      line-height: 24px;
    }
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      top: 50%;
      right: 47px;
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
    padding-top: 90px;
    gap: 16px;

    .drop {
      height: 40px !important;
    }
  }
  .ant-select-selection__placeholder {
    color: #1268fb;
  }

  .fullorder_data .ant-select-selection__placeholder {
    color: #898989;
  }

  .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important;
  }

  .servicebale-select {
    .ant-select-selection {
      width: 176px !important;

      .ant-select-arrow {
        left: 146% !important;
      }
    }
  }
  //   .bulksearch{
  //     height:100%;
  //   .bulk-search-button{
  //     height: 100%;
  //     margin-right: 16px;
  //     font-size: 12px;
  //     letter-spacing: 0.032em;
  //     line-height: 16px;
  //     width: 112px;
  //     background: #0068ff !important;
  //     font-weight: 500 !important;
  //     box-shadow: 2px 2px 8px  !important;
  //     border: none;
  //     color: #fff;
  //   }
  //   .bulk-search-button:hover{
  //     color: #fff;
  //     background-color: #0063f2 !important;
  //     border: 2px solid #0058d6 !important;
  //   }
  // }
  // .Globalsearch{
  //   position: relative;
  //   .search-click{
  //    border-radius: 0px !important;
  //   }
  //   .drop-down-bulk{
  //    position: absolute;
  //    right: 0;
  //    top:0;
  //    width: 22px;
  //    height: 100%;
  //    border:1px solid #770f0f;
  //    background-color: #770f0f;
  //    border-radius: 0 2px 2px 0;
  //    svg{
  //      position: relative;
  //      top:8px;
  //      left:2px;
  //      transition: all  0.5s;
  //      transform:rotateX(0deg);
  //      g {
  //       fill:none
  //      }
  //    }
  //   }
  //   .drop-down-bulk:hover  .bulksearch{
  //      display: block;
  //   }
  //   .drop-down-bulk:hover  svg{
  //     transition: all  0.5s;
  //     transform:rotateX(180deg);
  //     // display: none;
  //     g{
  //       fill:#898989;
  //     }

  //   }
  //  .bulksearch{
  //    position: absolute;
  //    display: none;
  //    right: -1px;
  //    bottom: -51px;
  //    width: 112px;
  //    height: 50px;
  //    z-index: 9999;
  //  .bulk-search-button{
  //    height: 100%;
  //    width: 100%;
  //    margin-right: 16px;
  //    font-size: 12px;
  //    letter-spacing: 0.032em;
  //    line-height: 16px;
  //    background: #0068ff !important;
  //    font-weight: 500 !important;
  //    box-shadow: 2px 2px 8px  !important;
  //    border: none;
  //    color: #fff;
  //  }
  //  .bulk-search-button:hover{
  //    color: #fff;
  //    background-color: #0063f2 !important;
  //    border: 2px solid #0058d6 !important;
  //  }
  // }
  // }
}
#orders-page-nav {
  .ant-input-group-addon {
    button {
      padding-right: 20px !important;
    }
  }
}
.commondropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px;
  .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px;
  }
}

.commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important;
}
.fullorder_data .ant-select-selection__placeholder {
  color: #898989;
}

@media screen and (min-width: 1440px) {
  .opennav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
  .commondropdown {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 32px;
    .anticon svg {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  .fullorder_data .ant-select-selection__placeholder {
    color: #898989;
  }

  .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important;
  }
  .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    border-bottom: 2px solid #b5dafe !important;
    border-radius: 0px !important;
  }
}

.Globalsearch-order2 {
  position: relative;
  > div {
    height: 40px !important;
  }
  .import-product-search {
    width: 300px !important;
  }
  .ant-input-group {
    width: 300px !important;
    button {
      width: 100px !important;
    }
  }
  .search-click {
    border-radius: 0px !important;
  }
  .ant-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 40px;
    background-color: #cf3341;
    border: 0;

    svg {
      transition: all 0.5s;
      fill: white;
      path {
        stroke: white;
      }
    }
  }
  .ant-btn:hover {
    border-color: transparent;
  }
  .ant-btn:hover svg {
    transition: all 0.5s;
    transform: rotateX(180deg);
    // display: none;
    g {
      fill: white;
    }
    path {
      stroke: white;
    }
  }
  svg {
    position: relative;
    left: -9px;
  }
  .drop-down-bulk {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 100%;
    border: 1px solid #770f0f;
    background-color: #770f0f;
    border-radius: 0 2px 2px 0;
    svg {
      position: relative;
      top: 8px;
      left: 2px;
      transition: all 0.5s;
      transform: rotateX(0deg);
      g {
        fill: none;
      }
    }
  }
  .drop-down-bulk:hover .bulksearch {
    display: block;
  }
  .drop-down-bulk:hover svg {
    transition: all 0.5s;
    transform: rotateX(180deg);
    // display: none;
    g {
      fill: #898989;
    }
  }
  .bulksearch {
    position: absolute;
    display: none;
    right: -1px;
    bottom: -51px;
    width: 112px;
    height: 50px;
    z-index: 9999;
    .bulk-search-button {
      height: 100%;
      width: 100%;
      margin-right: 16px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      background: #0068ff !important;
      font-weight: 500 !important;
      box-shadow: 2px 2px 8px !important;
      border: none;
      color: #fff;
    }
    .bulk-search-button:hover {
      color: #fff;
      background-color: #0063f2 !important;
      border: 2px solid #0058d6 !important;
    }
  }
}

// max-width 1600px
@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .opennav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}

.non-cursor-pointer {
  pointer-events: none;
}
.bulk-search-menu {
  width: 132px;
  text-align: center;
}

.flex-wraper-all-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
