.global-tooltip-black {
  display: flex;
  align-items: center;
  justify-content: center;
}
.global-tooltip-icon-black {
  font-size: 16px !important;
  margin: 0px 8px !important;
}
.global-tooltip-black:hover .global-tooltip-icon-black {
  // background: #000;
  border-radius: 50%;
  cursor: pointer;

  svg {
    g {
      stroke: #ffffff;
    }

    :last-child {
      g {
        stroke: #26b99a;
      }
    }
  }
}
.global-tooltip-black:hover .global-tooltip-icon-black g text {
  fill: #fff;
}
.global-tooltip-black:hover .global-tooltip-icon-black g > g {
  stroke: #ffffff;
}
