.catalog-product {
  letter-spacing: 0.032em !important;
  text-rendering: optimizelegibility;
  // page global css
  background: #f0f2f5;
  .loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(141, 141, 141, 0.3);
    border-radius: 50%;
    border-top-color: #212121;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  // center component styling
  .center-details {
    .product-title-wraper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .product-name {
        width: fit-content;
        max-width: 445px;
        color: #212121;
        font-weight: 600;
        font-size: 20px;
        text-overflow: ellipsis;

        /* Needed to make it work */
        // overflow: hidden;
        white-space: wrap;
      }

      .product-id-wraper {
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 8px;
        font-size: 12px;
        padding-left: 8px;
        color: #898989;

        .product-id {
          > p {
            margin-right: 4px;
          }
        }
      }
      .line {
        margin-right: 8px;

        margin-top: 8px;
      }
      .product-id {
        display: flex;
        margin-top: 8px;
      }
    }
    .product-sub-heading {
      margin-bottom: 10px;
      font-size: 14px;
      color: #898989;
      margin-bottom: 20px;
    }

    .star-wraper {
      display: flex;
      align-items: center;
      .stars {
        display: flex;
        align-items: center;
      }
    }
  }

  button {
    cursor: pointer;
  }
  .f8 {
    font-size: 8px;
  }
  .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    word-wrap: break-word;
  }
  .f16 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.032em;
    word-wrap: break-word;
  }
  .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em;
    word-wrap: break-word;
  }
  .mr0 {
    margin-right: 0px;
  }
  .ml0 {
    margin-left: 0px;
  }
  .mt0 {
    margin-top: 0px;
  }
  .mb0 {
    margin-bottom: 0px;
  }
  .mr8 {
    margin-right: 8px;
  }
  .ml8 {
    margin-left: 8px;
  }
  .mt8 {
    margin-top: 8px;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .mr16 {
    margin-right: 16px;
  }
  .ml16 {
    margin-left: 16px;
  }
  .mt16 {
    margin-top: 10px;
  }
  .mb16 {
    margin-bottom: 12px;
  }
  .w100 {
    width: 100%;
  }
  .py8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .px8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .py2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .px2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .semibold {
    font-weight: 500;
  }
  .bold {
    font-weight: 600;
  }
  .italic {
    font-style: italic;
  }
  .underline {
    text-decoration: underline !important;
  }
  .inline-list {
    list-style-type: none;
    margin-bottom: 0px !important;
    padding: 0;
    li {
      display: inline-block;
    }
  }
  .blue-text {
    color: #1370fc;
  }
  .btn-green {
    background: #2bc822;
    border-color: #2bc822;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }
  .btn-blue {
    background: #1370fc;
    border-color: #1370fc;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }
  .top-section-container {
    background: white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  }
  .top-section {
    width: 1200px;
    margin: auto;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .bottom-section-container {
    width: 1200px;
    margin: auto;
    margin-top: 35px;
  }
  .bottom-section {
    width: calc(100% - 320px);
  }
  .basic-tags {
    background: #fff0c4;
    color: #707070;
    cursor: pointer;
  }
  .basic-tags:hover {
    background: #f3705a;
    color: white;
  }
  .qty-increment-btn {
    background: #1370fc;
    color: white;
    width: 24px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border: none;
  }
  .qty-decrement-btn {
    background: #1370fc;
    color: white;
    width: 24px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: none;
  }
  .bottom-section {
    position: relative;
  }
  .bottom-section ul.tabs {
    position: sticky;
    padding: 8px 0px 0px;
    top: 0;
    z-index: 9;
    background: #f0f2f5;
    // z-index: 9999;
  }
  ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li:first-of-type {
      button {
        border-top-left-radius: 2px;
      }
    }
    li:last-of-type {
      button {
        border-top-right-radius: 2px;
      }
    }
    li {
      display: inline-block;
      margin-right: 8px;

      button {
        min-width: fit-content !important;
        max-width: 300px;

        padding-top: 8px;
        padding-left: 23px !important;
        padding-right: 23px !important;
        padding-bottom: 8px;
        border: none;
        background: #fcfcfc;
      }
    }
    li.active {
      button {
        background: #1370fc;
        color: #fff;
      }
    }
  }
  table.shipping-zones {
    width: 550px;
    border: 2px solid #e3e3e3;
    font-size: 12px;
    border-radius: 2px;
  }
  table.shipping-zones th {
    background: #f5f5f5;
    color: #898989;
    font-size: 12px;
    font-weight: 400;
    border-right: 2px solid #e3e3e3;
    text-align: center;
    padding: 8px 0px;
  }
  table.shipping-zones thead {
    border-bottom: 2px solid #e3e3e3;
  }
  table.shipping-zones th:last-child {
    background: #f5f5f5;
    color: #898989;
    border-right: 0px;
  }
  table.shipping-zones td:nth-child(1),
  table.shipping-zones td:nth-child(2) {
    background: #f5f5f5;
    color: #898989;
  }
  table.shipping-zones td:nth-child(1) {
    width: 72px;
    text-align: center;
    padding: 12px;
    border: 2px solid #e3e3e3;
    border-left: none;
    border-top: none;
  }
  table.shipping-zones td:nth-child(2) {
    width: 224px;
    padding: 12px;
    border: 2px solid #e3e3e3;
    border-top: none;
  }
  table.shipping-zones td:nth-child(3) {
    width: 96px;
    text-align: center;
    padding: 12px;
    border: 2px solid #e3e3e3;
    border-right: none;
    border-top: none;
  }
  .tabs-content {
    background: white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
    padding: 24px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    .tabs-content-container {
      max-width: 100%;
    }
  }
  table.variants-table {
    min-width: 665px;
    font-size: 12px;
    th {
      color: #898989;
      font-weight: 400;
      padding-bottom: 4px;
    }
    td {
      padding: 24px 16px;
      border: 2px solid #e3e3e3;
      img {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        object-fit: cover;
        margin-right: 8px;
      }
    }
    td:nth-child(1) {
      background: #f5f5f5;
      color: #898989;
    }
  }
  .description-tag {
    position: relative;
    font-size: 12px;
    padding: 4px 8px;
    background: #fff0c4;
    color: #707070;
    margin-right: 22px;
    margin-bottom: 8px;
  }
  .description-tag::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 14px solid #fff0c4;
  }
  .description-table {
    width: 100%;
    font-size: 12px;
    border-radius: 2px;
    overflow: hidden;
    td {
      width: 50%;
      padding: 12px 16px;
      border: 2px solid #e3e3e3;
    }
    td:nth-child(1) {
      background: #f5f5f5;
      color: #898989;
    }
  }
  .description-html-iframe {
    border: none;
    width: 100%;
  }
  .variants-selector::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  .variants-selector::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  .variants-selector::-webkit-scrollbar-thumb {
    background: #1370fc;
  }
  /* Handle on hover */
  .variants-selector::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .catalog-name-tooltip {
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.032em;
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0px;
    overflow: hidden;
    cursor: pointer;
  }
  .catalog-name-tooltip .catalog-tooltip {
    display: none;
    background-color: #212121;
    color: #fff;
    font-weight: 400;
    text-align: center;
    border-radius: 2px;
    padding: 8px;
    position: absolute;
    z-index: 9;
    top: 40%;
    left: 0%;
    min-width: 160px;
    max-width: 240px;
    font-size: 12px;
    white-space: normal;
  }
  .catalog-name-tooltip .catalog-tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #212121 transparent;
  }
  .catalog-name-tooltip:hover .catalog-tooltip {
    display: block;
  }
  @keyframes animationdown {
    from {
      top: -100%;
    }
    to {
      top: 120px;
    }
  }
  .copied-id svg g path {
    // fill: #1370fc !important;
  }
  .retail-price-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 26px;
    margin-bottom: -2px;
    border-radius: 0px;
    border: 2px solid #b5dafe;
  }
  .retail-price-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1370fc !important;
    border-color: #1370fc !important;
    border-top-left-radius: 2px !important;
    border-right: 1px solid #fff !important;
  }
  .bulk-price-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 26px;
    margin-bottom: -2px;
    border-radius: 0px;
    border: 2px solid #b5dafe;
  }
  .bulk-price-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1370fc !important;
    border-color: #1370fc !important;
    border-top-left-radius: 2px !important;
    border-right: 1px solid #fff !important;
  }
  .sea-freight-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 26px;
    margin-bottom: -2px;
    border-radius: 0px;
    border: 2px solid #b5dafe;
  }
  .sea-freight-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1370fc !important;
    border-color: #1370fc !important;
    border-top-left-radius: 2px !important;
    border-right: 1px solid #fff !important;
  }
  .copied-id svg g path {
    // fill: #898989;
    padding-right: 4px;
  }
  .copied-id {
    padding-right: 8px;
  }
  .customize-data {
    display: flex;
    justify-content: flex-start;
  }
  .bulet-no {
    background: #f5f5f5;
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 50%;
  }
  .product-tooltip:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer;
  }
  .product-tooltip:hover svg.product-tooltip-icon g text {
    fill: #fff;
  }
  .product-tooltip:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb;
  }
  .add-to-store {
    margin-top: 16px !important;
    margin-bottom: 16px !important;

    span {
      text-shadow: none;
      font-weight: 500;
    }
  }
  .cost-updated {
    color: #898989;
    font-style: italic;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    span {
      color: #212121;
      margin-left: 4px;
    }
  }

  .request-latest-price.disabled {
    color: #898989;
    svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .request-latest-price {
    display: flex;
    color: #212121 !important;
    font-size: 10px !important;
    svg {
      color: #212121;
      font-size: 14px;
      margin-right: 5px;
      transition: all 0.3s;
      transform: rotate(0deg);
    }
  }

  .request-latest-price:hover {
    display: flex;
    color: #212121 !important;
    transition: all 0.3s;

    svg {
      transform: rotate(220deg) !important;

      path {
        fill: #0058d6 !important;
      }
    }
  }

  .request-latest-price.disabled {
    color: #898989 !important;
    font-size: 10px !important;
    transition: none;
    svg {
      font-size: 14px !important;
      margin-right: 5px !important;
      path {
        fill: #898989 !important;
      }
    }
  }

  .request-latest-price.disabled:hover {
    color: #898989;
    transform: none;

    svg {
      transform: rotate(0deg) !important;
      path {
        fill: #898989 !important;
      }
    }
  }

  .last-updated-pricing {
    font-size: 10px !important;
    color: #898989;
    font-style: italic;
    margin-bottom: 12px !important;

    span {
      font-size: 10px !important;
      color: #212121;
      font-style: italic;
    }
  }

  .bottom-pointer {
    display: flex;
    align-items: center;
    justify-content: center;

    .f12 {
      font-weight: 300 !important;
      font-size: 12px !important;
      margin-right: 10px !important;
    }
  }
}

// .product-tooltip {
//   display: flex;
//   background: #fff0c4;
//   padding: 16px;
//   align-items: baseline;
//   margin-top: 16px;

//   .anticon:hover {
//     svg {
//       #Ellipse_44 {
//         stroke: #1268fb;
//         fill: #1268fb;
//       }
//       #Ellipse_47 {
//         stroke: #fff;
//         fill: #ffff;
//       }
//       #Rectangle_2462 {
//         stroke: #fff;
//         fill: #ffff;
//       }
//     }
//   }

// }

.suggested-selling-price {
  margin-right: 8px;
}

.star-parent {
  cursor: pointer;
}

.star-parent:hover {
  span {
    .under {
      color: #e61047;
      span {
        text-decoration: underline;
      }
    }
  }
}

.ant-message {
  z-index: 99999 !important;
}

.basic-tags-tooltip {
  .ant-tooltip-arrow {
    border-top-color: #f3705a;
  }
  .ant-tooltip-inner {
    background: #f3705a;
    font-size: 12px;
    color: white;
  }
  .shipping-zones thead th {
    text-align: center;
    color: #898989;
    border-right: 1px solid #898989;
  }
}

@media screen and (min-width: 1360px) {
  .catalog-product {
    .top-section {
      width: 90%;
      margin: auto;
    }
    .bottom-section-container {
      width: 1240px;
      margin: auto;
      margin-top: 35px;
    }
  }
}

.bulk-inventory-booking-confirmation-modal {
  .ant-modal-close-x {
    display: none;
  }
  .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
  }
  .f16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em;
  }
  .italic {
    font-style: italic;
  }
  .underline {
    text-decoration: underline !important;
  }

  .py8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .w100 {
    width: 100%;
  }
  .py8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .px8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .btn-green {
    background: #2bc822;
    border-color: #2bc822;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }
  .ant-modal-content {
    border-radius: 2px;
    padding: 24px;
    .ant-modal-header {
      border-bottom: none;
      padding: 0;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
  .btn-wraper {
    margin-top: 24px;
    margin-bottom: 0px;

    .track-sourcing {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin-right: 6px;
        line-height: 10px;
      }

      svg {
        font-size: 14px;
        path {
          stroke: #1268fb;
        }
      }
    }
  }
}

.variant-vfsku {
  color: #212121;
  font-weight: 500;
  margin-left: 16px;
}

.variant-vfsku-allspecs {
  margin-top: 5px;
  margin-left: 8px;
}

.variant-allspecs-name-small {
  width: 80px;
  display: inline-flex;
}

.variant-allspecs-name-larg {
  width: 120px;
  display: inline-flex;
}

// global classes
.line-global {
  font-size: 13px;
  margin-left: 8px;
  margin-right: 8px;
  color: #b2b2b2;
}
.catalog-product {
  .center-details {
    .product-title-wraper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .product-name {
        width: fit-content;
        max-width: 445px;
        color: #212121;
        font-weight: 600;
        font-size: 20px;
        text-overflow: ellipsis;

        /* Needed to make it work */
        // overflow: hidden;
        white-space: wrap;
      }

      .product-id-wraper {
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 8px;
        font-size: 12px;
        padding-left: 8px;
        color: #898989;

        .product-id {
          > p {
            margin-right: 4px;
          }
        }
      }
      .line {
        margin-right: 8px;
        color: #b2b2b2;
        font-size: 12px;

        margin-top: 8px;
      }
      .product-id {
        display: flex;
        margin-top: 8px;
      }
    }
    .product-sub-heading {
      margin-bottom: 10px;
      font-size: 14px;
      color: #898989;
      margin-bottom: 20px;
    }

    .star-wraper {
      display: flex;
      align-items: center;
      .stars {
        display: flex;
        align-items: center;
      }
      .inventory-status {
        display: flex;
        align-items: center;
        i {
          margin-right: 8px;
        }

        p {
          font-size: 12px;
          color: #898989;
        }
      }

      .inventory-restocking-status {
        display: flex;
        align-items: center;
        i {
          margin-right: 8px;
        }
        p {
          font-size: 12px;
          color: #898989;

          span {
            color: #212121;
          }
        }
      }
    }

    // bulk pricing
    .bulk-pricing-wraper {
      .heading {
        height: 32px;
        background-color: #26b99a;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        // width: 176px;
        padding: 0px 16px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      // retail price
      .retail-order-pricing-wraper {
        margin-bottom: 28px;
        .pricing-wraper {
          border: 2px solid #26b99a;
          display: flex;
          align-items: center;
          height: 64px;

          .price {
            width: 420px;
            display: flex;
            align-items: center;
            margin-left: 24px;

            p {
              color: #212121;
              font-size: 16px;
              font-weight: 500;
            }
            span {
              color: #212121;
              font-size: 12px;
              font-weight: 400;
              margin-left: 8px;
            }
          }

          .description {
            font-size: 10px;
            color: #212121;
            font-style: italic;

            line-height: 16px;
          }
        }
      }

      //  Bulk order pricing table
      .bulk-order-pricing-table-wraper {
        margin-bottom: 5px;

        .heading {
          height: 32px;
          background-color: #26b99a;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          // width: 176px;
          padding: 0px 16px !important;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }

        .heading-wraper {
          display: flex;
          align-items: end;
          justify-content: space-between;

          button {
            font-size: 12px;
            padding: 0px 16px;
            color: #898989;
            height: 24px;
            border: none;
            background-color: #f5f5f5;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;

            i {
              margin-left: 8px;
            }
          }
        }
        .pricing-wraper {
          border: 2px solid #26b99a;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // height: 64px;

          .table-headings,
          .table-sub-headings,
          .table-row-data {
            background-color: #26b99a;
            width: 100%;
            height: 22px;
            display: flex;
            font-size: 12px;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            // padding-left: 14px;
            // padding-right: 40px;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .transit-time {
              width: 110px;
            }
            .vf-days {
              width: 111px;
              display: flex;
              align-items: center;
              justify-content: center;
              i {
                margin: 0px 4px;
              }
            }
            :nth-child(3) {
              width: 110px;
            }
            :nth-child(4) {
              width: 130px;
            }
          }

          .table-sub-headings {
            height: 32px;
            background-color: #f5f5f5;

            div {
              color: #898989;
            }
          }

          .table-row-data {
            height: 48px;
            background-color: #fff;

            div {
              color: #212121;
            }
          }
        }
      }

      .table-row-data:nth-child(even) {
        background-color: #f5f5f5 !important;
      }
    }
  }
}

.product-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-tooltip-icon {
  font-size: 16px;
}
.product-tooltip:hover .product-tooltip-icon {
  background: #0058d6;
  border-radius: 50%;
  cursor: pointer;

  svg {
    g {
      stroke: #0058d6;
    }

    :last-child {
      g {
        stroke: #fff;
      }
    }
  }
}
.product-tooltip:hover .product-tooltip-icon g text {
  fill: #fff;
}
.product-tooltip:hover .product-tooltip-icon g > g {
  stroke: #0058d6;
}
// Raise bulk orde
.raise-bulk-order-pricing-table-wraper {
  margin-bottom: 24px;
  margin-top: 10px;

  .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between;

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0058d6 !important;
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0px 16px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      svg {
        margin-right: 4px;
      }
    }
  }
  .pricing-wraper {
    border: 2px solid #0058d6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // height: 64px;

    .table-headings {
      height: 30px;
      background-color: #0058d6;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inventory-freight-type {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          color: #fff;
        }
        .golden-star {
          margin-right: 4px;
        }
        svg {
          font-size: 12px;
        }

        //
        .type-select {
          .ant-select-selection.ant-select-selection--single {
            border: none;
            background-color: transparent;

            .ant-select-selection__rendered {
              line-height: 32px;
            }

            .ant-select-selection-selected-value {
              font-size: 12px;
              display: block;
              color: #fff;
              opacity: 1;
              font-weight: 500;
              margin-right: 6px;
            }

            .ant-select-arrow {
              right: 8px;
            }

            svg {
              font-size: 16px;
              margin-top: -3px;
            }
          }
        }
      }

      .transit-time {
        font-size: 12px;
        color: #fff;
        display: flex;
        align-items: center;
        span {
          margin-left: 4px;
        }
      }
    }

    .table-sub-headings,
    .table-row-data {
      background-color: #0058d6;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      // padding-left: 14px;
      // padding-right: 40px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .transit-time {
        width: 110px;
      }
      .vf-days {
        width: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0px 4px;
        }
      }
      .vf-inventory {
        width: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0px 4px;
        }
      }
      .air-days {
        width: 112px;
      }
      .booking-qty {
        width: 115px;
      }
    }

    .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5;

      .variants {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 14px;
        min-width: 210px !important;
      }

      div {
        color: #898989;
      }
    }

    .table-row-data {
      height: 64px;
      background-color: #fff;

      div {
        color: #212121;
      }

      .variants-details {
        padding-left: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 210px !important;

        .img {
          height: 32px;
          width: 32px;
          border-radius: 2px;
          margin-right: 8px;

          img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px;
          }
        }

        .sku-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          p {
            color: #898989;
            font-size: 12px;
            margin-right: 4px;
          }

          span {
            color: #212121;
            font-size: 12px;
          }

          .sku {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .specs {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }
      }
      .vf-inventory {
        font-size: 16px;
        font-weight: 600;
        color: #8b8b8b;
      }
      .air-days {
        font-size: 16px;
        font-weight: 600;
        color: #212121;
      }
    }
  }
  .booking-qty {
    button:first-of-type {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    button:last-of-type {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    button {
      font-size: 16px;
      border: none;
      background-color: #1370fc;
      height: 32px;
      width: 24px;
      color: #fff;
    }

    input {
      width: 48px;
      height: 32px;
      font-size: 16px;
      color: #212121;
      outline: none;
      border: none;
      background: #f5f5f5;
      text-align: center;
    }
  }

  .booking-qty.disabled {
    pointer-events: none;
    opacity: 0.5;

    button {
      font-size: 16px;
      border: none;
      background-color: #1370fc;

      height: 32px;
      width: 24px;
      color: #fff;
    }
  }
}

.raise-bulk-order-pricing-table-wraper .table-row-data:nth-child(even) {
  background-color: #f5f5f5 !important;
  input {
    background-color: #fff;
  }
}

@media screen and (max-width: 1280px) {
  .center-details {
    width: 504px;
  }
  .product-name {
    width: 342px !important;
    max-width: 342px !important;
  }

  ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li:first-of-type {
      button {
        border-top-left-radius: 2px;
      }
    }
    li:last-of-type {
      button {
        border-top-right-radius: 2px;
      }
    }
    li {
      display: inline-block;
      margin-right: 8px;

      button {
        min-width: 136px;
        max-width: 300px;

        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 8px;
        border: none;
        background: #fcfcfc;
      }
    }
    li.active {
      button {
        background: #1370fc;
        color: #fff;
      }
    }
  }

  .line-global {
    font-size: 13px;
    margin-left: 4px;
    margin-right: 4px;
    color: #b2b2b2;
  }
}
//
// Recommended packaging
.recommended-packaging-table-wraper {
  margin-bottom: 24px;
  margin-top: 32px;

  .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between;

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f6222c;
      svg {
        margin-right: 4px;
      }

      height: 32px;

      font-size: 14px;
      font-weight: 500;
      color: #fff;
      // width: 176px;
      padding: 0px 16px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
  .pricing-wraper {
    border: 2px solid #f6222c;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // height: 64px;

    .table-headings {
      height: 30px;
      background-color: #f6222c;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inventory-freight-type {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          color: #fff;
        }
        .golden-star {
          margin-right: 4px;
        }
        svg {
          font-size: 12px;
        }

        //
        .type-select {
          .ant-select-selection.ant-select-selection--single {
            border: none;
            background-color: transparent;

            .ant-select-selection__rendered {
              line-height: 32px;
            }

            .ant-select-selection-selected-value {
              font-size: 12px;
              display: block;
              color: #fff;
              opacity: 1;
              font-weight: 500;
              margin-right: 6px;
            }

            .ant-select-arrow {
              right: 8px;
            }

            svg {
              font-size: 16px;
              margin-top: -3px;
            }
          }
        }
      }

      .transit-time {
        font-size: 12px;
        color: #fff;
        display: flex;
        align-items: center;
        span {
          margin-left: 4px;
        }
      }
    }

    .table-sub-headings,
    .table-row-data {
      background-color: #0058d6;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      // padding-left: 14px;
      // padding-right: 40px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .transit-time {
        width: 110px;
      }
      .vf-days {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0px 4px;
        }
      }
      .vf-inventory {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0px 4px;
        }
      }
      :nth-child(3) {
        width: 110px;
      }
      :nth-child(4) {
        width: 130px;
      }
    }

    .table-row-data {
      cursor: pointer;
    }

    .table-row-data.inactive {
      pointer-events: none;
    }

    .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5;

      .MOQ {
        width: 160px;
      }

      .variants {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 14px;
        width: 210px;
      }

      div {
        color: #898989;
      }

      .blank {
        width: 120px;
      }
    }

    .table-row-data {
      height: 64px;
      background-color: #fff;

      div {
        color: #212121;
      }

      .variants-details {
        padding-left: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 210px;

        .img {
          height: 32px;
          width: 32px;
          border-radius: 2px;
          margin-right: 8px;

          img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px;
          }
        }

        .sku-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          p {
            color: #898989;
            font-size: 12px;
            margin-right: 4px;
            min-width: 70px;
          }

          span {
            color: #212121;
            font-size: 12px;
          }

          .sku {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .specs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
      .vf-inventory {
        width: 120px;

        .Complimentary {
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #9d24d6;
          width: 120px;

          height: 24px;
          border-radius: 2px;
          border: 1px solid #9d24d6;
        }
      }

      .MOQ {
        width: 160px;
      }
      .air-days {
        font-size: 16px;
        font-weight: 600;
        color: #212121;
      }

      .air-days.comp {
        font-size: 16px;
        font-weight: 600;
        color: #909090;
        text-decoration: line-through;
        text-decoration-color: #212121;
        text-decoration-thickness: 2px;
      }
      .booking-qty {
        button:first-of-type {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
        button:last-of-type {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        button {
          font-size: 16px;
          border: none;
          background-color: #1370fc;
          height: 32px;
          width: 24px;
          color: #fff;
        }

        input {
          width: 48px;
          height: 32px;
          font-size: 16px;
          color: #212121;
          outline: none;
          border: none;
          text-align: center;
        }
      }
    }

    .footer-data {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 8px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 208px;
        border-radius: 2px;
        border: none;
        background: #f6222c;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 16px;
        height: 32px;
      }
    }
  }
}

.recommended-packaging-table-wraper .table-row-data:nth-child(even) {
  background-color: #f5f5f5 !important;
}

.restocking-btn {
  transition: 0.3s all;
}
.restocking-btn:hover {
  transition: 0.3s all;
  background: #1370fc !important;
  color: #fff !important;

  svg {
    position: relative !important;
    animation: glide 0.3s linear alternate infinite !important;
    path {
      fill: #fff;
    }
  }
}

.rotationClass {
  // transform: scale(1.2);
  border-radius: 50%;
  font-size: 16px;
  animation: pulse-gold 2.4s infinite, rotation 2.4s infinite;
}

@-webkit-keyframes glide {
  from {
    left: 0px;
    top: 0px;
  }

  to {
    left: 0px;
    top: 2px;
  }
}

@keyframes pulse-gold {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #fbc52d;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(12, 12, 12, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes rotation {
  50% {
    transform: rotate(180deg);
  }
}

@keyframes flip {
  50% {
    transform: rotateY(180deg);
  }
}

.error-wraper-catalog {
  position: relative;
  .ant-alert-error {
    height: 150px;
  }
  a {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 208px;
    border-radius: 2px;
    border: none;
    background: #fd4726;
    font-weight: 500;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 16px;
    height: 32px;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
  }
}
