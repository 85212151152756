.tabFirstContainer {
  height: 100%;
  background-color: #fcc583;
  border-radius: 8px 0 0 8px;
  .logocontainer {
    padding:40px 0 25px 0;
  }
  .bar-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 70px;
    gap:12px;
    .bar {
        width: 300px;
        height: 40px;
        border-radius: 24px;
        margin-left: 55px;
        background-color: #ffffff;
        font-size: 14px;
        padding-top: 3px;
        text-align: left;
        padding-left: 12px;
      .right-logo{
        position: relative;
        top: 6px;
        right: 4px;
      }
      .data{
        display: inline-block;
        // width: 183px;
        text-align: left;
      }
      }
  }

}
