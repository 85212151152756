.woCommerce_detail_input {
  .common_label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    // width: 208px;
    margin-bottom: 4px;
  }
  .upeer_label_margin {
    margin-right: 6px;
    margin-bottom: 16px;
  }

  .shop_name {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }
  .shop_url {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }
  .shop_consumer_key {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }
  .shop_consumer_secret {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }

  .clickable_btn {
    background: #36c626;
    border-color: #36c626;
    color: white;
    width: 512px;
    border-radius: 2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 8px;
    margin-bottom: 24px;
    height: 40px;
  }
  .skip-step {
    border: 1px solid #898989;
    margin-top: -16px;
    margin-bottom: 16px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .onboarding-skip {
      width: 512px;
      border: none;
      background: transparent;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
    }
  }

  .shopify-connect-title {
    font-size: 16px;
    color: #ff005e;
    margin: 0;
    letter-spacing: 0.032em;
    line-height: 22px;
  }
  .shopify-connect-subtitle {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .star {
    color: #c62929;
  }
}
