.catalog-product-new {
  background-color: #fff;
  padding: 38px 48px;
  display: flex;
  padding-bottom: 0px !important;
  height: 100%;
  gap: 40px;

  .gallery {
    flex: 1;
    width: 282px;

    .inline-list {
      list-style-type: none;
      margin-bottom: 0px !important;
      padding: 0;
      li {
        display: inline-block;
      }
    }

    .cost-updated {
      color: #898989;
      font-style: italic;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      span {
        color: #212121;
        margin-left: 4px;
      }
    }
  }

  .mt0 {
    margin-top: 0px;
  }

  .mt8 {
    margin-top: 8px;
  }

  .center-details {
    flex: 3;
    .product-title-wraper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .product-name {
        width: fit-content;
        max-width: 445px;
        color: #212121;
        font-weight: 600;
        font-size: 20px;
        text-overflow: ellipsis;

        /* Needed to make it work */
        // overflow: hidden;
        white-space: wrap;
      }

      .product-id-wraper {
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 8px;
        font-size: 12px;
        padding-left: 8px;
        color: #898989;

        .product-id {
          > p {
            margin-right: 4px;
          }
        }
      }
      .line {
        margin-right: 8px;

        margin-top: 8px;
      }
      .product-id {
        display: flex;
        margin-top: 8px;
      }
    }
    .product-sub-heading {
      margin-bottom: 10px;
      font-size: 14px;
      color: #898989;
      margin-bottom: 20px;
    }

    .star-wraper {
      display: flex;
      align-items: center;
      .stars {
        display: flex;
        align-items: center;
      }
    }
    .inventory-status {
      display: flex;
      align-items: center;
      i {
        margin-right: 8px;
      }

      p {
        font-size: 12px;
        color: #898989;
      }
    }

    .new-moq-table {
      border: 2px solid #26b99a;
      .main-heading {
        background-color: #26b99a;
        height: 24px;
        > div {
          color: #fff !important;
          font-size: 12px;
        }
      }

      .main-heading,
      .new-moq-table-row {
        display: flex;
        padding: 12px 0;
        gap: 12px;
        > div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #212121;
        }

        .type {
          justify-content: flex-start;
          padding-left: 24px;
        }
      }

      .new-moq-table-row:nth-child(even) {
        background-color: #f5f5f5;
      }
    }

    .action-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 12px;

      .ant-btn {
        height: 40px;
      }

      .ant-btn-primary {
        background-color: #21a819;
        border: 1px solid #21a819;
        font-size: 16px;
        padding: 0px 38px;
      }

      .ant-btn-primary:hover {
        background-color: #0c7c06 !important;
        border: 1px solid #076f02 !important;
        font-size: 16px;
        padding: 0px 38px;
      }
    }
  }

  .right {
    flex: 1;
    background-color: #f4f4f4;
    border-radius: 8px;
    height: 352px;
    padding: 16px 24px;

    h1 {
      font-size: 12px;
      margin-bottom: 16px;
      color: #898989;
    }

    .bar-content {
      display: flex;
      gap: 12px;
      .bar {
        width: 4px;
        height: 280px;
        background-color: #35446e;
        position: relative;

        .payment,
        .confirmation,
        .dispatch,
        .received {
          position: absolute;
          left: 50%;
          height: 16px;
          min-width: 16px;

          border-radius: 50%;
          transform: translateX(-50%);
          background-color: #21a819;
        }

        .payment {
          border: 2px solid #35446e;
          background-color: #cecece;
        }

        .confirmation {
          top: 50px;
          background-color: #35446e;
        }

        .dispatch {
          top: 170px;

          .dispatch-bar {
            position: relative;
          }
        }
        .dispatch-bar::before {
          content: "";
          height: 90px;
          width: 4px;
          background-color: #20a819;
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .received {
          bottom: 0px;

          border: 2px solid #20a819;
          background-color: #cecece;
        }
      }

      .text {
        > div {
          position: relative;
          font-size: 12px;
          color: #212121;
        }
        .confirmation-text {
          top: 30px;
        }

        .avg-wraper {
          top: 64px;
          left: 24px;

          .avg {
            font-weight: 600;
          }
        }

        .dispatch-text {
          top: 96px;
        }

        .avg-wraper-green {
          top: 116px;
          left: 24px;

          div {
            color: #20a819 !important;
          }

          .avg {
            font-weight: 600;
          }
        }

        .received-text {
          top: 136px;
        }
      }
    }
  }
}
