.woCommerceContant {
  .ant-modal-content {
    padding: 8px 0px;
    border-radius: 0px !important;
    .ant-modal-header {
      padding: 16px 8px;
      width: 530px;
      margin: auto;
    }

    .flex-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > div {
        margin-right: 3px;
      }
    }
    .ant-modal-footer {
      padding: 16px 0px;
      text-align: left !important;
      background: transparent;
      border-top: 1px solid #e8e8e8;
      border-radius: 0 0 4px 4px;
      width: 530px;
      margin: auto;
    }
    // .bottom_button {
    //     // display: none;
    //     padding: 16px 0px;
    //     text-align: left !important;
    //     background: transparent;
    //     border-top: 1px solid #e8e8e8;
    //     border-radius: 0 0 4px 4px;
    //     width: 530px;
    //     margin: auto;

    //   .go_back_btn {
    //     border: none;
    //     box-shadow: none;
    //     font-size: 12px;
    //     color: #212121;
    //     font-weight: 400;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin-right: 4px;
    //     .svg_go_back {
    //       margin-right: 4px;
    //     }
    //   }
    
    //   .go_back_btn:hover {
    //     text-decoration: underline;
    //   }

    //   .clickable_btn {
    //     width: 120px;
    //     background: #28af21;
    //     height: 32px;
    //     border: none;
    //     color: #fff;
    //     font-weight: 500;
    //     font-size: 12px;
    //     letter-spacing: 0.032em;
    //     line-height: 16px;
    //     margin-right: 8px;
    //   }
    //   .cancel_close_btn {
    //     width: 120px;
    //     background: #f5f5f5;
    //     height: 32px;
    //     border: none;
    //     color: #898989;
    //     font-weight: 500;
    //     font-size: 12px;
    //     letter-spacing: 0.032em;
    //     line-height: 16px;
    //     margin-right: 8px;
    //   }
    // }
    .woCommerce_detail_input {
      //   display: flex;
      //   flex-wrap: wrap;
      //   margin-bottom: 32px;

      .common_label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        width: 208px;
        margin-bottom: 4px;
      }
      .upeer_label_margin {
        margin-right: 6px;
        margin-bottom: 16px;
      }

      .shop_name {
        width: 100%;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
      .shop_url {
        width: 100%;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
      .shop_consumer_key {
        width: 100%;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
      .shop_consumer_secret {
        width: 100%;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
    }
  }
.bottom_button{
    text-align: right !important;
        background: transparent;
        border-top: 1px solid #e8e8e8;
        border-radius: 0 0 4px 4px;
        margin: auto;  
        padding-top: 5px; 
}
  .go_back_btn {
    border: none;
    box-shadow: none;
    font-size: 12px;
    color: #212121;
    font-weight: 400;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    margin-right: 4px;
    .svg_go_back {
      margin-right: 4px;
      position: relative;
      top: 4px;
    }
  }

  .go_back_btn:hover {
    text-decoration: underline;
  }
  .not-fill{
    pointer-events: none;
    opacity: 0.5;
}
  .clickable_btn {
    width: 120px;
    background: #28af21;
    height: 32px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-right: 8px;
  }
  .cancel_close_btn {
    width: 120px;
    background: #f5f5f5;
    height: 32px;
    border: none;
    color: #898989;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-right: 8px;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 16px;
    font-size: 12px;
    p {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
      margin-bottom: 0px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }

  .ant-modal-close {
    display: none;
  }
  .star {
    color: #c62929;
  }
}
