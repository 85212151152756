.store-listing {
  .ant-pagination {
    margin-top: 20px;
  }
  .content-page-title {
    margin-bottom: 0px;
  }
  .page-subtitle {
    font-size: 16px;
    color: black;
    margin-bottom: 0px;
  }
  table {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    .store-info {
      flex: 1;
      p {
        font-size: 12px;
        margin-bottom: 0px;
        letter-spacing: 0.032px;
        line-height: 24px;
        color: #212121;
        font-weight: 400;
      }
    }
    .store-status {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        margin-bottom: 0px;
        margin-left: 8px;
        letter-spacing: 0.032px;
        line-height: 24px;
        color: #212121;
        font-weight: 500;
      }
      .ant-switch-checked {
        background-color: #229d1b;
      }
    }
    thead {
      tr {
        th {
          font-size: 12px;
          background: #16273b !important;
          border-radius: 0 !important;
          color: white !important;
          letter-spacing: 0.032px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    tbody {
      background: white;
      tr {
        td {
          font-size: 12px;
          border-bottom-color: #f2f2f2 !important;
          letter-spacing: 0.032px;
          line-height: 24px;
        }
      }
    }
  }
  .ant-switch-small {
    min-width: 32px;
    height: 16px;
    line-height: 14px;
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px !important;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px !important;
  }
  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px !important;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px !important;
  }
  .ant-table-tbody > tr > td :last-child {
    border-bottom: 0px !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px !important;
  }
  .ant-table-tbody > tr {
    border-bottom: 1px solid #cecece !important;
  }
  .ant-table-tbody > tr:last-child {
    border-bottom: 0px !important;
  }
  .shopify-link {
    font-weight: 500;
    letter-spacing: 0.032px;
    line-height: 24px;
    font-size: 12px;
    a {
      color: #1268fb;
    }
    .add-on {
      font-weight: 500;
      color: #212121;
      font-size: 12px;
    }
  }
}

.Re-verify-details {
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  text-decoration: underline !important;
  line-height: 16px;
  margin-bottom: 8px;
}

.reverify_geo {
  font-weight: 600 !important;
  text-decoration: underline !important;
}
// select geo overlay scss

.select-geo {
  .ant-dropdown-menu {
    background-color: #f8f8f8;
    border-radius: 2px;
  }

  .ant-dropdown-menu-item {
    height: 40px;
    display: flex;
    align-items: center;
    width: 105px;
  }

  .ant-dropdown-menu-item:nth-of-type(1) {
    border-bottom: 1px solid #cecece;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 12px;
      color: #898989;
    }
  }
}

// select geo dropdown

.storgeo-dropdown {
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    color: #898989;
  }

  .arrow {
    transition: 0.3s all;
  }
  .arrow-fill {
    transform: rotateX(180deg);
    g {
      fill: #898989;
    }
  }
}

// store geo modal
.store-geo-modal {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  padding: 0;

  width: 398px !important;
  .ant-modal-content {
    border-radius: 2px;
  }
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding: 24px;
  }
  h1 {
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    color: #212121;
    margin-bottom: 5px;
    text-align: center;
  }

  h2 {
    font-size: 12px;
    color: #212121;
    margin: auto;

    text-align: center;
    font-weight: 400;
  }

  h5 {
    font-size: 12px;
    color: #212121;
    margin: auto;

    text-align: center;
    font-weight: 400;
    margin-bottom: 24px;

    span {
      font-weight: 500;
    }
  }

  .content {
    background-color: #f8f8f8;
    padding: 0 16px;

    .div1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 49px;

      p {
        font-size: 12px;
        color: #212121;
      }
    }
    margin-bottom: 24px;

    .flex {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        font-size: 12px;
        color: #898989;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .confirm {
      width: 176px;
      background-color: #2bc822;
      color: white;
      font-size: 16px !important;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .cancel {
      width: 161px;
      background-color: white;
      border: 2px solid #898989;
      color: 898989;
      font-size: 16px;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
