.social-data {
  .popular-link {
    a {
      font-size: 14px;
    }
  }
  .social_stats {
    .facebook-stats {
      padding: 10px;
      background: #eeeeee;
      .stats-info {
        display: flex;
        // background: #ddd;
        // padding: 15px 30px;
      }
      p {
        // width: 50%;
        font-size: 0.9rem;
        font-weight: 400;
        color: #040404;
        span {
          font-weight: 500;
        }
      }
    }
    .engagement-stats {
      padding: 22px;
      background: #eeeeee;
      display: flex;
      justify-content: space-between;
      .ant-row {
        width: 100%;
      }
      .ant-col {
        text-align: center;
        font-size: 10px;
        color: #040404;
        span {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
  .internal-notes {
    margin-top: 20px;
    display: flex;
    p {
      font-size: 12px;
      margin-bottom: 24px;
      color: #595650;
      font-weight: 400;
    }
    .ant-btn {
      border: none;
      background: #ff005e;
      color: white;
      box-shadow: none;
      height: 20px;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .shopify-metrics {
    display: flex;
    margin-bottom: 24px;
    p {
      background: #eeeeee;
      padding: 10px;
      font-size: 14px;
      margin-bottom: 0px;
      color: #040404;
      font-weight: 400;
      margin-right: 15px;
      span {
        font-size: 12px;
        margin-bottom: 0px;
        font-weight: 500;
      }
    }
  }
  .store-list {
    padding-left: 15px;
    margin-bottom: 0px;
    li {
      a {
        color: #ff005e;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .country-list {
    margin-bottom: 0px;
    padding-left: 15px;
    li {
      line-height: 22px;
      color: #838383;
      font-size: 14px;
      font-weight: 400;
    }
  }
  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .instagram-influencers {
    display: flex;
    overflow: auto;
    .insta-influence {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      .insta-img {
        padding: 10px;
        background: #eeeeee;
        margin-right: 10px;
        width: 200px;
        img {
          width: 100%;
          height: 200px;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #0068ff;
          margin-bottom: 0px;
        }
        .ant-btn {
          border: none;
          background: #ffba13;
          font-size: 8px;
          color: white;
          box-shadow: none;
          width: 48px;
          height: 16px;
          padding: 0px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .facebook-images {
    display: flex;
    margin-top: 24px;
    overflow: auto;
    img {
      width: 108px;
      height: 108px;
      margin-right: 10px;
      border: 1px solid black;
    }

    .anticon {
      bottom: 10px;
      position: absolute;
      right: 22px;
      background: #0068ff;
      color: white;
      padding: 5px;
      border-radius: 18px;
      font-size: 15px;
    }
    video {
      width: 120px;
      height: 120px;
      margin-right: 15px;
      margin-bottom: 15px;
      border: 1px solid black;
    }
  }
  .report-graph {
    margin-top: 24px;
    .graph-stat {
      display: flex;
      align-items: center;
      .stat-name {
        margin-right: 20px;
        p {
          font-size: 12px;
          color: #000000d9;
          font-weight: 500;
          .anticon {
            font-size: 10px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
