.onboarding-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  background: url("../../assets/images/backgrounds/onboarding_bg.svg") no-repeat;
  background-size: cover;
  .form-container-card {
    margin: auto;
    padding: 0px 26px 26px 26px;
    text-align: center;
    background-position: bottom right;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 456px;
    margin-top: 7%;
    border-radius: 2px !important;
    .illustartion-img {
      margin: 16px 0px;
      width: 112px;
      height: 112px;
    }
    .header-txt {
      font-size: 16px;
      letter-spacing: 0.032em;
      font-weight: 500;
      line-height: 22px;
      color: #fc125e;
      margin-bottom: 8px;
    }
    .header-subtitle {
      font-size: 12px;
      // letter-spacing: 0.012em;
      line-height: 18px;
      color: #4b4b4b;
    }
    .myaccount-btn {
      margin-top: 24px;
      width: 368px !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
    .anticon {
      margin-top: 8px;
      margin-left: 4px !important;
    }
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
  .btn-green {
    background: #3eca2a;
    border-color: #3eca2a;
    color: white;
    width: 200px;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.032em;
    font-weight: 500;
    height: 40px;
    margin-top: 8px;
    color: #fff;
  }
  .btn-green:active {
    background: #3eca2a;
  }
  .btn-green:hover {
    background-color: #24a51d;
  }
  .onboarding {
    flex: 1;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 2.5vw 15px 0 15px;

    .logo {
      margin-bottom: 18px;
      max-width: 200px;
    }
    .tagline {
      color: rgba(0, 0, 0, 0.45);
      margin: 0 0 42px 0;
    }
    .ant-tabs-bar {
      border: 0;
      text-align: center;
    }

    .login-form-forgot,
    .login-form-signup-here {
      float: right;
    }

    .ant-alert {
      margin-bottom: 15px;
    }

    .login-form-button {
      width: 100%;
      margin: 0 0;
      box-shadow: 0px 1px 2px rgba(0, 104, 255, 0.59);
    }

    .register-tag-line {
      margin: 15px 0 10px 0;
    }

    .login-page-footer {
      position: sticky;
      top: calc(100% - 55px);
      .footer-links {
        list-style-type: none;
        padding: 0;
        li {
          display: inline-block;
          margin: 0 20px;
          a {
            color: rgba(0, 0, 0, 0.45);
          }
          a:hover {
            color: rgb(0, 0, 0);
          }
        }
      }
    }
  }
  .onboarding-sidebar {
    width: 327px;
    background: url("../../assets/images/backgrounds/onboarding-sidebar-bg.svg")
      #16273b;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    a {
      color: rgba(255, 255, 255, 0.4);
      font-size: 15px;
    }
    a:hover {
      color: white;
    }

    .steps {
      display: flex;
      flex: 1;
      align-items: center;
      ul {
        list-style-type: none;
        margin: 0;
        position: relative;
        li {
          color: #737d89;
          font-size: 15px;
          position: relative;
          margin: 10px 0;
        }
        li:before {
          content: "";
          position: absolute;
          left: -34px;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #737d89;
        }
        li.active {
          color: white;
        }
        li.active:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #16273b;
        }
        li.completed:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #36c626;
        }
        li.active:before {
          background: white;
        }
      }
      ul:before {
        content: "";
        position: absolute;
        top: 22px;
        left: 12px;
        width: 4px;
        height: calc(100% - 42px);
        background: #737d89;
      }
    }

    .testimonial {
      color: white;
      text-align: center;
      font-size: 14px;
      .testimonial-user {
        display: flex;
        width: fit-content;
        margin: auto;
        align-items: center;
        img {
          width: 50px;
        }
        p {
          flex: 1;
          margin: 0 0 0 10px;
          text-align: left;
        }
      }
    }
  }
}
