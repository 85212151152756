.ndr-modal {
  .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000;
  }

  .no-top-margin {
    margin-top: 0rem;
  }

  input,
  textarea {
    border: 1.5px solid #979797;
    border-radius: 2px;
    font-size: 12px;
  }

  .ant-form-item-control {
    position: relative;
    line-height: 32px;
    zoom: 1;
    font-size: 12px;
  }
}

.ant-modal-content {
  border-radius: 3px;
}
