.ql-container.ql-snow {
  background: white;
  min-height: 300px;
  font-size: 0.75rem;
  .ql-editor {
    line-height: 1.125rem;
    p {
      strong {
        margin-right: 0.125rem;
      }
    }
  }
}
.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;
}
.hidden {
  display: none;
}
