.shipmentprogressbar_data {
  .shipmentprogressbar_data3 {
    background: #eff5ff;
    margin-bottom: 10px;
    padding: 8px 8px;
    border: 1px solid transparent;
    .line-container {
      margin-top: 24px;
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      .progress-line {
        position: relative;
        margin: 0 auto;
        border-radius: 10px;
        width: 60%;
        background: #cecece;
        height: 8px;
        z-index: 90;
        display: flex;
        justify-content: space-between;
        margin-left: 32px;

        .progress {
          position: absolute;
          width: 75%;
          height: 100%;
          background: #2bc822;
          transition: width 1s ease-in-out;
        }
      }

      .status {
        position: relative;
        top: -6px;
        left: -1px;

        .dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #cecece;
          // border: 5px solid #cecece;
          cursor: pointer;

          .current {
            background: white;
            border: 5px solid orange;
          }

          &.completed {
            position: relative;
            background: #2bc822;
            border: 5px solid #2bc822;
          }
        }
      }
    }

    .shipment_step_color {
      color: #2bc822;
      margin-left: -16px;
      font-size: 12px;
    }
    .original_color {
      color: #cecece;
      margin-left: -16px;
      font-size: 12px;
    }

    .Order_tracking_status p {
      letter-spacing: 0.032em;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
