.dispute-modal {
  width: 672px !important;
  .ant-modal-content {
    padding: 10px 10px;
    .ant-modal-header {
      padding: 16px 0px;
      width: 93.5%;
      margin: auto;
    }
  }
  .ant-modal-body {
    padding-top: 0;
    margin-top: -19px;

    .esc-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding-top: 16px;
      margin-bottom: 12px;
      .details {
        width: 200px;
        p {
          margin-bottom: 4px;
          font-size: 12px;
          color: #898989;
        }
        .id {
          height: 32px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 16px;
          font-weight: 500;
          font-size: 12px;
          color: #1370fc;
        }
        .details {
          width: 200px;
        }
      }
    }
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    :first-child {
      font-size: 16px;
      color: #212121;
    }
    :last-child {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
    }
  }
  .blue-text {
    color: #0068ff;
  }
  .dispute-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px;
    p {
      font-size: 12px;
      color: #000000d9;
      // font-weight: 500;
      margin-bottom: 0px;
    }
  }
  .dispute-form {
    margin-top: 7px;
    .dispute-reason {
      margin-top: 24px;
      p {
        font-size: 12px;
        color: #898989;
        margin-bottom: 5px;
      }
      .ant-select {
        width: 100% !important;
        .ant-select-selection {
          border-radius: 0;
          border: none;
          background: #f5f5f5;
          .ant-select-selection-selected-value {
            font-size: 12px;
          }
          .ant-select-selection__placeholder,
          .ant-select-search__field__placeholder {
            color: #898989;
            font-size: 12px;
            // font-weight: 500;
          }
        }
      }
      textarea.ant-input {
        border: none;
        background: #f5f5f5;
        // font-weight: 500;
        color: #898989;
        font-size: 12px;
        border-radius: 0;
      }
      .ant-upload {
        width: 100%;
        border-radius: 0;
        .ant-btn {
          box-shadow: unset;
          border-radius: 0;
          font-size: 12px;
          color: #898989;
          background-color: #f5f5f5;
          border: none;
          width: 100%;
          // font-weight: 500;
        }
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: none;
    :first-of-type button {
      height: 40px;
      width: 176px;
      border-radius: 2px;
    }
    :last-of-type button {
      height: 40px;
      width: 176px;
      border-radius: 2px;
    }
  }
  .fields {
    width: 100%;
  }

  .collapse_infromation {
    margin-top: 24px;
    .important_guide_txt {
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: -8px;
      color: #212121;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-left: 16px;
      display: flex;
      align-items: center;
    }
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      left: auto !important;
      right: 16px !important;
      margin-top: 16px;
    }
    .following_instructions {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #44433d;
      margin-left: -24px;
    }
    .ant-collapse {
      background-color: #fffbe6 !important;
      border: 1px solid #ffe58f !important;
    }

    .panel-content {
      .left-content {
        .note_txt {
          font-size: 12px;
          letter-spacing: 0.032e;
          letter-spacing: 0.032em;
          font-style: italic;
          margin-right: 8px;
          color: #212121;
          font-weight: 500;
        }
        .vfulfil_txt {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #898989;
          font-style: italic;
          margin-right: 8px;
        }
      }
      .upload_instructions {
        font-size: 12px;
        text-decoration: none;
        list-style-type: none;
        letter-spacing: 0.03em;
        margin-left: -32px;
        color: #212121;
        li {
          margin-bottom: 4px;
          font-size: 12px;
          letter-spacing: 0.032em;
        }
      }
      .uploD_txt {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
        margin-bottom: 8px;
      }
    }
    .book_img img {
      height: 22px;
      margin-right: 8px;
    }
    .ant-collapse-content {
      border-top: 1px solid #fffce6 !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px;
      background: #fffef9 !important;
    }
  }
  .star {
    color: #e61047;
  }
}

li.ant-select-dropdown-menu-item.dispute-option {
  font-size: 12px;
  font-weight: 500;
}

.op {
  border-bottom: 1px solid #cecece;
  color: #898989;
  font-size: 12px;
  font-weight: 400;
}

.op[aria-selected="true"] {
  color: #212121;
  font-weight: 500;
  border-bottom: 1px solid #6fc1ff;
}

.ant-select {
  .ant-select-selection {
    box-shadow: none;
  }
}
