.topPerformingProductHeadWraper {
  * {
    letter-spacing: 0.032em !important;
  }

  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff;
  .topPerformingProductHead {
    height: 56px;
    background-color: #f0f2f659;
    padding: 16px 24px;
    font-size: 12px;
    color: #898989;
    position: relative;
    .name {
      margin-left: 0px !important;
    }

    span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px;
    }

    span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px;
    }
  }

  .product-heads {
    height: 32px;
    padding: 0 24px;
    background-color: #f0f2f659;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    div {
      font-size: 12px;
      font-weight: 500;
      color: #262626;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name-head {
      justify-content: flex-start;
      width: 305px;
    }
    .total-order-head {
      width: 90px;
    }
    .total-delivered-head {
      width: 146px;
    }
    .delivered-percentage-head {
      width: 65px;
    }
    .delivered-order-head {
      width: 190px;
    }
  }

  .product-row-wraper {
    padding-top: 12px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
    .product-row {
      height: 56px;
      padding-left: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        font-size: 12px;
        font-weight: 500;
        color: #898989;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .name-head {
        justify-content: flex-start;
        width: 295px;
        .product-wraper {
          height: 40px;
          display: flex;
          align-items: center;
          .img {
            height: 40px;
            width: 40px;
            border-radius: 2px;
            border: 2px solid #cecece;
            margin-right: 16px;
            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
            }
          }

          .details {
            .name {
              font-size: 12px;
              color: #1162fb;
            }
            .id {
              font-size: 12px;
              color: #898989;
            }

            .name-wraper {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              svg {
                transition: all 0.3s linear;
                opacity: 0;
                margin-left: 6px;
              }
            }

            .name-wraper:hover {
              svg {
                transition: all 0.3s linear;
                opacity: 1;
              }
            }
          }
        }
      }
      .total-order-head {
        width: 90px;
      }
      .total-delivered-head {
        width: 146px;
      }
      .delivered-percentage-head {
        width: 65px;
      }
      .delivered-order-head {
        width: 190px;
      }
    }

    .product-row:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
}

.maps-container {
  display: flex;
  grid-gap: 24px;
  gap: 0px;
  flex-wrap: wrap;
  > div {
    width: 100% !important;
  }
  .maps-wraper {
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    height: fit-content;
    position: relative;
    width: 60%;
    // height: 528px;
    .not-found-wraper {
      margin-bottom: 0px;
    }

    > svg {
      position: relative !important;
      height: 500px !important;
    }

    .map-head-wraper {
      padding: 16px 24px 12px 24px;
      height: 72px;
      background-color: #f0f2f659;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .topPerformingProductHead {
        font-size: 12px;
        color: #898989;
        position: relative;
        margin-bottom: 4px;
        .name {
          margin-left: 0px !important;
        }

        span {
          font-size: 12px;
          color: #898989;
          margin-left: 4px;
        }

        span:first-of-type {
          color: #212121;
          font-size: 16px;
          font-weight: 500;
          margin-right: 4px;
        }
      }

      .product-heads {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        letter-spacing: 0.032em;

        div {
          font-size: 12px;
          font-weight: 400;
          color: #898989;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .name-head {
          justify-content: flex-start;
          // width: 290px;
        }

        .delivered-order-head {
          width: 180px;
          justify-content: flex-end;
          padding-right: 8px;
        }
      }
    }
  }

  .scroller {
    max-height: 430px;
    overflow-x: hidden;
  }
  .scroller.extra {
    max-height: 396px;
  }
  .table {
    position: absolute;
    * {
      letter-spacing: 0.032em;
    }
    right: 32px;
    top: 100px;

    .wraper-first {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;

      height: 24px;
      margin-bottom: 8px;

      .first-row {
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        width: 71%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f0f2f659;
      }

      .second-row {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        width: 29%;
        align-items: center;
        justify-content: center;
      }
    }

    .wraper {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;

      .first-row {
        font-size: 12px;
        width: 150px;
        overflow: hidden;
        padding-left: 6px;
        padding-right: 6px;
      }

      .head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        background-color: #f0f2f659;
      }

      .sub-head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 22px;
      }

      .first-row {
        border-left: 0px;
        justify-content: flex-start;
      }

      .second-row {
        width: 100px;
      }

      .third-row {
        width: 100px;
      }

      .fourth-row {
        width: 70px;
      }
    }
    .wraper.wraper2 {
      border-top: 0px;
      font-weight: 400;
    }
    .wraper.wraper2:nth-child(2n + 1) {
      background-color: #f5f5f5;
    }
  }

  .scroller::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  .scroller::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .scroller::-webkit-scrollbar {
    width: 8px;

    border: 1px solid #898989;
  }
}

.HoverInfo {
  padding: 0px !important;
  min-width: 100px !important;
  max-width: 300px !important;
  z-index: 999999;

  .graph-custom-tooltip {
    border: 1px solid #000;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    opacity: 1;
    z-index: 999999;
    padding: 11px 16px;
    transition: top 200ms, left 200ms;
    font-size: 12px;
    min-width: 150px;
    .bold-5 {
      font-weight: 500;
    }
  }
}
.ant-alert-with-description {
  * {
    letter-spacing: 0.032em;
  }
  .ant-alert-message {
    font-size: 12px;
    font-weight: 600;
    color: #212121;
  }
  .ant-alert-description {
    font-size: 12px;
    color: #212121;
  }
}

.table-first-head {
  background-color: #f0f2f6;
}
