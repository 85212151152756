@import url("https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap");
.right-card-signupcontainer {
  .signup-line {
    // margin-top: -8px;
    margin-bottom: 12px;
    .login-form-signup-here {
      margin-left: 127px;
    }
  }
  .rightlogocontainer {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    top: 3px;
    left: 6px;
  }

  .facebookcontainer {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    top: 2px;
    left: 0;
  }
  .googlecontainer {
    position: relative;
    margin-left: 10px;
    margin-right: 5px;
    top: 3px;
    left: 0;
  }
  .error-phone {
    color: #f5222d;
    line-height: 1;
    padding-bottom: 6px;
  }
  .heading-bar {
    padding-top: 58px;
    padding-left: 56px;
  }
  .main-heading {
    font-size: 28px;
    font-weight: 800;
    font-family: "Martel", serif !important;
    color: #212121;
  }
  .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041;
  }
  .flex-from {
    padding: 10px 10%;
    .flex-form-inner {
      overflow: scroll;
      max-height: 50vh;
      width: 100%;
      padding: 0 2px;
      overflow-x: hidden;
      box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2);
      .ant-form-item {
        margin-bottom: 16px;
      }
      .otp-info {
        font-size: 12px;
        color: #898989;
        font-style: italic;
        line-height: 1;
        margin-top: 6px;
      }
      .input-signup23 {
        height: 40px !important;
        border-radius: 4px !important;
        border: 1px solid #898989;
        background-color: #f5f5f5;
        font-size: 14px;
        padding: 10px 0 10px 16px;
        width: 100%;
      }

      .input-signup22 {
        input,
        select {
          height: 40px !important;
          border-radius: 4px !important;
          border: 1px solid #898989;
          background-color: #f5f5f5;
          font-size: 14px;
          padding: 10px 0 10px 16px;
          width: 100%;
        }
        input {
          height: 40px !important;
          border-radius: 4px !important;
          border: 1px solid #898989;
          background-color: #f5f5f5;
          font-size: 14px;
          padding: 10px 0 10px 16px;
          width: 100%;
        }
        .ant-input-suffix {
          // right: 115px;
        }
      }
      .headingquestion {
        line-height: 1;
        margin-bottom: 8px;
        color: #212121;
      }
      .question-container {
        font-size: 14px !important;
        color: #898989 !important;
        margin-bottom: 24px !important;
      }
      .option-group {
        display: flex;
        width: 496px;
        flex-wrap: wrap;
        .option {
          width: 47%;
          color: #898989 !important;
          margin-bottom: 4px;
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #38b957;
      }
      .ant-radio-checked .ant-radio-inner::after {
        background-color: #38b957;
      }
      .ant-radio-wrapper-checked {
        span {
          color: #212121 !important;
        }
      }
      .ant-radio:hover .ant-radio-inner {
        border-color: #38b957;
      }
      .ant-radio-inner {
        background-color: #f5f5f5;
      }
      .question-container-first {
        margin-top: 24px;
      }
      .question-container-last {
        margin-bottom: 20px !important;
      }

      .phone-relative {
        .ant-form-item-children {
          position: relative;

          p {
            pointer-events: none;
            position: absolute;
            top: 20px;
            left: 75px;
            transform: translate(0%, -50%);
            z-index: 9;
            font-size: 14px;
            color: #898989;
            opacity: 0.5;
          }
        }

        .phoneinput {
          input {
            height: 40px !important;
            border-radius: 4px !important;
            border: 1px solid #898989 !important;
            background-color: #f5f5f5;
            font-size: 14px;
            padding: 10px 0 10px 16px;
            width: 100% !important;
            padding-left: 45px !important;
          }
        }
      }

      .error-phone-input {
        input:focus {
          border: 1px solid red !important ;
        }
      }

      .submitbutton {
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent;
      }
      .facebookbutton {
        background-color: #2275c8;
        text-align: center;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent;
        padding-top: 10px;
      }
      .signupbutton {
        // background-color: #2275C8;
        border-radius: 2px !important;
        text-align: center;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        border: 1px solid #212121;
        font-weight: 500;
        padding-top: 7px;
        color: #212121;
      }
    }
  }
  .flex-form2 {
    padding-left: 56px;
    padding-top: 5px;
    .submitbutton {
      background-color: #38b957;
      border-radius: 2px !important;
      height: 40px !important;
      width: 390px;
      font-size: 14px;
      display: block;
      color: #ffffff;
      font-weight: 500;
      margin-bottom: 12px;
      border: 0px solid transparent;
    }
  }
}
