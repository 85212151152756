.single-filter-wraper {
  * {
    letter-spacing: 0.032em;
  }
  position: relative;
  margin-bottom: 12px;

  .custom-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    left: 16px;
    z-index: 10;
  }

  .ant-select-open {
    svg {
      g {
        fill: #898989;
      }
    }
  }

  .ant-select-selection.ant-select-selection--single {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px;

    .ant-select-selection__rendered {
      height: 40px;

      .ant-select-selection-selected-value {
        height: 40px;
        display: flex !important;
        align-items: center;
        font-size: 12px;
        color: #1268fb;
        font-weight: 500;
        padding-left: 108px;
        .ant-select-selection-selected-value {
          margin-left: 3px;
        }
      }
    }
  }

  .ant-select-arrow {
    margin-top: -7px;
  }
}

.single-filter-overlay {
  * {
    letter-spacing: 0.032em;
  }

  .ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item {
    color: #898989;
    min-height: 40px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #cecece;
    // padding-left: 40px;

    i {
      position: absolute;
      right: 280px !important;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 12px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: #212121;
    font-weight: 500;
    i {
      background-color: #2bc822;
      border-color: #2bc822 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        stroke: 2px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item:hover {
    background-color: #f5faff;
    border-bottom: 1px solid #b5dafe;
    svg {
      color: #898989;
    }
  }
}
