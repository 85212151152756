.redirect-kyc {
  .ant-modal-content {
    border-radius: 2px;
    padding: 24px 24px 0 24px;
    .ant-modal-header {
      padding: 0;
      .icon-container {
        position: relative;
        top: 7px;
        margin-right: 6px;
      }
      .ant-modal-title {
        padding: 0 0 8px;
        .heading-main {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .ant-modal-body {
      padding: 16px 0;
      .steps-kyc-modal {
        font-size: 12px;
        color: #898989;
        .upperheading {
          color: #898989;
          margin-bottom: 24px;
        }
        // .heading-info {
        //   font-weight: 400;
        // }
        .sub-heading {
          margin-bottom: 5px;
        }
        .step {
          font-weight: 600;
        }
        .footer-info {
          margin-top: 5px;
        }
      }
    }
    .ant-modal-footer {
      padding: 16px 0;
      .clickable-green {
        background-color: #2bc822;
        color: #ffffff;
        border-radius: 2px !important;
      }
      .clickable-green:hover {
        border: 1px solid #2bc822;
      }
    }
  }
}
