.ndr-page {
  .bucket-filters-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .custom-arrow-icon {
    pointer-events: none !important;
  }

  .ndr-header-row {
    padding: 0 0 20px 0;
    .content-page-title {
      margin-bottom: 20px;
    }
    .page-subtitle {
      font-size: 16px;
      color: black;
      margin-bottom: 0px;
      span {
        font-size: 14px;
        color: black;
      }
    }
  }
  .tabs-row {
    .ant-tabs-bar {
      padding: 10px 40px 0px 40px;
      margin-bottom: 0px;
    }
    .ant-tabs-tabpane {
      padding: 40px;
    }
  }
  .pick {
    color: #1268fb !important;
    background-color: white !important;
    margin-left: 8px;
    font-size: 12px !important;
    box-shadow: 0px 2px 8px #00000014;
  }
  .ant-row {
    font-size: 14px;
    .content-page-title {
      font-size: 12px;
      text-decoration: underline;
    }
  }

  // ndr page select css
  .select-all-wraper {
    * {
      letter-spacing: 0.032em;
    }
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .select-all {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #898989;
      font-size: 12px;

      .ant-checkbox-wrapper {
        display: flex;
        align-items: flex-end;
        span {
          font-size: 12px;
          color: #898989;
        }
      }

      .divider {
        margin-right: 8px;
      }
    }

    p {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #898989;
      position: relative;

      button {
        margin-left: 8px !important;
        font-weight: 500 !important;
        padding-right: 40px;

        span {
          font-weight: 500 !important;
        }
      }

      .disabled {
        opacity: 0.5;
      }

      .white-arrow {
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-45%);
        right: 16px;
        z-index: 9;
        transform: translateY(-50%) rotateX(0);
        transition: all 0.3s ease 0s;
        svg {
          font-size: 16px;
          g {
            path:last-of-type {
              fill: #fff;
            }
          }
        }
      }

      .white-arrow.open {
        transition: all 0.35s ease 0s;
        transform: translateY(-50%) rotate(180deg);
        svg {
          g {
            fill: #fff;
          }
        }
      }
    }
  }
}

.bucket-drodown-overlay {
  .ant-dropdown-menu {
    background-color: #f8f8f8;
    border-radius: 2px;
  }

  .ant-dropdown-menu-item {
    height: 40px;
    display: flex;
    align-items: center;
    width: 164px !important;
  }

  .ant-dropdown-menu-item:nth-of-type(1) {
    border-bottom: 1px solid #cecece;
  }
  .ant-dropdown-menu-item:hover {
    border-color: #b5dafe !important;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 12px;
      color: #898989;
    }
  }
}

// bulk action modal
.bulk-action-modal {
  width: 420px !important;

  .ant-modal-close-x {
    display: none;
  }

  * {
    letter-spacing: 0.032em;
  }
  .ant-modal-body {
    padding: 30px 16px 32px 16px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 22px;
      p {
        margin-left: 8px;
        font-size: 16px;
        color: #212121;
        font-weight: 500;
      }

      svg {
        font-size: 24px;
      }
    }

    .picker {
      margin-top: 12px;
    }

    .content-data {
      margin-bottom: 26px;
      p {
        font-size: 12px;
        color: #898989;
        margin-bottom: 8px;
        text-align: center;
      }
      h3 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #212121;
      }
    }
    .custom-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .ant-btn-ghost {
      width: 154px;
      height: 40px;
      border-color: #898989;
      font-size: 16px;
    }
    .ant-btn-ghost:hover {
      border-color: #2986ff;
    }
    .ant-btn-primary {
      width: 184px;
      font-size: 16px;
      height: 40px;
      background-color: #2bc822;
      border: #2bc822;
    }
  }
  .ant-modal-footer {
    display: none !important;
  }
  .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000;
  }

  .no-top-margin {
    margin-top: 0rem;
  }

  input,
  textarea {
    border: 1.5px solid #979797;
    border-radius: 2px;
    font-size: 12px;
  }

  .ant-form-item-control {
    position: relative;
    line-height: 32px;
    zoom: 1;
    font-size: 12px;
  }
  .disabled-Btn {
    opacity: 0.5 !important;
    color: #fff;
    span {
      color: #fff;
    }
  }
  .ant-btn-primary {
    opacity: 1;
  }
}
