.maps-container {
  display: flex;
  grid-gap: 24px;
  gap: 0px;
  flex-wrap: wrap;
  > div {
    width: 100% !important;
  }
  .maps-wraper {
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    height: fit-content;
    position: relative;
    width: 60%;
    // height: 528px;
    .not-found-wraper {
      margin-bottom: 0px;
    }

    > svg {
      position: relative !important;
      height: 500px !important;
    }

    .map-head-wraper {
      padding: 16px 24px 12px 24px;
      height: 72px;
      background-color: #f0f2f659;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .topPerformingProductHead {
        font-size: 12px;
        color: #898989;
        position: relative;
        margin-bottom: 4px;
        .name {
          margin-left: 0px !important;
        }

        span {
          font-size: 12px;
          color: #898989;
          margin-left: 4px;
        }

        span:first-of-type {
          color: #212121;
          font-size: 16px;
          font-weight: 500;
          margin-right: 4px;
        }
      }

      .product-heads {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        letter-spacing: 0.032em;

        div {
          font-size: 12px;
          font-weight: 400;
          color: #898989;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .name-head {
          justify-content: flex-start;
          // width: 290px;
        }

        .delivered-order-head {
          width: 180px;
          justify-content: flex-end;
          padding-right: 8px;
        }
      }
    }
  }

  .scroller {
    max-height: 430px;
    overflow-x: hidden;
  }
  .scroller.extra {
    max-height: 396px;
  }
  .map-table-wraper {
    width: 55% !important;
    position: absolute;
    * {
      letter-spacing: 0.032em;
    }
    right: 0.5px;
    top: 72px;

    .wraper-first {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;

      height: 24px;
      margin-bottom: 8px;

      .first-row {
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        width: 71%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f0f2f659;
      }

      .second-row {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        width: 29%;
        align-items: center;
        justify-content: center;
      }
    }

    .wraper {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;

      .first-row {
        font-size: 12px;
        width: 150px;
        overflow: hidden;
        padding-left: 6px;
        padding-right: 6px;
      }

      .head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        background-color: #f0f2f659;
      }

      .sub-head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 22px;
      }

      .first-row {
        border-left: 0px;
        justify-content: flex-start;
      }

      .second-row {
        width: 100px;
      }

      .third-row {
        width: 100px;
      }

      .fourth-row {
        width: 70px;
      }
    }
    .wraper.wraper2 {
      border-top: 0px;
      font-weight: 400;
    }
    .wraper.wraper2:nth-child(2n + 1) {
      background-color: #f5f5f5;
    }
  }

  .scroller::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  .scroller::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .scroller::-webkit-scrollbar {
    width: 8px;

    border: 1px solid #898989;
  }
}

.HoverInfo {
  padding: 0px !important;
  min-width: 100px !important;
  max-width: 300px !important;
  z-index: 999999;

  .graph-custom-tooltip {
    border: 1px solid #000;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    opacity: 1;
    z-index: 999999;
    padding: 11px 16px;
    transition: top 200ms, left 200ms;
    font-size: 12px;
    min-width: 150px;
    .bold-5 {
      font-weight: 500;
    }
  }
}
.ant-alert-with-description {
  * {
    letter-spacing: 0.032em;
  }
  .ant-alert-message {
    font-size: 12px;
    font-weight: 600;
    color: #212121;
  }
  .ant-alert-description {
    font-size: 12px;
    color: #212121;
  }
}

.table-first-head {
  background-color: #f0f2f6;
}

// table css

.map-table-wraper {
  .ant-table-header{
    overflow: hidden !important;
  }
  * {
    letter-spacing: 0.032em;
  }

  overflow-x: scroll;
  overflow-y: hidden;
  float: right;
  min-height: 400px;
  max-height: 500px;

  .ant-spin-container {
    border: 1px solid #e8e8e8;
  }

  .global-tooltip-w {
    i {
      margin-right: 0px !important;
    }
  }

  .global-tooltip-w:hover {
    #icomponent1 {
      stroke: #0c182a;
    }
    #icomponent2 {
      stroke: #0c182a;
    }
  }

  .tooltip-title {
    display: flex !important;
    align-items: center;
    justify-content: center;
    > span {
      width: 40px;
    }

    .global-tooltip-w {
      width: max-content !important;
      min-width: max-content !important;
    }
  }

  .ant-table-thead {
    tr {
      height: 32px;
      th {
        background-color: #f0f2f659;
        color: #fff !important;
        font-size: 12px;
        height: 32px;
        padding: 0px;

        span {
          display: block;
          min-width: 100px;
          text-align: -webkit-center;
        }

        .ant-table-column-title {
          text-align: center;
          color: #212121;
          display: block;
          width: max-content;
        }
      }

      .ant-table-column-has-actions.ant-table-column-has-sorters {
        position: relative;

        .ant-table-column-sorters {
          .ant-table-column-title {
          }
          .ant-table-column-sorter {
            position: absolute;
            right: -36px;
          }
        }
      }

      th:first-of-type {
        // width: 200px !important;
        span {
          display: block;
          //   min-width: 200px;
          text-align: left;
        }
      }
    }
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px;
  }

  .ant-table-body {
    tr {
      td {
        border-bottom: 0px !important;
        border-top: 0px !important;
        font-size: 12px;
        padding: 6px;
        text-align: center;
        span {
          font-size: 12px;
        }
      }

      td:first-of-type {
        // width: 200px !important;
        text-align: left;
        span {
          //   width: 200px !important;
        }
      }
    }
    tr:nth-child(even) {
      background: #f5f5f5;
    }
  }

  .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
    border-top-right-radius: 0px;
  }
  .ant-table-fixed-right {
    .ant-table-thead {
      tr {
        th {
          span {
            text-align: center;
          }
        }
      }
    }

    td {
      font-size: 12px;
      border-bottom: 0px;
      text-align: center;
      padding: 6px;

      span {
        font-size: 12px;
        border-bottom: 0px;
      }
    }

    tr:nth-child(even) {
      background: #f5f5f5;
    }
  }

  .ant-table-fixed-right,
  .ant-table-fixed-right table {
    border-radius: 0 0 0 0;
  }
  .ant-table-fixed-left,
  .ant-table-fixed-left table {
    border-radius: 0 0 0 0;
  }
  .ant-table-fixed-left {
    .ant-table-fixed {
      .ant-table-thead {
        .ant-table-column-sorters {
          width: 100%;
        }
      }
    }
    .ant-table-row.ant-table-row-level-0 {
      height: 30px;
      .ant-table-column-has-actions.ant-table-column-has-sorters {
        padding: 0px !important;
        padding-left: 16px !important;
      }
    }

    .ant-table-thead {
      tr {
        th {
          span {
            text-align: left;
            padding-left: 16px;
            span {
              padding-left: 0px;
            }
          }
        }
      }

      .ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort {
        // display: none;

        .ant-table-column-sorters {
          position: relative;

          .ant-table-column-sorter {
            position: absolute;
          }
        }
      }
    }
    td {
      font-size: 12px;
      border-bottom: 0px;
      text-align: left;
      span {
        font-size: 12px;
        border-bottom: 0px;
      }
    }

    tr:nth-child(even) {
      background: #f5f5f5;
    }
  }
}
