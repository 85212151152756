.onboarding-field {
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  label {
    display: inline-block;
    transform: translateX(35px) scale(1.1);
    transition-duration: 0.3s;
    margin-bottom: 10px;
  }
  .input-addon {
    position: absolute;
    right: 1px;
    bottom: 1px;
    background: #efefef;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }
  .input-field {
    border-radius: 2px;
  }
  .shopify-field:after {
    content: ".myshopify.com";
  }
  .input-field:focus + label {
    transform: translate(0, 3px);
  }
  .input-field:focus,
  .input-field:hover {
    box-shadow: none;
    border: 1px solid black;
  }
}
