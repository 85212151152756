.my-products {
  margin-bottom: 30px;
  // position: relative;
  .ant-card-body {
    padding: 32px;
    position: relative;
    overflow: hidden;
  }
  .ant-card.my-products-card.provisional.ant-card-bordered {
    box-shadow: 0px 3px 10px #0000000d;
    // border: 0px !important;
  }

  .import-product-search .ant-input-group .ant-input {
    height: 40px;
    box-shadow: 0px 2px 8px #00000014;
    border-radius: 2px 0 0 2px;
    border: 0px;
    background: #f8f8f8;
  }
  .linkedStatusDropdown {
    background: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
    width: 288px;
    .ant-select-selection {
      width: 288px;
      border: none !important;
      background: #f8f8f8 !important;
    }
    .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important;
      margin-top: 4px;
      font-size: 12px;
      font-weight: 500;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
      }
    }
  }
  .product-status {
    display: flex;
    float: left;
    align-items: center;
  }
  .ProductLinkStatusDropdown {
    margin-bottom: 0;
    font-size: 12px;
    color: #898989;
    margin-right: 13px;
    margin: 11px 0px 0px 8px;
  }

  .Product_status {
    .ant-select-selection.ant-select-selection--single {
      height: 25px !important;
      border: none !important;
      background: transparent;
      width: 100px !important;
      margin: 5px 0 0 -11px;
      box-shadow: none !important;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #1268fb;
    }
  }
}

.Product_status {
  .ant-select-selection__rendered {
    margin-left: -7px !important;
    color: #1268fb !important;
    font-size: 12px;
  }
  .ant-select-dropdown-menu {
    font-size: 12px !important;
  }
}

.ant-select-dropdown-menu-item:last-child {
  border-bottom: none !important;
}

.commondropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px;
  .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px;
  }
}

.commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 1px solid #b5dafe !important;
  color: #212121;
  font-weight: 500;
  .cancel-status:hover {
    display: none;
  }
}

.not-linked-status {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px;
}

.not-linked-status:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  color: #212121;
  font-weight: 500;
  .cancel-status:hover {
    display: none;
  }
}

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .filter-option .ant-select-arrow {
    top: 44% !important;
    left: 89% !important;
  }
}
