.shipment-info-tab {
  padding: 18px 13px 7px 13px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .line-item {
    background: #f7f7f7;
    margin-bottom: 10px;
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    .line-item-product-detail {
      display: flex;
      align-items: center;
      width: 250px;
      margin-left: 0px;
    }
    .line-item-img {
      width: 56px;
      height: 56px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important;
      // border: 1px solid #898989;
    }
    .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px;
    }
    .line-item-sku {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px;
    }
  }
  .line-items-tab_header_upper_data {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 16px 16px 8px;
    margin: -16px 0 -2px 0;
    color: #898989;
    .flex33 {
      width: 250px;
    }
    .flex44 {
      width: 85px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flex55 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      span {
        margin-right: 20px;
      }
      .Quantity {
        width: 70px;
      }
      .star {
        width: 30px;
      }
      .Shopify_Selling_Price {
        width: 150px;
      }
    }
    .flex66 {
      width: 115px;
      display: flex;
      // align-items: center;
      // justify-content: center;
      padding-left: 29px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  .quntyandpices {
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    color: #000;
    .Quantity {
      width: 70px;
      // text-align: center;
    }
    .star {
      width: 30px;
    }
    .Shopify_Selling_Price {
      width: 150px;
    }
  }
  .end_data {
    width: 115px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding-left: 29px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;

    .rtouppercase {
      text-transform: uppercase;
    }
    .normalcase {
      text-transform: capitalize;
    }
  }
}
