.sidebar {
  background: #192f4a;
  height: calc(100vh);
  // box-shadow: 2px 0 4px rgba(0, 0, 0, 0.57);
  overflow: auto;
  min-width: 200px;
  transition: all 0.3s linear;
  z-index: 999;

  .home {
    svg {
      fill: none !important;
    }
  }

  .logo {
    background: #16273b;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-self: center;
    a {
      display: block;
      img {
        padding: 18px 23px;
        display: block;
        margin: auto;
        width: 75%;
      }
    }
  }
  ul.ant-menu {
    background: #192f4a;
    .ant-menu-submenu {
      min-height: 2rem !important;
      // background-color: red;
      margin-bottom: 8px;
    }
    .ant-menu-submenu .ant-menu-submenu-title {
      // height: 100%;
      min-height: 2rem !important;
      font-size: var(--small-font-size);
    }
    a,
    a:active,
    a:focus,
    a:hover {
      color: rgba(255, 255, 255, 0.65);
      text-decoration: none;
      font-weight: 400;
      letter-spacing: 0.3px;
      font-size: var(--small-font-size);
    }
    i.anticon {
      margin-top: -2px;
      margin-right: 0.5rem !important;
      font-size: 1rem !important;
    }
    .products-count {
      font-size: var(--small-font-size);
      background: red;
      padding: 2px 10px;
      color: white;
      // border: 0.03125rem solid white;
      border-radius: 13px;
      display: inline-block;
      height: 30px;
      line-height: 20px;
      min-width: 30px;
      max-width: 60px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 7px;
    }
    .orders-count {
      font-size: var(--small-font-size);
      background: linear-gradient(135deg, #0077ff, #000aff);
      color: white;
      padding: 2px 10px;
      border: 0.25px solid white;
      border-radius: 13px;
      display: inline-block;
      height: 25px;
      line-height: 20px;
      min-width: 30px;
      max-width: 60px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 7px;
    }
    .sidebar-count {
      // padding: 0 7px;
      margin-right: 10px;
      float: right;
    }
    .anticon {
      font-size: 1rem;
      vertical-align: middle;
      // margin-right: 8px !important;
      opacity: 1 !important;
    }
    .ant-menu-item {
      margin: 0;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      font-size: var(--medium-font-size);
      padding-left: 1rem !important;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 8px;
      height: 2rem !important;
      line-height: 2rem;
    }
    .ant-menu-item:hover {
      a {
        color: white;
      }
    }
    .ant-menu-item:active {
      background: transparent;
    }
    .ant-menu-item-selected {
      background: #081321;
      width: 100%;
      a {
        color: white;
      }
    }
    :after {
      display: none;
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        margin: 0;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        padding-right: 1rem;
        padding-left: 1rem !important;
      }
      .ant-menu-submenu-title:active {
        background: inherit;
      }
      .ant-menu-submenu-title:hover {
        color: white;
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
      .ant-menu-sub {
        .ant-menu-item {
          padding-left: 2.5rem !important;
        }
        .ant-menu-item-selected {
          background: #050c15;
          // color: white;
          border-left: 8px solid #0074ff;
          padding-left: 2rem !important;
          width: 100%;
          height: 2.5rem;
          // line-height: 31px;
        }
        :after {
          display: none;
        }
      }
    }
    .ant-menu-submenu-open.ant-menu-submenu-selected {
      // background: #081321;
      .ant-menu-submenu-title {
        color: white;
        background: #081321;
      }
    }
    .ant-menu-submenu-open {
      .ant-menu-submenu-title {
        background-color: #081321 !important;
        color: white !important;
        height: 32px !important;
      }
    }
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 2rem !important;
  }
  .navbarFlex {
    // background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .navbarFlex2 {
    // background-color: gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    a {
      // background-color: red;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  // .navbarFlex4 {
  //     background-color: gray;
  //     height: 4rem;
  //     display: flex;
  //     justify-content: flex-start;
  //     align-items: center;
  //     margin: 0;
  //     padding: 0;
  //
  // }
  .navbarFlex3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
    .prc {
      position: relative;
      background: #ff006c;
      color: white;
      font-size: var(--small-font-size);
      padding: 2px 10px;
      color: white;
      // border: 0.03125rem solid white;
      border-radius: 13px;
      display: inline-block;
      height: 1.5rem;
      // line-height: 26px;
      min-width: 1.5rem;
      max-width: 3.3rem;
      vertical-align: middle;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .svI {
    color: white;
    fill: white;
  }
  .navbarFlex3 > span {
    margin-right: 10px;
  }
}

.prc2 {
  position: absolute;
  background-image: linear-gradient(to right, #0063f2, #4398ed);
  color: white;
  font-size: var(--small-font-size);
  padding: 2px 10px;
  color: white;
  // border: 0.03125rem solid white;
  border-radius: 2px;
  display: inline-block;
  height: 1.5rem;
  // line-height: 26px;
  min-width: 1.5rem;
  max-width: 3.3rem;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.navbarFlex2 {
  .prc {
    position: relative;
    background: #ff006c;
    color: white;
    font-size: var(--small-font-size);
    padding: 2px 10px;
    color: white;
    // border: 0.03125rem solid white;
    border-radius: 13px;
    display: inline-block;
    height: 1.5rem;
    // line-height: 26px;
    min-width: 1.5rem;
    max-width: 3.3rem;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: -0.5rem;
  }
}

.ant-menu-item-selecte {
  background: transparent !important;
}

@media screen and (min-width: 180px) and (max-width: 1439px) {
  .sidebar {
    background: #192f4a;
    height: calc(100vh);
    // box-shadow: 2px 0 4px rgba(0, 0, 0, 0.57);
    overflow: auto;
    min-width: 200px !important;
    max-width: 200px !important;
    transition: all 0.3s linear;
  }
  .sidebar .navbarFlex2 a {
    margin-right: 1.5rem;
  }

  .prc2 {
    right: 0.65rem;
  }
}

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .sidebar {
    background: #192f4a;
    height: calc(100vh);
    // box-shadow: 2px 0 4px rgba(0, 0, 0, 0.57);
    overflow: auto;
    min-width: 240px !important;
    max-width: 240px !important;
    transition: all 0.3s linear;
  }
  .sidebar ul.ant-menu .ant-menu-item {
    padding-left: 1.5rem !important;
    padding-right: 2rem !important;
    transition: all 0.3s linear;
  }
  .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    transition: all 0.3s linear;
  }
  .sidebar ul.ant-menu i.anticon {
    margin-right: 0.5rem !important;
    transition: all 0.3s linear;
  }
  .sidebar .navbarFlex2 a {
    width: 100% !important;
    transition: all 0.3s linear;
  }
  .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item {
    padding-left: 3rem !important;
    transition: all 0.3s linear;
  }
  .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item-selected {
    padding-left: 2.5rem !important;
  }
  .sidebar ul.ant-menu .sidebar-count {
    margin-right: 0 !important;
  }
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px !important;
}

.upheader {
  // transition: 0.6s linear all;
  // transform: translateY(0px);
  margin-top: 0px !important;
}

.downheader {
  // transition: 0.6s linear all;
  // transform: translateY(56px);
  margin-top: 56px !important;
}

// .upheader1 {
//   transition: 0.6s linear all;
//   transform: translateY(-56px);
// }

// .downheader2 {
//   transition: 0.6s linear all;
//   transform: translateY(56px);
// }

.feedback-widget-overlay {
  left: auto !important;
  right: 18px !important;
}
