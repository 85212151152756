.oreder_card_new {
  margin-bottom: 32px;
  letter-spacing: 0.032em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 32px !important;
  position: relative;

  .rto-badge {
    position: absolute;
    top: 12px;
    right: 12px;

    color: #fff;
    padding: 1px 6px;
    font-size: 12px;
    font-weight: 500 !important;
    border-radius: 5px;
  }

  .rto-badge.custom-pos {
    right: 160px !important;
  }

  .rto-badge.high {
    background-color: #e61047;
  }

  .rto-badge.no {
    background-color: #73d13d;
  }

  .rto-badge.moderate {
    background-color: #ffc53d;
    color: #212121;
  }

  .in-proces-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 12px;
      color: #898989;
    }

    p {
      span {
        color: #212121 !important;
      }
    }
  }
  .ant-card-body {
    padding: 0 !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .btn-on-hold {
    // background-color: #1370fc;
    color: #898989;
    // border-color: #1370fc;
    font-size: 12px;
    // padding: 8px;
    // border-radius: 3px;
    font-weight: 500;
    border-radius: 2px;
  }
  .vforderid_tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    .vf_order {
      // padding: 3px;
      // position: absolute;
      left: 0px;
      top: 23px;
      // background: #1161fb;
      // color: white;
      // border-bottom-left-radius: 0;
      // font-size: 12px;
      // margin-right: 8px;
      // border-top-right-radius: 2px;
      // border-bottom-right-radius: 2px;
      // width: 168px;
      // text-align: center;
    }

    .product-tag-wraper {
      // position: absolute;
      left: 220px;
      top: 23px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > p {
        margin-right: 6px;
        font-size: 12px;
        color: #898989;
      }

      .more {
        font-size: 12px;
        color: #898989;
        cursor: pointer;
      }
    }
    .not_serviceable {
      // position: absolute;
      left: 196px;
      top: 22px;
      background: #dd5725;
      color: white;
      font-size: 8px;
      margin-right: 8px;
      border-radius: 3px;
      height: 24px;
      padding: 4px;
      display: flex;
      align-items: center;
      margin-top: 1px;
      justify-content: center;
      width: 152px;
      height: 24px;
    }
    .order_tooltip svg {
      position: absolute;
      left: 163px;
      top: 3px;
      color: #191818;
      font-size: 8px;
      // height: 24px;
      display: flex;
      align-items: center;
    }
  }
  .middleupperdata {
    display: flex;
    width: 100%;

    text-align: center;
    padding: 24px 10px 24px 24px;
    margin: 0px 0 -8px 0px;

    .right_data {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      h3 {
        margin-right: 48px;
        display: flex;
        align-items: center;
      }
    }
    .left_data {
      width: 28%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 24px;
      .dropdownmenu {
        width: 128px;
        height: 32px;
        background: #1161fb;
        color: #fff;
        position: relative;
        border-radius: 2px;
      }
      .dropdownmenu2 {
        width: 128px;
        height: 32px;
        background: #e61047;
        color: #fff;
        position: relative;
        border-radius: 2px;
      }
      .dropdownmenu2:hover {
        background: #c70c3c;
      }

      .verified-dropdownmenu {
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: center;
      }
      .tooltip-title {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        text-align: center;
      }
    }
  }
  .mr-32 {
    width: 40px;
    display: flex;
    justify-content: center;
  }
  .mr-33 {
    width: 40px;
    display: flex;
    justify-content: center;
  }

  .mr-3 {
    margin-right: 8px;
  }
  .mr-4 {
    margin-right: 8px;
  }
  span {
    vertical-align: middle;
    i {
      vertical-align: middle;
    }
  }
  .blue-text {
    color: #0068ff;
  }
  .tabs-row {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 0 0 24px;
      height: 40px;
      background: #16273b;
      li {
        display: inline-block;
        padding: 8px 24px;
        color: white;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 24px;
      }
      li.active,
      li:hover {
        background: #244060;
      }
    }
    .bulk-action-btn {
      letter-spacing: 0.032em;
      background: transparent;
      color: white;
      border: 1px solid #fff;
      border-radius: 2px;
      font-weight: 400;
      line-height: 16px;
    }
    .ant-avatar {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
    }
  }

  .add-note-btn,
  .view-notes-btn {
    border-color: #0068ff;
    color: #0068ff;
    font-size: 12px;
  }
  .add-note-btn:hover,
  .view-notes-btn:hover {
    border: 1px solid #034eb8 !important;
    color: #034eb8 !important;
    box-shadow: none;

    svg {
      path {
        fill: #034eb8;
      }
    }
  }
  .order-card span {
    vertical-align: middle;
  }
  .ant-dropdown-button.ant-btn-group
    > .ant-btn:last-child:not(:first-child):not(:disabled) {
    background: #1370fc;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.15) !important;
  }

  .ant-btn-group {
    .ant-btn {
      vertical-align: middle;
    }
  }
  .tab-container {
    background: #f5f5f5;
    padding: 0 24px 24px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .or-txt {
      text-align: center;
    }
  }
  .line-items-tab {
    padding: 16px 16px 8px 16px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .line-item {
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 14px 12px;
      display: flex;
      justify-content: space-between;
      border: 1px solid transparent;

      // .ant-checkbox-wrapper {
      //   transform: scale(1.15);
      // }
      // .ant-checkbox {
      //   .ant-checkbox-inner {
      //     border: 1px solid #ededed;
      //   }
      // }
      .quantity-field {
        width: 70px !important;
        margin-right: 8px !important;
        margin-left: -6px !important;
        border-radius: 2px !important;
      }
      .anticon {
        font-size: 22px;
      }
      .line-item-product-detail {
        display: flex;
        align-items: center;
        width: 250px;
        margin-left: 0px;
        span p {
          margin-bottom: -2px;
        }
      }
      .line-item-img {
        width: 56px;
        height: 56px;
        margin-right: 8px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        border: 0px solid #c8c8c8;
      }
      .line-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
        width: 150px;
      }
      .line-item-sku {
        font-size: 12px;
        color: #000;
        font-weight: 400;
        margin: 0;
        overflow: inherit !important;
        a {
          color: #1161fb;
        }
        a:hover {
          // color: #1161fb;
          text-decoration: underline !important;
        }
      }
      .line-item-name-sku {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
        width: 150px;
      }
      .line-item-name-Specs {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        width: 150px;
        color: #000;
        font-weight: 400;
        margin: 0;
      }
    }
    .line-item.selected {
      background: #eeffe5;
      border: 1px solid #73d13d;
    }
    .edit-quantity-btn {
      background: transparent;
      // border: 1px solid #ff005e;
      // border-radius: 4px;
      color: #ff005e;
      border: none;
      cursor: pointer;
    }
    // .edit-quantity-btn:hover {
    //   background: #ff005e;
    //   border: 1px solid #ff005e;
    //   box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
    //   color: white;
    // }
    .line-items-tab_header_upper_data {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 16px 16px 8px;
      margin: -16px 0 -2px 0;
    }

    .flex33 {
      width: 250px;
    }
    .flex44 {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flex55 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      span {
        margin-right: 20px;
      }
      .Quantity {
        width: 70px;
      }
      .star {
        width: 30px;
      }
      .Shopify_Selling_Price {
        width: 150px;
      }
    }
    .flex66 {
      width: 115px;
      display: flex;
      justify-content: flex-start;
    }

    .verify-linked-bundle {
      width: 115px;
      display: flex;
      padding-left: 6px;
    }

    .openordersvg {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 18px;
      }
    }
    .quntyandpices {
      display: flex;
      width: 250px;
      justify-content: space-evenly;
      align-items: center;
      font-size: 12px;
      .Quantity {
        width: 70px;
        // text-align: center;
      }
      .star {
        width: 30px;
      }
      .Shopify_Selling_Price {
        width: 150px;
      }
    }
    .editbtn {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
    .shopify_selling_price {
      text-align: center;
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 8px 16px;
      border: 1px solid transparent;
      font-size: 12px;
    }
  }

  .shipments-tab {
    padding: 16px 8px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .no-shipment {
      text-align: center;
    }
    .shipment-header {
      background: #f2f2f2;
      padding: 16px 16px;
      margin-bottom: 5px;
      color: black;
    }
    .shipment-items {
      background: #eff5ff;
      // padding: 5px 10px;
      margin-top: -5px;
    }
    .shipment-item {
      background: transparent;
      padding: 8px 8px;
      display: flex;
      align-items: center;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: 1px solid #aaa;
        }
      }
      .anticon {
        font-size: 18px;
      }
      .shipment-item-product-detail {
        display: flex;
        align-items: center;
      }
      .shipment-item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        border: 1px solid #8989;
      }
      .shipment-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
      }
      .shipment-item-sku {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin: 0;
        margin-top: -2px;
      }
    }
    .shipment-item:nth-of-type(2n) {
      background: white;
    }
    .font_text_size {
      font-size: 12px;
      font-weight: 500;
      color: #898989;
    }
  }
  .customer-info-tab {
    background: white;
    padding: 8px 16px;
    .ant-row {
      margin-bottom: 15px;
    }

    .ant-input:read-only {
      background: #f5f5f5;
      cursor: no-drop;
    }

    .ant-form-item-control {
      width: 32%;
      line-height: 32px;
      .ant-form-explain {
        font-size: 12px !important;
        padding-top: 4px;
      }
    }
    .btn-edit-customer {
      color: #ff005e;
      border: 1px solid #ff005e;
      border-radius: 0px;
      font-size: 12px;
      width: 168px;
      letter-spacing: 0.032em;
      line-height: 22px;
    }
    .btn-edit-customer:hover {
      color: #fff;
      background: #ff005e;
    }
    .btn-save-customer {
      background: #73d13d;
      border-color: #73d13d;
      color: white;
      border-radius: 0px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 22px;
      width: 168px;
    }

    .customerinfo_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: 1px solid #212121;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
      .ant-input-number {
        position: relative;
        font-size: 12px;
        margin: 0;
        padding: 0;
        border-radius: 0px;
        margin-right: 8px;
        border: 1px solid #212121;
        width: 100%;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
    }

    .customerinfo-header-editable-state {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: none;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-weight: 400;
        color: #898989;
      }
      .ant-input-number {
        position: relative;
        font-size: 12px;
        margin: 0;
        padding: 0;
        border-radius: 0px;
        margin-right: 8px;
        border: none;
        width: 100%;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-weight: 400;
        color: #898989;
      }
    }

    .customerinfo_middledata {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: 1px solid #212121;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }

    .customerinfo-middledata-editable-state {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-input {
        border-radius: 0px;
        font-size: 12px;
        border: none;
        margin-right: 24px;
        color: #898989;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }

    .customerinfo_footerdata {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: 1px solid #212121;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }

    .customerinfo-footerdata-editable-state {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-input {
        border-radius: 0px;
        font-size: 12px;
        border: none;
        margin-right: 24px;
        color: #898989;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
  }
  // .ant-checkbox-inner {
  //   border: 1px solid #ededed;
  //   border-radius: 2px;
  // }

  .myinput {
    .ant-checkbox-inner {
      background-color: #ededed;
    }
  }
  .ant-checkbox-inner::after {
    width: 5.71428571px;
    height: 9.14285714px;
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #1370fc;
    font-size: 12px;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #1370fc;
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background-color: #1370fc;
    border-color: #1370fc;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: transparent;
  }

  .line-item-sku svg g path {
    stroke: #1161fb;
    text-decoration: underline;
  }
  .line-item-sku:hover svg g path {
    stroke: #1161fb;
    text-decoration: underline;
  }
  .error_tag {
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 0px;
    top: 8px;
    background: #f44336;
    color: white;
    font-size: 12px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  // .error_tag:before {
  //   content: "";
  //   position: absolute;
  //   height: 0;
  //   width: 0;
  //   top: 100%;
  //   right: 0px;
  //   border-bottom: 9px solid #f44336;
  //   border-left: 9px solid transparent;
  //   transform: rotate(180deg);
  // }
}

.line-item-name a:hover {
  text-decoration: underline !important;
}

.custom_dropdown2 .ant-dropdown-menu-item {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
}
.custom_dropdown2 .ant-dropdown-menu-item-active {
  box-shadow: 0px -1px 0px #b5dafe inset;
  border-bottom: none !important;
  a {
    color: #212121;
  }
}

.oreder_card_new.provisional {
  border: 1px solid #ff7d7b !important;
  .left_data {
    .dropdownmenu {
      background-color: gray !important;
    }
  }
}

// .custom_dropdown {
//   .ant-dropdown-placement-bottomLeft {
//     display: none;
//   }

//   .ant-dropdown-menu-item > a,
//   .ant-dropdown-menu-submenu-title > a {
//     border-bottom: 1px solid #cecece;
//   }
// }
.custom_dropdown {
  cursor: pointer;
  .disable {
    cursor: not-allowed !important;
    background-color: gray !important;
  }
}
.disable-dropdown {
  cursor: not-allowed !important;
  background-color: gray !important;
}
.diable-dropdownmenu {
  background: gray !important;
}
.order_tooltip {
  .order-tooltip-icon {
    margin-right: 8px;
  }
}

.order_tooltip:hover svg.order-tooltip-icon {
  background: #c70c3c;
  border-radius: 50%;
  cursor: pointer;
}
.order_tooltip:hover svg.order-tooltip-icon g text {
  fill: #fff;
}
.order_tooltip:hover svg.order-tooltip-icon g > g {
  stroke: #fff;
}

.order_tooltip2:hover svg.order-tooltip-icon {
  background: #1370fc;
  border-radius: 50%;
  cursor: pointer;
}

.order_tooltip2:hover svg.order-tooltip-icon g text {
  fill: #fff;
}
.order_tooltip2:hover svg.order-tooltip-icon g > g {
  stroke: #fff;
}

.provisional-remark {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  text-align: center;
}

.tag-overlay {
  .ant-tooltip-inner {
    background: #fff !important;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
  }
  .ant-tooltip-arrow::before {
    background: #fff !important;
  }
}
