.coupon-code-field {
  margin-bottom: 4px !important;

  input {
    height: 34px;
  }
  .ant-input-group-addon {
    padding: 0px !important;

    > button {
      border: none;
      margin-top: 0px;
      box-shadow: none;
    }
  }

  .anticon-spin {
    fill: blue;
  }
}
