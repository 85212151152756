.onboarding-header {
  text-align: center;
  .title {
    font-size: 24px;
    color: #ff005e;
    margin: 0;
  }
  .subtitle {
    font-size: 16px;
    color: #4b4b4b;
  }
}
