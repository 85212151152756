.notification {
  width: 300px;
  padding: 0px;
  border-radius: 3px;

  .img-wraper {
    margin-right: 16px;
    min-width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e8e8e8;
  }

  a {
    display: flex;
    padding: 16px 16px 13px;
    border-bottom: 1px solid #f5f5f5;
    margin: 0px -12px 0px 0px;
  }
  p {
    font-size: var(--extra-medium-small-font-size);
    margin-bottom: 0px;
    color: #898989;
    span {
      color: #898989;
      font-weight: 100;
      font-size: var(--small-font-size);
      white-space: break-spaces;
      line-height: 16px;
      padding: 3px 0px;
      letter-spacing: 0.032em;
    }
  }
  span {
    color: #898989;
    font-weight: 400;
    font-size: 13px;
    float: right;
    letter-spacing: 0.032em;
  }
  .notification-footer .ant-dropdown-menu-item-group-title {
    padding: 0px !important;
  }

  .notification-heading {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    word-break: break-all;
    white-space: break-spaces;
    padding: 3px 0px 0px;
    text-decoration: underline;
    letter-spacing: 0.032em;
  }

  .notification-header-color {
    color: #212121;
  }
}

.notification:hover span {
  // color: #212121 !important;
}

.notification:hover p {
  color: #212121;
  // font-weight: 500;
}

.notification-header:hover {
  background: #fff;
}

.notification-header button:hover {
  text-decoration: underline;
}

.notification:hover a {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe;
}

.notifications-dropdown {
  padding: 0px 10px 0px 10px;
  overflow: scroll;
  max-height: 345px;
  width: 312px;
  margin-top: 1.5rem;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  position: sticky;
  background: white;
  top: 0;
  border-bottom: 1px solid #f5f5f5;
  p {
    margin-bottom: 0px;
    font-size: var(--small-font-size);
    font-weight: 500;
    color: #212121;
    letter-spacing: 0.032em;
  }
  button {
    border: none;
    font-size: var(--extra-small-font-size);
    font-weight: 500;
    color: #000000b8;
    background: transparent;
    width: unset;
    padding: 0px;
    cursor: pointer;

    font-size: 12px;
    color: #0043ff;
    letter-spacing: 0.032em;
    font-weight: 400;
  }
}

button:focus {
  outline: unset;
}

.notification-footer {
  padding: 0px;
  background: white;
  ul {
    padding-left: 0px;
    li {
      padding: 0px;
    }
  }
  button {
    width: 100%;
    border: none;
    border-radius: 0px;
    background: #f9f9fb;
    padding: 10px;
    font-size: var(--small-font-size);
    font-weight: 500;
    letter-spacing: 0.032em;
    color: #548fd9;
    cursor: pointer;
  }
  .notification-detail-view {
    text-align: center;
    color: #2198ff;
    font-size: 12px;
    cursor: pointer;
    margin: 0;
  }
}

.nav-notification {
  display: flex !important;
  align-items: center;
  .bell-without-hover {
    display: block;
    font-size: 21px;
    margin-right: 0px;
    color: black;
  }
  .bell-on-hover {
    display: none;
    font-size: 21px;
    margin-right: 0px;
    vertical-align: middle;
  }
  .notification-arrow {
    margin-left: 5px;
    font-size: 16px;
    transition: all 0.5s;
  }
  .notification-arrow.transform-up {
    transform: rotateX(180deg);
    g {
      fill: #898989;
    }
  }
}

.nav-notification:hover {
  .bell-on-hover {
    display: block;
  }
  .bell-without-hover {
    display: none;
  }
}

.ant-badge {
  display: flex;
  align-items: center;
}

.ant-badge-count {
  right: 25px;
}
