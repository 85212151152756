@import url("https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap");
.otpVerifyContaner {
  color: #212121;
  min-height: 550px;
  .heading-bar {
    padding-top: 58px;
    padding-left: 42.4px;
  }
  .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121;
  }
  .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041;
  }
  .email-section {
    padding-top: 24px;
    padding-left: 42.4px;
    padding-bottom: 16px;
    .input-email {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px;
    }
    .edit {
      margin-left: 4px;
      .editiconContainer {
        position: relative;
        top: 2px;
      }
      .edit-s {
        text-decoration: underline;
        color: #fc125e;
      }
    }
    .otp-info {
      font-size: 12px;
      font-style: italic;
      color: #898989;
    }
  }
  .otp-section {
    padding-left: 42.4px;
    .otp-verify {
      .otp-number {
        width: 56px;
        height: 40px;
        height: 40px !important;
        border-radius: 4px !important;
        border: 1px solid #898989;
        background-color: #f5f5f5;
        font-size: 14px;
        margin-right: 6px;
        padding-left: 25px;
        border: 1px solid #898989;
      }
      .otp-number:focus {
        border: 1px solid #3a2ccd;
      }
    }
    .msg-otp {
      font-size: 12px;
      font-style: italic;
      color: #898989;
      margin-top: 6px;
      margin-bottom: 4px;
    }
    .resend {
      border: 0;
      background-color: #ffffff;
      text-decoration: underline;
      color: #3b2dd1;
      cursor: pointer;
    }
    .disableresend {
      text-decoration: underline;
      opacity: 0.6;
      pointer-events: none;
    }
    .time-container {
      color: #898989;
    }
    .submit-section {
      padding-top: 199px;
      .verify-container {
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent;
      }
      .disable-verify-container{
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent;
        opacity: 0.6;

      }
      .go-back-btn {
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        color: #898989;
        border: 1px solid #898989;
      }
      .rightlogocontainer {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        top: 3px;
        left: 6px;
      }
    }
  }
  .change-btn-container {
    .change-emailbtn {
      background-color: #38b957;
      border-radius: 2px !important;
      height: 40px !important;
      width: 390px !important;
      font-size: 14px;
      display: block;
      color: #ffffff;
      font-weight: 500;
      margin-bottom: 12px;
      border: 0px solid transparent;
    }
  }
  .ant-btn[disabled]{
    opacity: 0.6;
  }
}
