.catalog-listing {
  background: #f0f2f5;

  .div-sidebar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h1 {
      margin-right: 8px;
    }
  }

  .f8 {
    font-size: 8px;
  }
  .f10 {
    font-size: 10px;
  }
  .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 0.4rem;
    .special-cost-txt {
      color: #898989;
      margin-right: 8px;
    }
  }
  .product-card-cost {
    margin-bottom: 4px;
    align-items: center;
    display: flex;
  }
  .f16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em;
  }
  .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em;
  }
  .mr0 {
    margin-right: 0px;
  }
  .ml0 {
    margin-left: 0px;
  }
  .mt0 {
    margin-top: 0px;
  }
  .mb0 {
    margin-bottom: 0px;
  }
  .mb4 {
    margin-bottom: 4px;
  }
  .mr8 {
    margin-right: 8px;
  }
  .ml8 {
    margin-left: 8px;
  }
  .mt8 {
    margin-top: 8px;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .mr16 {
    margin-right: 16px;
  }
  .ml16 {
    margin-left: 16px;
  }
  .mt16 {
    margin-top: 10px;
  }
  .mb16 {
    margin-bottom: 10px;
  }
  .w100 {
    width: 100%;
  }
  .py8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .px8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .py2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .px2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .semibold {
    font-weight: 500;
  }
  .bold {
    font-weight: 600;
    .default_retail_price {
      font-size: 12px;
      margin-left: -10px;
      margin-right: 8px;
      font-style: italic;
      letter-spacing: 0.032em;
      text-decoration: line-through;
      text-decoration-color: #898989;
    }
  }
  .italic {
    font-style: italic;
    margin-top: 8px;
  }
  .underline {
    text-decoration: underline !important;
  }
  button {
    cursor: pointer;
  }
  .clamp1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .niche-item {
    font-size: 12px;
    color: #212121;
    text-transform: capitalize;
  }
  .catalog-listing-content {
    width: 90% !important;
    margin: auto;
    display: flex;
    padding-top: 24px;
    .content-sidebar {
      width: 248px;
      margin-right: 24px;
      .filters {
        background: white;
        padding: 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 0px;

        .no-filters {
          height: 24px;
          background-color: #eeeeee;
          color: #898989;
          font-size: 12px;
          text-align: left;
          padding-left: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .filter-tag {
          display: block;
          color: #acacac;
          background: #eeeeee;
          padding: 8px 16px;
          margin-bottom: 16px;
          letter-spacing: 0.025em;
          display: flex;
          align-items: center;
          .filter-available {
            height: 16px;
            margin-right: 8px;
          }
          .buyback {
            height: 16px;
            margin-right: 8px;
          }
        }
        .filter-tag.active {
          color: #212121;
        }
        .location-tag.active {
          background: #2bc822;
          color: white;
        }

        .ican-image {
          height: 16px;
          margin-right: 8px;
          .anticon svg {
            width: 16px;
            height: 16px;
          }
        }
        .filter-text {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
        }

        .ant-checkbox-inner {
          border: none;
        }
      }
      .filter-product-data {
        margin-top: 27px;
      }
    }
    .content {
      flex: 1;
    }
    .catalog-listing-labelsection {
      margin-bottom: 30px;
      .catalog-listing-labelhead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;
        border-bottom: 2px solid #000;
        padding-bottom: 0px;
        font-size: 18px;
      }
    }
  }
  .product-Tooltipican:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer;
  }
  .product-Tooltipican:hover svg.product-tooltip-icon g text {
    fill: #fff;
  }
  .product-Tooltipican:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb;
  }
  .top-import-button {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background: rgb(43, 200, 34);
    padding: 8px 16px;
    color: #fff;
    max-height: 40px;
    border-radius: 2px;
    border: none;
  }
  .product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    .product-card {
      background: white;
      margin-bottom: 24px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      flex-direction: column;
      .potd-label {
        position: absolute;
        background: linear-gradient(150deg, #fd104d, #f0320f);
        padding: 4px 8px;
        color: white;
        top: 8px;
        z-index: 2;
      }
      .product-watchlist-btn {
        position: absolute;
        top: 0;
        right: 16px;
        padding: 0;
        border: none;
        background: transparent;
        .anticon {
          position: relative;
          left: 16px;
          svg {
            width: 48px;
            height: 48px;
          }
        }
      }

      .content {
        padding: 16px;
        padding-bottom: 0px;
        display: flex;
        .sorter {
          .ant-dropdown-link.ant-dropdown-trigger {
            color: #0068ff !important;
          }
        }

        .product-img {
          height: 168px;
          position: relative;
          overflow: hidden;
          border-radius: 2px;
          margin-right: 16px;
          margin-bottom: 4px;
          img {
            width: 168px;
            height: 168px;
            object-fit: cover;
            border-radius: 2px;
            border: 2px solid #f5f5f5;
            transition: all 0.5s ease;
          }
          .prev-img,
          .next-img {
            position: absolute;
            transition: all 0.5s ease;
            height: 100%;
            width: 32px;
            background: rgba(33, 33, 33, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            display: none;
          }
          .prev-img {
            top: 0;
            left: 0;
          }
          .next-img {
            top: 0;
            right: 0;
          }
        }
        .product-img:hover {
          img {
            transition: all 0.5s ease;
            transform: scale(1.05);
          }
          .prev-img,
          .next-img {
            transition: all 0.5s ease;
            opacity: 1;
            transform: scale(1.05);
          }
        }
        .product-img:hover {
          .prev-img,
          .next-img {
            display: block;
          }
        }
        .details {
          flex: 1;
          // a:hover {
          //   text-decoration: underline !important;
          // }
        }
      }
    }
  }
  .product-stock-count {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 6px;
  }
  .stock-processing-time {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 6px;
  }
  .product-research-txt {
    margin-top: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .product-tooltip-icon {
    margin-top: -2px;
  }
  .sv:hover {
    fill: #0068ff;
  }
  .anticon:hover {
    svg {
      #Ellipse_44 {
        stroke: #1268fb;
        fill: #1268fb;
      }
      #Ellipse_47 {
        stroke: #fff;
        fill: #ffff;
      }
      #Rectangle_2462 {
        stroke: #fff;
        fill: #ffff;
      }
    }
  }
  .product-Tooltipican svg {
    width: 16px;
    height: 16px;
  }

  .prev-img svg {
    width: 18px;
    height: 41px;
  }
  .next-img svg {
    width: 18px;
    height: 41px;
  }
  .cost-update-date {
    color: #212121;
  }

  .filter-range {
    color: #acacac;
    background: #eeeeee;
    padding: 16px;
    margin-bottom: 16px;
    letter-spacing: 0.025em;
    .inventory-restocking-time-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      font-weight: 400;
      margin-right: 8px;
    }
    .refresh-svg .anticon svg {
      width: 8px;
      height: 8px;
      margin-bottom: 2px;
    }

    .ant-slider-rail {
      height: 4px;
      background-color: #cecece;
      border-radius: 0px;
    }
    .ant-slider:hover .ant-slider-rail {
      background-color: #cecece;
    }

    .ant-slider-handle {
      background-color: #2bc822;
      border: solid 2px #2bc822;
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: #2bc822;
    }
    .type-range {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .start-range {
        width: 56px;
        height: 24px;
        border: none;
        color: #212121;
        font-size: 12px;
        text-align: center;
      }
      .end-range {
        width: 56px;
        height: 24px;
        border: none;
        color: #212121;
        font-size: 12px;
        text-align: center;
      }
      .days {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
    .ant-slider {
      margin: 8px 0px 16px;
    }

    .ant-slider-track {
      background-color: #36c626;
    }
  }

  .set-range-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    margin-left: -2px;
  }
  .request_lattest_ican {
    display: flex;
    justify-content: space-between;
    margin-bottom: -19px;
  }
  .request_lattest_ican2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .sorter-link {
    .arrow {
      transition: all 0.3s linear;
      transform: rotateX(0deg) !important;
      margin-left: 4px;
      svg {
        font-size: 16px;
      }

      vertical-align: middle;
    }

    .arrow-up {
      transform: rotateX(180deg) !important;
      transition: all 0.3s linear;
    }
  }

  .catalog-listing-labelhead {
    display: flex;
    align-items: center;
    border-bottom: none !important;
  }

  .catalog-listing-labelviewall {
    padding: 0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid #212121;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    position: relative;
    z-index: 999;

    p {
      color: #212121 !important;
    }
    // background: linear-gradient(28deg, #333239, #3b393f);

    .white-underline-animation {
      display: inline-block;
      position: relative;
      color: #ffffff;
    }

    .white-underline-animation:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      color: #fff;
      background-color: #fff;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    .forward {
      svg {
        transition: all 0.4s;

        transform: rotate(-90deg);
      }
    }
  }

  .catalog-listing-labelviewall:hover {
    background-color: #212121 !important;
    p {
      color: #fff !important;
    }
    .white-underline-animation:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    svg {
      g {
        path:nth-of-type(2) {
          fill: #fff;
        }
      }

      path {
        fill: #fff;
      }
    }

    .backward {
      svg {
        transition: all 0.4s;

        transform: rotate(90deg) translateY(6px) !important;
      }
    }

    .forward {
      svg {
        transition: all 0.4s;

        transform: rotate(-90deg) translateY(6px) !important;
      }
    }
  }

  .ant-divider-horizontal {
    margin: -1px 0 32px 0 !important;
    height: 2px;
    background-color: #21d4fd;
    background-image: -webkit-linear-gradient(71deg, #21d4fd 0%, #b721ff 100%);
    background-image: linear-gradient(19deg, #35343a 0%, #f0f2f6 90%);
  }

  .catalog-listing-labelname {
    font-size: 14px;
    letter-spacing: 0.032em;
    color: #fff;
    font-weight: 500;
    margin: 0 !important;
    margin-bottom: 0px !important;
    // height: 52px !important;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    padding: 0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    height: 36px;
    display: flex;
    align-items: center;
    background: linear-gradient(28deg, #333239, #3b393f);
  }

  .viewmore-label-products {
    cursor: pointer;
    font-size: 12px !important;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #fff;

    svg {
      transition: all 0.4s;
      g {
        path:nth-of-type(2) {
          fill: #212121;
        }
      }
    }
  }

  .viewmore-label-products.backward {
    svg {
      transform: rotate(90deg);
    }
  }
}

.content {
  .sorter {
    .ant-dropdown-link.ant-dropdown-trigger {
      color: #0068ff !important;
    }
  }
}

@media screen and (min-width: 1360px) {
  .catalog-listing {
    .catalog-listing-content {
      width: 90%;
      margin: auto;
    }
  }
}
