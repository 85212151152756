.wireSuccessModal1 {
  .copy-all {
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-decoration: underline;
    margin-top: 6px;
  }

  .ant-modal-body {
    min-height: 631px;
  }
  .transaction-sucess-content {
    h1 {
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: bold;
      margin-top: 10px;
      color: #242424;
    }
    p {
      font-size: 16px;
      color: #242424;
      font-weight: 500;
    }
    .anticon {
      font-size: 55px;
    }
  }
  .account-details {
    p {
      color: #242424;
      font-size: 14px;
      span {
        font-weight: 600 !important;
      }
    }
  }
  .copy-message {
    background: #f6f6f6;
    padding: 20px;
    margin-top: 34px;
    p {
      color: #242424;
      font-size: 14px;
      span {
        font-weight: 600;
      }
    }
    .ant-btn {
      padding: 0px 16px;
      text-align: center;
      background: #00b50e;
      font-size: 18px;
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      height: 48px;
      width: 320px;
      border-radius: 2px;
      border: none;
    }
  }
  .footer-button {
    margin-top: 30px;
    margin-top: 36px;
    .ant-btn {
      // padding: 0px 16px;
      // text-align: center;
      // background: #00b50e;
      font-size: 18px;
      // color: white;
      font-weight: 600;
      // letter-spacing: 1px;
      // height: 48px;
      // border-radius: 0px;
      // border: none;
      color: #00b50e;
      .anticon {
        transform: rotate(180deg);
        margin-left: 10px;
        color: #00b50e;
        path {
          fill: #00b50e;
        }
      }
    }
  }
}
