.shaker-card.plan-card {
  background-color: #fff;
  width: 300px;
  border: 1px solid #536be8;
  border-radius: 8px;
  position: relative;
  padding: 26px 12px 42px 12px;
  display: flex;
  flex-direction: column;
  background: #ededff !important;
  gap: 24px;
  margin-top: -12px;

  .footer-btn {
    button {
      background-color: #536be8 !important;
      border-radius: 2px;
      border: 1px solid #536be8;

      margin-top: 12px;
      width: 100%;

      i {
        svg {
          fill: #fff !important;
        }
      }

      span {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
      }
    }
  }

  .shaker-cards-wraper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 12px;
  }

  .top-bar {
    height: 64px;
    background-color: #536be8;
    border-radius: 6px 6px 0 0;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    .trend {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: #fff;
      margin-top: 8px;
      p {
        font-size: 14px;
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }

  .bottom-bar {
    height: 10px;
    width: 100%;
    background-color: #3b3b3b;
    border-radius: 0px 0px 6px 6px;
    left: 0px;
    position: absolute;
    bottom: 0px;
  }

  .price-card {
    background-color: #fff;
    border-radius: 6px;
    padding: 16px 12px 16px 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    h1 {
      font-size: 24px;
      color: #4a4be4;
      font-weight: 600;
      margin-bottom: 2px;
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700;
      }
      span {
        color: #898989;
        font-size: 16px !important;
      }
    }

    span {
      font-size: 12px;
      color: #898989;
    }

    button {
      background-color: #536be8 !important;
      border-radius: 2px;
      border: 1px solid #536be8;

      margin-top: 12px;
      width: 100%;

      i {
        svg {
          fill: #fff !important;
        }
      }

      span {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
      }
    }
  }

  .illustration-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    svg {
      display: block;
      min-width: 72px;
    }

    p {
      color: #212121;
      font-size: 12px;
      font-family: "Helvetica", "Arial", sans-serif !important;
      line-height: 18px;
      span {
        text-decoration: underline;
      }
    }
  }

  .features-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    h1 {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 15px;
      text-align: left;
    }

    .features {
      padding: 12px;
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .feature-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-size: 12px;
        color: #212121;

        p {
          span {
            font-weight: 500;
          }
        }

        svg {
          display: block;
          min-width: 16px;
        }
      }
    }
  }

  .save-more-card {
    h1 {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: #212121;
      margin-bottom: 8px;
      gap: 4px;
      span {
        font-size: 20px;
      }
    }

    .save-row-card {
      padding: 12px;
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .save-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-size: 12px;
        color: #212121;

        svg {
          display: block;
          min-width: 16px;
        }
      }
    }
  }

  .brands-card {
    h1 {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      color: #212121;
      margin-bottom: 10px;
    }

    .brands-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .prtool-logo {
        rect {
          fill: linear-gradient(red, yellow) !important;
        }
      }
    }
  }
}
