.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent {
  height: 32px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  h1 {
    font-size: 16px;
    color: #1c6513;
    font-weight: medium;
    margin: 0;
    padding: 0;
    line-height: 0;
    margin-right: 16px;
  }
}

// .discount {
//   height: 32px;
//   width: 100%;
//   background: #f5f5f5;
//   box-shadow: inset 0px 0px 0px 2px #f12f1a;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .first {
//     display: flex;
//     height: 100%;
//     align-items: center;
//     justify-content: space-around;
//     width: 75px;
//     background-image: linear-gradient(to left, #f0320f, #fd104d);
//     h1 {
//       color: white;
//       font-size: 12px;
//       margin: 0;
//       padding: 0;
//     }
//   }
//   .second {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     .s-left {
//       h2 {
//         font-size: 12px;
//         margin: 0;
//         padding: 0;
//         text-decoration: line-through;
//         text-decoration-color: #898989;
//       }
//     }
//     .s-right {
//       h2 {
//         font-size: 12px;
//         color: #1c6513;
//         font-weight: medium;
//         margin: 0;
//         padding: 0;
//       }
//     }
//   }
// }

// - discount
// .discount2 {
//   height: 32px;
//   width: 100%;
//   background: #f5f5f5;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   .first {
//     display: flex;
//     height: 100%;
//     align-items: center;
//     justify-content: center;
//     width: 75px;
//     background-image: linear-gradient(to left, #f0320f, #fd104d);
//     h1 {
//       color: white;
//       font-size: 12px;
//       margin: 0;
//       padding: 0;
//     }
//   }
//   .second {
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//     width: 100%;
//     .s-left {
//       h2 {
//         font-size: 10px;
//         margin: 0;
//         padding: 0;
//         margin-right: 4px;
//         text-decoration: line-through;
//         text-decoration-color: #898989;
//       }
//     }
//     .s-right {
//       h2 {
//         font-size: 12px;
//         color: #1c6513;
//         font-weight: medium;
//         margin: 0;
//         padding: 0;
//         margin-left: 4px;
//       }
//     }
//   }
// }

.gst-partner {
  font-size: 10px;
  font-weight: 500;
  // font-style: italic;

  color: #898989;
}
