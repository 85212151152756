.fill3 {
  .active {
    color: #1268fb;
    border-bottom: 2px #1268fb solid;
  }
  margin-bottom: -20px;
}

.pick {
  color: #1268fb !important;
  background-color: white !important;
  margin-left: 8px;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px #00000014;
}
.content-page-subtitle {
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em;
}
.custom_dropdown3 {
  .review_line_btn {
    border: none;
    box-shadow: none;
    background: transparent;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: transparent !important;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .reverse_pickup_status {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Raise_Escalation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .pointer_escalations {
    color: #00000040;
  }

  .raise_escalation_btn {
    margin-left: -11px;
  }
}

.custom_dropdown3 .ant-dropdown-menu-item {
  border-bottom: 1px solid #cecece !important;
  padding: 4px 0px 0px 0px !important;
}

.bulk-list-wraper {
  .detailswrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    > p {
      font-size: 12px;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 500;
        margin: 0px 6px;
      }
    }

    margin-top: 8px;
    .right {
      margin-left: 16px;

      .err {
        border-color: red !important;
        background-color: transparent !important;
        color: red !important;
      }
    }
  }

  .lst-wraper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12px;

    .left {
      transition: all 1s linear;
      background: #e6eaee;
      width: 100%;
      padding: 8px 12px 0px 12px;
      border-radius: 4px;
      border: 1px solid #b2b2b2;
    }

    .ant-tag {
      margin-bottom: 8px;
      border-radius: 2px;
      padding: 2px 6px;
      border-color: #b5daff;
      background-color: #f5faff;
    }

    .left::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    .left::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: #898989;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .left::-webkit-scrollbar {
      width: 8px;
      border: 1px solid #898989;
    }

    .left {
      display: flex;
      align-items: center;
      min-height: 20px;
      max-height: 80px;
      overflow-y: scroll;
      flex-wrap: wrap;

      p {
        margin-right: 8px;
      }
    }
    .right {
      margin-bottom: 12px;
    }
  }
}
