.counter-card-wraper {
  * {
    letter-spacing: 0.032em;
  }
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: fit-content;

  .counter-card {
    border-radius: 2px;
    width: 24.19%;

    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    .head {
      padding: 16px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      background-color: #0c182a;
      color: #fff;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      .global-tooltip-w {
        i {
          margin-right: 0px !important;
        }
      }

      .global-tooltip-w:hover {
        #icomponent1 {
          stroke: #0c182a;
        }
        #icomponent2 {
          stroke: #0c182a;
        }
      }
    }
    .body {
      padding: 12px 16px;
      height: fit-content;

      background-color: #fff;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .amount {
        display: flex;
        align-items: baseline;
        margin-bottom: 16px;
        span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.651);
          margin-right: 6px !important;
        }
        .amountp {
          font-size: 18px;
          color: #081321;
          font-weight: 500;
        }
        p {
          font-size: 18px;
          color: #081321;
          font-weight: 500;
        }
      }
      .h-line {
        height: 1px;
        background-color: #e8e8e8;
        margin-bottom: 8px;
      }

      .flex-wraper-count {
        display: flex;
        // gap: 24px;
        .attempt {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
        }

        // .attempt:last-of-type::after {
        //   content: "";
        //   height: 16px;
        //   width: 2px;
        //   position: absolute;
        //   left: -13px;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   background-color: #85888d;
        // }
      }

      .count {
        * {
          transition: all 0.3s linear !important;
        }

        display: flex;
        align-items: center;

        position: relative;
        // margin-bottom: 16px;
        // margin-top: 8px;
        span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.651);
          margin-right: 6px;
        }

        p {
          font-size: 14px;
          color: #707070;
        }

        .icon {
          position: absolute;
          right: 0px;
          bottom: -4px;
          height: 24px;
          width: 24px;
          // background: #1268fb;
          // border: 1px solid #1268fb;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            // height: 6px;

            transform: rotate(268deg);

            margin-left: 2px;
            path {
              fill: #212121;
            }
          }
        }
      }

      .count:hover {
        * {
          transition: all 0.3s linear !important;
        }

        .icon {
          position: absolute;
          right: 0px;
          bottom: -4px;
          height: 24px;
          width: 24px;
          // background: #1268fb;
          // border: 1px solid #1268fb;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            // height: 6px;

            transform: rotate(0deg);

            margin-left: 2px;
            path {
              fill: #1268fb;
            }
          }
        }
      }
    }
    .body-percentage {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      padding: 12px 16px;
      height: 106px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #081321;
      font-weight: 500;
    }
  }
}
.counter-card-wraper.trans {
  .counter-card {
    width: 23.79% !important;
  }
}

// sorter

.data-sorter {
  display: flex;
  align-items: center;

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #6e6e6e !important;
  }

  .ant-select-selection {
    background-color: transparent;
    border: none;
    border-radius: 0px;
  }

  .ant-select-selection__rendered {
    margin-left: 6px !important;
    margin-right: 32px !important;
  }

  .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em;
    margin-right: 4px;
  }

  a {
    font-size: 12px !important;
    color: #1268fb !important;
    letter-spacing: 0.032em !important;
    font-weight: 500 !important;
  }
  .anticon {
    font-size: 16px;
    margin-left: 4px !important;
    transition: all 0.5s;
  }
  .arrow-down {
    transform: rotateX(0deg);
  }
  .arrow-up {
    transform: rotateX(180deg);
    g {
      fill: #898989;
    }
  }
}

.sorter-dropdwon-item {
  .active {
    font-weight: 500;
    color: #000000a6;
  }
}

// custom tooltip div

.custom-div-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 280px;
  .line {
    height: 1px;
    margin: 12px 0px;
    width: 212px;
    background-color: #898989 !important;
  }

  h2 {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 12px;
  }

  p {
    font-weight: 500;

    font-size: 14px;
  }
}

.custom-bg-tooltip {
  .ant-tooltip-arrow::before {
    background-color: #1370fc !important;
    width: 9px;
    height: 10px;
  }

  .ant-tooltip-content {
    border-radius: 5px;
    border: 2px solid #1370fc !important;
    box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 50px 30px;
  }

  .ant-tooltip-inner {
    background-color: #fff !important;

    h2 {
      color: #898989;
    }

    color: #212121;
  }
}
