.form-container-card {
  box-shadow: 2px 2px 50px #cacaca !important;
  .ant-card-body {
    // padding: 8px;
  }
}

.green {
  border: 1px solid green;
}

.red {
  border: 1px solid red;
}

.onboarding-header {
  .title {
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 16px !important;
    line-height: 22px;
  }
}

.questions-form-step1 {
  overflow-x: auto !important;
  min-height: 30vh !important;
  max-height: 59vh;
  padding-right: 25px;
  margin-bottom: 8px;
  .op {
    font-size: 12px !important;
  }
  .opt {
    font-size: 12px !important;
  }
  text-align: left;
  p {
    font-size: 12px;
    color: #000000;
    margin-bottom: 6px;
    font-weight: 400;
  }
  input {
    height: 32px;
    background: #f5f5f5;
    border: none;
    width: 100%;
    font-size: 12px;
  }
  input:focus {
    outline: none !important;
  }
  // input {
  //   font-size: 14px;
  //   color: #000000;
  //   margin-bottom: 20px !important;
  //   outline: none;
  //   border: none;
  // }
  .ant-radio-group {
    width: 100%;
    .radiobutton_data {
      display: flex;
      // height: 34px;
      // align-items: center;
      // width: 50%;
      // background-color: #2d95ef;
      justify-content: space-between;
      align-items: center;
      div {
        width: 48%;
        background-color: #efefef;
        height: 32px;
        display: flex;
        align-items: center;
        label {
          padding-left: 10px;
        }
      }
    }
  }
  .form-input {
    // p {
    //   font-size:16px;
    //   color: #000000;
    //   margin-bottom: 10px;
    // }
    // input {
    //   font-size:14px;
    //   color: #000000;
    //   margin-bottom: 20px !important;
    //   outline: none;
    // }
    .dd {
      margin-bottom: 20px;
      input {
        padding-left: 10px;
      }
    }
    .nn {
      margin-bottom: 20px;
    }
  }
  .select-tab {
    background: #efefef;
    color: #4b4b4b;
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid transparent;
  }
  .select-tab.active {
    background: #e6f4ff;
    border: 1px solid #2d95ef;
  }
  .questions-form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    .step-count {
      color: #9b9b9b;
    }
  }

  .form-input input {
    height: 32px;
    background: #f5f5f5;
    border: none;
    width: 100%;
    // padding: 10px;
  }
  .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    // background: #f5f5f5;
    border-radius: 0;
    // border: 1px solid #898989;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
}

.iti {
  position: relative;
  display: inline-block;
  width: 100%;
}

.flna {
  display: flex;
  justify-content: space-between;
}

.questions-form-step1::-webkit-scrollbar {
  width: 5px;
}

.questions-form-step1::-webkit-scrollbar-thumb {
  background: #888;
}

.questions-form-step1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-select-selection__placeholder {
  font-size: 12px !important;
}

.react-tel-input {
  .flag-dropdown {
    border: none !important;
  }
  .form-control {
    background: #f5f5f5 !important;
    border: none !important;
    width: 100% !important;
  }
}

.selected-flag:hover {
  background: #f5f5f5 !important;
}

.custom-phn {
  border: 1px solid red !important;
}

.next {
  margin-top: 0px !important;
  background: #3eca2a;
  border-color: #3eca2a;
  color: white;
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.032em;
  font-weight: 500;
  height: 40px;
}

.skip {
  margin-top: 0px !important;

  border-color: #898989;
  color: gray;
  width: 100px;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.032em;
  font-weight: 500;
  height: 40px;
  margin-right: 16px;
}
