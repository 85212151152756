.dispute-raised-modal {
    .dispute-raised-header {
        width: 100%;
        .di {
            width: 88%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                font-size: 12px;
                margin-right: 10px;
            }
            h6 {
                margin: 0;
                padding: 0;
            }
        }
        text-align: center;
        p {
            font-size: 12px;
            color: #898989;
            line-height: 15px;
            text-align: left;
            width: 86%;
            margin: auto;
        }
        h6 {
            font-size: 16px;
            color: black;
            font-weight: 500;
        }
        .anticon {
            font-size: 76px;
            color: #00cc00;
            margin-bottom: 15px;
        }
        .ant-btn {
            background: #00cc00;
            color: white;
            border: none;
            box-shadow: none;
            width: 264px;
            height: 40px;
        }
    }
    .ant-modal-body {
        padding: 20px 10px 30px;
    }
}