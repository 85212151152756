.sourcing-card {
  background: white;
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  .amount-info {
    position: relative;
    top: 2px;
    svg {
      height: 12px;
    }
  }
  .media-attachments {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
   .item-container{
    position: relative;
   }
    .image-div {
      margin-top: 10px;
      margin-right: 10px;
      border: 1px solid #898989;
      
      img {
        width: 100px;
        height: 100px;
        cursor: pointer;
        padding: 3px;
      }
    }
    .video-data {
      width: 100px;
      height: 100px;
      cursor: pointer;
    }
    .download-image-data {
      position: absolute;
      bottom: 3px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 69px;
    }
  }
  .edd-date {
    display: flex;
    .heading {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-top: 6px;
      font-weight: 400;
      margin-right: 8px;
    }

    .calender-container {
      position: relative;
      top: 3px;
      svg {
        fill: #1268fb;
      }
    }
    .field-value {
      font-size: 12px;
      position: relative;
      top: 9px;
      font-weight: 600;
      .iconCantainer {
        margin-left: 2px;
        position: relative;
      }
    }
    .field-value:hover {
      svg {
        #Ellipse_44 {
          stroke: #1268fb;
          fill: #1268fb;
        }
        #Ellipse_47 {
          stroke: #fff;
          fill: #ffff;
        }
        #Rectangle_2462 {
          stroke: #fff;
          fill: #ffff;
        }
      }
    }
    .field-value1 {
      font-size: 12px;
      position: relative;
      top: 9px;
      font-weight: 600;
    }
  }

  .gst-field {
    font-size: 9px;
    color: #898989;
    line-height: 12px;
    font-weight: 400 !important ;
    display: block;
  }
  .top-section {
    display: flex;
    position: relative;
    padding: 32px;
    padding-bottom: 0px;

    .vforderid_tag {
      position: absolute;
      left: 0;
      z-index: 9;
      top: 23px;
    }
    .status-label {
      position: absolute;
      top: 24px;
      right: 32px;
      font-weight: 500;
      padding: 4px 0;
      text-align: center;
      font-size: 12px;
      color: white;
      border-radius: 2px;
      z-index: 9;
      height: 24px;
      letter-spacing: 0.032em;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .circle {
        height: 10px;
        width: 10px;
        margin: 0px 10px;
        background-color: #fff;
        border-radius: 50%;
      }
      padding-right: 10px;
    }
    .img-container {
      width: 160px;
      margin-right: 24px;
      height: 160px;
      img {
        width: 160px;
        height: 160px;
        border: 2px solid #f5f5f5;
      }
      button {
        position: absolute;
        top: 0;
        width: 32px;
        height: 100%;
        background: rgba(33, 33, 33, 0.4);
        border: none;
        cursor: pointer;
        display: none;
      }
      .next-img {
        right: 0;
      }
      .prev-img {
        left: 0;
      }
    }
    .img-container:hover {
      button {
        display: block;
      }
    }
    .basic-details {
      flex: 1;
      min-width: 0;
      .product-description {
        font-size: 16px;
        color: #212121;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        font-size: 16px;
        letter-spacing: 0.032em;
        line-height: 22px;
        font-weight: 500;
        color: #212121;
        margin-top: 3px;
        text-transform: capitalize;
      }
      .product-title-link a:hover {
        text-decoration: underline !important;
      }
    }
    .fields {
      display: flex;
      border-top: 1px solid #cecece;
      padding-top: 16px;
      margin-top: 8px;
      width: 100%;
      .field {
        flex: 1;
        margin-right: 20px;
        .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          font-weight: 400;
        }
        .field-value {
          font-size: 12px;
          padding: 8px 16px;
          background: #f5f5f5;
        }
      }
      .field:last-of-type {
        margin-right: 0;
      }
    }

    .bottom-fields {
      display: flex;
      padding-top: 16px;
      width: 100%;
      flex-wrap: wrap;
      row-gap: 24px;

      .field {
        width: 168px;
        margin-right: 24px;
        .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          font-weight: 400;
        }
        .field-value {
          font-size: 13px;
          padding: 8px 16px;
          font-weight: 600;
          background: #f5f5f5;
        }
      }
      .field-value.black {
        color: #212121 !important;
        text-align: center;
      }
      .field:last-of-type {
        margin-right: 0;
      }
    }

    .fields2 {
      display: flex;
      // border-top: 1px solid #cecece;
      padding-top: 10px;
      // margin-top: -16px;
      .field {
        flex: 1;
        margin-right: 24px;
        .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          font-weight: 400;
        }
        .field-value {
          font-size: 12px;
          padding: 8px 16px;
          background: #f5f5f5;
        }
      }
      .field:last-of-type {
        margin-right: 0;
      }
    }
  }
  .tabs-header {
    background: #16273b;
    height: 40px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 32px;
      li {
        display: inline-block;
        button {
          border: none;
          background: transparent;
          margin-right: 24px;
          font-size: 12px;
          padding: 10px 15px;
          color: #fff;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em;
          .tab-count {
            display: inline-block;
            background: #ff9400;
            width: 20px;
            height: 20px;
            border-radius: 16px;
            margin-left: 6px;
            text-align: center;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

      .add-notes-btn button {
        background: transparent;
        font-size: 12px;
        padding: 10px 15px;
        color: #fff;
        cursor: pointer;
        line-height: 20px;
        letter-spacing: 0.032em;
        border: 1px solid #fff;
        display: flex;
        height: 32px;
        margin-top: 4px;
        align-items: center;
      }
    }
  }
  .tabs-content {
    background: #f5f5f5;
    padding: 0 32px 32px 32px;
    letter-spacing: 0.032em;
    border-radius: 4px;
  }
  .flex {
    display: flex;
    align-items: center;
  }

  .flex-notes-logs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    margin-top: 8px;
    font-size: 12px;
    .flex-note-logs {
      width: 45%;
    }
    .flex-date {
      width: 27%;
    }
    .flex-addedby {
      width: 25%;
    }
  }

  .notes-detail {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px;
  }

  .flex-3 {
    flex: 2;
  }
  .flex-2 {
    flex: 3;
  }
  .flex-1 {
    flex: 1;
  }
  .text-center{
    text-align: center;
  }

  .anticon {
    margin-left: 4px;
  }

  .amount-tooltip-ican {
    .anticon:hover {
      svg {
        #Ellipse_44 {
          stroke: #1268fb;
          fill: #1268fb;
        }
        #Ellipse_47 {
          stroke: #fff;
          fill: #ffff;
        }
        #Rectangle_2462 {
          stroke: #fff;
          fill: #ffff;
        }
      }
    }
  }

  .tab-container {
    background: #f5f5f5;
    padding: 0px 0px 0px 0px;
    margin-top: 16px;
    border-radius: 4px;
  }

  .no-data {
    text-align: center;
    font-size: 12px;
    background: #fff;
    padding: 16px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }

  .booking-request-date {
    .booking-request-created-date {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 4px;
      font-weight: 400;
      margin-top: 8px;
    }
    .booking-request-field-value {
      font-size: 12px;
      padding: 8px 16px;
      background: #f5f5f5;
      width: 23%;
    }
  }

  .add-notes-btn {
    float: right;
  }

  .display-block-notes {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: block;
  }
  .display-none-notes {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: none;
  }

  .display-block-logs {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: block;
  }
  .display-none-logs {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: none;
  }
  .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 16px;
  }

  .right-cancel-btn {
    width: 102px;
    position: absolute;
    top: 57px;
    right: 22px;
    font-weight: 500;
    padding: 4px 0;
    text-align: center;
    font-size: 12px;
    border-radius: 2px;
    z-index: 9;
    height: 24px;
    letter-spacing: 0.032em;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    .cancel-btn {
      width: 120px;
      height: 32px;
    }
  }
  .green-color {
    color: #1c6513 !important;
    text-align: center;
  }
  .quantity-txt {
    color: #212121 !important;
    text-align: center;
  }

  // inv

  .invtype {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    .vr {
      width: 1px;
      background-color: #898989;
      height: 8px;
      margin: 0 8px;
    }

    p {
      font-size: 12px !important;
      margin: 0 !important;
      color: #898989 !important;
      margin-right: 4px !important;
    }

    span {
      font-size: 12px !important;
      font-weight: 500;
      color: #212121;
    }
  }
  .image-row {
    display: flex;
    .img-cointainer {
      height: 110px;
      width: 110px;
      position: relative;
      margin-right: 10px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .io-container {
        width: 110px;
        margin-right: 10px;
        height: 110px;
        margin-bottom: 12px;
        display: inline-block;
        background-color: lavender;
        svg {
          display: flex;
          margin: auto;
          height: 110px;
        }
      }
      .download-icon {
        position: absolute;
        top: 80px;
        right: 13px;
        transform: scale(2);
      }
    }
  }
  .heading-custom-image {
    margin-bottom: 12px;
    font-size: 12px;
  }
  .remarks-row {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 16px;
    font-size: 12px;
    flex-wrap: wrap;
    .remarks {
      margin-top: 12px;
      width: 90%;
      .remarks-heading {
        font-weight: 600;
        margin-right: 0px;
      }
    }
    button {
      align-self: center;
    }
  }
}
.edd-test {
  font-size: 12px;
  max-width: 350px;
  border-bottom: 1px solid gray;
  margin-bottom: 5px;
  .remark {
    margin-bottom: 10px;
    .heading {
      margin-right: 6px;
      font-weight: 600;
    }
  }
}
.edd-test:last-of-type {
  border-bottom: 0px solid gray;
}
.edd-log-text {
  font-size: 10px;
  margin-bottom: 3px;
  display: flex;
  .item {
    margin-right: 12px;

    .heading {
      margin-right: 6px;
      font-weight: 600;
    }
  }
}
.heading-eddlogs {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
}
