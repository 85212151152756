.add-dispute-modal {
  .ant-select-dropdown-menu-item-selected {
    color: red;
  }
  width: 672px !important;
  .ant-modal-content {
    border-radius: 2px !important;
  }
  .ant-modal-header {
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-bottom: 8px;
    padding-top: 30px;
  }
  .ant-modal-body {
    padding: 0px 24px 6px 24px;
    .dispute-form {
      margin-top: 7px;
      width: 97%;
      margin: auto;
      .dispute-reason {
        margin-top: 14px;
        p {
          font-size: 12px;
          color: #898989;
          margin-bottom: 5px;
        }
        .ant-select {
          width: 100% !important;
          .ant-select-selection {
            border-radius: 2px;
            font-size: 12px;
            border: none;
            background: #eaeaea;
            box-shadow: none;
            .ant-select-selection__placeholder,
            .ant-select-search__field__placeholder {
              color: #acacac;
              font-size: 12px;
            }
          }
        }
        .ant-input {
          border-radius: 2px;
          font-size: 12px;
          border: none;
          background: #eaeaea;
          color: #5a5a5a;
          ::placeholder {
            font-size: 12px;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    button {
      font-size: 12px;
      height: 40px;
      width: 176px;
      border-radius: 2px;
    }
  }
}

.op {
  border-bottom: 1px solid #cecece;
  color: #898989;
  font-size: 12px;
  font-weight: 400;
}

.op[aria-selected="true"] {
  color: #212121;
  font-weight: 500;
  border-bottom: 1px solid #6fc1ff;
}
