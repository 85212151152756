.rfq-variant-modal {
  .name-pro {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    padding-right: 12px;
  }
  .sub-heading {
    display: flex;
    justify-content: flex-end;
  }
  .Total-row {
    display: flex;
    justify-content: space-between;
    border: 1px solid #898989;
    padding: 12px 5px;
   
    .total {
      font-weight: 500;
      color: #212121;
    }
    .qty {
      padding-right: 12px;
      font-weight: 500;
      color: #212121;
    }
  }
  .heading-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 5px 12px;
    background-color: #16273b;
    .img-details {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: flex-start;

      img {
        height: 40px !important;
        width: 40px;
        object-fit: contain;
        border: 1px solid #e8e8e8;
        margin-right: 6px;
      }

      .text-wraper {
        font-size: 14px !important;
        line-height: 17px;
        font-weight: 500;
        flex: 1;
        color: #ffffff;
      }
    }
    .heading-qty {
      font-weight: 500;
      color: #ffffff;
      padding-right: 12px;
    }
  }

  .variant-row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    .img-details {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: flex-start;

      img {
        height: 40px !important;
        width: 40px;
        object-fit: contain;
        border: 1px solid #e8e8e8;
        margin-right: 6px;
      }

      .text-wraper {
        font-size: 14px !important;
        line-height: 14px;
        flex: 1;
        color: #212121;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        padding-right: 12px;
      }
    }
    .qty-variant {
      align-self: center;
      padding-right: 12px;
    }
  }
  .variant-row:nth-of-type(even) {
    background: #f5f5f5;
  }
}
