.reconnect_storeModal {
  .ant-modal-close span {
    display: none;
  }
  .ant-modal-body {
    padding: 18px;
    padding-bottom: 5px;
  }
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    margin-bottom: -16px;
  }
  .middledata p {
    text-align: center;
    color: #898989;
    margin-bottom: 16px;
    line-height: 16px;
    letter-spacing: 0.032em;
    font-size: 12px;
  }
  .middledata a {
    text-align: center;
    border: 1px solid #fd9526;
    padding: 8px;
    border-radius: 4px;
    background: #fd9526;
    color: #ffff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em;
  }
  .ant-modal-footer {
    border-top: none !important;
    padding-top: 0;
    padding-bottom: 16px;
  }
  .ant-btn {
    border: none;
    box-shadow: none;

    // padding: 0 15px;
    // font-size: 12px;
    // margin-top: -18px;
  }

  .d {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bt {
    padding-left: 4px !important;
  }

  .d:hover {
    svg {
      path {
        fill: #2986ff;
      }
    }
  }
}
