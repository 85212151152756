.contact-center-setting {
  padding: 8px 32px;

  .ant-radio-inner::after {
    top: 6px;
    left: 6px;
    width: 4px;
    height: 4px;
    background-color: #fff;
  }
  .ant-radio.ant-radio-checked .ant-radio-inner {
    background-color: #2bc822;
    border: none;
  }

  label.ant-radio-wrapper.ant-radio-wrapper-checked {
    color: #212121;
  }
}

.ant-tabs-content-animated {
  position: relative;
}
