.collapse-item-logs {
  width: 992px;
  margin: auto;
  border-radius: 0px !important;
  letter-spacing: 0.032em;
  .ant-collapse-item {
    border-radius: 0px !important;
    .container {
      background: #ffffff !important;
      padding: 8px 24px 8px 24px !important;
      display: flex;
      align-items: center;
      .details {
        width: 40%;
        height: 40px;
        display: flex;
        align-items: center;

        .img {
          height: 40px;
          width: 40px;
          background: gray;
          border: 2px solid #f5f5f5;
          margin-right: 24px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        p {
          font-size: 12px;
        }
      }
      .InventoryE {
        width: 40%;

        font-size: 12px;
        .view-in-tab-link:hover {
          span {
            text-decoration: underline !important;
          }
        }
        a:hover {
          text-decoration: underline;
        }
        .flex-rto {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          p {
            margin-left: 4px;
          }
        }
        .flex-rto:hover {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;

          p {
            text-decoration: underline;
          }
        }
      }

      :nth-child(3) {
        width: 20%;

        font-size: 12px;
      }
    }
  }

  .ant-collapse-header {
    border-radius: 0px !important;
    padding: 0 !important;
  }

  .ant-collapse-content {
    background: #f5f5f5;
  }

  .bottom-head {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    padding-left: 72px;
    font-size: 12px;

    :nth-child(1) {
      width: 30.7%;
      font-size: 12px;
    }
    :nth-child(2) {
      width: 21.8%;
      padding-left: 2px;
      font-size: 12px;
    }
    :nth-child(3) {
      width: 35%;
      padding-left: 5px;
      font-size: 12px;
    }

    h5 {
      width: 100% !important;
      text-decoration: underline;
    }
  }

  .bottom-head2 {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    padding-left: 72px;
    font-size: 12px;

    :nth-child(1) {
      width: 30.7%;
      font-size: 12px;
    }
    :nth-child(2) {
      width: 21.8%;
      padding-left: 2px;
      font-size: 12px;
    }
    :nth-child(3) {
      width: 35%;
      padding-left: 5px;
      font-size: 12px;
    }

    .live-inv {
      p {
        margin-left: 30px;
      }
    }

    .distribution {
      p {
        margin-left: 85px;
      }
    }
  }

  .product-link-name p a:hover {
    text-decoration: underline !important;
  }
}
