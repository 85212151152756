.recomended-packaging-modal {
  * {
    box-sizing: border-box;
    letter-spacing: 0.032em !important;
    margin: 0;
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
  p {
    font-size: 12px;
    color: #898989;
  }

  h1 {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 5px;
  }

  .ant-modal-header {
    padding-top: 30px;
    margin: 0 24px;
    padding-bottom: 24px;

    p {
      margin-bottom: 12px;
      text-align: center;
    }
    .ant-modal-title {
      > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 5px;
        svg {
          margin-right: 10px;
          font-size: 60px;
          margin-bottom: 14px;
        }
        h1 {
          line-height: 16px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .ant-modal-footer {
    margin: 0 24px;
    padding-bottom: 26px;
    padding-top: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btns {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      align-items: center;

      button:first-of-type {
        width: 160px;
        margin-right: 8px;
        border-radius: 2px !important;
      }
      button {
        font-size: 12px;
        font-weight: 500;
        width: 208px;
        height: 40px;
      }

      a {
        height: 40px;
        width: 208px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px !important;
      }
    }

    .green-outline {
      background: #fff !important;
      margin-right: 8px;
      width: 100px;
      font-size: 12px;
      color: #2a9c27 !important;
      border: 1px solid #2a9c27;
    }

    .clickable-dropdown.custom-width {
      margin-top: 12px;
      width: 376px !important;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 9px;
    }
  }

  .ant-modal-body {
    padding: 24px;
    padding-top: 20px;
    font-size: 12px;

    .head-1 {
      width: 480px;

      justify-content: flex-start !important;
    }
    .head-2 {
      width: 100px;
      .Complimentary {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 120px;
        color: #9d24d6;
        border-radius: 2px;
        border: 1px solid #9d24d6;
        font-weight: 500;
        padding: 0 8px;
      }
    }
    .head-3 {
      width: 100px;
    }
    .head-4 {
      width: 100px;
    }

    // .variants-headings
    .variants-headings {
      padding: 0px 16px 4px 16px;
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // variants-data-row
    .variants-data-row {
      padding: 8px 16px;
      display: flex;
      height: 72px;
      background-color: #f5f5f5;
      margin-bottom: 8px;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .head-1 {
        justify-content: flex-start;

        .details {
          flex-direction: column;
          align-items: flex-start;

          p {
            color: #898989;
            margin-right: 4px;
          }
          span {
            color: #212121;
          }
        }

        .img {
          height: 56px;
          width: 56px;
          margin-right: 16px;
          img {
            object-fit: cover;
            height: 100%;
            width: inherit;
          }
        }
      }
    }
  }
}

.recomended-packaging-modal.hide-border {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    display: none;
  }
}
