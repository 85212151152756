.tabSecondContainer {
  height: 100%;
  background-color: #fc9f83;
  border-radius: 8px 0 0 8px;
  .logocontainer {
    padding-top: 57px;
    padding-bottom: 40px;
  }
  .dis {
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: left;
    color: #212121;
    .dis-container {
      width: 320px;
      padding: 24px 18px 29px 18px;
      background-color: #ffffff;
      border-radius: 10px;
      .top-data {
        margin-bottom: 16px;
        .para {
          font-weight: 600;
        }
      }
      .name{
        color: #352FBA;
        font-weight: 600;
        margin-bottom: 4px;
      }
      .infodata{
        margin-bottom: 4px;
      }
      .stat{
        font-weight: 600;
        .greenArrowContainer{
            margin-right: 8px;
        }
      }
    }
  }
}
