.wallet-tabs {
  .ant-pagination {
    margin-top: 20px;
  }
  thead {
    tr {
      th {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.032em;
        background: #16273b !important;
        border-radius: 0 !important;
        color: white !important;
      }
    }
  }
  tbody {
    tr {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.032em;
    }
  }
  .ant-tabs-bar {
    border-bottom: unset;
    background: white;
  }
  .ant-tabs-tab {
    font-size: 18px;
    padding-bottom: 8px;
    color: #262626;
    font-weight: 500;
  }
  .ant-tabs-tab-active {
    color: #007bff;
  }
  .ant-tabs-ink-bar {
    background-color: #007bff;
  }
  .ant-table-thead > tr > th .ant-table-header-column {
    display: inline-block;
    vertical-align: top;
    width: 110px;
  }
  .ant-table-thead > tr > th:nth-child(4) .ant-table-header-column {
    width: 175px !important;
  }
  .ant-table-thead > tr > th:nth-child(7) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-thead > tr > th:nth-child(6) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-thead > tr > th:nth-child(8) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-thead > tr > th:nth-child(9) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-layout-fixed table {
    table-layout: unset !important;
  }
  .ant-table-thead {
    position: sticky !important;
    top: 0px !important;
    z-index: 99 !important;
    white-space: nowrap;
  }
  .ant-table-tbody > tr > td {
    border: none !important;
    color: #595959;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: none !important;
  }
}

.previous-page-link {
  padding: 15px;
  background: #192f4a;
  padding-bottom: 15px !important;
  a {
    color: white;
    font-size: 16px;
    font-weight: 500;
    .anticon {
      margin-right: 10px;
    }
  }
}
// .Transaction_History .ant-table-body::-webkit-scrollbar {
//   width: 8px;
//   height: 8px !important;
//   border-radius: 10px;
// }

// .Transaction_History .ant-table-body::-webkit-scrollbar-track {
//   background: #ffff !important;
//   border: 1px solid;
//   border-radius: 10px;
// }

// .Transaction_History .ant-table-body::-webkit-scrollbar-thumb {
//   background: #898989 !important;
//   border-radius: 10px !important;
// }

.chips-wraper {
  position: relative;
  bottom: 30px;
  .selectedValueChips {
    font-size: 12px;
    padding: 1px 6px;
    border-radius: 2px;
    background: #e6f4fe;
    display: inline-block;
    margin: 6px 8px;

    border: 2px solid #a6e6ff;
    position: relative;
    i.anticon.anticon-close {
      position: absolute;
      top: -7px;
      right: -6px;
      font-size: 10px;
      color: white;
      background: #a6e6ff;
      border-radius: 15px;
      padding: 2px;
    }
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}
