.remittance {
  .ant-table-wrapper {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .vf-cost-container {
    text-align: center;
  }
  .selected-column {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    .display-column-select {
      width: 20%;
      .ant-select-selection__placeholder {
        display: block !important;
      }
      .ant-select-selection__choice {
        display: none;
      }
    }
    h3 {
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }
  .total-stat-card {
    text-align: center;
  }
  .ant-calendar-picker {
    margin-left: 5px;
  }
  .reporting-card {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
    border: 1px solid #d6d6d6 !important;
    .ant-card-body {
      padding: 0;
      .header {
        color: white;
        background: linear-gradient(90deg, #16273b, #192f4a);
        padding: 12px 24px;
        font-size: 16px;
        .header-tooltip {
          float: right;
        }
      }
      .body {
        padding: 12px 24px;
        font-size: 22px;
        letter-spacing: 0.3px;
      }
      .divider {
        margin: 0 20px;
        border-top: 1px solid #e8e8e8;
      }
      .footer {
        padding: 10px 24px;
        font-size: 13px;
        hr {
          border: 0;
          border-top: 1px solid #e8e8e8;
        }
      }
    }
  }
  .selectedValue {
    padding: 1px 6px;
    background: #e6f4fe;
    border-radius: 5px;
    border: 2px solid #a6e6ff;
    position: relative;
    display: inline-block;
    margin: 6px 8px;
    i.anticon.anticon-close {
      position: absolute;
      top: -7px;
      right: -6px;
      font-size: 10px;
      color: white;
      background: #a6e6ff;
      border-radius: 15px;
      padding: 2px;
    }
  }
  table {
    thead {
      tr {
        th {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.3px;
          background: #16273b !important;
          border-radius: 0 !important;
          color: white !important;
        }
      }
    }
    tbody {
      background: white;
      tr:nth-child(2n) {
        background: #f2f2f2;
      }
      tr {
        td {
          font-size: 16px;
          border-bottom-color: #f2f2f2 !important;
          color: #676666;
          font-weight: 400;
          p {
            margin-bottom: 0px;
          }
        }
        .order-content {
          p {
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 12px;
            color: #53a2db;
          }
        }
      }
    }
  }
}
