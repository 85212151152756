.plan-screen-wraper {
  .scroll-area {
    height: calc(100vh - 98px);
    overflow: scroll !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .plan-card-wraper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    flex-wrap: wrap;

    .save {
      font-weight: 400;
      background-color: #ffdf56;
      color: #212121;
      padding: 3px 12px;
      border-radius: 12px;

      span {
        font-weight: 500 !important;
        font-size: 14px !important;
        color: #212121 !important;
      }
    }
  }

  .plan-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 44px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .switch-wraper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .ant-switch {
        min-width: 60px !important;
        height: 24px !important;
      }

      .ant-switch::after {
        top: 2px !important;
      }

      h2 {
        font-size: 14px;
        color: #212121;
        font-weight: 400;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #2bc822;
      }
    }
  }

  .plan-footer {
    position: fixed;
    bottom: 0px;
    background-color: #ffdf56;
    width: 90vw !important;
    padding: 12px;
    display: flex;
    align-items: center;
    border: 0px;
    border-radius: 0px;
    justify-content: center;
    button {
      background-color: #212121;
      border: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      padding-left: 52px !important;
      border-radius: 4px !important;
      padding-right: 52px !important;
    }
  }
}

// commin card css
.plan-card {
  background-color: #fff;
  width: 334px !important;
  // width: 25% !important;
  border: 1px solid #3b3b3b;
  border-radius: 8px;
  position: relative;
  padding: 26px 12px 42px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: auto;

  button {
    background-color: #fff !important;
    border-radius: 2px;
    border: 1px solid #536be8;

    margin-top: 12px;
    width: 100%;

    span {
      font-size: 14px;
      color: #536be8;
      font-weight: 500;
    }
  }

  .top-bar {
    height: 10px;
    background-color: #3b3b3b;
    border-radius: 6px 6px 0 0;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .bottom-bar {
    height: 10px;
    width: 100%;
    background-color: #3b3b3b;
    border-radius: 0px 0px 6px 6px;
    left: 0px;
    position: absolute;
    bottom: 0px;
  }

  .price-card {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 16px 12px 16px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    h1 {
      font-size: 24px;
      color: #4a4be4;
      font-weight: 600;
      margin-bottom: 2px;
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700;
      }
      span {
        color: #898989;
        font-size: 16px !important;
      }
    }

    span {
      font-size: 12px;
      color: #898989;
    }

    button {
      background-color: #fff !important;
      border-radius: 2px;
      border: 1px solid #536be8;

      margin-top: 12px;
      width: 100%;

      span {
        font-size: 14px;
        color: #536be8;
        font-weight: 500;
      }
    }

    .payment-disable {
      background-color: #2bc822 !important;
      border-color: #2bc822 !important;
      span {
        color: #fff;
      }
    }
    #payment-disable {
      background-color: #2bc822 !important;
      border-color: #2bc822 !important;
      span {
        color: #fff;
      }
    }
  }

  .illustration-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    svg {
      display: block;
      min-width: 72px;
    }

    p {
      color: #212121;
      font-size: 12px;
      font-family: "Helvetica", "Arial", sans-serif !important;
      line-height: 18px;
      span {
        text-decoration: underline;
      }
    }
  }

  .features-card {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h1 {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 15px;
      text-align: left;
    }

    .features {
      padding: 12px;
      background-color: #f4f4f4;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .feature-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-size: 12px;
        color: #212121;

        p {
          span {
            font-weight: 500;
          }
        }

        svg {
          display: block;
          min-width: 16px;
        }
      }

      .feature-row.cross {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-size: 12px;
        color: #212121;

        svg {
          background-color: red;
          border-radius: 50%;

          display: block;
          min-width: 16px;
        }
      }

      .custom-lineheight {
        p {
          line-height: 36px !important;
        }
      }
    }
  }

  .save-more-card {
    h1 {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: #212121;
      margin-bottom: 8px;
      gap: 4px;
      span {
        font-size: 20px;
      }
    }

    .save-row-card {
      padding: 12px;
      background-color: #f4f4f4;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .save-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-size: 12px;
        color: #212121;

        svg {
          display: block;
          min-width: 16px;
        }
      }
    }
  }

  .brands-card {
    h1 {
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      color: #212121;
      margin-bottom: 10px;
    }

    .brands-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .prtool-logo {
        rect {
          fill: #0c70f2 !important;
        }
      }

      .checkout-logo {
        path {
          fill: #0f55b1 !important;
        }
      }
    }
  }
}

/* common */
.card-ribbon {
  width: 138px;
  height: 138px;
  overflow: hidden;
  position: absolute;
  z-index: 999;
}
.card-ribbon::before,
.card-ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.card-ribbon span {
  position: absolute;
  display: block;
  width: 211px;
  padding: 12px 0;
  background-color: #3498db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.card-ribbon-top-left {
  top: -10px;
  left: -10px;
}
.card-ribbon-top-left::before,
.card-ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.card-ribbon-top-left::before {
  top: 0;
  right: 0;
}
.card-ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.card-ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.earth-quaker-card {
  order: 3;
}

.mover-card {
  order: 1;
}

.shaker-card {
  order: 2;
}
// preview card css

.payment-disable {
  background-color: #2bc822 !important;
  border-color: #2bc822 !important;
  span {
    color: #fff;
  }
}
#payment-disable {
  background-color: #2bc822 !important;
  border-color: #2bc822 !important;
  span {
    color: #fff;
  }
}
