.role-modal {
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-checkbox {
    border: 2px solid #e3e3e3 !important;
    border-radius: 2px !important;
  }
  .role-container {
    .role-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b2b2b2;
      margin-bottom: 16px;
      .anticon-close {
        margin-bottom: 16px;
      }
    }
    .role-body {
      border-bottom: 1px solid #b2b2b2;
      margin-bottom: 40px;

      p {
        font-size: 12px;
        color: #212121;
        margin-bottom: 4px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      .ant-btn {
        background: #1bca00;
        color: #fff !important;
        border: none !important;
        outline: none !important;
        font-size: 18px;
        font-weight: 600;
        border-radius: 4px;
        height: 50px;
        width: 100%;
        letter-spacing: 0.5px;
        margin-top: 16px;
      }
    }
  }
  .save-role {
    font-size: 16px;
    letter-spacing: 0.032em;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 16px;
    color: #212121;
  }
  .role-input {
    background: #f5f5f5 !important;
    border-radius: 2px !important;
    height: 40px !important;
    border: none !important;
    margin-bottom: 16px;
  }
  .ant-modal-content {
    border-radius: 2px !important;
  }

  .drawer-footer {
    background: #1bca00;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    height: 40px;
    letter-spacing: 0.032em;
    margin-top: -26px;
    float: right;
    width: 140px;
  }

  .ant-input::placeholder {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }

  .role-input:hover {
    border: 1px solid #898989 !important;
  }

  .ant-btn:not([disabled]):hover {
    background: #28af21;
    border: 2px solid #229d1b !important;
  }

  .arrow-right {
    transform: rotate(270deg);

    g {
      stroke: white;
      fill: white;

      path {
        fill: white;
      }
    }
  }
}
