.questionScreen2-wraper {
  padding-bottom: 60px;
}
.questionScreen2 {
    width: 700px;
    margin: 100px auto 0;
    padding: 30px px;
  .questionScreen2-header-txt {
    .questionScreen2-account-txt {
        font-size: 22px;
        letter-spacing: .032em;
        line-height: 28px;
        align-items: center;
        font-weight: 500;
        color: #fc125e;
        text-align: center;
    }
    .step1 {
      font-size: 12px;
        letter-spacing: .032em;
        line-height: 22px;
        align-items: center;
        font-weight: 400;
        text-align: center;
        color: #4b4b4b;
        margin-top: 5px;
    }
  }
  .question-following-txt {
    font-size: 16px;
      color: #000;
      letter-spacing: .032em;
      line-height: 16px;
      margin-top: 16px;
      margin-bottom: 20px;
  }
  .step-list1 {
    ul {
      list-style-type: none;
      padding: 0;
      li {
        background: #f5f5f5;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;

        label {
          display: block;
          padding: 8px;
        }

        .step1-list-checkbox {
          margin-right: 8px;
          color: #212121;
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }

  .step-list2 {
    ul {
      list-style-type: none;
      padding: 0;
      .questionScreenStep2 {
        background: #f5f5f5;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        width: 100%;

        .ant-radio-wrapper {
          white-space: initial !important;
          color: #212121;
          font-size: 13px;
          padding: 8px;
          display: flex;
          align-items: center;
          line-height: 20px;
        }

        .step2-list-checkbox {
          margin-right: 8px;
        }
        .step2-text {
          font-size: 13px;
          line-height: 19px;
          letter-spacing: 0.032em;
        }
        .ant-checkbox-wrapper + span,
        .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      }
    }
  }

  .step-list3 {
    margin-top: 10px;
    ul {
      list-style-type: none;
      padding: 0;
      .questionScreenStep3 {
        background: #f5f5f5;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;

        label {
          color: #212121;
          font-size: 12px;
          padding: 8px;
          width: 100%;
        }

        .step3-list-checkbox {
          .option-value {
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            margin-left: 4px;
          }
          .option-value-radio-btn {
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            margin-left: -4px;
          }
        }
        .step3-text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.032em;
        }
        .ant-checkbox-wrapper + span,
        .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      }
    }

    .multiselect-doublecolumn-list {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .questionScreenStep3 {
        background: #f5f5f5;
        // padding: 8px;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;
        width: 48%;
        .step3-list-checkbox {
          .option-value {
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            margin-left: 8p;
          }
        }
        .step3-text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.032em;
        }
        .ant-checkbox-wrapper + span,
        .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      }
      .questionScreenStep3:last-child {
        width: 100%;
      }
    }

    .singleselect-doublecolumn-list {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .questionScreenStep3 {
        background: #f5f5f5;
        // padding: 8px;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;
        width: 48%;
        .step3-list-checkbox {
          .option-value {
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            margin-left: 8p;
          }
        }
        .step3-text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.032em;
        }
        .ant-checkbox-wrapper + span,
        .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      }
    }
    .widthquestionScreenStep3 {
      width: 100% !important;
      margin: 0px !important;
    }
    .other-select-niche {
      background: #f5f5f5;
      padding: 10px;
      padding-top: 0;
      margin-bottom: 0;
      width: 100% !important;
      .selectNiche {
        background: #fff;
        border: none;
        border-radius: 0px;
        font-size: 12px;
        letter-spacing: 0.032em;
      }
    }
  }

  .continue-btn {
    width: 100%;
    height: 40px;
    color: #fff;
    background: #94e390;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    pointer-events: none;
  }
  .continue-btn2 {
    width: 100%;
    height: 40px;
    color: #fff;
    background: #3eca2a;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
  }
  .header-question-txt {
    font-size: 15px;
      margin-bottom: 5px;
      letter-spacing: .032em;
      line-height: 18px;
      color: #898989;
      margin-bottom: 10px;
      margin-top: 20px;
      font-weight: 600;
      color: #463f3f;
  }
  .input-type-number {
    margin-bottom: 16px;
    .question-number-input {
      input {
        background: #f5f5f5;
        border: none;
        border-radius: 0px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      .ant-input-group-addon {
        border: none !important;
        background: #f5f5f5f5 !important;
        border-radius: 0px !important;
      }
    }
  }

  .input-type-text {
    margin-bottom: 16px;
    .question-text-input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  .step3-number {
    margin-right: 5px;
  }
  .mandatory-validation {
    color: #c62929;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .error-red {
    color: #c62929;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #46ce3f;
  }
  .ant-radio-inner::after {
    background-color: #2bc823;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #46ce3f;
  }

  .communication-mode {
    width: 100%;

    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-top-width: 1.02px;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #212121;
      font-weight: 400;
    }
  }

  .react-tel-input .form-control {
    position: relative;
    font-size: 12px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 32px;
    width: 300px;
    outline: none;
  }
  .react-tel-input .form-control {
    background: #f5f5f5 !important;
    border: none !important;
    width: 100% !important;
    border-radius: 0px;
  }

  .dd-communication-mode {
    margin-bottom: 16px;
    .input-communication-mode {
      width: 100%;
      height: 32px;
      border: none;
      background: #f5f5f5;
      font-size: 12px;
      padding-left: 8px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background: #f5f5f5f5;
    border-radius: 3px 0 0 0;
  }
}

.communication-mode-option {
  border-bottom: 1px solid #cecece !important;
  padding: 4px 8px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px;
  .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px;
  }
}
.communication-mode-option:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important;
}
