.recharts-wrapper {
  margin-top: 50px !important;
  position: relative !important;
  .recharts-surface {
    width: 99%;
  }
  .recharts-legend-wrapper {
    width: 458px !important;
    height: auto !important;
    left: -50px !important;
    bottom: 38rem !important;
    margin-top: 10px !important;
  }
}

// .ant-tabs-tabpane {
//   background-color: white !important;
// }
.yAxis {
  line {
    display: none !important;
  }
}

.xAxis {
  line {
    display: none !important;
  }
}

.Sold_graph-revenue {
  font-size: var(--small-font-size);
  position: relative;
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.data-point-revenue {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2rem;
  top: -2rem;
  font-size: var(--small-font-size);
  .first {
    margin-right: 3rem;
    p {
      font-size: var(--small-font-size);
      font-weight: 500;
    }
  }
  .second {
    p {
      font-size: var(--small-font-size);
      font-weight: 500;
    }
  }
}
