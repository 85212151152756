.unicorn-card-wraper {
  background-color: #fff;
  border: 1px solid #9f492f;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  gap: 24px;
  margin-top: 56px;
  margin-left: 8rem;
  margin-right: 8rem;

  .price-card {
    background-color: #fc9f83;
    border-radius: 6px;
    padding: 16px 12px 24px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    flex: 1;
    h1 {
      font-size: 24px;
      color: #9f492f;
      font-weight: 600;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      span {
        color: #fff;
        font-weight: 400;
      }
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700;
      }
      span {
        color: #fff;
        font-size: 16px !important;
      }
    }

    span {
      font-size: 12px;
      color: #898989;
    }

    button {
      background-color: #fff !important;
      border-radius: 2px;
      border: 1px solid #9f492f;
      padding-left: 24px !important;
      padding-right: 24px !important;
      margin-top: 12px;
      width: 100%;

      span {
        font-size: 14px;
        color: #9f492f;
        font-weight: 500;
      }
    }
  }

  .right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 24px;
    flex: 2;

    .illustration-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 22px;
      // width: 360px;

      svg {
        display: block;
        min-width: 72px;
      }

      p {
        color: #212121;
        width: 40%;
        font-size: 12px;
        font-family: "Helvetica", "Arial", sans-serif !important;
        line-height: 18px;
        span {
          text-decoration: underline;
        }
      }
    }

    .features-card {
      display: flex;
      h1 {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
      }

      .features {
        border-radius: 2px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 36px;
        row-gap: 15px;

        .feature-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          font-size: 12px;
          color: #212121;
          width: fit-content;

          svg {
            display: block;
            min-width: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .illustration-wraper {
    p {
      width: 100% !important;
    }
  }
}
