.ndr-reattamp_modal {
  margin-top: 8px;
  border: 1px solid #898989;
  padding: 8px;
  .upper_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;

    .ant-form-item-control {
      width: 200px;
    }
  }
  .middle_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;
    .ant-form-item-control {
      width: 200px;
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;
  }
  .btn-save-customer {
    display: flex;
    justify-self: end;
  }

  .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000;
  }
  input,
  textarea {
    border: 1.5px solid #979797;
    border-radius: 2px;
    font-size: 12px;
  }
  .ant-form-item-control {
    position: relative;
    line-height: 32px;
    zoom: 1;
    font-size: 12px;
    margin-right: 8px;
  }
}
