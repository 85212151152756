.shipment_request_review-line-item {
  .ant-modal-content {
    padding: 8px 0px;
    border-radius: 0px !important;
    .ant-modal-header {
      padding: 16px 8px;
      width: 856px;
      margin: auto;
    }

    .flex-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > div {
        margin-right: 3px;
      }
    }
    .ant-modal-footer {
      padding: 16px 0px;
      text-align: left !important;
      background: transparent;
      border-top: 1px solid #e8e8e8;
      border-radius: 0 0 4px 4px;
      width: 856px;
      margin: auto;

      .end_customer_detail {
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 16px;
        // .end_customer_detail_label {
        //   display: flex;
        //   justify-content: space-between;
        //   margin-bottom: 4px;
        //   .customer_detail_text {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 208px;
        //   }

        //   .customer_detail_text2 {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 250px;
        //   }

        //   .account_number_text {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 208px;
        //   }

        //   .account_number_text2 {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 250px;
        //   }
        //   .confirm_account_number_text {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 208px;
        //   }
        //   .ifsc {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 208px;
        //   }
        //   .ifsc2 {
        //     font-size: 12px;
        //     letter-spacing: 0.032em;
        //     line-height: 16px;
        //     color: #898989;
        //     width: 250px;
        //   }
        // }
      }

      .title_text {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
        margin-bottom: 12px;
        font-weight: 500;
      }
      .replacement_text {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        margin-bottom: 16px;
      }

      .note_txt {
        font-size: 14px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #000;
        margin-bottom: 16px;
        font-weight: 500;
        font-style: italic;
        margin-right: 6px;
      }

      .reversePickupReason {
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 16px;
        .pickup_reason {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
          margin-bottom: 8px;
          font-weight: 500;
        }

        .reverse_pickup_reason_data {
          font-size: 12px;
          width: 100%;
          margin-bottom: 16px;
          .ant-select-selection {
            display: block;
            box-sizing: border-box;
            background-color: #f5f5f5;
            border: none;
            border-radius: 0;
            outline: none;
          }
        }
        .other-textarea textarea {
          width: 100%;
          border: none;
          outline: none;
          background-color: #f5f5f5;
          height: 100px;
          padding: 8px;
          font-size: 12px;
          letter-spacing: 0.032em;
          margin-bottom: 16px;
        }
        .other_label {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
        }
      }

      .end_customer_detail_input {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;

        .common_label {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #898989;
          width: 208px;
          margin-bottom: 4px;
        }
        .upeer_label_margin {
          margin-right: 6px;
          margin-bottom: 16px;
        }

        .customer_detail_text {
          width: 208px;
          border-radius: 0px;
          background: #f5f5f5;
          border: none;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
        }
        .account_number_text {
          width: 208px;
          border-radius: 0px;
          background: #f5f5f5;
          border: none;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
        }
        .confirm_account_number_text {
          width: 208px;
          border-radius: 0px;
          background: #f5f5f5;
          border: none;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
        }
        .ifsc {
          width: 208px;
          border-radius: 0px;
          background: #f5f5f5;
          border: none;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
        }
      }

      .amount_data {
        margin-top: -22px;
        .amount_value {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          // color: #212121;
        }
        .end_customer_amount_input {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
        }
        .customer_amount_text {
          width: 208px;
          border-radius: 0px;
          background: #f5f5f5;
          border: none;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
        }
      }
    }
    .bottom_button {
      text-align: right;
      .not_clickablebtn {
        width: 176px;
        background: #88b6fd;
        height: 40px;
        border: none;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        pointer-events: none;
      }
      .clickable_btn {
        width: 176px;
        background: #1370fc;
        height: 40px;
        border: none;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      .cancel_close_btn {
        width: 176px;
        background: #f5f5f5;
        height: 40px;
        border: none;
        color: #898989;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
  }

  .order_vfid span {
    color: #898989;
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    p {
      color: #898989 !important;
      margin-bottom: 0px;
      font-size: 16px;
      letter-spacing: 0.032em;
      line-height: 22px;

      > span {
        color: #212121 !important;
      }
    }
    .imp {
      font-size: 12px !important;
      color: #898989;
      > span {
        color: #ce1543;
      }
    }
  }
  .blue-text {
    color: #1268fb;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 16px;
    font-size: 12px;
    p {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
      margin-bottom: 0px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  .line-items-tab {
    background: #fff;
    margin-bottom: -16px;
    padding: 8px;
    .line-item {
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-input {
        width: 80px;
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: none;
          width: 14px;
          height: 14px;
        }
      }
      .quantity-field {
        max-width: 65px;
        margin-right: 5px;
      }
      .anticon {
        font-size: 22px;
      }
      .line-item-product-detail {
        display: flex;
        align-items: center;
        width: 61%;
        margin-left: 0px;
        .mr {
          margin-right: 8px;
        }
      }
      .line-item-img {
        width: 56px;
        height: 56px;
        margin-right: 0px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        // border: 1px solid #c8c8c8;
      }
      .line-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
        margin-top: -12px;
      }
      .line-item-sku {
        font-size: 12px;
        color: #000;
        font-weight: 400;
        margin: 0;
        // margin-top: -2px;
      }
    }
    .line-item.selected {
      background: #eeffe5;
      border: 1px solid #73d13d;
    }
    .edit-quantity-btn {
      background: transparent;
      border: 1px solid #ff005e;
      border-radius: 4px;
      color: #ff005e;
    }
    .edit-quantity-btn:hover {
      background: #ff005e;
      border: 1px solid #ff005e;
      box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
      color: white;
    }
  }
  .shipments-tab {
    padding: 12px 13px;
    background: #fff;
    .no-shipment {
      text-align: center;
    }
    .shipment-header {
      background: #f2f2f2;
      padding: 28px 20px;
      margin-bottom: 5px;
      color: black;
    }
    .shipment-items {
      background: #eff5ff;
      padding: 5px 10px;
    }
    .shipment-item {
      background: transparent;
      padding: 16px 8px;
      display: flex;
      align-items: center;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: none;
        }
      }
      .anticon {
        font-size: 22px;
      }
      .shipment-item-product-detail {
        display: flex;
        align-items: center;
      }
      .shipment-item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
      }
      .shipment-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 15px;
      }
      .shipment-item-sku {
        font-size: 14px;
        color: #676666;
        font-weight: 400;
        margin: 0;
        margin-top: -2px;
      }
    }
    .shipment-item:nth-of-type(2n) {
      background: white;
    }
  }
  .quantity {
    width: 20%;
  }

  .action {
    width: 15%;
    .action_button {
      background: transparent;
      border: none;
      box-shadow: none;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #fd115e;
      padding: 0;
    }
  }

  .variants_detail_header {
    margin-top: -8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 8px;

    .varaint_text {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
      margin-bottom: 0px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 55%;
    }

    .quantity_txt {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
      margin-bottom: 0px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 20%;
    }

    .action_txt {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
      margin-bottom: 0px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 15%;
    }
  }

  .bottom_check-box {
    display: flex;
    justify-content: start;
    padding: 16px 8px 0px 16px;
    .following_action {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      margin-right: 16px;
    }
    .reverse_pickup {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      margin-right: 16px;
      .reverse_pickup_checkbox {
        margin-left: 8px;
      }
    }
    .refund_end {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      margin-right: 16px;
      .refund_end_checkbox {
        margin-left: 8px;
      }
    }
    .replacement {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      margin-right: 16px;
      .replacement_checkbox {
        margin-left: 8px;
      }
    }
  }

  .ghanta-icon-wraper {
    margin-right: 8px;
    margin-left: 8px;
    background: #f0eab3;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .replacement_border {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 16px;
    margin-top: 4px;
  }

  .replacement_new_txt {
    background: #fffce3;
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 16px;
    letter-spacing: 0.032em;
    border: 1px solid #feea79;
    color: #212121;
  }
  .refund_disable_circle {
    margin-right: 4px;
    color: #94e390;
    font-size: 16px;
  }

  .bank_detail_txt {
    color: #212121;
    font-weight: 500;
  }

  .groundaction_filter {
    background: #f5f5f5;
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #212121;
    margin-bottom: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    line-height: 16px;
  }

  .link_data {
    float: right;
  }
  .ali-in-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #fd104d;
  }
  .ali-in-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 85%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 2px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .ali-in-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .view-status-txt {
    color: #ce1543;
    margin-right: 4px;
  }

  .replacement_text_data {
    font-size: 12px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 500;
  }

  .important_txt {
    color: #212121;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-right: 8px;
  }
  .disabledaction {
    color: #00000040;
  }

  .disabled_checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f5f5f5;
    //border-color: #36c626;
  }

  .disabled_checkbox .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: #00000040 !important;
  }
  .mandatory_filed {
    font-size: 12px !important;
    letter-spacing: 0.032em !important;
    line-height: 16px !important;
  }
  .note_text {
    font-style: italic;
  }
  .ant-select-selection-selected-value {
    color: #212121;
  }
}
