.import-list {
  width: 100%;
  overflow-x: scroll;
  .import-product-menu {
    color: #898989;
  }
  .menudropdownacton a .import-product-menu {
    color: #898989 !important;
  }
  .import-product-menu :hover #discover-icon span.import-product-menu {
    color: #212121 !important;
  }
  a .import-product-menu:hover {
    color: #212121 !important;
  }
  .import-product-menu :hover .download-icon svg g path {
    fill: #212121;
  }
  .import-product-menu :hover .discover-icon svg g path {
    stroke: #212121;
  }
  .ant-tabs-bar {
    margin: 0 0 25px 0;
  }
  .ant-input-search {
    .ant-input {
      font-size: 0.75rem;
    }
    .ant-input::placeholder {
      font-size: 0.75rem;
    }
    .ant-btn {
    }
  }
  .select-button {
    .ant-btn {
      margin-left: 5px;
      font-size: 0.75rem;
      height: 1.75rem;
      padding: 0px 8px;
    }
  }
  .content-page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0.032em;
    margin: 0px 8px 0px 0px;
  }
  .content-page-subtitle-right {
    text-align: end;
    font-size: 0.75rem;
  }
  .ant-tabs-nav-container {
    font-size: 0.875rem;
  }
  .import-product-btn {
    color: white !important;
    background-color: #fff0;
    border-color: #0068ff;
    font-size: 0.75rem;
    height: 1.75rem;
    cursor: "pointer";
  }
  // .import-product-btn:hover {
  //   background: #0068ff;
  //   color: white;
  // }
  .select-all-checkbox:hover .ant-checkbox-inner {
    border-color: #36c626;
  }
  .menu-items-modal:hover {
    background-color: red;
  }

  .slect-all-order-checkbox {
    margin-bottom: 0.5rem;
    margin-top: -16px;
  }
}

.product-import-list {
  .ant-alert {
    word-break: break-all;
  }

  .ql-toolbar.ql-snow {
    z-index: 9 !important;
  }
}

.select-all-checkbox {
  .ant-checkbox-inner {
    margin: 0;
    margin-top: 0.125rem;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #36c626;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #36c626;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #36c626;
    border-color: #36c626;
  }
}

.import-product-search .ant-input-group .ant-input {
  height: 40px;
  box-shadow: 0px 2px 8px #00000014;
  border-radius: 2px 0 0 2px;
  border: 0px;
}

.menu-items-modal {
  .hov {
    :hover {
      background-color: #36c626;
    }
  }
}

.ant-dropdown
  .menudropdownacton
  ul
  .menu-items-modal:nth-child(1):hover
  svg
  g
  path {
  fill: #212121;
  // stroke: #212121;
}

.ant-dropdown
  .menudropdownacton
  ul
  .menu-items-modal:nth-child(2):hover
  svg
  g
  path {
  // fill: #212121;
  stroke: #212121;
}

// .ant-input:hover {
//     background-color: white !important;
// }
