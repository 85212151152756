.update-email-modal {
  text-align: center;
  .btn-green {
    background: #36c626;
    border-color: #36c626;
    color: white;
  }
}

.otp-verification-form {
  position: absolute;
  left: 35%;
  margin-top: 48px;
  .ant-card {
    border-radius: 2px !important;
    width: 456px;
    margin: 0 auto;
    height: auto;
    margin-top: 40px;
  }

  .support-email-phone-modal {
    .ant-modal-content {
      .ant-modal-close {
        display: none !important;
      }
      .support-phone-number-txt {
        text-align: center;
        font-size: 16px;
        letter-spacing: 0.032em;
        line-height: 22px;
        margin-bottom: 0px;
        color: #fc125e;
      }
      .number-verify-txt {
        font-size: 12px;
        color: #4b4b4b;
        line-height: 22px;
        letter-spacing: 0.032em;
        text-align: center;
      }
      .ant-modal-header {
        border-bottom: none !important;
        padding: 32px 24px !important;
      }
      .ant-modal-footer {
        border-top: none !important;
      }
    }
    .otp-verify {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
      .otp-number {
        width: 15%;
        border: none;
        background: #f5f5f5;
        height: 32px;
        text-align: center;
      }
    }
    .otp-txt {
      font-size: 12px;
      margin-top: 16px;
      margin-bottom: 5px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
    }
    .resend-code {
      float: right;
      margin-top: -16px;
      font-size: 12px;
      letter-spacing: 0.032em;
      .resend-code-txt {
        color: #3273cc;
        text-decoration: underline !important;
        margin-right: 4px;
        background: transparent;
        border: none;
        cursor: pointer;
      }
      .resend-code-txt2 {
        color: #98b9e5;
        text-decoration: underline !important;
        margin-right: -3px;
        background: transparent;
        border: none;
        pointer-events: none;
      }
    }
    .header-support-txt {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #898989;
      margin-top: 56px;
      .change-number-txt {
        color: #fc125e;
        border: none;
        background: none;
        text-decoration: underline !important;
        cursor: pointer;
      }
      .save-number-txt {
        color: #28af21;
        border: none;
        background: none;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
    .edit-ican {
      margin-right: 3px;
    }
    .save-ican {
      margin-right: 3px;
      svg {
        path {
          fill: #28af21;
        }
      }
    }
    .input-number-data {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .ind-91 {
        width: 20%;
        background: #f5f5f5;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 12px;
        .ant-select-selection {
          background: none;
          border: none;
          font-size: 12px;
          display: flex;
          justify-content: center;
          border-radius: 2px;
          color: #212121;
        }
        .ant-select-selection__rendered {
          line-height: 23px !important;
        }
        .ant-select-arrow {
          margin-top: -7px !important;
        }
      }
      .input-email-txt {
        width: 100%;
        input {
          background: #f5f5f5;
          border: none;
          border-radius: 0px;
          color: #212121;
          letter-spacing: 0.032em;
          line-height: 20px;
        }
      }
      .input-number-txt {
        width: 100%;
        input {
          background: #f5f5f5;
          border: none;
          border-radius: 0px;
          color: #212121;
          letter-spacing: 0.032em;
          line-height: 20px;
        }
      }
    }
    .verify-continue-btn {
      width: 408px;
      margin-top: 8px;
      border: none;
      box-shadow: none;
      background: #2bc822;
      border-radius: 2px;
      color: #fff;
      letter-spacing: 0.032em;
      height: 40px;
      line-height: 16px;
    }

    .cancel-btn {
      width: 408px;
      margin-top: 8px;
      border: 1px solid #898989;
      box-shadow: none;
      // background: #2bc822;
      border-radius: 2px;
      color: #898989;
      letter-spacing: 0.032em;
      height: 40px;
      line-height: 16px;
    }
    .otp-number-txt {
      font-size: 12px;
      font-style: italic;
      color: #898989;
      margin-top: 4px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
    .ant-input {
      font-size: 12px !important;
    }
    .disable-phonenumber {
      background: #f5f5f5;
      font-size: 12px;
      height: 32px;
      display: flex;
      align-items: center;
      letter-spacing: 0.032em;
      color: #212121;
      padding-left: 16px;
    }
  }
  .header-txt {
    .account-txt {
      text-align: center;
      color: #fc125e;
      font-size: 16px;
      letter-spacing: 0.032em;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .verify-emai-mobile-txt {
      text-align: center;
      margin-bottom: -40px;
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #4b4b4b;
      font-weight: 400;
    }
  }
  .verify-continue-btn {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #2bc822 !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    margin-top: 16px;
  }
  .verify-continue-btn2 {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #94e38f !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    pointer-events: none;
  }
}

.verify-continue-btn:hover {
  background: #2bc822 !important;
  color: #fff !important;
}

.verify-continue-btn2:hover {
  background: #94e38f !important;
  color: #fff !important;
}
