.withdrawalModal {
  .ant-modal-header {
    padding: 0px 0px;
    .head-background {
      background: url("../../../../assets/images/backgrounds/withdrawal-background.svg");
      padding: 30px 24px;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
      h1 {
        font-size: 32px;
        color: white;
        font-weight: bold;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        color: white;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: 1px;
      }
    }
  }
  .ant-modal-body {
    padding: 0;
    .withdrawal-request {
      p {
        font-size: 20px;
        font-weight: 500;
        color: black;
      }
    }
    .balance-exceeded-warning {
      background: #ffeded;
      color: #f40505;
      padding: 20px 70px;
    }
    .funds-input {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin-bottom: 0px;
        font-size: 32px;
        color: black;
      }
      .ant-input {
        border: none;
        border-bottom: 1px solid black;
        border-radius: 0px;
        width: 200px;
        margin-left: 10px;
        margin-right: 10px;
        height: 41px;
        color: black;
        font-size: 16px;
      }
      .ant-input:focus {
        box-shadow: unset;
      }
      span {
        padding: 9px 24px;
        background: #d6d6d6;
        font-size: 16px;
        font-weight: 500;
        color: black;
      }
    }
    .funds-amount {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      span {
        padding: 9px 24px;
        background: #e3eeff;
        font-size: 16px;
        font-weight: 500;
        color: black;
      }
    }
    .total-name {
      p {
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #00b700;
      }
    }
  }
  .ant-modal-footer {
    padding: 0px 0px;
    .ant-btn {
      text-align: center;
      background: #feaa74;
      font-size: 16px;
      color: white;
      font-weight: bold;
      letter-spacing: 1px;
      height: 46px;
      border-radius: 0px;
      border: none;
    }
  }
  button.ant-modal-close {
    color: white;
  }
}
