.PLocation-contant-cointainer{
    .ant-modal-header {
        padding: 16px 8px !important;
        border-bottom: 1px solid #cecece !important;
        display: flex;
        margin: 0 auto;
        width: 456px;
      }
      .title_txt {
        font-size: 14px;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-weight: 500;
      }
      .ant-modal-close-x {
        display: none;
      }
    
      .ant-modal-content {
        border-radius: 0px;
      }
      .footer_button_continue {
        padding: 16px 2px !important;
        border-top: 1px solid #cecece !important;
        display: flex;
        margin: 16px auto;
        width: 456px;
        margin-bottom: -16px;
        justify-content: flex-end;
      }
      .ant-modal-body {
        padding: 24px !important;
      }
      .global_and_indian_dropshipping {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .flag_radio_btn {
        display: flex;
        justify-content: space-between;
        .Global-type-card {
          height: 136px;
          width: 136px;
          border: 1px solid #212121;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          background: #fff;
          margin-right: 16px;
          transition: all 0.3s linear;
          cursor: pointer;
          .anticon {
            margin: auto;
            svg {
              width: 80px;
              height: 80px;
            }
          }
        }
    
        .India-type-card {
          height: 136px;
          width: 136px;
          border: 1px solid #212121;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          background: #fff;
          transition: all 0.3s linear;
          cursor: pointer;
    
          .anticon {
            margin: auto;
            svg {
              width: 80px;
              height: 80px;
            }
          }
        }
    
        .flag {
          font-size: 12px;
          color: #000000;
          letter-spacing: 0.032em;
          line-height: 24px;
          margin-top: 4px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    
      .India-type-card span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
      .Global-type-card span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
      .flag_radio_btn .Global-type-card:hover {
        border: 2px solid #212121;
        background: #ea8634;
        transition: all 0.3s linear;
      }
    
      .flag_radio_btn .India-type-card:hover {
        border: 2px solid #212121;
        background: #03aa6f;
        transition: all 0.3s linear;
      }
    
      .continue_btn {
        flex: unset;
        width: 238px;
        font-size: 12px;
        color: white;
        padding: 8px 0 !important;
        background: #36c626;
        position: relative;
        left: -2px;
        top: 2px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px;
        text-align: center;
      }
    
      .store_objective {
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        font-size: 12px;
        line-height: 24px;
        margin-top: 6px;
        color: #212121;
        font-weight: 400;
      }
    
      .indian_flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .global_flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .ant-radio-inner {
        border-color: #fff !important;
        display: none !important;
      }
      .ant-radio-wrapper {
        width: 100%;
        height: 100%;
      }
    
      .go_back_btn {
        margin-right: 8px;
        border: none;
        box-shadow: none;
        font-size: 12px;
        color: #212121;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        .svg_go_back {
          margin-right: 4px;
        }
        .go_back_txt {
          margin-top: -3px;
        }
      }
      .go_back_btn:hover {
        text-decoration: underline;
      }
    
     
}