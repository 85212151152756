.img-gallery {
  img {
  }

  .all-imgs {
    img {
      width: 100%;
    }
  }

  .main-img {
    img {
      width: 100%;
    }
  }

  .img-container:hover {
    border: 2px solid #36c626;
    .eyeIcon {
      transition-duration: 0.5s;
      bottom: 0;
      right: 0;
      height: 1.25rem;
      padding: 0.125rem 0.3125rem;
    }
  }
  .img-container {
    position: relative;
    border: 2px solid #eee;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    cursor: pointer;

    .select-container {
      position: absolute;
      top: 0;
      left: 0;
      background: #36c626;
      color: white;
      padding: 0px 0.25rem;
      border: none;
      // border-bottom-right-radius: 0.25rem;
      .anticon {
        vertical-align: -0.0625rem;
        font-size: 0.875rem;
      }
    }
    .eyeIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #0366fb;
      padding: 0 0;
      border: none;
      box-shadow: unset;
      border-radius: 2px;
      height: 0;
      transition-duration: 0.5s;
      .anticon {
        font-size: 1rem;
      }
    }
    .anticon-check {
      font-size: 0.75rem;
    }
  }
  .img-container.selected {
    border: 2px solid #36c626;
    border-radius: 0.25rem;
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #36c626;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #36c626;
  border-color: #36c626;
}
.select-deselect {
  p {
    margin-bottom: 0;
    font-size: 0.75rem;
    float: left;
  }
  .ant-checkbox-wrapper {
    span {
      font-size: 0.75rem;
    }
  }
}
@media (max-width: 576px) {
  .img-gallery {
    .all-imgs {
      img {
        width: 100%;
      }
    }

    .main-img {
      img {
        width: 100%;
      }
    }
  }
}
