.link-product-bundle-modal {
  .ant-modal-body {
    font-size: 12px;
    line-height: 1.5;
    word-wrap: break-word;
    padding-top: 16px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    .ant-select-lg .ant-select-selection--multiple {
      min-height: 47px;
      border: none;
      background: #f5f5f5;
      border-radius: unset;
    }
    .ant-select-selection--multiple .ant-select-selection__placeholder {
      margin-left: 6px;
      font-size: 12px;
      line-height: 35px;
      height: 47px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
      color: #212121 !important;
      background-color: #fff !important;
      border: none !important;
      border-radius: 0px;
      font-size: 12px;
      display: flex;
      align-items: center;
      letter-spacing: 0.032em;
      margin-bottom: 5px;
    }
    .ant-radio-group {
      display: block;

      .ant-radio-wrapper {
        border: 1.5px solid #979797;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        display: block;
        margin: 1rem auto;

        .label-text {
          font-weight: 700;
        }
      }
    }
    p.input-label {
      margin-bottom: 0;
      font-weight: 600;
    }
    input:first-of-type {
      margin-bottom: 15px;
    }
    .bulk-import {
      .icon-container {
        margin-top: 10px;
        padding: 10px;
        border: 2px solid #979797;
        border-radius: 4px;
        color: #979797;
        text-align: center;
        cursor: pointer;

        i {
          svg {
            width: 5rem;
            height: 5rem;
          }
        }
      }
      .icon-container:hover {
        border-color: #5e73ff;
        color: #5e73ff;
      }
    }
  }

  .ant-modal-footer {
    padding-top: 16px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .next-button {
    background-color: #2bc822;
    outline: none;
    border: none;
    width: 161px;
    border-radius: 2px;
  }
  .cancle-button {
    width: 161px;
    border-radius: 2px;
  }

  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #8bd987;
    border-color: #8bd987;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    width: 161px;
  }
  .ant-select-selection__rendered {
    line-height: 29px !important;
    li {
      height: 38px !important;
      line-height: 32px !important;
      margin-top: 5px !important;
      width: 381px !important;
    }
  }
  .input-label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400 !important;
    margin-bottom: 4px !important;
  }
  .link-product-note-txt {
    color: #898989;
    letter-spacing: 0.032em;
    font-style: italic;
    line-height: 16px;
    margin-bottom: 14px;
    width: 433px;
    font-size: 12px !important;
    margin-top: 8px;
    text-align: start !important;
  }

  .bundle-product-list {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    color: #212121;
  }
  .bundle-product-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 400;
    color: #212121;
    margin-bottom: -8px;
  }
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    padding: 24px !important;
  }

  .ant-modal-header {
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    padding-bottom: 16px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
