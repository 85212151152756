.click-to-copy {
  cursor: pointer;
  .sp {
    color: #898989;
    font-size: 12px;
    margin-right: 4px;
  }
  .hover-underline-animation1 {
    display: inline-block;
    position: relative;

    span {
      color: #0058d6;
    }
  }
  .hover-underline-animation1:after {
    content: "";
    position: absolute;
    width: 75%;
    transform: scaleX(0);
    height: 1px;
    bottom: 2px;
    left: 0;
    background-color: #0058d6;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation1:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .hover-underline-animation2:after {
    content: "";
    position: absolute;
    width: 81%;
    transform: scaleX(0);
    height: 1px;
    bottom: 2px;
    left: 0;
    background-color: #0058d6;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation2:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .anticon {
    svg {
      font-size: 12px !important;
    }
  }
}

.click-to-copy:hover {
  a {
    color: #0058d6;
    span {
      color: #0058d6;
    }

    g {
      fill: #0058d6;
    }
  }
}
