.my-wallet-review-modal {
  max-width: 100%;
  .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 12px 24px;
  }
  .ant-modal-body {
    padding: 0;
    table {
      thead {
        th:nth-child(3) {
          text-align: left;
        }
      }
    }
    .ant-table-body {
      background: white;
      .products-list {
        img {
          max-width: 50px;
          padding: 0.5rem;
          border: 1px solid #707070;
        }
      }

      .blue-text {
        color: #5e73ff;
      }
    }
  }
}
