// .recharts-wrapper {
//   margin-top: 50px !important;
//   position: relative !important;
//   .recharts-legend-wrapper {
//     width: 458px !important;
//     height: auto !important;
//     left: -50px !important;
//     bottom: 19rem !important;
//     margin-top: 10px !important;
//   }
// }
// .ant-tabs-tabpane {
//   background-color: white !important;
// }

// .yAxis {
//   line {
//     display: none !important;
//   }
// }

// .xAxis {
//   line {
//     display: none !important;
//   }
// }

// .Sold_graph {
//   font-size: 12px;
// }

// // .legend-item-0 svg {
// //   display: none !important;
// // }

.ic-sales {
  margin-top: 7px;
  display: flex;

  .svg {
    display: flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-top: 6px;
  }

  h5 {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  .content {
    span {
      font-weight: 500;
    }
  }
}

.ic-orders {
  margin-top: 14px;
  display: flex;

  .svg {
    display: flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-top: 6px;
  }

  h5 {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .content {
    span {
      font-weight: 500;
    }
  }
}

.data-point-sales {
  position: absolute;
  display: flex;
  align-items: center;
  top: 1rem;
  left: 2rem;
  font-size: var(--small-font-size);
  .first {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    p {
      font-size: var(--small-font-size);
      font-weight: 500;
    }
  }
  .second {
    p {
      font-size: var(--small-font-size);
      font-weight: 500;
    }
  }
}
