.download-utility-table {
  margin-bottom: 20px;
  letter-spacing: 0.032em !important;

  // table header
  .ant-table-thead {
    tr {
      background-color: #16273b;

      th {
        padding-top: 12px !important;
        padding-bottom: 11px !important;
        background-color: #16273b !important;
      }
      th:first-of-type {
        padding-left: 24px !important;
      }

      .ant-table-column-title {
        font-size: 12px;
        color: #fff;
      }

      th:last-of-type {
        padding-left: 32px;
      }
    }
  }

  // table body
  .ant-table-tbody {
    box-shadow: 0px 0px 4px #00000020;
    tr {
      background-color: #fff;
      td:first-of-type {
        padding-left: 24px;
      }

      td {
        border-color: #cecece;
        font-size: 12px;
        color: #212121;
        padding: 19px 16px !important;
        span {
          font-size: 12px;
          color: #212121;
        }
      }
    }

    tr:last-of-type {
      td {
        border-bottom: 0px solid #000;
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        margin-right: 4px;
      }
    }
    .download-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #1370fc;
      font-weight: 500;

      i {
        margin-right: 8px;
      }
    }
    .download-link-disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}
.msg {
  letter-spacing: 0.032em;
  color: #898989;
  margin-bottom: 12px;
  font-size: 12px;
}
