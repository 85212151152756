.inventories-modal {
  .ant-modal-header {
    background: #0e1d34;
    .inventories-header {
      display: flex;
      justify-content: space-between;
      img {
        width: 105px;
      }
      p {
        margin-bottom: 0px;
        margin-left: 10px;
        color: white;
        font-weight: 400;
      }
    }
  }
  .ant-modal-body {
    .main-inventory-container {
      .product-card {
        margin-right: 24px;
        h4 {
          color: black;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 0px;
          line-height: 22px;
        }
        .product-info {
          display: flex;
          margin-bottom: 16px;
          img {
            width: 100px;
            border: 1px solid black;
          }
        }

        .bulk-price {
          margin-top: 10px;
          margin-bottom: 24px;
          p {
            color: #585858 !important;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 0px;
            text-align: center;
            padding: 0px 13px;
            span {
              color: #2b2a2a !important;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }
        .stock-location.selected-location {
          background: #f7fff6 !important;
          border: 2px solid #67c72b !important;
          span {
            border: 2px solid #67c72b;
          }
        }
        .disabled-location {
          margin-bottom: 10px;
          background: #eeeeee;
          .location-error {
            color: white;
            font-weight: 500;
            font-size: 10px;
            padding: 8px;
            background: #eb2d2d;
            margin-bottom: 0px;
          }
          .location-name {
            margin-left: 10px;
          }
          .stock-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 10px 0px 28px;
            p {
              color: #acacac;
              font-weight: 400;
              font-size: 10px;
              margin-bottom: 6px;
              background: none;
              padding: 0px;
            }
          }
        }
        .stock-location {
          padding: 10px;
          background: #eeeeee;
          display: flex;
          margin-bottom: 8px;
          border: none;
          .location-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              color: black;
              font-weight: 500;
              font-size: 12px;
              margin-bottom: 8px;
            }
          }
          .stock-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              color: #585858;
              font-weight: 400;
              font-size: 10px;
              margin-bottom: 6px;
            }
          }
          span {
            width: 12px;
            height: 12px;
            border-radius: 20px;
            border: 1px solid #707070;
            margin-right: 5px;
            margin-top: 4px;
            background: white;
          }
        }
      }
      .order-from-card {
        hr {
          margin-top: 24px;
        }
        .order-heading {
          font-size: 14px;
          color: #989898;
          line-height: 22px;
          font-weight: 500;
          margin-bottom: 0px;
        }
        .order-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
          p {
            font-size: 12px;
            color: black;
            margin-bottom: 0px;
            flex: 1;
          }
          .ant-input {
            width: 95px;
            border-color: black;
          }
        }
      }

      .inventory-btn {
        border: none;
        border-radius: 0px;
        background: #36c626;
        color: white;
        height: 36px;
      }
      .inventory-btn:disabled {
        background: rgba(54, 198, 38, 0.8);
      }
    }
  }
}
.modal-note {
  border: 1px solid #0068ff;
  background: #eaf3ff;
  text-align: center;
  padding: 12px 24px;
  font-size: 10px;
  color: #141414;
  margin-bottom: 12px;
  line-height: 18px;
  margin-top: 18px;
}
