.newValueModal {
  .ant-modal-header {
    padding: 1.5rem 1.5rem 0px;
    border-bottom: none;
  }
  .newValueTitle {
    h2 {
      font-size: 1rem;
      margin-bottom: 0px;
    }
    p {
      font-size: 0.875rem;
      margin-bottom: 0px;
      font-weight: 400;
      color: rgb(125, 125, 125);
    }
  }
  .ant-input-number {
    width: 100%;
    border-radius: 0.125rem;
  }
  .ant-input-number:hover {
    border-color: unset;
  }
  .ant-input-number:focus {
    border-color: unset;
    box-shadow: unset;
  }
  .ant-modal-footer {
    padding: 0 1.5rem 1.5rem;
    border-top: none;
    text-align: center;
    .ant-btn {
      font-size: 0.75rem;
      width: 6rem;
      box-shadow: none;
    }
  }
}
