.Sourcing_nav_data {
  .Sourcing_nav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 100px;
        .filter {
          .ant-select-selection {
            padding: 0 !important;
            background: none;
            border: none;
            margin-right: 0;
          }
          svg {
            font-size: 20px;
          }
          .ant-select-arrow {
            margin-top: -10px;
            top: 39%;
            left: 145% !important;
          }
          .ant-select-selection-selected-value {
            padding-right: 26px;
          }
        }
        .show_data_from {
          font-size: 12px;
        }
        .dispute-status {
          font-size: 12px;
        }
      }
    }

    .ant-input {
      width: 80%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px;
    }
    .ant-calendar-range-picker-separator {
      color: #1268fb !important;
    }
    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em;
    }
    .ant-calendar-range-picker-input {
      width: 35% !important;
      letter-spacing: 0.032em;
      line-height: 24px;
    }
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      top: 50%;
      right: 47px;
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-top: 40px;
    margin-bottom: 32px;
  }

  // .ant-select-selection__placeholder {
  //   color: #1268fb;
  // }

  .fullorder_data .ant-select-selection__placeholder {
    color: #898989;
  }
  .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important;
  }

  .ant-select-selection {
    width: 200px !important;
    border: none !important;
    background: #f8f8f8 !important;
  }
  .ant-select-dropdown-menu-item:last-child {
    border-bottom: none !important;
  }

  .commondropdown {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 32px;
    .anticon svg {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      padding-top: 2px;
    }
  }

  .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    border-bottom: 1px solid #b5dafe !important;
    color: #212121;
    font-weight: 500;
    .cancel-status:hover {
      display: none;
    }
  }

  .filter .ant-select-arrow {
    top: 39%;
    left: 130% !important;
  }
}

@media screen and (min-width: 1440px) {
  .Sourcing_nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;

    .ant-select-selection {
      width: 200px !important;
      border: none !important;
      background: #f8f8f8 !important;
    }
  }

  .filter .ant-select-arrow {
    top: 39%;
    left: 130% !important;
  }

  .commondropdown {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 32px;
  }
  .fullorder_data .ant-select-selection__placeholder {
    color: #898989;
  }

  .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important;
  }
  .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    border-bottom: 1px solid #b5dafe !important;
    color: #212121;
    font-weight: 500;
  }

  .sourcing-request-status-cancel {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 32px;
  }

  .sourcing-request-status-cancel:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    color: #212121;
    font-weight: 500;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Sourcing_nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }

  .drop {
    background-color: #f8f8f8 !important;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;
    .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important;
    }
    .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb;
      .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
      }
    }
    .ant-select-selection {
      width: 159px;
      border: none !important;
      background: #f8f8f8 !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
  }
}

.sourcing-request-status-cancel {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px;
}

.sourcing-request-status-cancel:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  color: #212121;
  font-weight: 500;
}
