.product-split-modal {
    width: 800px !important;
    max-width: 100%;

    .bold-text {
        font-weight: 600;
        color: black;
    }
    
    .block-column {
        padding: 0.5rem 1rem;
        border: 1.5px solid #979797;
        border-radius: 4px;
        margin-bottom: 1rem;

        .ant-checkbox-group {
            margin-left: 3rem;

            .ant-checkbox-group-item {
                margin-right: 3rem;
                font-weight: 500;
            }
        }
    }

    .ant-radio-group {
        display: block;

        .radio-column {
            cursor: pointer;

            .ant-radio-wrapper {
                font-weight: 700;
                color: black;
            }

            .sub-text {
                margin-left: 1rem;
                font-weight: 500;
            }
        }
    }

    .sub-text {
        font-weight: 400;
        font-size: 0.85rem;
    }
}