.collapse-item.rto {
  width: 992px;
  margin: auto;
  border-radius: 0px !important;
  letter-spacing: 0.032em;
  .ant-collapse-item {
    border-radius: 0px !important;
    .container {
      background: #ffffff !important;
      padding: 8px 24px 8px 24px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .details {
        width: 240px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: auto;

        .img {
          height: 40px;
          width: 40px;
          background: gray;
          border: 2px solid #f5f5f5;
          margin-right: 24px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        p {
          font-size: 12px;
          margin: 0;
          padding: 0;
        }
      }
      .InventoryE {
        width: 56px;
        margin-left: 331px;
        font-size: 12px;
        height: 40px;
        display: flex;
        align-items: center;
      }

      :nth-child(3) {
        width: 248px;
        padding-left: 30px;
        font-size: 12px;
        height: 40px;
        display: flex;
        align-items: center;
        color: #1370fc;
        cursor: pointer;
      }
    }
  }

  .ant-collapse-header {
    border-radius: 0px !important;
    padding: 0 !important;
  }

  .ant-collapse-content {
    background: #f5f5f5;
  }

  .bottom-head-rto {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 12px;
    // padding-top: 12px;

    font-size: 12px;

    div {
      height: 32px;
      display: flex;
      align-items: center;
      p {
        text-align: center;
      }
      h5 {
        font-size: 12px;
        width: 100%;
        padding: 0 !important;
        text-decoration: underline;
        text-align: center;
        margin-bottom: 0px;
      }
    }

    div:nth-of-type(1) {
      width: 120px;
      p {
        text-align: start;
      }
      h5 {
        font-size: 12px;
        width: 100%;
        padding: 0 !important;
        text-align: start;
      }
    }

    div:nth-of-type(2) {
      width: 120px;
      h5 {
        text-align: left !important;
      }
    }

    div:nth-of-type(3) {
      width: 40px;
      h5 {
        text-align: center !important;
      }
    }

    div:nth-of-type(4) {
      width: 100px;
      display: flex;
      justify-content: end;
    }
  }

  .bottom-head-rto.data {
    height: 40px;

    border-top: 1px solid #fff;

    div:nth-of-type(6) {
      p {
        text-align: left;
      }
    }

    div:nth-of-type(5) {
      p {
        text-align: left;
      }
    }

    div:nth-of-type(3) {
      p {
        text-align: center;
      }
    }
    div:nth-of-type(2) {
      p {
        text-align: left;
      }
    }
  }

  .bottom-head-rto.data:last-of-type {
    margin-bottom: 0px !important;
    border-bottom: 0px;
  }

  .product-link-name p a:hover {
    text-decoration: underline !important;
  }

  .view-in-vf-link:hover {
    text-decoration: underline !important;
  }

  .bottom-head-rto.data.first {
    margin-top: 0px;
  }
}
