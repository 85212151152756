.orders-add-note-modal {
  .ant-modal-content {
    border-radius: 2px !important;
  }
  .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000;
  }

  .no-top-margin {
    margin-top: 0rem;
  }

  input,
  textarea {
    border: 1.5px solid #979797;
    border-radius: 4px;
    padding-top: 8px !important;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-input {
    background-image: none !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: #f5f5f5;
    color: #212121;
  }
  .ant-input::placeholder {
    font-size: 12px;
    font-weight: 400px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
  }
  .ant-modal-body {
    padding: 16px 24px;
    font-size: 12px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .ant-modal-header {
    padding: 16px 0px;
    border-bottom: 1px solid #cecece;
    display: flex;
    margin: 0 auto;
    width: 512px;
  }
  .ant-modal-footer {
    padding: 16px 0px;
    text-align: right;
    border-top: 1px solid #cecece;
    width: 512px;
    margin: 0 auto;
  }
  .ant-modal-content {
    border-radius: 0px;
  }
  .add-note-btn {
    background: #2bc822;
    border: none;
    width: 144px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
  }
  .add-note-cancel {
    border: 1px solid #898989;
    width: 88px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
    color: #898989;
  }
  .ant-modal-title {
    color: #212121;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 16px;
  }
}
