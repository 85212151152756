.settings-page {
  font-family: "Poppins", sans-serif;

  .abc {
    .ant-card-body {
      padding-bottom: 2rem !important;
    }
  }

  .ant-card-bordered {
    padding: 0.1rem 1rem;

    h3 {
      color: #212121;
      font-size: 1rem;
      font-weight: 500;
    }

    .ant-divider-horizontal {
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }

  .tabs-card {
    .ant-card-body {
      padding: 24px 32px 24px 0;
    }

    .ant-tabs-left-bar {
      width: 256px;
      .ant-tabs-tab {
        padding: 20px 0 20px 60px;
        text-align: left;
      }
      .ant-tabs-tab-active {
        background: #efefef;
      }
    }
    .ant-tabs-ink-bar {
      width: 6px;
      background: #050c15;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
      color: black;
    }
    .ant-tabs-tab-active {
      color: black;
    }
  }

  .title {
    padding-left: 1rem;
    font-weight: 400;
    color: #6c6c6c;
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .ant-checkbox-wrapper {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .sub-text {
    font-size: 0.85rem;
    margin-top: 0.3rem;
    color: #9a9a9a;
  }

  .options-container {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .ant-switch {
    margin-right: 10px;
  }

  .normal-text {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .auto-update-options .normal-text {
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .inline-input {
    display: inline-flex;
    max-width: 25%;
    margin-left: 10px;
    flex-direction: column;
  }
}
.settings-sidebar {
  list-style: none;
  padding: 0;
  font-size: 1rem;
  margin-top: 16px;
  li {
    a {
      color: rgba(0, 0, 0, 0.65);
      padding: 1rem;
      display: block;
      text-decoration: none;
    }
    a:hover,
    a.active {
      color: #5e73ff;
    }
  }
}

@media screen and (max-width: 1280px) {
  .settings-page {
    .tabs-card {
      .ant-card-body {
        padding: 24px 32px;
      }
    }
  }
}

.content-page-title {
  font-size: 1rem;
}
// .ant-card-body {
//   padding-top: 10px;
// }
.user-firstname {
  text-transform: capitalize;
  letter-spacing: 0.032em;
  font-size: 12px;
}
