.contact-center-nav {
  .contact-ceneternav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .contactcenterwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .contact-center-secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tab-container-contact {
        display: flex;

        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        .tab {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;
          color: #898989;
          .active {
            color: #1268fb;
          }
          button {
            display: contents;
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-inventory-underline-animation {
          position: relative;
        }
        .hover-inventory-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 0px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-inventory-underline-animation:hover {
          color: #4398ed !important;
          .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-inventory-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left;
        }

        .hover-inventory-underline-animation-click {
          position: relative;
        }
        .hover-inventory-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 0px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left;
        }
        // .tab {
        //   height: 41px;
        //   display: flex;
        //   align-items: center;
        //   font-size: 12px;
        //   color: #1268fb;
        //   letter-spacing: 0.032em;
        //   line-height: 20px;
        //   border-bottom: 2px solid #1268fb;
        // }
      }
    }
  }

  .contact-center-btn {
    display: flex;

    .save-settings-btn {
      button {
        margin-right: 16px !important;
      }
    }

    .contact-center-settings-btn {
      margin-bottom: 10px;
      height: 32px;
      line-height: 30px;
      margin-right: 100px;
    }
    .save-settings-btn {
      margin-bottom: 10px;
      height: 32px;
      line-height: 30px;
      margin-right: 16px;
      button {
        height: 37px !important;
      }
    }
  }

  .disable-clickable-dropdown {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #94e38f !important;
    border-color: #94e38f !important;
  }

  .disable-contact-center-settings-btn {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #4d78e9 !important;
    border-color: #4d78e9 !important;
    height: 37px;
  }
}

.body-container-contact {
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: 32px;
}

.contact-p {
  letter-spacing: 0.032em;
  svg {
    font-size: 16px;
    color: white;
    g {
      color: white;
    }

    path {
      color: white;
      fill: white;
    }
    margin-right: 8px;
  }

  font-weight: 500;
  img {
    margin-right: 10px;
  }
  height: 37px;
}
.contact-g{
  height: 37px;
}

@media screen and (min-width: 1440px) {
  .contact-ceneternav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
}

@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .contact-ceneternav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}
.logo-containr {

  svg {
    position: relative;
    height: 12px;
    width: 18px;
    transform: scale(1.5);
    left: 1px;
    top: 2px;
  }
}
