.switch-store-modal {
  .ant-alert-info {
    margin-bottom: 1rem;
  }

  .ant-input-group-wrapper {
    margin-bottom: 1rem;

    .ant-input {
      padding: 1.2rem;
    }
    .ant-input::placeholder {
      font-size: 1rem;
    }

    .ant-input-group-addon {
      font-size: 0.9rem;
    }
  }

  .subscript {
    font-weight: 400;
    color: #707070;
  }

  .ant-modal-close-x {
    display: none !important;
  }

  .middle_data {
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    padding: 32px;
    p {
      margin-right: 32px;
    }
  }
  .footer_button {
    display: flex;
    justify-content: center;
  }
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-content {
    padding: 32px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
  .user_description {
    text-align: center;
    margin-bottom: 16px;
    margin-top: -16px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #898989;
    font-size: 12px;
  }

  .footer_description p {
    margin-bottom: 24px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-size: 12px;
  }
}
