.preferences_seetting_data {
padding-bottom: 32px;
    .settingnav {
      padding-left: 59px;
      padding-top: 85px;
      padding-right: 158px;
      background-color: #f5f5f5;
      margin-right: -16%;
      margin-left: -6%;
      // margin-top: -10%;
      transform: translateY(-125.6px);
      box-shadow: 0px 3px 8px #00000012;
      position: absolute;
      width: 100%;
      left: 21.7%;
      z-index: 99;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      .settingwrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .store-filters {
          box-shadow: none;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            margin-bottom: 0px;
            font-size: 12px;
            color: #6e6e6e;
            margin-right: 0px;
          }
        }
      }
      .secondwrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .filtertab {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          .tab {
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 32px;
            color: #898989;
            .active {
              color: #1268fb;
            }
            button {
              cursor: pointer;
              border: none;
              background: transparent;
              font-size: 12px;
              letter-spacing: 0.032em;
              font-weight: 500;
            }
          }
  
          .hover-ndr-underline-animation {
            position: relative;
          }
          .hover-ndr-underline-animation:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            left: 1px;
            bottom: 0px;
            background-color: #4398ed;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
  
          .hover-ndr-underline-animation:hover {
            color: #4398ed !important;
            .circle {
              border: #4398ed 1px solid !important;
              color: #4398ed !important;
              font-size: 12px;
              letter-spacing: 0.032em;
              font-weight: 400;
            }
          }
  
          .hover-ndr-underline-animation:hover:after {
            transform: scaleX(1);
            transform-origin: top left;
          }
  
          .hover-ndr-underline-animation-click {
            position: relative;
          }
          .hover-ndr-underline-animation-click:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            left: 1px;
            bottom: 0px;
            background-color: #1268fb;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
            transform: scaleX(1);
            transform-origin: top left;
          }
        }
      }
    }
    .bottom_data {
      display: flex;
      justify-content: space-between;
      padding-top: 90px;
      margin-top: 32px;
      padding-top: 12px;
      margin-bottom: 8px;
    }
    .ant-select-selection__placeholder {
      color: #1268fb;
    }
  }
  
  @media screen and (min-width: 1440px) {
    .settingnav {
      padding-left: 104px !important;
      padding-top: 85px;
      padding-right: 244px !important;
      background-color: #f5f5f5;
      margin-right: -16%;
      margin-left: -6%;
      // margin-top: -10%;
      box-shadow: 0px 3px 8px #00000012;
      position: absolute;
      width: 100%;
      left: 22.7% !important;
      z-index: 99;
    }
  }
  
  @media screen and (min-width: 1441px) and (max-width: 1900px) {
    .settingnav {
      padding-left: 104px !important;
      padding-top: 85px;
      padding-right: 244px !important;
      background-color: #f5f5f5;
      margin-right: -16%;
      margin-left: 0 !important;
      // margin-top: -10%;
      box-shadow: 0px 3px 8px #00000012;
      position: absolute;
      width: 100%;
      left: 240px !important;
      z-index: 99;
    }
  }
  
  .fullorder_data {
    .uppp {
      transform: translateY(-195.6px);
      transition: all 0.3s linear;
    }
    .downn {
      transform: translateY(-125.6px);
      transition: all 0.3s linear;
    }
  }
  
  .non-cursor-pointer {
    pointer-events: none;
    border-bottom: none !important;
  }
  .fullorder_data {
    .uppp {
      transform: translateY(-195.6px);
      transition: all 0.3s linear;
    }
    .downn {
      transform: translateY(-125.6px);
      transition: all 0.3s linear;
    }
  }