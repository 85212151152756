.non-delivery-reports {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block;
  }
}
