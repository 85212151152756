.import-list-product-container {
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 3px 10px #0000000d !important;
  border: 0px !important;
  letter-spacing: 0.032em;
  border-radius: 4px;
  .ant-tabs-nav-scroll {
    background: none !important;
  }
  .ant-tabs-nav-wrap {
    margin-bottom: 0px;
    padding-top: 10px;
  }

  .ant-card-body {
    padding: 16px 32px 32px;
    .ant-tabs-extra-content {
      line-height: 36px;
    }
    // a:hover {
    //   color: #212121 !important;
    // }
  }

  .ant-tabs {
    color: #898989;
  }
  .ant-tabs-ink-bar {
    bottom: 0px;
    height: 1px;
  }
  .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 24px;
    margin: 0 0 16px 0 !important;
    .ant-tabs-nav {
      // border-bottom: 1px solid #cecece;
      margin-left: 3rem;
      .ant-tabs-tab-active {
        font-weight: 400;
      }
      .ant-tabs-tab {
        padding: 0px 0px 2px;
        font-size: 12px;
      }
    }
  }
  .product-selection-container {
    position: absolute;
    top: 6px;
    left: 1px;
    .ant-checkbox-inner {
      margin: 0 0 0 -1px;
      border: 1px solid #e3e3e3;
      background-color: #ededed;
    }
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #36c626;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #36c626;
      border-color: #36c626;
    }
  }
  .ant-tabs-tabpane {
    max-height: 600px;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  a:hover {
    color: #fff !important;
  }
  .menudropdownacton {
    margin-left: 8px;
  }
}

.ant-dropdown {
  .menudropdownacton ul {
    border: none;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08) !important;
    border-radius: 2px;
    .menu-items-modal {
      padding: 12px 0 12px 24px !important;
      color: #898989 !important;
      line-height: 16px !important;
      margin: 0 !important;
      font-size: 12px !important;
      &:hover {
        background-color: #f5faff !important;
        color: #212121 !important;
        font-weight: 500;
      }
    }
    .menu-items-modal:hover + .ant-menu-item-divider {
      background-color: #b5dafe;
      margin: 0px 0;
    }
    .ant-menu-item-divider {
      margin: 0px 0 !important;
    }
  }
}

.import-list-product-container.selected {
  border: 1px solid #36c626 !important;
}
.ant-dropdown-open:hover > .action-arrow {
  transform: rotate(180deg);
}
.ant-dropdown-link {
  color: #898989;
}
.importlist_takeaction {
  font-size: 12px;
  transform: translate(-50%, 34%);
  line-height: 16px;
  cursor: pointer;
  padding: 12px 24px;
  color: #fff;
  font-weight: 500;
  width: 128px;
  height: 32px;
  background: #1161fb;
  border-radius: 2px;
  .ant-dropdown-trigger {
    letter-spacing: 0.032em;
    .up-arrow {
      display: none;
    }
  }
}

.ant-dropdown-link.ant-dropdown-trigger.ant-dropdown-open .down-arrow {
  display: none;
}
.up-arrow {
  display: none;
}
.ant-dropdown-link.ant-dropdown-trigger.ant-dropdown-open .up-arrow {
  display: inline-block;
}
