.editable-text {
  textarea.ant-input {
    margin-bottom: 1em;
    padding: 0;
    width: calc(100% - 20px);
  }
}
.importlistclass {
  margin-bottom: 1em;
  padding: 0.5rem !important;
  font-size: 0.75rem;
  border-radius: 0.125rem;
  width: calc(100% - 20px);
}
.ant-input:hover .ant-input:focus,
.ant-input:active {
  box-shadow: none;
  border-color: unset;
}
.ant-input:hover {
  border-color: unset;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: unset;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: unset;
}
