.table-top-head {
  margin-bottom: 6px;

  .clickable {
    span {
      font-size: 12px;
    }
  }

  span {
    font-size: 14px;
  }
}

.table-top-head.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-table-wraper {
  // overflow-x: scroll;
  // overflow-y: hidden;
  // min-height: 400px;

  .details {
    .name {
      font-size: 12px;
      color: #1162fb;
      font-weight: 500;
    }
    .id {
      font-size: 12px;
      color: #898989;
      font-weight: 500;
    }

    .name-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        transition: all 0.3s linear;
        opacity: 0;
        margin-left: 6px;
      }
    }

    .name-wraper:hover {
      svg {
        transition: all 0.3s linear;
        opacity: 1;
      }
    }
  }

  .global-tooltip-w {
    i {
      margin-right: 0px !important;
    }
  }

  .global-tooltip-w:hover {
    #icomponent1 {
      stroke: #0c182a;
    }
    #icomponent2 {
      stroke: #0c182a;
    }
  }

  .tooltip-title {
    display: flex !important;
    align-items: center;
    justify-content: center;
    > span {
      width: 40px;
    }

    .global-tooltip-w {
      width: max-content !important;
      min-width: max-content !important;
    }
  }

  .ant-table-thead {
    tr {
      height: 51px;
      th {
        background-color: #16273b;
        color: #fff !important;
        font-size: 12px;

        span {
          display: block;
          min-width: 100px;
          text-align: -webkit-center;
        }

        // .ant-table-column-title {
        //   text-align: center;

        //   display: block;
        //   width: max-content;
        // }
      }
    }
  }
  .ant-table-body {
    tr {
      td {
        border-bottom: 0px !important;
        border-top: 0px !important;
        font-size: 12px;
        text-align: center;
        span {
          font-size: 12px;
        }
      }
    }
    tr:nth-child(even) {
      background: #f5f5f5;
    }
  }
  .ant-table-fixed-right,
  .ant-table-fixed-left {
    td {
      font-size: 12px;
      border-bottom: 0px;
      text-align: center;
      span {
        font-size: 12px;
        border-bottom: 0px;
      }
    }

    tr:nth-child(even) {
      background: #f5f5f5;
    }
  }
}

.rtoTable.custom-table-wraper {
  .ant-table-column-title:nth-child(3) {
    display: none !important;
  }

  .ant-table-thead {
    tr {
      height: 51px;
      th {
        background-color: #16273b;
        color: #fff !important;
        font-size: 12px;

        // .ant-table-column-title {
        //   text-align: center;

        //   display: block;
        //   width: max-content;
        // }
      }
    }
  }
}

.table-pagination {
  padding: 12px 0px;
}

.custom-table-wraper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.custom-table-wraper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #898989;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.custom-table-wraper::-webkit-scrollbar {
  width: 8px;

  border: 1px solid #898989;
}
