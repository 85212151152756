// variants table
.variants-table-pricing-table-wraper {
  margin-bottom: 24px;
  margin-top: 20px;
  .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .table-headings,
    .table-sub-headings,
    .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .transit-time {
        width: 160px;
        padding-left: 16px;
        justify-content: flex-start;

        .img {
          height: 24px;
          width: 24px;
          border-radius: 2px;
          margin-right: 8px;

          img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px;
          }
        }
      }
      .vf-days {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      :nth-child(3) {
        width: 120px;
        justify-content: flex-start;
        p {
          margin-right: 6px;
        }
      }
      :nth-child(4) {
        width: 110px;
        justify-content: flex-start;
        p {
          margin-right: 6px;
        }
      }
      :nth-child(5) {
        width: 153px !important;
        align-items: center;
        justify-content: flex-start;
        p {
          margin-right: 6px;
        }
      }
      :nth-child(6) {
        width: 90px;
      }
    }

    // seprate table sub heading
    .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5;
      div {
        color: #898989;
      }
    }
    // seprate table sub heading
    .table-row-data {
      height: 48px;
      background-color: #fff;

      div {
        color: #212121;
      }
    }

    .table-row-data:nth-child(odd) {
      background-color: #f5f5f5 !important;
    }
  }
}

//  Invnentory Restocking Table
.inventory-restocking-table-wraper {
  margin-bottom: 24px;

  .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    // width: 176px;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between;

    button {
      font-size: 12px;
      padding: 0px 16px;
      color: #898989;
      height: 24px;
      border: none;
      background-color: #f5f5f5;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      i {
        margin-left: 8px;
      }
    }
  }
  .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // height: 64px;

    .table-headings,
    .table-sub-headings,
    .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      // padding-left: 14px;
      // padding-right: 40px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .transit-time {
        width: 340px;
        justify-content: flex-start;
        padding-left: 16px;
      }
      .vf-days {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0px 4px;
        }
      }
      :nth-child(3) {
        width: 180px;
        margin-right: 40px;
      }
      :nth-child(4) {
        width: 130px;
      }
    }

    .table-headings {
      .sea-days {
        width: 58%;
      }
    }

    .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5;

      div {
        color: #898989;
      }

      .air-days {
        width: 160px;
      }
    }

    .table-row-data {
      height: 48px;
      background-color: #fff;

      div {
        color: #212121;
      }
      .air-days {
        width: 160px;
      }
    }
    .table-row-data:nth-child(even) {
      background-color: #f5f5f5 !important;
    }
  }
}
