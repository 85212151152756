.snapshot_card {
  display: flex;
  justify-content: space-between;

  .left {
    width: 49%;
  }
  .right {
    width: 49%;
  }
  .ant-card-body {
    padding: 16px !important;
    padding-bottom: 22px !important;
  }

  .ant-card.ant-card-bordered:hover {
    width: 23%;
    cursor: pointer;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.192) !important;
  }

  .research_tool {
    p {
      color: #595959;
      font-size: var(--small-font-size);
      font-weight: 400;
      line-height: 1.25rem;
      margin-bottom: 0px;
    }
  }

  .ant-card-meta-title {
    overflow: hidden;

    color: #212121;
    font-weight: 500;
    white-space: normal;
    text-overflow: ellipsis;
    font-size: var(--medium-font-size);
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    span:nth-of-type(2) {
      display: none;
    }

    svg {
      font-size: 16px;
      margin-top: 1px;
      transition: all 0.35s ease 0s;

      g {
        path:nth-of-type(2) {
          fill: #fff;
        }
      }
    }
  }

  .clickable.new-tab {
    svg {
      font-size: 12px;
    }
  }

  .clickable.open {
    svg {
      transition: all 0.35s ease 0s;
      transform: rotate(180deg);

      g {
        path {
          fill: #fff;
        }
      }
    }
  }
}
