@import url("https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap");
.right-card-signincontainer {
  .rightlogocontainer {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    top: 3px;
    left: 6px;
  }

  .facebookcontainer {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    top: 2px;
    left: 0;
  }
  .googlecontainer {
    position: relative;
    margin-left: 10px;
    margin-right: 5px;
    top: 3px;
    left: 0;
  }
  .error-phone {
    color: #f5222d;
    line-height: 1;
    padding-bottom: 6px;
  }
  .heading-bar {
    padding-top: 58px;
    padding-left: 56px;
  }
  .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121;
    padding-left: 90px;
  }
  .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041;
    padding-left: 84px;
  }
  .flex-from {
    padding-left: 56px;
    padding-top: 24px;
    .ant-form-item {
      margin-bottom: 16px;
    }
    .otp-info {
      font-size: 12px;
      color: #898989;
      font-style: italic;
      line-height: 1;
      margin-top: 6px;
    }
    .input-signup23 {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px;
    }

    .input-signup22 {
      input {
        height: 40px !important;
        border-radius: 4px !important;
        border: 1px solid #898989;
        background-color: #f5f5f5;
        font-size: 14px;
        padding: 10px 0 10px 16px;
        width: 390px;
      }
      .ant-input-suffix {
        right: 68px;
      }
    }
    .phoneinput {
      input {
        height: 40px !important;
        border-radius: 4px !important;
        border: 1px solid #898989 !important;
        background-color: #f5f5f5;
        font-size: 14px;
        padding: 10px 0 10px 16px;
        width: 390px !important;
        padding-left: 45px !important;
      }
    }
    .error-phone-input {
      input:focus {
        border: 1px solid red !important ;
      }
    }
    .forgetBtn {
      border: 0px solid;
      font-weight: 500;
      color: #0068ff;
      box-shadow: none;
      padding:0;
    }
    .alert-error {
      width: 390px;
      margin-bottom: 16px;
      margin-top: 16px;
    }
    .submitbutton {
      background-color: #38b957;
      border-radius: 2px !important;
      height: 40px !important;
      width: 390px;
      font-size: 14px;
      display: block;
      color: #ffffff;
      font-weight: 500;
      border: 0px solid transparent;
    }
    .signup-line {
      // margin-top: -8px;
      margin-bottom: 12px;
      .login-form-signup-here {
        margin-left: 129px;
      }
    }
    .facebookbutton {
      background-color: #2275c8;
      text-align: center;
      border-radius: 2px !important;
      height: 40px !important;
      width: 390px !important;
      font-size: 14px;
      display: block;
      color: #ffffff;
      font-weight: 500;
      margin-bottom: 12px;
      border: 0px solid transparent;
    }
    .signupbutton {
      // background-color: #2275C8;
      border-radius: 2px !important;
      text-align: center;
      height: 40px !important;
      width: 390px !important;
      font-size: 14px;
      display: block;
      border: 1px solid #212121;
      font-weight: 500;
      color: #212121;
    }
  }
  .login-page-footer {
    font-weight: 500;
    margin-top: 50px;

    p {
      margin-bottom: 0px;
      color: #1a1a1a;
      padding-left: 165px;
    }
    .contactus-line{
      text-align:center;
      width:100%;
    }
    .footer-links {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0px;

      padding-left: 128px;

      li {
        display: inline-block;
        margin: 0 20px;
        a {
          color: #1a1a1a;
        }
        a:hover {
          color: rgb(0, 0, 0);
        }
      }
    }
  }
}
