.ali-in-store {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 2px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    cursor: pointer;
    width: auto !important;
  }
  .ali-in-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #fd104d;
  }
  .ali-in-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 85%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 2px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .ali-in-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  margin-top: 4px;

  a {
    span {
      color: #ce1543;
    }
    svg {
      g {
        path {
          stroke: #ce1543;
        }
      }
    }
  }
}

.ali-in-store-ali-in-store:hover {
  a {
    span {
      // text-decoration: underline;
      color: #e61047;
    }
  }
}
