.addfundsmodal {
  top: 32px;

  .addfunds-tabs {
    .ant-tabs-nav-animated {
      width: 100%;
      > div {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        .ant-tabs-tab {
          width: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .fund-err {
    color: crimson;
  }

  .ant-modal-header {
    padding: 0px 0px;
    .head-background {
      background: url("../../../../assets/images/backgrounds/add-funds-background.svg");
      padding: 30px 24px;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
      h1 {
        font-size: 32px;
        color: white;
        font-weight: bold;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        color: white;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: 1px;
      }
    }
  }
  .ant-modal-body {
    .funds-input {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin-bottom: 0px;
        font-size: 32px;
        color: black;
      }
      .ant-input {
        border: none;
        border-bottom: 1px solid black;
        border-radius: 0px;
        width: 200px;
        margin-left: 10px;
        margin-right: 10px;
        height: 41px;
        color: black;
        font-size: 16px;
      }
      .ant-input:focus {
        box-shadow: unset;
      }
      span {
        padding: 9px 24px;
        background: #d6d6d6;
        font-size: 16px;
        font-weight: 500;
        color: black;
      }
    }
    .funds-amount {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      span {
        padding: 9px 24px;
        background: #e3eeff;
        font-size: 16px;
        font-weight: 500;
        color: black;
        cursor: pointer;
      }
    }
    .total-fee,
    .total-amount {
      padding: 9px 24px;
      background: #d6d6d6;
      font-size: 16px;
      font-weight: 500;
      color: black;
      margin-top: 15px;
      p {
        margin: 0;
        font-weight: normal;
      }
    }
    .total-name {
      p {
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #00b700;
      }
    }
    .select-payment {
      background: #f7f7f7;

      .wire-div,
      .cc-div {
        position: relative;
      }

      .abs-text {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 10px;
        font-style: italic;
      }

      p {
        font-size: 16px;
        color: white;
        font-weight: 600;
        margin-bottom: 0px;
        padding: 10px;
        background: #72a0ff;
        letter-spacing: 1px;
      }
      .ant-radio-group {
        width: 100%;
      }
      .ant-radio-button-wrapper-checked {
        color: #fff;
        background: #e2ffe4;
        border: 2px solid #00b50e !important;
        border-radius: 0px;
        width: 100%;
        height: 48px;
      }
      .ant-radio-button-wrapper {
        color: #fff;
        background: #f7f7f7;
        border: 2px solid #f7f7f7;
        border-radius: 0px;
        width: 100%;
        height: 48px;
        font-size: 14px;
        color: black;
        padding: 0 30px;
        background: unset;
        margin-bottom: 0px;
        box-shadow: unset;
        span {
          display: flex;
          align-items: center;
        }
        .anticon {
          font-size: 40px;
          margin-right: 20px;
        }
        .feeCharge {
          font-size: 10px;
          color: black;
          font-style: italic;
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 0px 0px;
    .ant-btn {
      text-align: center;
      background: #336ae2;
      font-size: 16px;
      color: white;
      font-weight: bold;
      letter-spacing: 1px;
      height: 46px;
      border-radius: 0px;
      border: none;
      .anticon {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
  }
  button.ant-modal-close {
    color: white;
  }
}
