.inventory_order_data {
  .inv-nav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 32px;
      .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .filter {
          .ant-select-selection {
            padding: 0 !important;
            background: none;
            border: none;
            margin-right: 0;
          }
          svg {
            font-size: 20px;
          }
          .ant-select-arrow {
            margin-top: -10px;
          }
          .ant-select-selection-selected-value {
            padding-right: 26px;
          }
        }
        .show_data_from {
          font-size: 12px;
        }
        .dispute-status {
          font-size: 12px;
        }
      }
    }
    .secondwrap {
      display: flex;
      align-items: center;
      height: 42px;
      justify-content: space-between;
      .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        .tab {
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;
          color: #898989;
          .active {
            color: #1268fb;
          }
          button {
            display: contents;
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }
        .circle {
          margin-left: 8px !important;
          position: relative;
          background: #f5f5f5;
          font-size: 12px;
          padding: 2px 10px;
          color: #898989;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #898989;
        }
        .backgroundcircle {
          margin-left: 8px !important;
          position: relative;
          background: #1161fb;
          font-size: 12px;
          padding: 2px 10px;
          color: #fff;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #1161fb;
        }
        .hover-inventory-underline-animation {
          position: relative;
        }
        .hover-inventory-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 0px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-inventory-underline-animation:hover {
          color: #4398ed !important;
          .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-inventory-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left;
        }

        .hover-inventory-underline-animation-click {
          position: relative;
        }
        .hover-inventory-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 0px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left;
        }
      }
      .download_csv {
        margin-right: 100px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 32px;
        line-height: 30px;
      }
    }

    // date picker css
    .inventory-datepicker {
      margin-right: 10px;
      .ant-calendar-picker-input {
        width: 88%;

        .anticon {
          right: 62px;
        }
      }
    }

    .inventory-datepicker:focus {
      .ant-calendar-picker-input {
        box-shadow: none;
      }
    }

    .ant-input {
      width: 80%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px;
    }
    .ant-calendar-range-picker-separator {
      color: #1268fb !important;
    }
    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em;
    }
    .ant-calendar-range-picker-input {
      width: 35% !important;
      letter-spacing: 0.032em;
      line-height: 24px;
    }
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      top: 50%;
      right: 47px;
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-top: 90px;

    .import-product-search {
      width: 360px !important;

      .ant-input-wrapper {
        width: 360px !important;
      }
    }
  }
  .ant-select-selection__placeholder {
    color: #1268fb;
  }
}

@media screen and (min-width: 1440px) {
  .inv-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 242px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .inv-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}

.movement-filter-wraper {
  * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer !important;
  }
  margin-bottom: 48px;
  position: relative;

  .ant-select-selection-item {
    display: none !important;
  }

  .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none;

    span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px;
    }
  }

  .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s;
  }

  .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg);

    g {
      fill: #898989;
    }
  }

  .ant-select.ant-select-enabled {
    height: 40px;
  }

  .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px;
    .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      // padding-left: 90px;

      // .ant-select-selection__placeholder {
      //   display: block !important;
      // }
      .ant-select-selection__choice {
        max-width: 60px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        border: 1px solid #e8e8e8;
        padding: 0 2px !important;
        span {
          display: none;
        }
      }

      .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px;
      }
    }
  }

  .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb;
  }

  .ant-checkbox.ant-checkbox-checked {
    display: none;
  }
}

.movement-filter-overlay {
  * {
    letter-spacing: 0.032em;
  }

  .ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item {
    color: #898989;
    min-height: 40px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #cecece;
    padding-left: 40px;

    i {
      position: absolute;
      right: 260px !important;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 12px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: #212121;
    font-weight: 500;
    i {
      background-color: #2bc822;
      border-color: #2bc822 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        stroke: 2px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item:hover {
    background-color: #f5faff;
    border-bottom: 1px solid #b5dafe;
    svg {
      color: #898989;
    }
  }
}

.type-filter-wraper {
  * {
    letter-spacing: 0.032em;
  }
  position: relative;
  margin-bottom: 48px;

  .custom-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    left: 16px;
    z-index: 10;
  }

  .ant-select-open {
    svg {
      g {
        fill: #898989;
      }
    }
  }

  .ant-select-selection.ant-select-selection--single {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px;

    .ant-select-selection__rendered {
      height: 40px;

      .ant-select-selection-selected-value {
        height: 40px;
        display: flex !important;
        align-items: center;
        font-size: 12px;
        color: #1268fb;
        font-weight: 500;
        width: fit-content !important;
        padding-left: 44px;
        .ant-select-selection-selected-value {
          margin-left: 3px;
        }
      }
    }
  }

  .ant-select-arrow {
    margin-top: -7px;
  }
}

.type-filter-overlay {
  * {
    letter-spacing: 0.032em;
  }

  .ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item {
    color: #898989;
    min-height: 40px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #cecece;
    // padding-left: 40px;

    i {
      position: absolute;
      right: 280px !important;
      height: 16px;
      width: 16px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 12px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: #212121;
    font-weight: 500;
    i {
      background-color: #2bc822;
      border-color: #2bc822 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        stroke: 2px;
      }
    }
  }

  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
    i {
      display: none;
    }
  }

  .ant-select-dropdown-menu-item:hover {
    background-color: #f5faff;
    border-bottom: 1px solid #b5dafe;
    svg {
      color: #898989;
    }
  }
}
