.notifications {
  .notification-list {
    border-bottom: 1px solid #f5f5f5;
    .notification-icon-content p span {
      color: #898989;
    }
    .notification-icon-content p {
      color: #898989;
    }
    .notification-date span {
      color: #898989;
    }
  }
  .notification-list:hover {
    background-color: #f5faff;
    border-bottom: 1px solid #b5dafe;
    cursor: pointer;

    .notification-icon-content p span {
      color: #212121;
    }
    .notification-icon-content p {
      color: #212121;
    }
    .notification-date span {
      color: #212121;
    }
  }
  .all-notification-container {
    .recent_notifications p {
      margin-bottom: 8px;
      font-size: 16px;
      letter-spacing: 0.032em;
      color: #262626;
      line-height: 22px;
      font-weight: 500;
    }
  }
}
