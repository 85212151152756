.ant-modal-wrap {
  z-index: 1000;
}
.app-subscription-modal {
  padding: 0px !important;
  width: 90vw !important;
  top: 42px !important;
  border-radius: 0px;

  .logout {
    // text-align: right;
    // padding: 6px;
    margin-right: 40px;
    padding-top: 18px;
    cursor: pointer;
    color: blue;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
  .scroller {
    max-height: 270px !important;
  }
  .ant-skeleton {
    padding: 12rem !important;
  }

  * {
    letter-spacing: 0.032em !important;
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    display: none !important;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0px;
  }

  .ant-modal-content {
    border-radius: 8px !important ;
  }

  // .ant-modal-close-x {
  //   display: none;
  // }
  .ant-modal-body {
    padding: 0px !important;
    border-radius: 0px;
  }
}

.app-subscription-modal.screen-2 {
  width: 65vw !important;
  .ant-modal-content {
    border-radius: 8px !important ;
  }
  .stripe-card-wraper {
    margin-bottom: 22px !important;
  }
}
.app-subscription-modal.screen-3 {
  width: 65vw !important;
  .ant-modal-content {
    border-radius: 8px !important ;
  }
}
