@import url("../Common/index.scss");

.Transactions_head {
  .Vforderid_txt {
    padding: 0px 24px;
    span {
      padding: 5px;
      padding-left: 8px;
      background: #3385ff;
      color: white;
      border-radius: 2px;
      border-bottom-left-radius: 0;
      font-size: var(--small-font-size);
      font-weight: 500;
      letter-spacing: var(--normal-letter-spacing);
      display: inline-block;
      margin-right: 8px;
    }

    // .transaction_type {
    //   background: none;

    //   .transaction_type_debit {
    //     padding: 5px;
    //     padding-left: 8px;
    //     background: #e14643;
    //     color: white;
    //     border-radius: 2px;
    //     border-bottom-left-radius: 0;
    //     font-size: var(--minimun-size);
    //     font-weight: 500;
    //     letter-spacing: 0.032em;
    //     display: inline-block;
    //   }
    //   .transaction_type_credit {
    //     padding: 5px;
    //     padding-left: 8px;
    //     background: #36c626;
    //     color: white;
    //     border-radius: 2px;
    //     border-bottom-left-radius: 0;
    //     font-size: var(--minimun-size);
    //     font-weight: 500;
    //     letter-spacing: 0.032em;
    //     display: inline-block;
    //   }
    // }
  }
  .modal_headerdata {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 24px;
    align-items: center;
  }
  .Header_name {
    font-size: var(--small-font-size);
    padding: 0px 24px;
  }
  .Transactions-table {
    width: 100%;
    font-size: var(--small-font-size);
    transition-duration: 1s;
    // margin-bottom: 20px;
    overflow-x: scroll;
    padding: 16px 24px;
    display: inline-block;

    th {
      color: #898989;
      font-weight: 400;
      padding: 16px;
      white-space: normal;
      letter-spacing: 0.032em;
      line-height: var(--small-line-height);
    }

    td {
      padding: 16px 16px;
      color: #212121;
      text-transform: capitalize;
      border: 2px solid #e3e3e3;
      text-align: center;
      font-size: 16px;

      .wraper {
        font-size: 12px;
      }
    }
  }
  .transaction_images span {
    white-space: nowrap;
    display: inline-block;
  }

  .transaction_images.gb {
    width: 318px;
    text-align: start;
  }

  .transaction_images {
    .wraper {
      display: flex;
      align-items: center;
    }
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }
  .ant-modal-body table thead th:nth-child(3) {
    text-align: none !important;
  }
  .Transactions-table_Total {
    width: 100%;

    transition-duration: 1s;
    margin-bottom: 20px;
    overflow-x: scroll;
    padding: 16px 24px;
    display: inline-block;
    margin: -56px 0 0 38px;

    td {
      padding: 16px 16px;
      color: #212121;
      text-transform: capitalize;
      // border: 2px solid #e3e3e3;
      width: 200px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  tr.no-border {
    border: none !important;
    font-size: var(--medium-font-size);
    font-weight: 600;
    letter-spacing: var(--normal-letter-spacing);
    line-height: var(--small-line-height);
  }
  .no-border td {
    border: none !important;
    background: white !important;
  }
  .red_text {
    color: #e14643;
    font-weight: 600;
    letter-spacing: var(--normal-letter-spacing);
    line-height: var(--small-line-height);
  }
  .green_text {
    color: #36c626;
    font-weight: 600;
    letter-spacing: var(--normal-letter-spacing);
    line-height: var(--small-line-height);
  }
}

.Transactions_head .Transactions-table th:first-child {
  padding-left: 0;
}
.Transactions_head .Transactions-table td:first-child {
  background: #f5f5f5;
}

.Transactions_head.gb {
  .Transactions-table {
    td {
      padding: 16px 30px !important;
    }
  }
}
