.walletsummarynav {
  .walletnav {
    padding-left: 59px;
    padding-top: 104px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 121;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .filter {
          .ant-select-selection {
            padding: 0 !important;
            background: none;
            border: none;
            margin-right: 0;
          }
          svg {
            font-size: 20px;
          }
          .ant-select-arrow {
            margin-top: -10px;
          }
          .ant-select-selection-selected-value {
            padding-right: 26px;
          }
        }
        .show_data_from {
          font-size: 12px;
        }
        .dispute-status {
          font-size: 12px;
        }
      }
    }
    .secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        .tab {
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;
          color: #898989;
          .active {
            color: #1268fb;
          }
          button {
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 500;
          }
        }

        .hover-transactions-underline-animation {
          position: relative;
        }
        .hover-transactions-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-transactions-underline-animation:hover {
          color: #4398ed !important;
          .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-transactions-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left;
        }

        .hover-transactions-underline-animation-click {
          position: relative;
        }
        .hover-transactions-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left;
        }
      }
      .download_csv {
        margin-right: 100px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 32px;
        line-height: 30px;
      }
    }
    .ant-input {
      width: 80%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px;
    }
    .ant-calendar-range-picker-separator {
      color: #1268fb !important;
    }
    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em;
    }
    .ant-calendar-range-picker-input {
      width: 36% !important;
      letter-spacing: 0.032em;
      line-height: 24px;
    }
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      top: 50%;
      right: 46px;
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
  }
  .bottom_data {
    display: flex;
    justify-content: space-between;
    // margin-top: 24px;
    padding-top: 104px;

    .custom-wraper {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
      height: 60px;
      .Globalsearch {
        margin-top: 12px !important;
      }
    }

    .Globalsearch {
      .ant-input-wrapper.ant-input-group {
        width: 400px !important;
      }
    }
  }
  .ant-select-selection__placeholder {
    color: #1268fb;
  }
  .ant-cascader-picker {
    width: 368px !important;
    border-radius: 2px !important;
  }
  .ant-input {
    border-radius: 0px !important;
    font-size: 12px !important;
    border: none;
  }
  .ant-cascader-menu-item {
    padding: 8px 16px !important;
    color: #898989;
  }
  .ant-cascader-menu-item {
    padding: 8px 16px !important;
    border-bottom: 1px solid #e6f4ff !important;
    color: #898989;
  }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background: #e6f4ff !important;
    // border-bottom: 2px solid #b5dafe !important;
    background: #e6f4ff;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding: 8px 16px !important;
  }
  .ant-cascader-menu-item:hover {
    background: #e6f4ff;
    // border-bottom: 2px solid #b5dafe !important;
    background: #e6f4ff;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding: 8px 16px !important;
  }
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
    right: 2px !important;
  }
  .ant-cascader-menu {
    width: 155px;
  }

  .transaction-head-btn-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
.ant-cascader-menu:nth-of-type(2) {
  width: 266px;
}
@media screen and (min-width: 1440px) {
  .walletnav {
    padding-left: 104px !important;
    padding-top: 104px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
  .ant-cascader-menu {
    width: 155px;
  }
  .ant-cascader-menu-item {
    padding: 8px 16px !important;
    border-bottom: 1px solid #e6f4ff !important;
    font-size: 12px;
    color: #898989;
  }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background: #e6f4ff !important;
    // border-bottom: 2px solid #b5dafe !important;
    background: #e6f4ff;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding: 8px 16px !important;
  }
  .ant-cascader-menu-item:hover {
    background: #e6f4ff;
    background: #e6f4ff;
    // border-bottom: 2px solid #b5dafe !important;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding: 8px 16px !important;
  }
  .ant-select-selection__placeholder {
    color: #898989;
  }
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
    right: 2px !important;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .walletnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.walletsummarynav {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}

// custom transaction filter

.multi-transaction-head-filter {
  letter-spacing: 0.032em !important;
  margin-top: 12px;
  > p {
    margin-bottom: 0.5em;
    font-size: 16px;
    color: #212121;
    font-weight: 500;
  }

  .wraper {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

  .multiple-search {
    width: 55.6%;
    position: relative;

    .transactions-select {
      width: 100%;
      height: 32px;
      overflow: hidden;
      background-color: #fff;
      border: none;
      box-shadow: 0px 2px 8px #00000014;
      .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
        .ant-checkbox-checked {
          margin-right: 0px;
          opacity: 0;
          font-size: 0px;
          input {
            font-size: 0px;
          }

          span {
            height: 0px;
            width: 0px;
          }
        }
      }

      .ant-select-selection__rendered {
        margin-right: 10px;

        .ant-select-selection__choice__content {
          width: 122px;
          font-size: 12px !important;
        }
        .ant-select-selection__choice__disabled {
          width: 55px;
        }

        .ant-select-selection__placeholder {
          color: #00000040 !important;
        }
      }
      .ant-select-selection__choice {
        padding-left: 6px;
      }
      .ant-select-selection {
        border: none;
        border-radius: 2px;
        background-color: #fff;
      }
    }
    .arrow-icon {
      font-size: 16px;
      position: absolute;
      top: 20%;
      right: 8px;
      // transform: translateY(-50%);
      z-index: 990;
      transform: rotate(0deg);
      transition: 0.5s all;
    }
    .rotate {
      transform: rotate(180deg);
      transition: 0.5s all;
      g {
        fill: gray;
      }
    }
  }
}

// custom transaction filter dropdown
.transaction-dropdown {
  letter-spacing: 0.032em !important;
  background-color: #fff !important;
  padding: 16px 12px;
  border-radius: 2px;
  width: 992px !important;
  .ant-checkbox {
    margin-right: 8px;
  }

  > div {
    ul {
      // ::--webkit-scrollbar-thumb {
      //   background-color: #898989 !important;
      //   width: 20px;
      // }
      // ::-webkit-scrollbar {
      //   width: 8px;
      //   color: red;
      //   border: 1px solid red;
      // }

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
      }

      // overflow: hidden !important;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    max-height: 232px !important;

    li {
      width: 236px;
      padding-right: 6px !important;
      font-size: 12px !important;
      color: #898989;
      border: 1px solid #f5f5f5;
      border-radius: 0px !important;

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #36c626 !important;
          border: 1px solid #36c626;
        }
      }

      .anticon.anticon-check.ant-select-selected-icon {
        display: none;
      }
    }

    li:first-of-type {
      width: 100%;
      border: none;
      color: #0062ff;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      cursor: pointer;
      font-weight: 500;

      span {
        cursor: pointer;
      }

      span:hover {
        text-decoration: underline;
      }

      .active.red-e {
        color: #ff005e !important;
      }
    }
    li:first-of-type:hover {
      background-color: #fff;
      cursor: auto;
      box-shadow: none;
    }

    li:first-of-type:focus {
      background-color: #fff;
      cursor: auto;

      box-shadow: none;
    }
    li:first-of-type:active {
      background-color: #fff;
      cursor: auto;
      box-shadow: none;
    }

    li.ant-select-dropdown-menu-item-selected {
      color: #212121;
      font-weight: 400;
    }

    li {
      border: none;
      border-top: 1px solid #cecece;
      border-left: 1px solid #cecece;
    }

    li:nth-of-type(5) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(9) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(13) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(17) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(21) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(25) {
      border-right: 1px solid #cecece;
    }
    li:nth-of-type(29) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(33) {
      border-right: 1px solid #cecece;
    }

    li:nth-of-type(37) {
      border-right: 1px solid #cecece;
      border-bottom: 0px solid #cecece !important;
    }

    li:nth-of-type(38) {
      border-bottom: 1px solid #cecece;
    }

    li:nth-of-type(39) {
      border-bottom: 1px solid #cecece;
    }

    li:nth-of-type(40) {
      border-bottom: 1px solid #cecece !important;
    }
    li:nth-of-type(41) {
      border-bottom: 1px solid #cecece !important;
      border-right: 1px solid #cecece;
    }

    .ant-select-dropdown-menu-item-active:first-of-type {
      background-color: #fff;
    }

    li:hover {
      border-bottom-color: #b5dafe;
      .anticon.anticon-check.ant-select-selected-icon {
        display: none;
      }
      .ant-checkbox-inner {
        border-color: #36c626 !important;
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
      border: 1px solid #898989;
    }
    ::--webkit-scrollbar-thumb {
      background-color: #898989;
    }
    // li:last-of-type {
    //   border-bottom: 2px solid #f5f5f5 !important;
    // }
  }

  ul::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  ul::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ul::-webkit-scrollbar {
    width: 8px;

    border: 1px solid #898989;
  }
}
