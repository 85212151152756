.dispute-failed-modal {
    .di {
        height: 60px;
        display: flex;
        justify-content: left;
        align-items: center;
        svg {
            font-size: 12px;
            margin-right: 15px;
            fill: red;
            color: red;
        }
        h6 {
            margin: 0;
            padding: 0;
        }
    }
    .dispute-raised-header {
        width: 102%;
        text-align: center;
        p {
            font-size: 12px;
            color: #898989;
            line-height: 16px;
            text-align: left;
        }
        h6 {
            font-size: 16px;
            color: black;
            font-weight: 500;
        }
        .anticon {
            font-size: 76px;
            color: #e20101;
            margin-bottom: 15px;
        }
        .ant-btn {
            border: none;
            box-shadow: none;
            width: 118px;
            font-size: 12px;
            font-weight: 500;
            margin-right: 15px;
        }
        a {
            color: #919191;
            font-size: 12px;
            font-weight: 500;
            text-decoration: none;
        }
    }
    .ant-modal-body {
        padding: 25px 20px 30px 35px;
    }
    .but {
        margin-top: 20px;
    }
}