.right-varaint-image-data {
  width: 50%;
}
.allspec-data {
  display: flex;
}

.allspec-dropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 24px;
}

.allspec-dropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important;
}

.option-varaint.allspecs-product-name {
  margin-bottom: -5px;
}

// .allspecs-product-name {
//   margin-bottom: -5px;
// }
