.stripe-screen-wraper {
  .stripe-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 44px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .stripe-card-wraper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;

    .payment-element-wraper {
      h1 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .payment-element {
        width: 500px;
        min-height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 12px;
        background-color: #f7fafc !important;

        .btn-wraper {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            height: 40px;
            border: 1px solid #536be8;
            margin-bottom: 8px;
            margin-top: 20px;
            padding-left: 40px;
            padding-right: 40px;

            background-color: #536be8;

            i {
              svg {
                fill: #fff !important;
              }
            }

            span {
              color: #fff;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .secure {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        color: #898989;
        font-size: 14px;
        margin-top: 20px;
      }
    }

    .preview-card-wraper {
      .plan-card {
        padding-bottom: 14px !important;

        gap: 8px !important;
        padding-bottom: 20px;

        .price-card {
          gap: 0px !important;
        }
        .bottom-bar {
          display: none;
        }
      }

      .shaker-card.plan-card {
        margin-top: 0px !important;
      }

      .shaker-cards-wraper {
        gap: 8px !important;
      }

      .change-plan {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        h1 {
          font-size: 16px;
          font-weight: 600;
        }

        h2 {
          font-size: 12px;
          font-weight: 400;
          color: #536be8;
          cursor: pointer;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          svg {
            fill: #536be8;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .stripe-screen-wraper {
    padding: 12px;

    .stripe-header {
      h1 {
        text-align: center;
      }
    }
    .payment-element {
      width: 450px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .ant-modal.app-subscription-modal.screen-2 {
    width: calc(100vw - 16px) !important;
  }

  .stripe-screen-wraper {
    padding: 12px;

    .stripe-header {
      h1 {
        text-align: center;
      }
    }
    .payment-element {
      width: 300px !important;
    }
  }
}
