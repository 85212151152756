.kyc-individual-organisation {
  .checkbox-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -22px;
    .checkbox-profile-type-card {
      height: 216px;
      width: 240px;
      border: 3px solid #e3e3e3;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      background: #fff;
      cursor: pointer;
      .flag {
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.032em;
        line-height: 18px;
        padding: 16px;
        font-weight: 400;
        .registered-india {
          text-decoration: underline;
          font-weight: 500;
        }
        .not-registered-india {
          text-decoration: underline;
          font-weight: 500;
        }
      }
      .footer {
        flex: unset;
        width: 240px;
        font-size: 12px;
        color: white;
        padding: 8px 0;
        background: #36c626;
        position: relative;
        left: -3px;
        top: 12px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      .anticon {
        margin: auto;
        margin-top: 24px;
        svg {
          width: 80px;
          height: 80px;
        }
      }
    }
    .individual-card {
      position: relative;
      margin-right: 32px;
      margin-top: 56px;
    }

    .organisation-card {
      position: relative;

      margin-top: 56px;
    }

    .checkbox-profile-type-card:hover {
      border: 3px solid #30c638;
      // box-shadow: 1px 2px 3px #00000080;
      .registered-india {
        font-weight: 600 !important;
      }
      .not-registered-india {
        font-weight: 600 !important;
      }
    }
  }

  .skip-now {
    margin-top: 64px;
  }

  .skip-now a {
    cursor: pointer;
    margin-top: 24px;
    width: 120px;
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background: none;
    font-size: 12px;
    color: #4b4b4b;
    padding: 8px;
  }

  .skip-now a:hover {
    background: #0031ff;
    color: white;
    border: none;
  }

  .back-btn {
    margin-top: 16px;
    background: none;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    float: right;
    cursor: pointer;
    line-height: 16px;
    &:hover {
      font-weight: 500;
      text-decoration: underline;
      font-size: 12px;
      letter-spacing: 0.032em;
    }
    .anticon {
      vertical-align: middle;
      margin-left: 2px;
      margin-top: -1px;
      svg {
        transform: rotate(89deg);
        width: 16;
        height: 8px;
      }
    }
  }

  .small-indian-flag {
    .anticon {
      svg {
        width: 24px !important;
        height: 24px !important;
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }
  }
  .organisation-small-indian-flag {
    .anticon {
      svg {
        width: 24px !important;
        height: 24px !important;
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }
  }
}
