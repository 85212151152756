.withdrawalSuccessModal {
  .withdrawal-sucess-content {
    h1 {
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: bold;
      margin-top: 10px;
      color: #242424;
    }
    p {
      font-size: 14px;
      color: #242424;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .anticon {
      font-size: 55px;
    }
  }
  .withdrawal-time {
    margin-top: 25px;

    font-size: 14px;
    color: #242424;
    font-style: italic;
  }
  .footer-button {
    margin-top: 25px;
    .ant-btn {
      padding: 0px 16px;
      text-align: center;
      background: #00b50e;
      font-size: 18px;
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      height: 48px;
      border-radius: 0px;
      border: none;
      .anticon {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
  }
}
