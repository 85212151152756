.verify-profile {
  .first-step-footer {
    height: 228px;
    background-position: center;
    background-size: cover !important;

    background-repeat: no-repeat !important;
    background: url(../../../assets/images/backgrounds/test.svg);
  }
  .label-row {
    display: flex;
    justify-content: space-between;
    text-align: left;
    .label {
      position: relative;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
    }
    .required::after{
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .onboarding-skip {
    cursor: pointer;
    margin-top: 24px;
    width: 120px;
    height: 40px;
    border: 1px solid black;
    border-radius: 2px;
    background: none;
    font-size: 12px;
    color: #4b4b4b;
  }

  // .onboarding-skip:hover {
  //   background: #0031ff;
  //   color: white;
  //   border: none;
  // }

  .ant-row.ant-form-item {
    text-align: left;
    position: relative;

    button {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 22px;
      background-color: none;
      border: none;
      cursor: pointer;
    }
  }
  .content-container {
    width: 100%;
    max-width: 510px;
    margin: auto;
    text-align: center;
    // margin-bottom: 69px;
    padding-bottom: 8px;
    .profile-indian-global {
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-indian {
        margin-right: 32px;
      }
    }

    .select-option-txt {
      font-size: 12px;
      color: #4b4b4b;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-bottom: 13px;
      margin-top: 69px;
    }
  }
  .profile-type-card {
    height: 216px;
    width: 240px;
    border: 3px solid #e3e3e3;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    .flag {
      font-size: 12px;
      color: #000000;
      letter-spacing: 0.032em;
      line-height: 18px;
      margin-bottom: 16px;
      font-weight: 400;
      .registered-india {
        text-decoration: underline;
        font-weight: 500;
      }
      .not-registered-india {
        text-decoration: underline;
        font-weight: 500;
      }
    }
    .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
    .anticon {
      margin: auto;
      margin-top: 24px;
      svg {
        width: 80px;
        height: 80px;
      }
    }
  }
  .ant-upload {
    text-align: left;
    padding: 0 15px;
    p {
      margin-bottom: 0 !important;
      .anticon {
        color: unset !important;
        font-size: 22px !important;
      }
    }
  }
  .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
    border-radius: 50% !important;
    display: none;
  }
  .iti--allow-dropdown input,
  .iti--allow-dropdown input[type="text"],
  .iti--allow-dropdown input[type="tel"],
  .iti--separate-dial-code input,
  .iti--separate-dial-code input[type="text"],
  .iti--separate-dial-code input[type="tel"] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;
    width: 100%;
    display: inline-block;
    border: none;
    background: #efefef;
    height: 32px;
    font-size: 14px;
  }
  .iti {
    position: relative;
    display: inline-block;
    width: 100%;
    border: none;
    display: flex;
    justify-content: space-between;

    li {
      padding: 0px 8px !important;
      outline: none !important;
      border-bottom: 1px solid #f5f5f5;
    }
    .iti__country-list {
      border: none !important;
    }
    .iti__flag-container {
      background: #f5f5f5;
      height: 32px;
      width: 70px;
    }

    input:focus-visible {
      outline: none !important;
      border-radius: 0px !important;
    }
  }

  .deDisclaimer-txt {
    background: #fffcf8;
    border: 2px solid #db8230;
    padding: 16px;
    color: #212121;
    font-weight: 400;
    letter-spacing: 0.032em;
    margin: 16px;
    line-height: 20px;
    border-radius: 4px;
    font-size: 12px;
    width: 650px;
    position: relative;
    right: 14%;
    .disclaimer {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.032em;
      font-weight: 600;
      color: #db8230;
    }
  }

  .ant-upload-list-item-info {
    .anticon-paper-clip {
      display: none;
    }
    > span {
      > span {
        display: none;
      }
    }
  }
  .ant-upload-list-item {
    position: relative;
    height: 0px !important;
    margin-top: 8px !important;
    font-size: 0px !important;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #fff !important;
  }

  .uploadcross-btn {
    margin-top: 8px;
  }
  .onboarding-header .title {
    color: #fc125e;
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.032em;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .onboarding-header .subtitle {
    font-size: 12px !important;
    line-height: 22px;
    letter-spacing: 0.032em;
    color: #4b4b4b;
    line-height: 22px;
  }

  .ant-form-item label {
    position: relative;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
  }
  .upload-label {
    margin-bottom: 8px;
  }
  .back-btn {
    background: none;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    float: right;
    cursor: pointer;
    line-height: 16px;
    &:hover {
      font-weight: 500;
      text-decoration: underline;
      font-size: 12px;
      letter-spacing: 0.032em;
      cursor: pointer;
    }
    .anticon {
      vertical-align: middle;
      margin-left: 2px;
      margin-top: -1px;
      svg {
        transform: rotate(89deg);
        width: 16;
        height: 8px;
      }
    }
  }
  .gst-individual {
    background: #f5f5f5;
    border: none;
    padding: 16px;
    color: #212121;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 18px;
    border-radius: 0px;
    font-size: 12px;
    width: 512px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    .anticon {
      margin-right: 8px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
  .flag {
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.032em;
    line-height: 18px;

    .registered-india {
      text-decoration: underline;
      font-weight: 500;
    }

    .not-registered-india {
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .profile-form {
    margin-top: 8px;
  }
  .top-header-steps {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    margin-bottom: 16px;
    p {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  .country-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .state-wrapper {
      width: 48%;
      .state-select {
        .ant-select-selection {
          box-shadow: none;
          border: none;
          background: #f5f5f5;
          border-radius: 0px;
        }
        .ant-select-selection-selected-value {
          font-size: 12px !important;
          letter-spacing: 0.032em;
        }
      }
    }

    .city-wrapper {
      width: 48%;
      .city-select {
        .ant-select-selection {
          box-shadow: none;
          border: none;
          background: #f5f5f5;
          border-radius: 0px;
        }
        .ant-select-selection-selected-value {
          font-size: 12px !important;
          letter-spacing: 0.032em;
        }
      }
    }

    .global-country {
      width: 48%;
      .city-select {
        .ant-select-selection {
          box-shadow: none;
          border: none;
          background: #f5f5f5;
          border-radius: 0px;
        }
        .ant-select-selection-selected-value {
          font-size: 12px !important;
          letter-spacing: 0.032em;
        }
      }
    }
  }

  .city-pincode-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .india-country {
      width: 48%;
      .city-select {
        .ant-select-selection {
          box-shadow: none;
          border: none;
          background: #f5f5f5;
          border-radius: 0px;
        }
        .ant-select-selection-selected-value {
          font-size: 12px !important;
          letter-spacing: 0.032em;
        }
      }
    }
    .pincode-wrapper {
      width: 48%;
    }
  }
  .iti--allow-dropdown input,
  .iti--allow-dropdown input[type="text"],
  .iti--allow-dropdown input[type="tel"],
  .iti--separate-dial-code input,
  .iti--separate-dial-code input[type="text"],
  .iti--separate-dial-code input[type="tel"] {
    padding-left: 16px !important;
    margin-left: 0;
  }
  ::-webkit-input-placeholder {
    color: #898989;
    letter-spacing: 0.032em;
  }
}

.verify-profile .iti--allow-dropdown input,
.verify-profile .iti--allow-dropdown input[type="text"],
.verify-profile .iti--allow-dropdown input[type="tel"],
.verify-profile .iti--separate-dial-code input,
.verify-profile .iti--separate-dial-code input[type="text"],
.verify-profile .iti--separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
  width: 100%;
  display: inline-block;
  border: none;
  background: #f5f5f5 !important;
  height: 32px;
  font-size: 12px !important;
  margin-left: 80px;
}

.verify-profile .profile-type-card:hover {
  border: 3px solid #30c638;
  // box-shadow: 1px 2px 3px #00000080;
  .registered-india {
    font-weight: 600;
  }
  .not-registered-india {
    font-weight: 600;
  }
}

.country-list {
  max-height: 200px !important;
  background: hotpink;
}

.profile-dropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important;
}

.profile-dropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 4px 8px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px;
}

.phone-field-wraper {
  position: relative;

  .anchor {
    top: 35%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    cursor: pointer !important;

    z-index: 999 !important;
    height: 35px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1px !important;
    text-decoration: none !important;
  }

  .verify-phone.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important;
  }

  .verify-phone2.anchor {
    opacity: 1 !important;
  }

  .verify-email.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important;
  }

  .verify-email2.anchor {
    opacity: 1 !important;
  }
}

.phone-field-wraper.custom {
  height: 88px !important;
  .anchor {
    top: 26%;
  }
}

.email-field-wraper {
  position: relative !important;
  min-height: 80px !important;
  #email {
    position: relative;
  }

  .anchor {
    top: 27%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    cursor: pointer !important;
    z-index: 999 !important;
    height: 33px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1px !important;
    text-decoration: none !important;
  }

  .verify-phone.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important;
  }

  .verify-phone2.anchor {
    opacity: 1 !important;
  }

  .verify-email.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important;
  }

  .verify-email2.anchor {
    opacity: 1 !important;
  }
}
