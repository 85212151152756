.edit-payment-modal {
  width: 560px !important;

  .stripe-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-bottom: 24px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .stripe-card-wraper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;

    .payment-element-wraper {
      h1 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .payment-element {
        transition: 1s all linear !important;
        width: 500px !important;
        min-height: 150px;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 12px;
        background-color: #f5f5f5 !important;

        .btn-wraper {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            height: 40px;
            border: 1px solid #536be8;
            margin-bottom: 8px;
            margin-top: 20px;
            padding-left: 40px;
            padding-right: 40px;

            background-color: #536be8;

            i {
              svg {
                fill: #fff !important;
              }
            }

            span {
              color: #fff;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .secure {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        color: #898989;
        font-size: 14px;
        margin-top: 20px;
      }
    }

    .preview-card-wraper {
      .plan-card {
        padding-bottom: 14px !important;

        gap: 8px !important;
        padding-bottom: 20px;

        .price-card {
          gap: 0px !important;
        }
        .bottom-bar {
          display: none;
        }
      }

      .shaker-card.plan-card {
        margin-top: 0px !important;
      }

      .shaker-cards-wraper {
        gap: 8px !important;
      }

      .change-plan {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        h1 {
          font-size: 16px;
          font-weight: 600;
        }

        h2 {
          font-size: 12px;
          font-weight: 400;
          color: #536be8;
          cursor: pointer;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          svg {
            fill: #536be8;
          }
        }
      }
    }
  }

  .error-div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: red;
  }

  .payment-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }

  .StripeElement {
    border: 1px solid #f5f5f5;
    margin-bottom: 4px;
    background-color: #fff !important;
    padding: 6px 12px !important;
  }

  .StripeElement--invalid {
    border: 1px solid red;
  }
}
