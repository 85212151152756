.lay {
  padding-top: 5.25rem;
}

.lay2 {
  padding-top: 4.1rem;
}

.dashboard-header {
  background: #f7f7fc;
  color: #000;
}

.dashboard-content {
  padding: 1.25rem 3.5rem;
  width: 100%;
  margin: auto;
}

.dashboard-content.no-sidebar {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-top: -20px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.dashboard-content > div {
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px;
  }
}

@media screen and (max-width: 1438px) {
  .dashboard-content {
    padding: 1.25rem 3.5rem;
    width: 100%;
    margin: auto;
  }
  .dashboard-content-padding{

  }
}

@media screen and (min-width: 1439px) and (max-width: 1445px) {
  .dashboard-content {
    padding: 1.25rem 104px;
    width: 100%;
    margin: auto;
  }
  .dashboard-content-padding{
    padding: 1.25rem 65px;
  }
}

.ant-layout {
  background: #f0f3f7;
}

.clickable.link {
  padding: 8px 12px;
}
