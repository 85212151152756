// notification
.custom-notification {
  width: 307px;
  padding: 10px 8px;
  padding-right: 30px;
  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-close {
    right: 8px;
    top: 8px;

    svg {
      path {
        fill: #2a9c27;
      }
    }
  }
  .notification-wraper {
    display: flex;

    .notification-icon-svg {
      display: flex;

      align-items: center;
      margin-right: 16px;
      svg {
        height: 48px;
        width: 48px;
      }
    }

    .content {
      h5 {
        display: flex;
        align-items: center;
        margin: 0;
        h2 {
          text-decoration: underline;
          font-weight: 600;

          font-size: 12px;
          margin: 0;
          margin-bottom: 3px;
          color: #212121;
          letter-spacing: 0.032em;
        }

        span {
          text-decoration: none;
          font-weight: 600;

          font-size: 12px;
          margin: 0;
          margin-bottom: 3px;
          color: #212121;
          letter-spacing: 0.032em;
        }
      }

      p {
        font-size: 12px;
        color: #383838;
        letter-spacing: 0.032em;
      }

      h3 {
        height: 22px;
      }

      h4 {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        position: absolute;
        right: 8px;
        bottom: 10px;
        font-weight: 400;
        font-size: 12px;
        color: #383838;
        font-style: italic;
        display: inline-block;
      }
    }
  }
}

.custom-notification-hold {
  .ant-notification-notice-close {
    right: 8px;
    top: 8px;

    svg {
      path {
        fill: #f3b62d;
      }
    }
  }
  .notification-wraper {
    .notification-icon-svg {
      svg {
        height: 48px;
        width: 48px;
        g {
          g {
            stroke: #f3b62d;
          }
          path {
            stroke: #f3b62d;
          }
        }
      }
    }
  }
}

.custom-notification-cancel {
  .ant-notification-notice-close {
    right: 8px;
    top: 8px;

    svg {
      path {
        fill: #c70c3c;
      }
    }
  }
  .notification-wraper {
    .notification-icon-svg {
      svg {
        height: 48px;
        width: 48px;
        g {
          g {
            stroke: #c70c3c;
          }
          path {
            stroke: #c70c3c;
          }
        }
      }
    }
  }
}

// escalations
.escalate {
  width: 303px;
  padding: 10px 8px;
  padding-right: 30px;
}

.import-ali {
  width: 315px;
}
