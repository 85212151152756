.variants-modal {
  .variant-container {
    .variants-data {
      .variant-header {
        background: black;
        display: flex;
        justify-content: space-between;
        p {
          color: white;
          margin-bottom: 0px;
          padding: 12px 24px;
        }
      }
      .variant-body {
        display: flex;
        margin-bottom: 10px;
        position: relative;
        .ant-select {
          flex: 1;

          .ant-select-selection {
            background: #ececec;
            border-radius: 0px;
            height: 48px;
          }
          .ant-select-selection__rendered {
            line-height: 48px;
          }
        }
        .anticon.anticon-close-circle {
          position: absolute;
          z-index: 999;
          left: -8px;
          top: 15px;
          font-size: 19px;
          color: #707070;
        }
        .ant-input-number {
          width: 100px;
          border: none;
          background: #ececec;
          border-radius: 0px;
          margin-left: 15px;
          height: 48px;
          .ant-input-number-input {
            height: 48px;
          }
        }
      }
      .total-quantity {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        p {
          background: #eaf3ff;
          padding: 10px 24px;
          text-align: center;
          font-weight: 500;
          color: black;
          margin-bottom: 0px;
        }
      }
      .add-variant-btn {
        text-align: end;
        .ant-btn-link {
          color: #ff0064;
          margin-bottom: 12px;
        }
      }
      .variant-footer {
        display: flex;
        .ant-btn-primary {
          border: none;
          border-radius: 0px;
        }
        .save-btn {
          border: none;
          background: #36c626;
          border-radius: 0px;
          color: white;
          flex: 1;
          margin-left: 10px;
        }
      }
    }
  }
}
