.shipment-card {
  margin-bottom: 3%;
  letter-spacing: 0.032em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .vforderid_tag {
    position: absolute;
    left: 0px;
    top: 24px;
  }
  // .vforderid_tag:before {
  //   content: "";
  //   position: absolute;
  //   height: 0;
  //   width: 0;
  //   top: 100%;
  //   left: 0px;
  //   border-bottom: 9px solid hsla(216, 100%, 35%, 1);
  //   border-left: 9px solid transparent;
  //   transform: rotate(270deg);
  // }
  .ant-dropdown-button.ant-btn-group
    > .ant-btn:last-child:not(:first-child):not(:disabled) {
    background: #3587ff;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.15) !important;
  }
  .ant-btn-group {
    .ant-btn {
      vertical-align: middle;
    }
  }
  .hidden {
    display: none;
  }
  .ant-card-body {
    padding: 0;
  }
  .blue-text {
    color: #1161fb;
  }
  .mr-3 {
    margin-right: 10px;
  }
  span {
    vertical-align: middle;
    i {
      vertical-align: middle;
    }
  }
  .header-row {
    display: flex;
    align-items: center;
    padding: 64px 32px 16px 32px;
    justify-content: space-between;

    h3 {
      margin-bottom: 0px;
      font-size: 12px;
      font-weight: 400;
      color: #898989;
      line-height: 16px;
      letter-spacing: 0.032em;
    }
    .shipment-date {
      display: flex;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 17px;
        // margin-right: 5px;
      }
    }
    .shipment-action {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      justify-content: space-around;

      .icansvg {
        margin-top: -2px;
      }
    }

    .shipment-action_bg {
      width: 168px;
      height: 32px;
      background: #1161fb;
      color: #fff;
      position: relative;
      border-radius: 2px;
    }
    .middledata {
      display: flex;
      justify-content: center;
    }
  }
  .tabs-row {
    background: #16273b;
    padding: 0 20px 0 28px;
    display: flex;
    align-items: center;
    height: 40px;
    overflow: hidden;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 11px 32px;
        color: white;
        cursor: pointer;
        font-size: 12px;
        height: 42px;
      }
      li.active,
      li:hover {
        background: #244060;
      }
    }
    .bulk-action-btn {
      background: transparent;
      color: white;
      border: 2px solid #fff;
      border-radius: 4px;
    }
    .ant-avatar {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
    }
  }
  .tab-container {
    background: #f2f2f2;
    padding: 0 28px 16px 28px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .end_data svg {
    width: 16px !important;
    height: 16px !important;
  }
  .icansvg svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .delivered-date {
    margin-bottom: 0px;
    // color: #f12f1a;
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
    line-height: 16px;
  }
  .middledata_shipment {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.review_pickup_flex {
  display: flex;
  justify-content: start;
  align-items: center;
  .antCircleCheck.anticon {
    font-size: 16px;
    color: #d9d9d9;
  }
}

.review_pickup_flex .ant-tooltip-open:hover {
  svg {
    #Ellipse_44 {
      stroke: #1268fb;
      fill: #1268fb;
    }
    #Ellipse_47 {
      stroke: #fff;
      fill: #ffff;
    }
    #Rectangle_2462 {
      stroke: #fff;
      fill: #ffff;
    }
  }
}

.review_pickup_flex .antCircleCheck.anticon:hover {
  font-size: 16px;
  color: #1268fb !important;
}

.review_pickup_flex button:hover {
  svg {
    path {
      fill: #0058d6 !important;
      stroke: #fff;
    }
    #Ellipse_44 {
      stroke: #1268fb;
      fill: #1268fb;
    }
    #Ellipse_47 {
      stroke: #fff;
      fill: #ffff;
    }
    #Rectangle_2462 {
      stroke: #fff;
      fill: #ffff;
    }
  }
}
// .ant-tooltip-open .anticon:hover {
//   font-size: 16px;
//   color: #1268fb;
// }
