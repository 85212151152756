.transaction-complete-wraper {
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .payment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    > div {
      height: 300px !important;
      width: 300px !important ;
    }

    svg {
      font-size: 240px;
    }
  }

  h1 {
    margin-top: 32px;
    text-align: center;
  }

  h2 {
    text-align: center;
    margin-top: 12px;
  }
}
