.bulk-pricing-not-available {
  background-image: url("../../../../../../assets/images/backgrounds/bulk-pricing-empty-state.svg");
  padding: 24px 43px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    color: #040404;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  p {
    color: #040404;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
  }
}
