.login-page-container {
  width: 100%;
  height: 100%;
  padding: 20px 0px 20px 20px;
  // padding-left: 20px;
  // background-image: url("../../assets/images/backgrounds/login_background.svg");
  // background-color: #f7f7fc;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-attachment: fixed;
  // overflow-y: auto;
  .ant-form-item {
    margin-bottom: 15px;
  }
  .login-container {
    width: 390px;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 7vw 15px 0 15px;

    .logo {
      margin-bottom: 16.9px;
      width: 119.31px;
    }
    .login-page-heading {
      margin-bottom: 0px;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
    }
    .tagline {
      color: #1a1a1a;
      margin: 0 0 23.6px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
    }
    .ant-tabs-bar {
      border: 0;
      text-align: center;
    }
    .login-form .ant-input-lg {
      height: 36px;
    }
    .login-form-forgot,
    .login-form-signup-here {
      float: right;
    }
    .login-form-forgot {
      font-weight: 500;
    }

    .ant-alert {
      margin-bottom: 15px;
    }

    .login-form-button {
      width: 100%;
      margin: 0 0;
      box-shadow: 0px 1px 2px rgba(0, 104, 255, 0.59);
    }

    .register-tag-line {
      margin: 15px 0 10px 0;
    }

    .login-page-footer {
      position: sticky;
      top: calc(100% - 55px);
      p {
        margin-bottom: 0px;
        color: #1a1a1a;
      }
      .footer-links {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0px;
        li {
          display: inline-block;
          margin: 0 20px;
          a {
            color: #1a1a1a;
          }
          a:hover {
            color: rgb(0, 0, 0);
          }
        }
      }
    }
    .login_form {
      flex: 1 0 auto;
    }
  }
}
