.product-id-g {
  transition: all 0.5s ease;
  font-size: 12px;
  margin-bottom: 6px;
  color: #898989;
  svg {
    height: 14px;
    width: 14px;
    margin: 0 !important;
  }
  .id {
    transition: all 0.5s ease;
  }
  .id:hover {
    transition: all 0.5s ease;
    color: #0058d6;
    text-decoration: underline;
    #Rectangle_2461 {
      transition: all 0.5s ease;
      fill: #0058d6;
      stroke: #0058d6;
    }
  }
}
