.variantData {
  .ant-table {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  .ant-table-thead > tr > th {
    color: white;
    background: #16283b;
  }
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    text-align: left;
  }
  tr.ant-table-row {
    background: white;
  }
  .variant-image {
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
      // width: 70px;
      // height: 70px;
    }
  }
}
