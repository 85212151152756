.user-card {
  .ant-tabs {
    .ant-tabs-ink-bar {
      background-color: black;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: black;
    }
    .ant-tabs-nav .ant-tabs-tab:hover {
      color: black;
    }
  }
  .user-settings {
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .text {
        font-size: 1.43rem;
        font-weight: 400;
        color: #151515;
      }

      .add-user-btn {
        background: #ff5858;
        border-color: #ff5858;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 3px;
        height: 2.5rem;

        .anticon + span {
          margin-left: 0.25rem;
        }
      }
      .add-user-btn:hover,
      .add-user-btn:active,
      .add-user-btn:focus,
      .add-user-btn:focus-within {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .ant-divider {
      background: #979797;
      height: 2px;
    }

    .disclaimer {
      font-size: 16px;
      color: #979797;
    }

    .ant-table {
      // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;

      .user-name {
        text-transform: capitalize;
        margin-bottom: 0;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 24px;
        font-weight: 400;
      }

      .role-tag {
        border-radius: 9px;
        font-size: 0.86rem;
        font-weight: 500;
        padding: 0 1rem;
      }
      .ant-table-thead > tr > th {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.3px;
        background: #16273b !important;
        border-radius: 0 !important;
        color: white !important;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      // .ant-table-tbody > tr:nth-child(2n) {
      //   background: #f2f2f2;
      // }

      .ant-table-tbody > tr > td {
        color: #212121;
        font-size: 12px;
        // border-bottom-color: #f2f2f2 !important;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 24px;
        p {
          margin-bottom: 0px;
        }
        .actions-div {
          font-size: 12px;
          .ant-btn {
            height: 28px;
            width: 28px;
            font-size: 12px;
            padding: 0px;
            margin-right: 16px;
            font-weight: 500;
            border: none;
            box-shadow: none;
            background: none;
          }
          // .ant-btn {
          //   svg * {
          //     fill: #ff005e;
          //   }
          // }
          // .edit-btn {
          //   border: 1px solid #ff005e;
          // }
          // .edit-btn:hover {
          //   border: 1px solid #ff005e;
          // }
          .ant-switch-checked {
            background-color: #229d1b;
          }
        }
      }
    }
  }
  .ant-card-body {
    padding: 0px;
    zoom: 1;
    margin-bottom: 32px;
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px !important;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px !important;
  }
  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px !important;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px !important;
  }
  .ant-table-tbody > tr > td :last-child {
    border-bottom: 0px !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px !important;
  }
  .ant-table-tbody > tr {
    border-bottom: 1px solid #cecece !important;
  }
  .ant-table-tbody > tr:last-child {
    border-bottom: 0px !important;
  }
  .review-role {
    background: none;
    border: none;
    cursor: pointer;
  }
}
.ant-card {
  border-radius: 2px !important;
}
