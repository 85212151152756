.my-wallet {
  margin-top: 48px;

  .ant-alert-with-description {
    * {
      letter-spacing: 0.032em;
    }
    .ant-alert-message {
      font-size: 12px;
      font-weight: 600;
      color: #212121;
    }
    .ant-alert-description {
      font-size: 12px;
      color: #212121;
    }
  }

  .wallet-title {
    margin-top: 30px;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .wallet-sub-title {
    font-size: 18px;
    font-weight: 500;
    color: #262626;
    margin-bottom: 30px;
  }
  .wallet-balance {
    display: flex;
    .wallet-balance-title {
      flex: 1.5;
      background: #0c182a;
      display: flex;
      margin: -24px;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 176px;
      p {
        color: white;
        font-size: 20px;
        letter-spacing: 0.032em;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .wallet-balance-number {
      flex: 3 1;
      display: flex;
      align-items: center;
      .balance-amount {
        flex: 1;
        text-align: center;
        p {
          margin-bottom: 0px;
          color: #000;
          font-size: 24px;
          letter-spacing: 0.038em;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .balance-button {
        display: flex;
        flex-direction: column;
        .ant-btn {
          margin-bottom: 16px;
        }
      }
    }
  }
  .add-balance-btn {
    background-color: #2bc822 !important;
    box-shadow: 0px 2px 8px #002f7225 !important;
    border: 2px solid #2bc822 !important;
    border-radius: 2px !important;
    line-height: none !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #fff !important;
    text-shadow: none !important;
    cursor: pointer;
    letter-spacing: 0.032em;
  }
  .ant-row {
    margin-bottom: 2rem;

    .ant-card {
      border: 1px solid #979797;
      border-radius: 4px;

      .sub-text {
        color: #908f8f;
        font-size: 0.75rem;
        margin-bottom: 0;
        margin-top: 1rem;
      }

      .gap {
        flex: 1;
      }

      .transaction-details {
        .review-button {
          font-weight: normal;
          font-size: 0.7rem;
          margin-left: 0.5rem;
          background: transparent;
          color: #fff;
          background-color: #0068ff;
          border-color: #0068ff;
        }
      }
      // .transaction-details:hover {
      //   color: #208aed;
      //   .review-button {
      //     color: white;
      //     background: #208aed;
      //   }
      // }

      .blue-text {
        color: #208aed;
      }
      .green-text {
        color: #229d1b;
        font-weight: 500;
      }
      .red-text {
        color: #c70c3c;

        font-weight: 500;
      }
      .orange-text {
        color: #ff9800;
        font-weight: 500;
      }
    }

    .table-card,
    .stats-card {
      .ant-card-body {
        padding: 0px !important;
      }
      .yaxis {
        display: flex;
        justify-content: end;
        font-weight: 500;
        font-size: 12px;
        color: #000;
        letter-spacing: 0.032em;
        line-height: 16px;
        p {
          margin-right: 8px;
        }
      }
      .debit_credit {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        color: #000;
        letter-spacing: 0.032em;
        line-height: 16px;
        margin-bottom: 24px;
      }
    }
  }
  .ant-row:last-of-type {
    margin-bottom: 0.5rem;
  }

  .antd-pro-charts-chart-card-chartTop {
    display: none;
  }
  .antd-pro-charts-chart-card-content {
    overflow-x: scroll;
  }
  .withdrawBank {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    li {
      line-height: 29px;
    }
  }

  .ant-table-body {
    height: 368px !important;
    border-radius: 4px !important;
  }
  .antd-pro-charts-chart-card-chartCard {
    position: relative;
    padding: 24px !important;
  }
  .Wallet_Stats_txt {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 22px;
    color: #000;
    margin-bottom: 8px;
  }
  .Transaction_History {
    position: relative;
    .ant-table-body {
      height: 368px !important;
      border-radius: 4px;
      overflow: visible;
    }
    // ::-webkit-scrollbar {
    //   -webkit-appearance: none;
    //   width: 8px;
    //   height: 8px !important;
    // }

    // ::-webkit-scrollbar-thumb {
    //   border-radius: 5px;
    //   background-color: rgba(0, 0, 0, 0.5);
    //   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    // }
    .line {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 10px;
      background: #16273b;
      border-top-right-radius: 4px;
    }
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #0068ff;
    font-size: 12px;
    margin-left: 8px !important;
  }
  .Wallet_stats {
    .ant-card {
      height: 176px;
    }
  }

  .ant-tooltip-inner {
    text-align: center !important;
  }
}

.my-wallet .add-balance-btn:hover {
  background-color: #14b50a !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
}

.my-wallet .add-balance-btn:active {
  background-color: #229d1b !important;
  color: #fff !important;
}

// .ant-table-body:hover {
//   overflow-y: scroll !important;
// }

// .ant-table-tbody:hover::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 8px;
//   height: 8px !important;
// }

// .ant-table-tbody:hover::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background-color: rgba(0, 0, 0, 0.5);
//   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000bf;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 99;
  bottom: 146%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
