.link-product-import-list-modal {
  width: 800px !important;
  max-width: 100%;

  .ant-select-selection--single {
    font-weight: 500;
    border-radius: 0px;
    background-color: #f5f5f5;
    border: none;
    padding: 0 8px;
    font-size: 12px;
  }
  .ant-select-lg .ant-select-selection--single {
    height: 56px;
  }
  .ant-select-lg .ant-select-selection__rendered {
    line-height: 56px;
  }
  .checkbox-container {
    margin-top: 3rem;

    .ant-checkbox-wrapper {
      font-weight: 500;
    }
  }
  .sub-text {
    font-weight: 400;
    font-size: 0.85rem;
  }
  .next-button {
    background-color: #2bc822;
    border-color: #2bc822;
    outline: none;
    border: none;
    width: 161px;
    border-radius: 2px;
    color: #fff;
    height: 40px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
  }

  .cancle-button {
    width: 161px;
    border-radius: 2px;
    height: 40px;
    color: #898989 !important;
    border-color: #898989 !important;
    &:hover {
      color: #898989 !important;
      border-color: #898989 !important;
    }
  }

  .input-label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 4px;
    color: #898989;
  }
  .existing_product_txt {
    font-size: 12px;
    color: #212121;
    margin-bottom: 8px;
    margin-top: -8px;
  }

  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #8bd987;
    border-color: #8bd987;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    width: 161px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    height: 40px;
  }

  .link-import-list {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    color: #212121;
  }
  .existing-product-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 400;
    color: #212121;
    margin-bottom: -8px;
  }
  .ant-modal-body {
    padding: 16px 24px;
    font-size: 12px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .ant-modal-close-x {
    display: none;
  }
  .link-import-list-modal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .existing-product {
      width: 50%;
      margin-right: 16px;
    }
    .link-product {
      width: 50%;
    }
  }
  .ant-modal-footer {
    padding: 16px 24px;
  }
  .image-with-txt {
    background: #f5f5f5;
    padding: 8px 8px;
    display: inline-block;
    width: 100%;
    img {
      vertical-align: middle;
      border: 1px solid #898989;
      padding: 4px;
      width: 40px;
      height: 40px;
    }
    .image-product-name {
      margin: 0 0 0 20px;
      vertical-align: middle;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 50px);
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-left: 8px;
    }
  }
  .option-image {
    vertical-align: middle;
    border: 1px solid #898989;
    padding: 4px;
    width: 40px;
    height: 40px;
  }
}
