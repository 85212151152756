.line-items-tab {
  padding: 16px 16px 16px 16px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .line-item {
    background: #f7f7f7;
    margin-bottom: 10px;
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    .line-item-product-detail {
      display: flex;
      align-items: center;
      width: 250px;
      margin-left: 0px;
    }
    .line-item-img {
      width: 56px;
      height: 56px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important;
      // border: 1px solid #898989;
    }
    .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px;
    }
    .line-item-sku {
      font-size: 12px;
      color: #000;
      font-weight: 400;
      margin: 0;
      overflow: inherit !important;
      a {
        color: #1161fb;
      }
      a:hover {
        // color: #1161fb;
        text-decoration: underline !important;
      }
    }
    .line-item-sku svg g path {
      stroke: #1161fb;
      text-decoration: underline;
    }
    .line-item-sku:hover svg g path {
      stroke: #1161fb;
      text-decoration: underline;
    }
    .line-item-name-sku {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px;
    }
  }
  .delivered-date {
    margin-bottom: 0px;
    // color: #f12f1a;
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
  }
  .line-items-tab_header_upper_data {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 16px 16px 8px;
    margin: -16px 0 -2px 0;
    color: #898989;
    .flex33 {
      width: 250px;
    }
    .flex44 {
      width: 85px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flex55 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      span {
        margin-right: 20px;
      }
      .Quantity {
        width: 70px;
      }
      .star {
        width: 30px;
      }
      .Shopify_Selling_Price {
        width: 150px;
      }
    }
    .flex66 {
      width: 115px;
      display: flex;
      // align-items: center;
      // justify-content: center;
      padding-left: 20px;
    }
  }

  .openordersvg {
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quntyandpices {
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    color: #000;
    .Quantity {
      width: 70px;
      // text-align: center;
    }
    .star {
      width: 30px;
    }
    .Shopify_Selling_Price {
      width: 150px;
    }
  }
  .end_data {
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    margin-right: 3px;
  }
  .lost-data {
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    margin-right: 25px;
  }

  .other-status {
    white-space: nowrap;
    letter-spacing: 0.032em;
    font-weight: 500;
    line-height: 16px;
  }
  .rto-in-transit-status {
    margin-left: 10px;
  }
}
