.product_niche {
  margin-top: 26px;
  .ant-card-body {
    padding: 0px;
    .niche-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .border-container {
        height: 5rem;
        border: 1px solid #d6d6d6;
      }
      .border-container:hover {
        border: 1px solid #0068ff;
      }
      .niche-column-selected {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #d7f3d4;
        padding: 0.5rem 1.5rem;
        height: 3rem;
        position: relative;
        z-index: 1;
        .niche-name-selected {
          display: flex;
          align-items: center;
          .ant-col-md-8 {
            text-align: right;
          }
          .anticon {
            font-size: 1.75rem;
          }
          p {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 0.625rem;
            margin-left: 0.5rem;
            color: black;
          }
        }
      }
      .niche-column {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: unset;
        padding: 1rem 1.5rem;
        height: 5rem;
        position: relative;
        z-index: 1;
        cursor: pointer;
        .niche-name {
          display: flex;
          align-items: center;
          .ant-col-md-8 {
            text-align: right;
          }
          .anticon {
            font-size: 2rem;
          }
          p {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 0.75rem;
            margin-left: 0.5rem;
            color: black;
            letter-spacing: 0.02125rem;
            // max-width: 150px;
            // text-align: center;
          }
        }
      }
      .niche-check {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.125rem 0.5rem 0.5rem 0.125rem;
        background: #2ee31b;
        color: white;
        border-radius: 0px 0px 1.5rem 0px;
        font-size: 0.625rem;
        z-index: 99;
      }
      .ant-btn {
        font-size: 0.75rem;
        color: white;
        font-weight: 500;
        background: #24b914;
        border: unset;
        border-radius: 0px;
        height: 1.9375rem;
      }
    }
  }
}
