.orders-confirmation-modal {
  width: 1148px !important;

  .fulfilment {
    background-color: #fef7c8 !important;
  }

  .itemCost {
    background-color: #fdf3e4 !important;
  }

  .both {
    background-color: #cee6f8 !important;
  }

  .custom-width-120 {
    width: 120px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-content {
    border-radius: 2px;
    padding: 0 24px;
  }
  .ant-modal-header {
    padding: 24px 0px;
    border-bottom: 1px solid #cecece;
    display: flex;
    margin: 0 auto;
    width: 1100px;

    .ant-modal-title {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.032em;
      width: 100%;

      .flex-between {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .colors-grad {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 12px;
          width: 460px !important;
          .r {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-right: 12px;
            span {
              width: 20px;
              height: 20px;
              border-radius: 50%;

              background-color: #fef7c8;
              margin-right: 6px;
            }
          }

          .g {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-right: 12px;
            opacity: 0.7;
            span {
              width: 20px;
              height: 20px;
              border-radius: 50%;

              background-color: #fdf3e4;
              margin-right: 6px;
            }
          }

          .b {
            display: flex;
            align-items: center;
            justify-content: start;

            span {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 6px;

              background-color: #cee6f8;
            }
          }
        }
      }
    }
    .ant-modal-content {
      border-radius: 0px;
    }
  }

  .ant-modal-footer {
    padding: 8px 0px;
    text-align: right;
    border-top: 1px solid #cecece;
    width: 1100px;
    margin: 0 auto;
    margin-top: 10px;
    .footer-line-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-input {
        border: none;
        border-radius: 2px;
        font-size: 12px;
        width: 220px;
        background: #f5f5f5;
      }
      .snooze-until {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989;
      }
    }
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
    max-height: 560px;
    overflow: auto;
  }
  .subheading-txt {
    padding: 8px 0px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: -24px;
    margin-top: -8px;
    font-weight: 400;
  }

  .selected-orders {
    .header-row {
      display: flex;
      justify-content: space-between;
      padding: 8px 0px 8px 16px;
      align-items: center;
      width: 1100px !important;
      margin: 8px auto;
      margin-bottom: -4px;

      .order-number-txt {
        width: 140px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .order-items-txt {
        width: 200px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }

      .product-price-txt {
        width: 90px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        text-align: center;
      }
      .fulfillment-price-txt {
        width: 100px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        text-align: center;
      }

      .forward-shipping-txt {
        width: 115px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        text-align: center;
      }
      .order-cost-txt {
        width: 110px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        text-align: center;
      }

      .quantity-txt {
        width: 80px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        text-align: center;
      }
      .cod-txt {
        width: 90px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        text-align: center;
      }
    }

    .line-item {
      display: flex;
      justify-content: space-between;
      padding: 6px 0px 6px 16px;
      background: #f5f5f5;
      align-items: center;
      width: 1100px !important;
      margin: 0 auto;
      margin-bottom: 8px;
      position: relative;

      .rto-badge {
        // position: absolute;
        top: 0px;
        left: 0px;
        margin-top: 6px;
        color: #fff;
        padding: 6px 18px;
        font-size: 12px;
        font-weight: 500 !important;
        border-radius: 5px;
      }

      .rto-badge.high {
        background-color: #e61047;
      }

      .rto-badge.no {
        background-color: #73d13d;
      }

      .rto-badge.moderate {
        background-color: #ffc53d;
        color: #212121;
      }

      .orderid-txt {
        width: 155px;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        .order-id {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          color: #212121;
          a {
            color: #1161fb;
          }
        }
        .vFulfill-txt {
          margin-bottom: 2px;
        }
      }
      .line-item-img {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        border: 1px solid;
        padding: 12px;
      }
      .line-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
        color: #16273b;
      }
      .line-item-sku {
        font-size: 12px;
        color: #898989;
        margin: 0;
      }
    }

    .line-item-total-order-cost {
      width: 1100px !important;
    }
  }

  .ant-input {
    border-radius: 2px;
  }

  .line-item-product-detail {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 200px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    img {
      border: 1.5px solid #b2b2b2;
      margin-right: 8px;
      padding: 2px;
      width: 56px;
      height: 56px;
      object-fit: cover;
    }
  }

  .line-item-quantity-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 80px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    height: 48px;
    justify-content: center;
    .piece {
      font-size: 12px;
      color: #212121;
      letter-spacing: 0.032em;
      margin-left: 4px;
    }
  }

  .lineitem-product-price {
    width: 100px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center;
  }
  .lineitem-fulfilment-price {
    width: 100px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center;
  }
  .linitem-forwading-price {
    width: 115px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center;
  }

  .linitem-cod-price {
    width: 90px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center;
  }

  .lineitem-total-product-price {
    width: 110px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center;
  }

  .line-item-total-order-cost {
    padding: 16px;
    background: #f5f5f5;
    width: 1100px;
    margin: 0 auto;
    margin-bottom: 8px;
    text-align: right;
    .total-order-value {
      margin-right: 24px;
      color: #212121;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-right: 14px;
    }
    .total-order-cost {
      color: #898989;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-right: 48px;
    }
  }
  .lineitem-cancel-btn {
    border: 1px solid #898989;
    width: 144px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
    color: #898989;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .lineitem-conform-btn {
    background: #2bc822;
    border: none;
    width: 208px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
  }
  .line-item-select-resons {
    .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important;
    }
    .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb;
      .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
      }
    }
    .ant-select-selection {
      width: 500px;
      border: none !important;
      background: #f8f8f8 !important;
      height: 40px !important;
      border-radius: 2px;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    .ant-select-arrow {
      top: 47%;
      left: 88% !important;
    }
  }

  .ant-input-number {
    border: none !important;
    border-radius: 2px !important;
    background: #f5f5f5;
    height: 40px;
    font-size: 12px;
    padding: 4px;
  }
  .refundAmount-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-right: -8px;
    color: #898989;
  }
  .refunded-txt2 {
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .refunded-txt {
    color: #212121;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .linitem-checkbox-selection {
    padding: 5px 0px 0px 24px;
  }

  .not-available {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 59px;
  }
  .bundleitem-vfsku {
    color: #212121;
    line-height: 16px;
    letter-spacing: 0.032em;
  }
  .side p {
    color: #212121;
    line-height: 16px;
    letter-spacing: 0.032em;
  }

  .order-id a:hover {
    text-decoration: underline !important;
  }

  .side p a:hover {
    text-decoration: underline !important;
  }
  .disbaledropdown {
    background: #94e38f;
    border: none;
    width: 208px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
    color: #fff;
    pointer-events: none;
  }

  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #94e38f;
    border-color: #94e38f;
    text-shadow: none;
    box-shadow: none;
  }

  .check-box-wraper {
    display: flex;
    .notfulfill-check-txt2 {
      text-align: left;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 82%;
      color: #898989;
    }
    .notfulfill-check-txt {
      text-align: left;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 82%;
      color: #212121;
    }
  }
  .footer-right-line-item-btn {
    display: flex;
    align-items: center;

    button[disabled] {
      background-color: gray !important;
      border-color: gray !important;
    }
  }

  .sku_container {
    .sku_data_heading {
      display: flex;
      justify-content: space-between;
      padding: 8px 0px 8px 16px;
      width: 1100px !important;
      margin: 8px auto;
      margin-bottom: -4px;
      p {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .sku_txt {
        width: 200px;
      }
      .order_count_txt {
        width: 220px;
      }
      .current_qty_tx {
        width: 220px;
      }
      .placing_order_txt {
        width: 250px;
      }
    }

    .sku_value {
      display: flex;
      justify-content: space-between;
      padding: 8px 0px 8px 16px;
      background: #f5f5f5;
      align-items: center;
      width: 1100px !important;
      margin: 0 auto;
      margin-bottom: 8px;
      p {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
      .sku_txt {
        width: 200px;
      }
      .order_count_txt {
        width: 220px;
      }
      .current_qty_tx {
        width: 220px;
      }
      .placing_order_txt {
        width: 250px;
      }
    }
  }

  .alert_txt_ican {
    .anticon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .alert_txt {
    padding: 8px 0px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: -24px;
    margin-top: -8px;
    font-weight: 400;
  }
  .link_branding {
    padding-left: 24px;
    margin-top: 3px;
    font-size: 12px;
    letter-spacing: 0.032em;
  }

  .link_branding a:hover {
    text-decoration: underline !important;
  }
}
.inv-error {
  color: red;
  font-size: 12px;
  margin: 12px 0;
}

.retail-not-allowed {
  opacity: 0.7;
  cursor: not-allowed !important;
}
