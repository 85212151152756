.shoipify_store_data {
  .connect_shopify_store_btn {
    border-top: 1px solid #cecece !important;
    display: flex;
    justify-content: end;
    margin-bottom: -8px;
  }
  .connect_btn {
    margin-top: 16px;
    margin-right: 8px;
    width: 120px;
    background: #73d13d;
    border-color: #73d13d;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    height: 32px;
  }

  .cancel_btn {
    margin-top: 16px;
    margin-right: 8px;
    width: 120px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    height: 32px;
  }

  .go_back_btn {
    margin-top: 16px;
    margin-right: 4px;
    height: 32px;
    border: none;
    box-shadow: none;
    font-size: 12px;
    color: #212121;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg_go_back {
      margin-right: 4px;
    }
    .go_back_txt {
      margin-top: -3px;
    }
  }
  .go_back_btn:hover {
    text-decoration: underline;
  }

  .ant-alert-info {
    background-color: #f5faff;
    border: 2px solid #0058d6;
    border-radius: 2px !important;
  }

  .ant-alert-info .ant-alert-icon {
    color: #0058d6;
  }
  .ant-alert-message {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }
  .shopify_label {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-top: 16px;
  }
  .ant-input-suffix {
    right: 12px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px;
    font-size: 12px;
    border-radius: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    background: #f5f5f5;
    border: none;
  }
  .shopify-store_link {
    margin-bottom: 16px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    margin-top: -8px;
  }

  .has-error .ant-form-explain {
    color: #f5222d;
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 4px;
  }
}
