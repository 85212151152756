.statistic-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important;
  .ant-card-body {
    padding: 0;
    .header {
      height: 3rem;
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 0.5rem 1.5rem;
      font-size: var(--small-font-size);
      margin-bottom: 0.5rem;
      .header-tooltip {
        float: right;
      }
    }
    .body {
      padding: 0.5rem 1.5rem;
      font-size: var(--medium-font-size);
      letter-spacing: 0.3px;
    }

    .custom-body {
      min-height: 79px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8;
    }
    .footer {
      padding: 0.5rem 1.5rem;
      font-size: var(--extra-medium-small-font-size);
      p {
        span:first-of-type {
          font-size: var(--small-font-size);
        }
      }
      hr {
        border: 0;
        border-top: 1px solid #e8e8e8;
      }
    }
  }
}
