.global-tooltip-video {
  height: 28px;

  .icon-wraper {
    height: 24px;
    width: 24px;
    background-color: #ffd33c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    position: relative;
    z-index: 999 !important;
    > div {
      height: 24px;
      width: 24px;
    }
  }

  // .icon-wraper.radiusIncrease {
  //   border: 1px solid #a98100;
  //   transition: all 0.1s linear;
  // }
}
//

.tooltip-video-div-overlay {
  z-index: 999;

  .ant-tooltip-arrow {
    top: 0px !important;
    left: 50%;
    margin-left: -11px !important;
    border-width: 0 11px 8px !important;
    border-bottom-color: #212121 !important;
  }

  .ant-tooltip-content {
    min-width: fit-content;
  }

  .tooltip-video-div {
    display: flex !important;
    justify-content: space-between;

    * {
      letter-spacing: 0.032em;
      color: #fff;
      margin: 0;
      padding: 0;
      // display: block;
    }

    .left {
      // width: 400px;
      margin-right: 16px;
    }

    .right {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      * {
        letter-spacing: 0.032em;
      }
    }

    h1 {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #4398ed;
      margin-top: 2px;
    }
    h2 {
      text-align: left;
      font-size: 12px;

      font-weight: 500;
      margin-bottom: 28px;
    }

    h3 {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
    }

    .html-div {
      border-radius: 2px;

      padding-bottom: 0 !important;
      background: #2e2e2e;
      position: relative;
      p {
        text-align: left;
        display: flex;
        flex-direction: column;
        color: #b2b2b2 !important;
        // padding-bottom: 7px;
        font-size: 12px;

        b {
          margin-top: 6px;
          font-weight: 400 !important;
          color: #fff !important;
        }
      }
    }

    .image-div {
      cursor: pointer;
      width: 344px;
      position: relative;
      padding: 2px;
      background-color: #3e3e3e;
      height: 256px;
      object-fit: cover;

      img {
        height: 100%;
        object-fit: cover;
      }

      video {
        height: 100%;
      }

      .svg {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        left: 50%;
        color: #fff !important;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 160px;
        height: 48px;
        font-size: 14px;
        font-weight: 500;
        color: #212121;
        border-radius: 24px;
        background-image: linear-gradient(to right, #0e58d6, #115bd7, #4398ed);
        svg {
          margin-right: 10px;
        }
      }

      img {
        width: 100%;
      }

      video {
        width: 100%;
      }
    }

    .image-div::after {
      content: "";
      width: 100%;
      z-index: 0;
      height: 100%;
      z-index: 1;
      opacity: 0.5;
      background-color: rgb(0, 0, 0);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .ant-tooltip-inner {
    padding: 24px !important;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.4);
    background-color: #212121 !important;
  }

  .slick-dots.slick-dots-bottom {
    height: 40px;
    background-color: #434343;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 0 !important;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    li {
      margin-right: 4px;
      height: fit-content;
    }
    button {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #212121;
      opacity: 1;
    }
  }
  .slick-active {
    button {
      width: 32px !important;
      border-radius: 8px !important;
    }
  }

  .bottom-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-top: 16px;
      transition: all 0.3s ease-out;
      border-radius: 2px !important;
      padding: 0 24px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      height: 32px !important;
      border: none !important;
      cursor: pointer !important;
    }

    .quick-overview {
      transition: all 0.3s ease-in;
      color: #898989 !important;
      font-size: 12px !important;
      border: 1px solid #ffffff90 !important;
      color: #ffffff80 !important;
      background-color: rgba(0, 0, 0, 0);
    }

    .quick-overview:hover {
      transition: all 0.3s ease-out;
      color: #898989 !important;
      font-size: 12px !important;
      border: 1px solid #ffffff !important;
      color: #ffffff !important;
    }

    .quick-page {
      color: #000 !important;
      margin-left: 8px;
    }

    .quick-page:hover {
      background-color: #4398ed !important;
      color: #fff !important;
    }
  }
}

.tooltip-video-div-overlay::before {
  content: "";
  transition: all 2s linear;
  position: fixed;
  background-color: #7979796e;

  z-index: -1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
}

// .tooltip-video-div-overlay::after {
//   content: "";
//   height: 100%;
//   width: 100%;
//   background-color: red;
// }

.video-react-controls-enabled {
  padding-top: 0 !important;
}

.corousel-div {
  * {
    letter-spacing: 0.032em;
  }
  position: relative;
  padding: 16px !important;
  padding-bottom: 56px !important;

  .corousel-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .serial {
      height: 16px;
      width: 16px;
      background-color: #fd9226;
      margin-right: 8px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: #2e2e2e;
      justify-content: center;
      margin-bottom: 1px;
    }

    div {
      color: #fd9226;
      font-weight: 600;
      font-size: 14px;
    }
  }

  p {
    text-align: left;
    display: flex;
    flex-direction: column;
    color: #b2b2b2 !important;
    padding-bottom: 7px;
    font-weight: 500 !important;

    b {
      font-weight: 500 !important;
      color: #fff !important;
    }
  }
  // .prev-icon,
  // .next-icon {
  //   position: absolute;
  //   transform: translateY(-40%);
  // }
  // .prev-icon {
  //   left: 6px;
  //   top: 50%;
  // }
  // .next-icon {
  //   right: 6px;
  //   top: 50%;
  // }
}

// modal css
.video-modal {
  width: 700px !important;

  .ant-modal-body {
    padding: 0 !important;
  }
  video {
    width: 100%;
  }
}
