.customer-info-tab {
  background: #fff;
  padding: 8px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .header_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    .full_name {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
    .address_line1 {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
      }
    }
    .Pin_code {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
  }
  .middle_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    .email_address {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
    .address_line2 {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
    .ship_country {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898898;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
      }
    }
  }
  .footer_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .mobile_number {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
    .citytown {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898898;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
    .State {
      width: 32%;
      .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop;
      }
      .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }
  }
}
