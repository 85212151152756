.image-row {
  display: flex;
  .img-cointainer {
    height: 110px;
    width: 110px;
    position: relative;
    margin-right: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: scale-down;
    }
    .io-container {
      width: 110px;
      margin-right: 10px;
      height: 110px;
      margin-bottom: 12px;
      display: inline-block;
      background-color: lavender;
      svg {
        display: flex;
        margin: auto;
        height: 110px;
      }
    }
    .download-icon {
      position: absolute;
      top: 80px;
      right: 10px;
      transform: scale(2);
    }
  }
}
