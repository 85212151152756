.product-link-variant-matching-view {
  .ant-select-selection {
    min-height: 64px;
    border: none;
    background: #f5f5f5;
    border-radius: 2px;
    .ant-select-selection__rendered {
      min-height: 48px;
      padding-top: 6px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.032em;
      color: #212121;
    }
    .ant-select-search--inline {
      position: absolute;
      width: 100%;
      height: 100%;
      margin-top: -2px;
    }
  }
  .flexible-fullfillment-bar{
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    padding: 3px 7px;
    margin-bottom: 24px;
    color: #898989;
    font-size: 12px;
    // letter-spacing: 0.032em;
    line-height: 16px;
    background: #f5faff;
    padding: 8px;
    border-radius: 4px;
  }
  .ali-product-name {
    font-weight: 400;
    margin-top: 72px;
    margin-bottom: 24px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    background: #f5faff;
    padding: 8px;
    border-radius: 4px;
  }
  .header-txt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    p {
      color: #898989;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      font-weight: 400;
    }
  }
  .view-varaint-matching-data {
    display: flex;
    justify-self: space-between;
    width: 100%;
    .left-varaint-image-data {
      width: 50%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      border-radius: 2px;
      background: #f5f5f5;
      height: 64px;
      img {
        margin-left: 16px;
        max-width: 48px;
        padding: 2px;
        border: 1px solid #979797;
      }
      .image-allspecs {
        margin-left: 16px;
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #212121;
        line-height: 16px;
      }
    }
  }

  .ant-select-selection__placeholder {
    top: 72%;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
  }

  .save-continue {
    background: #2bc822;
    border: none;
    border-radius: 2px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 500;
  }
  .back-store-product-link {
    margin-top: 16px;
    display: flex;
    justify-content: end;
    .backBtn {
      margin-right: 8px;
    }
  }
  .back-store-product-link a {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989 !important;
  }
  .back-store-product-link a:hover {
    font-size: 12px;
    color: #1370fc !important;
    font-weight: 500;
    text-decoration: underline !important;
  }

  .back-store-product-link a {
    display: flex;
    align-items: center;
    svg {
      transform: rotate(91deg) !important;
      margin-right: 3px;
    }
  }

  .link-product-name {
    color: #0068ff;
    letter-spacing: 0.032em;
    font-weight: 400;
  }
  .cantainer-flexible-data{
    margin-top: 20px;
    padding: 24px;
    background-color: #fff;
    .heading-flexble-fullfillment{
      display: flex;
      .item{
        flex-basis: 50%;
        font-size: 12px;
        padding: 7px;
      }
    }
  }
}
