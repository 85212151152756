.pricing-requests-list {
  letter-spacing: 0.032em;
  .heading {
    .content-page-title {
      margin-bottom: 0;
      span {
        float: right;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }
    p {
      font-size: 16px;
      color: black;
    }
  }
  .requests-filters {
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    .store-filters {
      background-color: #f8f8f8 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 8px #00000014;
      letter-spacing: 0 !important;
      p {
        margin-bottom: 0px;
        font-size: 14px;
        color: black;
        margin-right: 5px;
      }
      .filter_select {
        .ant-select-selection__placeholder {
          display: block !important;
        }
        .ant-select-selection__choice {
          display: none;
        }
      }
      .filter.ant-select {
        width: auto;
        color: #004eff;
        font-size: 14px;
        font-weight: 600;
        .ant-select-selection {
          background-color: transparent;
          border: none;
          .ant-select-selection__rendered {
            line-height: 16px;

            margin-right: 5px;
            margin-left: 5px;
            line-height: 30px;
            margin-top: -6px;
            justify-content: center;
            display: flex;
            height: 40px;
            padding: 8px;
            .ant-select-selection__placeholder {
              color: #004eff;
            }
            ul {
              .ant-select-selection__choice {
                color: #004eff;
                .ant-select-selection__choice__remove:hover,
                .ant-select-selection__choice__remove {
                  color: rgb(2, 103, 251);
                }
              }
            }
          }
        }
        .ant-select-selection--single {
          height: 16px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ant-select-arrow {
          color: #004eff;
          right: 0px;
          margin-right: 8px;
        }
        .ant-select-selection-selected-value {
          margin-right: 10px;
          width: 260px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .ant-select-focused .ant-select-selection,
      .ant-select-selection:focus,
      .ant-select-selection:active {
        border-color: none;
        border-right-width: 0px !important;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .store-filters:hover {
    background-color: #f8f8f8 !important;
  }

  .card-data {
    margin-bottom: 30px;
    text-align: center;
    .icon-data {
      font-size: 60px;
    }
  }
}
