.image-modal {
  .ant-modal-body {
    min-height: 400px;

    .img-wraper {
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      height: 400px;
      .image-size {
        width: 100%;
        height: 100%;
        position: relative;
      }
      .video-size {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
  .ant-modal-close-x {
    width: 32px;
    height: 32px;
    line-height: 29px;
  }
  .image-arrow {
    position: absolute;
    top: 50%;
    z-index: 99;
    background: transparent;
    padding: 0px;
    height: 40px;
    width: 40px;
    border: none;
    box-shadow: none;
    .anticon {
      font-size: 40px;
    }
  }
}
