.signup-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  background: url("../../assets/images/backgrounds/onboarding_bg.svg") no-repeat;
  background-size: cover;
  .signup {
    flex: 1;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 2.5vw 15px 0 15px;

    .sms-switch {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: lighter;
      color: #808080;
      flex-wrap: wrap;
      gap: 8px;
      .right-text {
        display: flex;
        align-items: center;
        gap: 8px;
        .ant-switch {
          background-color: #1890ff !important;
        }

        .ant-switch-checked {
          background-color: #36c626 !important;
        }

        span {
          font-weight: 500;
        }
      }
    }

    .logo {
      margin-bottom: 18px;
      max-width: 200px;
    }
    .signup-form-container {
      min-height: 100%;
      // background: url('../../../assets/images/backgrounds/setUpbackground.png');
      // background: url("../../assets/images/backgrounds/onboarding-sidebar-bg.svg");
      // background-position-y: top;
      // // background-attachment: fixed;
      // background-size: cover;
      // background-repeat: no-repeat;
      background-position: bottom right;
      background-repeat: no-repeat;
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      padding: 10rem;
      position: relative;

      svg.logo {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        max-width: 100%;
      }

      .signup-card {
        // flex: 0.5 0.5;
        width: 700px;
        // max-width: 100%;
        margin: auto;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0;

        .react-tel-input {
          border: 2px solid #cdcdcc !important;
          min-height: 40px;
          background-color: #fff !important;
          border-radius: 4px !important;
          input {
            background: #fff !important;
          }
        }
        .ant-card-body {
          padding: 2.57rem;

          .title {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 0;
            color: #3a3543;
            font-weight: 500;
          }

          .sub-title {
            text-align: center;
            font-size: 1.143rem;
            color: #000;
            font-weight: lighter;
          }

          .signup-form {
            padding-top: 2.143rem;

            .flex {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              .ant-form-item,
              .form-button,
              .disclaimer {
                width: 48%;
                margin-bottom: 0.36rem;
              }
            }

            .ant-form-item {
              display: block;
              margin-top: 0.71rem;
              margin-bottom: 1.07rem;

              input {
                border: 2px solid #cdcdcc;
                border-radius: 4px;
                min-height: 40px;
              }

              input:hover,
              input:active,
              input:focus,
              input:focus-within {
                border-color: #4d4d4d;
                box-shadow: 0 0 0 2px rgba(77, 77, 77, 0);
              }

              .ant-checkbox-wrapper {
                display: block;

                span {
                  color: rgb(77, 77, 77);
                  font-size: 1rem;
                  font-weight: lighter;
                }
              }
            }

            .has-error input {
              border-color: #ff1c1c !important;
            }

            .label {
              font-size: 1rem;
              font-weight: lighter;
              color: #808080;
            }

            .checkbox-label {
              color: rgb(134, 134, 134);
              font-size: 1rem;
              font-weight: 400;
              font-style: italic;
              display: block;
            }

            .disclaimer {
              font-weight: 500;

              a {
                color: #ff806f !important;
              }
            }

            .form-button {
              font-size: 1.86rem;
              background: rgb(0, 219, 110);
              border-color: rgb(0, 219, 110);
              outline: none;
              padding: 16px 10%;
              text-transform: capitalize;
              height: 5.5rem;
              max-height: 100%;
              font-weight: 600;
              border-radius: 10px;
            }
          }

          .bottom-link {
            color: #868686;
            font-size: 0.86rem;
            font-weight: 500;
            text-align: center;
            margin: 1rem 0 0 0;

            a {
              color: #ff806f !important;
            }
          }
        }
      }

      .onboarding-footer {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

.signup-password-popover-content {
  h5 {
    color: #535353;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.1rem;
  }

  p {
    color: #868686;
    font-size: 1rem;
    margin-bottom: 0.1rem;

    svg {
      width: 1rem;
      height: 1rem;
      vertical-align: text-bottom;
    }
  }
}

@media (max-width: 500px) {
  .signup-container {
    .signup {
      height: 100%;
      margin: auto;
      // padding: 2.5vw 15px 0 15px;

      padding: 1rem !important;
      width: 100% !important;

      .sms-switch {
        margin-bottom: 12px;
        justify-content: center;
      }

      .ant-row-flex {
        transform: translateX(-50%) !important;
        left: 50% !important;
        position: absolute !important;
      }

      .logo {
        margin-bottom: 18px;
        max-width: 200px;
      }
      .signup-form-container {
        min-height: 100%;
        width: 100% !important;
        background-position: bottom right;
        background-repeat: no-repeat;
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        padding: 3rem 1rem !important;

        position: relative;

        svg.logo {
          position: absolute;
          top: 1.5rem;
          left: 1.5rem;
          max-width: 100%;
        }

        .signup-card {
          // flex: 0.5 0.5;

          // max-width: 100%;
          width: 100% !important;
          margin: auto;
          box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
          border-radius: 0;

          .react-tel-input {
            border: 2px solid #cdcdcc !important;
            min-height: 40px;
            background-color: #fff !important;
            border-radius: 4px !important;
            input {
              background: #fff !important;
            }
          }
          .ant-card-body {
            padding: 1rem;
            width: 100%;
            .title {
              text-align: center;
              font-size: 1.5rem;
              margin-bottom: 0;
              color: #3a3543;
              font-weight: 500;
            }

            .sub-title {
              text-align: center;
              font-size: 1.143rem;
              color: #000;
              font-weight: lighter;
            }

            .signup-form {
              padding-top: 1rem;
              width: 100%;
              .flex {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                .ant-form-item,
                .form-button,
                .disclaimer {
                  width: 100% !important;
                  margin-bottom: 0.36rem;
                }
              }

              .ant-form-item {
                display: block;
                margin-top: 0.71rem;
                margin-bottom: 1.07rem;

                input {
                  border: 2px solid #cdcdcc;
                  border-radius: 4px;
                  min-height: 40px;
                }

                input:hover,
                input:active,
                input:focus,
                input:focus-within {
                  border-color: #4d4d4d;
                  box-shadow: 0 0 0 2px rgba(77, 77, 77, 0);
                }

                .ant-checkbox-wrapper {
                  display: block;
                  line-height: 22px !important;
                  text-align: center;
                  span {
                    color: rgb(77, 77, 77);
                    font-size: 14px !important;
                    text-align: center !important;
                    font-weight: lighter;
                  }
                }
              }

              .has-error input {
                border-color: #ff1c1c !important;
              }

              .label {
                font-size: 1rem;
                font-weight: lighter;
                color: #808080;
              }

              .checkbox-label {
                color: rgb(134, 134, 134);
                font-size: 12px;
                text-align: center;
                font-weight: 400;
                line-height: 22px !important;
                font-style: italic;
                margin-top: 12px;
                display: block;
              }

              .disclaimer {
                font-weight: 500;
                font-size: 12px;
                text-align: center;

                a {
                  color: #ff806f !important;
                }
              }

              .form-button {
                font-size: 1.86rem;
                background: rgb(0, 219, 110);
                border-color: rgb(0, 219, 110);
                outline: none;
                padding: 16px 10%;
                text-transform: capitalize;
                height: 5.5rem;
                max-height: 100%;
                font-weight: 600;
                border-radius: 10px;
              }
            }

            .bottom-link {
              color: #868686;
              font-size: 0.86rem;
              font-weight: 500;
              text-align: center;
              margin: 1rem 0 0 0;

              a {
                color: #ff806f !important;
              }
            }
          }
        }

        .onboarding-footer {
          position: absolute;
          bottom: 1rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}
