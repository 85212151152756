.order-transaction-nav {
  .product-filter {
    width: 280px;
    margin-bottom: 6px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;
    position: relative;
    background-color: #ffffffd2 !important;

    .custom-cross-ic {
      position: absolute;
      top: 50%;
      right: 2%;
      transform: translate(-50%, -50%);
      right: 0px;
      z-index: 999;
      height: 14px;
      width: 14px;
      background-color: #898989;
      font-weight: 500;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      padding-right: 1px;
      cursor: pointer;
    }

    svg {
      transition: 0.3s all;
    }

    .ant-select-selection {
      background-color: #ffffffd2 !important;
    }

    .ant-select-selection__choice {
      padding-left: 6px !important;
      padding-right: 6px !important;
      svg {
        display: none !important;
      }
    }

    .ant-select.ant-select-enabled {
      width: 100%;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .ant-select-selection-selected-value {
      margin-right: 3px;
      padding-right: 3px;
    }

    .ant-select-open {
      svg {
        transform: rotate(180deg) !important;
        fill: #898989 !important;

        path {
          fill: #898989 !important;
        }
      }
    }
  }

  .Ndrnav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        right: 15px;
        .filter {
          .ant-select-selection {
            padding: 0 !important;
            background: none;
            border: none;
            margin-right: 0;
          }
          svg {
            font-size: 20px;
          }
          .ant-select-arrow {
            margin-top: -10px;
          }
          .ant-select-selection-selected-value {
            padding-right: 26px;
          }
        }
        .show_data_from {
          font-size: 12px;
        }
        .dispute-status {
          font-size: 12px;
        }
      }
    }
    .secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 100px;
      .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        .tab {
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;
          color: #898989;
          .active {
            color: #1268fb;
          }
          button {
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 500;
          }
        }
        .circle {
          // margin-left: 8px !important;
          position: relative;
          background: #f5f5f5;
          font-size: 12px;
          padding: 2px 10px;
          color: #898989;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #898989;
        }
        .backgroundcircle {
          // margin-left: 8px !important;
          position: relative;
          background: #1268fb;
          font-size: 12px;
          padding: 2px 10px;
          color: #fff;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #1268fb;
        }

        .hover-ndr-underline-animation {
          position: relative;
        }
        .hover-ndr-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-ndr-underline-animation:hover {
          color: #4398ed !important;
          .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400;
          }
        }

        .hover-ndr-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left;
        }

        .hover-ndr-underline-animation-click {
          position: relative;
        }
        .hover-ndr-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left;
        }
      }

      .download_csv {
        margin-right: 100px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 32px;
        line-height: 30px;
      }
    }
    .ant-input {
      width: 80%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px;
    }
    .ant-calendar-picker-input.ant-input {
      width: 86%;
    }
    .ant-calendar-range-picker-separator {
      color: #1268fb !important;
    }
    .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px;
    }
    .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em;
    }
    .ant-calendar-range-picker-input {
      width: 36.6% !important;
      letter-spacing: 0.032em;
      line-height: 24px;
    }
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      top: 50%;
      right: 47px;
      width: 14px;
      height: 14px;
      font-size: 12px;
    }
  }

  .bottom_data {
    display: flex;
    justify-content: space-between;
    padding-top: 90px;
    margin-top: 32px;
    padding-top: 90px;
  }
  .ant-select-selection__placeholder {
    color: #1268fb;
  }

  .drop {
    background-color: #f8f8f8 !important;

    > div {
      height: 40px !important;
    }

    // padding: 4.5px 5px 4.5px 17px;
    // width: 290px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;

    .ant-select.ant-select-enabled {
      height: 40px !important;
    }

    // margin: auto;
    .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;

      height: 40px !important;
      .ant-select-selection-selected-value {
        height: 40px !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important;
    }
    .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb;
      .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
      }
    }
    .ant-select-selection {
      width: 159px;
      height: 40px !important;
      border: none !important;
      background: #f8f8f8 !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    .ant-select-arrow {
      top: 53%;
      left: 159% !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    // margin-top: -10%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99;
  }
}

.fullorder_data {
  .uppp {
    transform: translateY(-195.6px);
    transition: all 0.3s linear;
  }
  .downn {
    transform: translateY(-125.6px);
    transition: all 0.3s linear;
  }
}

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important;
}

.overlay-footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999;
  button {
    background: #1268fb !important;
    color: #fff !important;
    margin-bottom: 4px;
  }

  button:hover {
    color: #fff !important;
  }
}
