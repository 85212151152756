.pStore-type {
    .ant-modal-header {
      padding: 16px 8px !important;
      border-bottom: 1px solid #cecece !important;
      display: flex;
      margin: 0 auto;
      width: 456px;
    }
    .title_txt {
      font-size: 14px;
      letter-spacing: 0.032em;
      line-height: 16px;
      font-weight: 500;
    }
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-content {
      border-radius: 4px;
    }
    .footer_button_continue {
      padding: 16px 2px !important;
      border-top: 1px solid #cecece !important;
      display: flex;
      margin: 0 auto;
      margin-bottom: -16px;
      justify-content: flex-end;
    }
    .ant-modal-body {
      padding: 24px !important;
    }
  
    .shopify_and_wooCommerece {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
    }
  
    .flag_radio_btn {
      display: flex;
      justify-content: space-between;
      .Wocoomerce-type-card {
        height: 136px;
        width: 136px;
        border: 1px solid #212121;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        background: #fff;
        margin-right: 16px;
        cursor: pointer;
        transition: all 0.3s linear;
        .footer {
          flex: unset;
          width: 240px;
          font-size: 12px;
          color: white;
          padding: 8px 0;
          background: #36c626;
          position: relative;
          left: -9px;
          top: 3px;
          font-weight: 500;
          letter-spacing: 0.032em;
          line-height: 16px;
        }
        .anticon {
          margin: auto;
          svg {
            width: 80px;
            height: 80px;
          }
        }
      }
  
      .Shopify-type-card {
        height: 136px;
        width: 136px;
        border: 1px solid #212121 !important;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        background: #fff;
        margin-right: 16px;
        cursor: pointer;
        transition: all 0.3s linear;
        .footer {
          flex: unset;
          width: 240px;
          font-size: 12px;
          color: white;
          padding: 8px 0;
          background: #36c626;
          position: relative;
          left: -9px;
          top: 3px;
          font-weight: 500;
          letter-spacing: 0.032em;
          line-height: 16px;
        }
        .anticon {
          margin: auto;
          svg {
            width: 80px;
            height: 80px;
          }
        }
      }
  
      .custome_store-card {
        height: 136px;
        width: 136px;
        border: 1px solid #212121 !important;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        background: #fff;
        cursor: pointer;
        transition: all 0.3s linear;
        .footer {
          flex: unset;
          width: 240px;
          font-size: 12px;
          color: white;
          padding: 8px 0;
          background: #36c626;
          position: relative;
          left: -9px;
          top: 3px;
          font-weight: 500;
          letter-spacing: 0.032em;
          line-height: 16px;
        }
        .anticon {
          margin: auto;
          svg {
            width: 80px;
            height: 80px;
          }
        }
      }
  
      .logo_txt {
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.032em;
        line-height: 24px;
        margin-top: 4px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }
  
      .continue_next {
        display: none;
      }
    }
  
    .continue_btn {
      flex: unset;
      width: 238px;
      font-size: 12px;
      color: white;
      padding: 8px 0 !important;
      background: #36c626;
      position: relative;
      left: -2px;
      top: 2px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px;
      text-align: center;
    }
  
    .Wocoomerce-type-card:hover {
      border: 2px solid #212121;
      background: #95bf46;
      transition: all 0.3s linear;
    }
  
 
  
    .Shopify-type-card:hover {
      border: 2px solid #212121;
      background: #7f54b3;
      transition: all 0.3s linear;
    }
  
    .custome_store-card:hover {
      border: 2px solid #212121;
      background: #56a1bf;
      transition: all 0.3s linear;
    }
  
    .store_objective {
      margin-bottom: 8px;
      letter-spacing: 0.032em;
      font-size: 12px;
      line-height: 24px;
      margin-top: 6px;
      color: #212121;
      font-weight: 400;
    }
    .Shopify_flag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .wocommerce_flag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .ant-radio-inner {
      border-color: #fff !important;
      display: none !important;
    }
    .ant-radio-wrapper {
      width: 100%;
      height: 100%;
    }
    .wocommerce_flag span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .Shopify_flag span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }