.import-product-search {
  .ant-input-group {
    width: 425px !important;
    .ant-input {
      height: 40px !important;
      box-shadow: 0px 2px 8px #66656514;
      border-radius: 2px 0 0 2px;
      background-color: #f8f8f8;
      border: 1px solid #e3e3e3 !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
      // transition: all 0.5s ease-in-out !important;
    }
    .ant-input::placeholder {
      // text-transform: capitalize;
      font-size: 12px !important;
      line-height: none !important;
    }
    .ant-input:hover {
      // height: 40px !important;
      // box-shadow: 0px 2px 8px #66656514;
      border: 1px solid #b2b2b2 !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
    }
    .ant-input:focus {
      // height: 40px !important;
      // box-shadow: 0px 2px 8px #66656514;
      border: 2px solid #fd104d !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
    }
  }
  button {
    width: 122px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    line-height: 8px !important;
    background: #fd104d !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    box-shadow: 2px 2px 8px #002f7225 !important;
    border: none;
    border: 2px solid #fd104d !important;
  }
  button:hover {
    box-shadow: 0px 2px 8px #a01d1d14;
    background-color: #e61047 !important;
    border: 2px solid #c70c3c !important;
    color: #fff !important;
  }
  button:active {
    background-color: #c70c3c !important;
  }
  .store-listing {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
}

.import-product-search-large .ant-input-group {
  width: 608px !important;
}

.five-lg .ant-input-group {
  width: 550px !important;
}

.nextwidth {
  .ant-input-group {
    width: 378px !important;
    .ant-input {
      height: 40px !important;
      box-shadow: 0px 2px 8px #66656514;
      border-radius: 2px 0 0 2px;
      background-color: #f8f8f8 !important;
      border: 1px solid #e3e3e3 !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
      // transition: all 0.5s ease-in-out !important;
    }
    .ant-input::placeholder {
      // text-transform: capitalize;
      font-size: 12px !important;
      line-height: none !important;
    }
    .ant-input:hover {
      // height: 40px !important;
      // box-shadow: 0px 2px 8px #66656514;
      border: 1px solid #b2b2b2 !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
    }
    .ant-input:focus {
      // height: 40px !important;
      // box-shadow: 0px 2px 8px #66656514;
      border: 2px solid #fd104d !important;
      border-right: none !important;
      transition: all 0.2s ease-in-out !important;
    }
  }
  button {
    width: 112px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    line-height: 8px !important;
    background: #fd104d !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    box-shadow: 2px 2px 8px #002f7225 !important;
    border: none;
    border: 2px solid #fd104d !important;
  }
  button:hover {
    box-shadow: 0px 2px 8px #a01d1d14;
    background-color: #e61047 !important;
    border: 2px solid #c70c3c !important;
    color: #fff !important;
  }
  button:active {
    background-color: #c70c3c !important;
  }
}
