.onboardingkyc-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  // background: url("../../assets/images/backgrounds/onboarding-form-bg.svg");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 100% auto;

  .verified-div {
    position: absolute;
    top: 56%;
    z-index: 9;
    right: 12px;
    svg {
      font-size: 18px;
    }
  }

  .verified-div-non-ind {
    position: absolute;
    top: 40%;
    z-index: 1000;
    right: 12px;
    svg {
      font-size: 18px;
    }
  }

  .verified-div-email {
    position: absolute;
    top: 42%;
    z-index: 9;
    right: 12px;
    svg {
      font-size: 18px;
    }
  }

  .verified-div.ind-indv {
    position: absolute;
    top: 42%;
    z-index: 9;
    right: 12px;
    svg {
      font-size: 18px;
    }
  }

  .ant-input-group-addon {
    border-color: transparent;
    background: #ffffff;
    .ant-select-selection {
      border-radius: 2px;
    }
    div {
      margin-right: 3px;
      background: #efefef;
    }
  }
  .address-input {
    margin-bottom: 8 !important;
  }
  .ant-input {
    background: #f5f5f5;
    border-radius: 0px;
    border-color: none;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }

  .ant-form-item-label {
    display: inline-block;
    overflow: hidden;
    line-height: 16px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }
  .ant-input::placeholder {
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.032em;
  }
  .ant-form-item-required::before {
    display: none;
  }
  .ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ContinueEnable {
    background: #36c626;
    border-color: #36c626;
    color: white;
    width: 512px;
    border-radius: 2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 8px;
    margin-bottom: 24px;
    height: 40px;
  }
  .btn-green:active {
    background: #31b422;
  }

  .ContinueDisable {
    pointer-events: none;
    background: #94e38f;
    border-color: #94e38f;
    color: white;
    width: 512px;
    border-radius: 2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 8px;
    margin-bottom: 24px;
    height: 40px;
  }

  .onboarding {
    flex: 1;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;

    .logo {
      margin-bottom: 32px;
      max-width: 120px;
      height: 48px;
    }
    .tagline {
      color: rgba(0, 0, 0, 0.45);
      margin: 0 0 42px 0;
    }
    .ant-tabs-bar {
      border: 0;
      text-align: center;
    }

    .login-form-forgot,
    .login-form-signup-here {
      float: right;
    }

    .ant-alert {
      margin-bottom: 15px;
    }

    .login-form-button {
      width: 100%;
      margin: 0 0;
      box-shadow: 0px 1px 2px rgba(0, 104, 255, 0.59);
    }

    .register-tag-line {
      margin: 15px 0 10px 0;
    }

    .login-page-footer {
      position: sticky;
      top: calc(100% - 55px);
      .footer-links {
        list-style-type: none;
        padding: 0;
        li {
          display: inline-block;
          margin: 0 20px;
          a {
            color: rgba(0, 0, 0, 0.45);
          }
          a:hover {
            color: rgb(0, 0, 0);
          }
        }
      }
    }
    .ant-row-flex {
      display: flex;
      flex-flow: row wrap;
      margin-top: 64px;
    }
  }
  .onboarding-sidebar {
    width: 327px;
    background: url("../../assets/images/backgrounds/onboarding-sidebar-bg.svg")
      #16273b;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: sticky;
    top: 0;
    bottom: 0;
    a {
      color: rgba(255, 255, 255, 0.4);
      font-size: 15px;
    }
    a:hover {
      color: white;
    }

    .steps {
      display: flex;
      flex: 1;
      align-items: center;
      ul {
        list-style-type: none;
        margin: 0;
        position: relative;
        li {
          color: #b2b2b2;
          font-size: 12px;
          position: relative;
          margin: 16px 0;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.032em;
        }
        li:before {
          content: "";
          position: absolute;
          left: -34px;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #b2b2b2;
        }
        li.active {
          color: #fff;
          letter-spacing: 0.032em;
          font-size: 14px;
        }
        li.active:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #16273b;
        }
        li.completed:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #36c626;
        }
        li.active:before {
          background: #fff;
        }
        .completed {
          color: #fff;
          font-size: 12px;
          position: relative;
          margin: 16px 0;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.032em;
        }
      }

      ul:before {
        content: "";
        position: absolute;
        top: 22px;
        left: 12px;
        width: 4px;
        height: calc(100% - 42px);
        background: #b2b2b2;
      }
    }

    .testimonial {
      color: white;
      text-align: center;
      font-size: 14px;
      .testimonial-user {
        display: flex;
        width: fit-content;
        margin: auto;
        align-items: center;
        img {
          width: 50px;
        }
        p {
          flex: 1;
          margin: 0 0 0 10px;
          text-align: left;
        }
      }
    }
    .go-dashboard {
      text-align: right;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 18px;
      text-decoration: underline;
      color: #ffffff;
      a {
        font-size: 12px;
      }
      .anticon {
        vertical-align: middle;
        margin-left: 2px;
        margin-top: -1px;
        svg {
          transform: rotate(267deg);
          width: 16;
          height: 8px;
        }
      }
    }
  }

  .skip-step {
    border: 1px solid #898989;
    margin-top: -16px;
    margin-bottom: 16px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .skip-step a {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #212121;
    cursor: pointer;
  }
  .skip-step .skip-btn {
    width: 512px;
    border: none;
    background: transparent;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
  }

  .skip-btn:hover {
    color: #212121;
  }

  .ant-upload-drag-icon {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px;
    margin-top: -9px;
    .anticon {
      svg {
        width: 16px;
        height: 14px;
        margin-right: 6px;
      }
    }
  }
  .ant-upload-drag {
    height: 32px;
    border-radius: 2px;
    border: none;
    background: #f5f5f5;
  }
  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    color: #f5222d;
    font-size: 13px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }

  .non-india-upload-file .ant-form-explain {
    margin-top: -16px !important;
    margin-bottom: 3px;
  }

  .upload-file .ant-form-explain {
    margin-top: -6px !important;
  }
  .phone-input-validation .ant-form-explain {
    margin-top: 3px !important;
  }

  .authorized_signatory {
    margin-top: -8px;
  }
  .verify-email {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #92bcf9;
    text-decoration: underline !important;
    pointer-events: none;
  }

  .verify-email2 {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #0063f2;
    text-decoration: underline !important;
  }

  .verify-phone {
    background: transparent;
    position: absolute;
    right: 44%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #92bcf9;
    text-decoration: underline !important;
    pointer-events: none;
  }

  .verify-phone2 {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #0063f2;
    text-decoration: underline !important;
  }
  .verifyphone-tooltip {
    position: absolute;
    right: 43%;
    margin-top: 5px;
    z-index: 99;
  }

  .otherPayment {
    margin-top: -16px;
  }
  .etc-txt {
    float: left;
    margin-top: -24px;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .etc-txt2 {
    float: left;
    margin-top: -16px;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .etc-txt4 {
    float: left;
    margin-top: 8px;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .authorized-signatory {
    .ant-form-item-label {
      text-align: left !important;
      width: 100% !important;
    }
  }
  .phone-number {
    margin-bottom: 4px;
    .phone-number-value {
      color: #212121 !important;
      letter-spacing: 0.032em !important;
      line-height: 16px !important;
    }
  }
  .placeholder-country-input {
    color: #212121 !important;
    letter-spacing: 0.032em !important;
    line-height: 16px !important;
  }
  .label-phone-no {
    line-height: 25px;
  }
  .input-phone-txt {
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .dialCode-txt {
      width: 20%;
      background: #f5f5f5;
      height: 32px;
      display: flex;
      font-size: 12px;
      align-items: center;
      padding-left: 16px;
      color: #212121;
      letter-spacing: 0.032em;
    }
    .ant-input {
      width: 78%;
    }

    .ind-91 {
      width: 20%;
      background: #f5f5f5;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 12px;
      .ant-select-selection {
        background: none;
        border: none;
        font-size: 12px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        color: #212121;
      }
      .ant-select-selection__rendered {
        line-height: 23px !important;
      }
      .ant-select-arrow {
        margin-top: -7px !important;
      }
    }
  }

  .aadhar-card-name {
    margin-bottom: 6px;
    margin-top: 19px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    font-weight: 500;
  }

  .welcomevfulfill-txt {
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 36%;
    left: 15%;
    text-decoration: underline;
    line-height: 22px;
    letter-spacing: 0.032em;
  }
  // .ant-input:hover{
  // border: 1px solid #212212;
  // }
  // .ant-select-selection:hover{
  //   border: 1px solid #212212 !important;
  // }

  .right-side-step {
    .kyc-step {
      height: 48px !important;
      padding: 0px 16px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .flex-step {
        display: flex;
        align-items: center;
        .circle {
          height: 16px;
          width: 16px;
          background-color: #b2b2b2;
          border-radius: 50%;
          position: relative;
        }
        .circle.completed {
          background-color: #2bc822;
        }

        .circle.completed:after {
          height: 40px;
          width: 4px;
          background-color: #2bc822;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }

        .circle.active {
          background-color: #2bc822;
          outline: 4px solid #229d1b;
        }

        .circle.active:after {
          height: 40px;
          width: 4px;
          background-color: #b2b2b2;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }

        .circle::after {
          content: "";
          height: 40px;
          width: 4px;
          background-color: #b2b2b2;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }

        .step-concat {
          margin-left: 16px;
          .font-weight {
            color: #fff;
          }
        }
      }
    }

    .kyc-step:last-of-type {
      .circle:after {
        display: none;
      }
    }

    .status-logs:last-of-type {
      .circle::after {
        content: "";
        height: 0px;
        width: 0px;
      }
    }
    .onboarding-step-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #ffffff;
    }
    .Profile-txt {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 22px;
    }
  }
  .welcome-vfulfill {
     position: absolute;
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    letter-spacing: 0.032em;
    line-height: 22px;
    top: 30%;
    left: 20%;
  }

  .already-verify-email {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #28af21;
    text-decoration: underline !important;
    pointer-events: none;
    .save-ican {
      margin-right: 3px;
      svg {
        path {
          fill: #28af21;
        }
      }
    }
  }
  .anticon-close {
    display: none;
  }

  .content-container {
    width: 100%;
    max-width: 510px;
    margin: auto;
    // text-align: center;
    // margin-bottom: 69px;
    padding-bottom: 8px;
    .profile-indian-global {
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-indian {
        margin-right: 32px;
      }
    }

    .select-option-txt {
      font-size: 12px;
      color: #4b4b4b;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-bottom: 13px;
      margin-top: 69px;
    }

    .profile-type-card {
      height: 216px;
      width: 240px;
      border: 3px solid #e3e3e3;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      background: #fff;
      cursor: pointer;
      .flag {
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.032em;
        line-height: 18px;
        margin-bottom: 16px;
        font-weight: 400;
        .registered-india {
          text-decoration: underline;
          font-weight: 500;
        }
        .not-registered-india {
          text-decoration: underline;
          font-weight: 500;
        }
      }
      .footer {
        flex: unset;
        width: 240px;
        font-size: 12px;
        color: white;
        padding: 8px 0;
        background: #36c626;
        position: relative;
        left: -9px;
        top: 3px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
      .Shopify_flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .anticon svg {
          width: 96px;
          height: 96px;
        }
      }
      .wocommerce_flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .anticon svg {
          width: 96px;
          height: 96px;
        }
      }

      .Shopify_footer {
        flex: unset;
        width: 240px;
        font-size: 12px;
        color: #212121;
        padding: 8px 0;
        position: relative;
        left: -9px;
        top: -24px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px;
      }
    }

    .profile-type-card:hover {
      border: 3px solid #30c638;
      .registered-india {
        font-weight: 600;
      }
      .not-registered-india {
        font-weight: 600;
      }
    }
  }
}

.Skip_woocommerce {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.support-email-phone-modal {
  .ant-modal-content {
    .ant-modal-close {
      display: none !important;
    }
    .support-phone-number-txt {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0.032em;
      line-height: 22px;
      margin-bottom: 0px;
      color: #fc125e;
    }
    .number-verify-txt {
      font-size: 12px;
      color: #4b4b4b;
      line-height: 22px;
      letter-spacing: 0.032em;
      text-align: center;
    }
    .ant-modal-header {
      border-bottom: none !important;
      padding: 32px 24px !important;
    }
    .ant-modal-footer {
      border-top: none !important;
    }
  }
  .otp-verify {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    .otp-number {
      width: 15%;
      border: none;
      background: #f5f5f5;
      height: 32px;
      text-align: center;
    }
  }
  .otp-txt {
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: 5px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
  }
  .resend-code {
    float: right;
    margin-top: -16px;
    font-size: 12px;
    letter-spacing: 0.032em;
    .resend-code-txt {
      color: #3273cc;
      text-decoration: underline !important;
      margin-right: 4px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    .resend-code-txt2 {
      color: #98b9e5;
      text-decoration: underline !important;
      margin-right: -3px;
      background: transparent;
      border: none;
      pointer-events: none;
    }
  }
  .header-support-txt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    font-size: 12px;
    letter-spacing: 0.032em;
    margin-top: -24px;
    color: #898989;
    .change-number-txt {
      color: #fc125e;
      border: none;
      background: none;
      text-decoration: underline !important;
      cursor: pointer;
    }
    .save-number-txt {
      color: #28af21;
      border: none;
      background: none;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
  .edit-ican {
    margin-right: 3px;
  }
  .save-ican {
    margin-right: 3px;
    svg {
      path {
        fill: #28af21;
      }
    }
  }
  .input-number-data {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .ind-91 {
      width: 20%;
      background: #f5f5f5;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 12px;
      .ant-select-selection {
        background: none;
        border: none;
        font-size: 12px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        color: #212121;
      }
      .ant-select-selection__rendered {
        line-height: 23px !important;
      }
      .ant-select-arrow {
        margin-top: -7px !important;
      }
    }
    .input-email-txt {
      width: 100%;
      input {
        background: #f5f5f5;

        border: none;
        border-radius: 0px;
      }
    }

    .input-number-txt {
      width: 78%;
      input {
        background: #f5f5f5;

        border: none;
        border-radius: 0px;
      }
    }
  }
  .verify-continue-btn {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #2bc822 !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    margin-top: 16px;
  }
  .verify-continue-btn2 {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #94e38f !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    pointer-events: none;
  }
  .cancel-btn {
    width: 408px;
    margin-top: 8px;
    border: 1px solid #898989;
    box-shadow: none;
    // background: #2bc822;
    border-radius: 2px;
    color: #898989;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
  }
  .otp-number-txt {
    font-size: 12px;
    font-style: italic;
    color: #898989;
    margin-top: 4px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .ant-input {
    font-size: 12px !important;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .disable-phonenumber {
    background: #f5f5f5;
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.032em;
    color: #212121;
    padding-left: 16px;
  }
}
