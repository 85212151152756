.global-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}
.global-tooltip-icon {
  font-size: 16px;
  margin: 0px 8px !important;
}
.global-tooltip:hover .global-tooltip-icon {
  background: #0058d6;
  border-radius: 50%;
  cursor: pointer;

  svg {
    g {
      stroke: #0058d6;
    }

    :last-child {
      g {
        stroke: #fff;
      }
    }
  }
}
.global-tooltip:hover .global-tooltip-icon g text {
  fill: #fff;
}
.global-tooltip:hover .global-tooltip-icon g > g {
  stroke: #0058d6;
}
