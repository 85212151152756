.orders-to-vf-graph-wraper {
  * {
    letter-spacing: 0.032em;
  }

  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;

  background-color: #fff;

  .graph-head {
    height: 56px;
    background-color: #f0f2f659;
    padding: 16px 24px 12px 24px;

    font-size: 12px;
    color: #898989;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px;
    }
    .name {
      margin-left: 0px;
    }

    span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px;
    }
    .abs-div {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: -48px;
      left: 48px;
      justify-content: flex-start;

      > div {
        margin-right: 34px;
      }

      svg {
        margin-right: 2px;
      }
      span {
        font-size: 12px;
        color: #212121;
        font-weight: 500;
      }
    }

    .compare {
      display: flex;
      align-items: center;
    }
  }
  .recharts-wrapper {
    padding-left: 6px;
    padding-right: 6px;

    margin-top: 76px !important;

    .recharts-surface {
      // padding-bottom: 14px !important;
      width: 100% !important;
    }

    .yAxis {
      font-size: 10px;
    }
    .xAxis {
      g {
        font-size: 14px;
        // text-transform: capitalize;
      }
    }
  }
}

.graph-custom-tooltip {
  border: 1px solid #000;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 1;
  padding: 11px 16px;
  transition: top 200ms, left 200ms;
  font-size: 12px;
  min-width: 200px !important;
  z-index: 999;
  .light-bold {
    font-weight: 500;
  }
  .date {
    margin-bottom: 7px;
  }

  .details {
    display: flex;
    .icon {
      display: flex;
      align-items: flex-start;
      margin-top: 6px;
      margin-right: 6px;
      svg {
        font-size: 6px;
      }
    }

    .text {
      text-transform: capitalize;
      p {
        font-weight: 500;
      }

      p:first-of-type {
        font-weight: 400;
      }
    }
  }

  .details.last {
    margin-top: 14px;
  }
}
