.advanceDetailContainer {
  padding: 48px;
  border-radius: 4px;
  .i-j{
    svg{
      height: 12px;
    }
  }
  .fl {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: rgb(33, 33, 33);
      span {
        margin-left: 4px !important;
      }
    }
    .right-gstdata-org-id {
      display: flex;
      align-items: center;
      .gst-enabled-txt {
        margin-right: 8px;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        letter-spacing: 0.032em;
      }
      .border-line {
        margin-right: 8px;
        color: #b2b2b2;
      }
      .right-org-data {
        display: flex;
        align-items: center;
        .gst-enabled-gst {
          height: 100%;
          margin-top: 2px;
        }
      }
    }
  }
  .edit-fields {
    color: #fd115e;
    margin-left: 8px;
    font-size: 12px;
  }
  .save-change {
    background: rgb(49, 195, 15);
    margin-right: 8px;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    letter-spacing: 0.032em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
  .save-change-data {
    color: #2bc822;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
  }
  .save-change-data svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-top: -2px;
    vertical-align: middle;
  }
  .cancle-edit-data {
    width: 120px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
  }
  .cancle-edit-data svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-top: -2px;
    vertical-align: middle;
  }
  // .kyc-blur-container {
  //   color: white;
  //   position: absolute;
  //   top: 25%;
  //   left: 0%;
  //   z-index: 2;
  //   width: 100%;
  //   min-height: 75%;
  //   padding: 20px;
  //   display: flex;
  //   align-items: center;
  //   text-align: center;
  // }
  // .bank-detail-blur-container {
  //   color: white;
  //   position: absolute;
  //   top: 25%;
  //   left: 0%;
  //   z-index: 2;
  //   width: 100%;
  //   min-height: 75%;
  //   padding: 20px;
  //   display: flex;
  //   align-items: center;
  //   text-align: center;
  // }
  .kyc-blur-container a,
  .bank-detail-blur-container a {
    width: 100%;
  }
  .kyc-blur-container button,
  .bank-detail-blur-container button {
    min-width: 150px;
  }
  .orgination-data-edit {
    border: 1px solid #898989;
    margin-bottom: 0;
    line-height: 16px;
    color: #212121;
    font-weight: 400;
    font-size: 12px;
    padding: 7px 16px;
    background: #f5f5f5;
    width: 100%;
    min-height: 32px;
    outline: none;
  }
  .cancle-edit {
    width: 120px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
  }
  .org-data {
    display: flex;
    justify-content: space-between;
    // cursor: pointer;
    min-height: 22px;
  }
  .kyc-data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    cursor: pointer;
    .clickable {
      cursor: pointer;
      background: #0068ff;
      color: white;
      border: none;
      height: 40px;
      padding: 4px 16px;
      border-radius: 2px;
      font-size: 12px;
    }
    .disbalebtn {
      background: #a2c7ff;
      color: white;
      border: none;
      height: 40px;
      padding: 4px 16px;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .banking-data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    cursor: pointer;
  }
  .field {
    flex: 1;
    margin-right: 8px;
    .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px;
    }
    .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize;
    }
  }
  .field1 {
    flex: 1;
    margin-left: 8px;
    .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px;
    }
    .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize;
    }
  }
  .field2 {
    margin-right: 8px;
    flex: 1;
    .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px;
    }
    .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize;
    }
  }
  .field3 {
    margin-left: 8px;
    margin-right: 8px;
    flex: 1;
    .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px;
    }
    .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize;
    }
  }
  .field4 {
    margin-left: 8px;
    margin-right: 8px;
    flex: 1;
    .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px;
    }
    .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize;
    }
  }
  .field5 {
    margin-left: 8px;
    flex: 1;
    .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px;
    }
    .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize;
    }
  }
  .orgDetails {
    letter-spacing: 0.032em;
    font-weight: 400;
    .orgbasicupperdetail {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0 0;
    }
    .orgbasicadressMiddledetail {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 0 0;
    }
    .orgbasicdetailfooter {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 0 0;
    }
  }
  .KYCDetails {
    letter-spacing: 0.032em;
    border-top: 1px solid #cecece;
    margin: 24px 0px 0px 0px;
    font-weight: 400;
    position: relative;
    .KYCDetailsBasicdetail {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0 0;
    }
    .kycmiddledDetail {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 0 0;
      .flex-1 {
        flex: 1;
        margin-bottom: 0;
        line-height: 16px;
        color: #212121;
        font-weight: 400;
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5;
        margin-right: 8px;
        height: 32px;
      }
      .flex-3 {
        height: 32px;
        flex: 3;
        margin-bottom: 0;
        line-height: 16px;
        color: #212121;
        font-weight: 400;
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5;
      }
    }
  }
  .bankingdetail {
    letter-spacing: 0.032em;
    border-top: 1px solid #cecece;
    margin: 24px 0px 0px 0px;
    font-weight: 400;
    position: relative;
    .banking {
      margin: 24px 0px 0px 0px;
    }
    .bankingBasicDetail {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0 0;
    }
  }
  .ant-checkbox-inner {
    border-radius: 10px !important;
  }

  .org-vfid {
    color: #212121;
  }
}
