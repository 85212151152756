.Private-catalog-listing {
  background: #f0f2f5;
  .f8 {
    font-size: 10px;
  }
  .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
  }
  .f16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em;
  }
  .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em;
  }
  .mr0 {
    margin-right: 0px;
  }
  .ml0 {
    margin-left: 0px;
  }
  .mt0 {
    margin-top: 0px;
  }
  .mb0 {
    margin-bottom: 0px;
  }
  .mb4 {
    margin-bottom: 2px;
  }
  .mr8 {
    margin-right: 8px;
  }
  .ml8 {
    margin-left: 8px;
  }
  .mt8 {
    margin-top: 8px;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .mr16 {
    margin-right: 16px;
  }
  .ml16 {
    margin-left: 16px;
  }
  .mt16 {
    margin-top: 10px;
  }
  .mb16 {
    margin-bottom: 0px;
  }
  .w100 {
    width: 100%;
  }
  .py8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .px8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .py2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .px2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .semibold {
    font-weight: 500;
  }
  .bold {
    font-weight: 500;
    .default_retail_price {
      font-size: 12px;
      margin-left: -40px;
      margin-right: 8px;
      font-style: italic;
      letter-spacing: 0.032em;
      text-decoration: line-through;
      text-decoration-color: #898989;
    }
  }
  .italic {
    font-style: italic;
    margin-top: 8px;
  }
  .underline {
    text-decoration: underline !important;
  }
  button {
    cursor: pointer;
  }
  .clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .niche-item {
    font-size: 12px;
    color: #212121;
    text-transform: capitalize;
  }
  .catalog-listing-content {
    width: 100%;
    margin: auto;
    display: flex;
    padding-top: 24px;
    .content-sidebar {
      width: 248px;
      margin-right: 24px;
      .filters {
        background: white;
        padding: 15px 16px 24px 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        .filter-tag {
          display: block;
          color: #acacac;
          background: #eeeeee;
          padding: 8px 16px;
          margin-bottom: 16px;
          letter-spacing: 0.025em;
        }
        .filter-tag.active {
          color: #212121;
        }
        .location-tag.active {
          background: #2bc822;
          color: white;
        }
      }
    }
    .content {
      flex: 1;
    }
  }
  .product-tooltipican:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer;
  }
  .product-tooltipican:hover svg.product-tooltip-icon g text {
    fill: #fff;
  }
  .product-tooltipican:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb;
  }
  .top-import-button {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background: rgb(43, 200, 34);
    padding: 8px 16px;
    color: #fff;
    max-height: 40px;
    border-radius: 2px;
    border: none;
  }
  .product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    .product-card {
      background: white;
      margin-bottom: 24px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      height: auto;
      flex-direction: column;
      .potd-label {
        position: absolute;
        background: linear-gradient(150deg, #fd104d, #f0320f);
        padding: 4px 8px;
        color: white;
        top: 8px;
        z-index: 2;
      }
      .product-watchlist-btn {
        position: absolute;
        top: 0;
        right: 16px;
        padding: 0;
        border: none;
        background: transparent;
      }
      .content {
        padding: 16px;
        padding-bottom: 0px;
        display: flex;
        .left {
          margin-right: 24px;
        }
        .product-img {
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;
          border-radius: 2px;
          img {
            width: 168px;
            height: 168px;
            object-fit: cover;
            border-radius: 2px;
            border: 2px solid #f5f5f5;
            transition: all 0.5s ease;
          }
          .prev-img,
          .next-img {
            transition: all 0.5s ease;
            position: absolute;
            // height: 100%;
            width: 32px;
            background: rgba(33, 33, 33, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            opacity: 0;
            height: 168px;
          }
          .prev-img {
            top: 0;
            left: 0;
          }
          .next-img {
            top: 0;
            right: 0;
          }
        }
        .product-img:hover {
          img {
            transition: all 0.5s ease;
            transform: scale(1.05);
          }
          .prev-img,
          .next-img {
            transition: all 0.5s ease;
            opacity: 1;
            transform: scale(1.05);
          }
        }
        .details {
          flex: 1;
          // a:hover {
          //   text-decoration: underline !important;
          // }
        }
      }
      .footer {
        padding: 0;
        display: flex;
        text-align: center;
        justify-content: space-between;

        .product_detail {
          background-color: #f5faff;
        }
        .view-product-details {
          border: none;
          box-shadow: none;
          color: #0068ff;
          font-weight: 700;
          background-color: #f5faff;
          border-radius: 0;
          font-weight: 600;
          font-size: 12px;
          height: 48px;
        }
      }
      .dropdown_link {
        width: 20%;
      }
      .ant-dropdown-link {
        width: 100%;
        background: #36c626;
        border: 0;
        font-weight: 700;
        border-radius: 0;
        color: #fff;
        font-size: 12px;
        height: 48px;
      }
    }
  }
  // span.copied-id svg g path {
  //     fill: #5E73FF !important;
  // }
  .request_lattest_ican {
    display: flex;
    justify-content: space-between;
    margin-bottom: -19px;
  }
  .request_lattest_ican2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  .product-tooltip-icon {
    margin-top: -2px;
  }
  .sv:hover {
    fill: #0068ff;
  }
  .anticon:hover {
    svg {
      #Ellipse_44 {
        stroke: #1268fb;
        fill: #1268fb;
      }
      #Ellipse_47 {
        stroke: #fff;
        fill: #ffff;
      }
      #Rectangle_2462 {
        stroke: #fff;
        fill: #ffff;
      }
    }
  }
  .product-tooltipican svg {
    width: 16px;
    height: 16px;
  }
  .prev-img svg {
    width: 18px;
    height: 41px;
  }
  .next-img svg {
    width: 18px;
    height: 41px;
  }
  .cost-update-date {
    color: #212121;
  }
  .req-latest-price svg {
    margin-bottom: 2px !important;
  }
}

.Private_ali-link-menu {
  padding: 10px !important;
  li a {
    padding: 2px 0;
    line-height: 22px;
    font-size: 12px;
    color: #ff005e;
  }
}

@media screen and (min-width: 1360px) {
  .Private-catalog-listing {
    .catalog-listing-content {
      width: 100%;
      margin: auto;
    }
  }
  .dropdown_link {
    width: 21%;
  }
}

.sv2 {
  pointer-events: none;
  svg {
    fill: #b2b2b2;
  }
  svg:hover {
    fill: #b2b2b2;
  }
}

#test {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
