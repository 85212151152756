.other-settings-container {
  
  .sub-text {
    margin-top: -0.5rem;
  }
  .rto-address {
    .ant-form-item-control {
      margin-top: 12px;
      label {
        font-weight: 500;
        font-size: 12px;
        color: black;
        line-height: 22px;
      }
      .ant-select-selection {
        border-radius: 0px;
      }
      .ant-input {
        margin-top: 7px;
        border-radius: 0px !important;
        font-size: 12px;
      }
      .ant-input::placeholder {
        color: #707070;
      }
    }
    .ant-btn-primary {
      margin-top: 24px;
    }
  }
  .has-error {
    label {
      color: #f5222d;
    }
  }
  .options-container {
    p {
      margin-bottom: 0px;
      line-height: 22px;
      font-weight: 500;
      color: #898989;
    }
    .ant-btn.ant-btn-link {
      padding: 0px;
    }
    .ant-radio-group {
      .ant-radio-button-wrapper {
        width: 108px;
        margin-right: 5px;
        border-radius: 2px;
        text-align: center;
        background: #bbbbbb;
        color: white;
        font-size: 12px;
        border: none;
        box-shadow: unset;
        
      }

      display: block;
      margin: auto;
      margin-right: 31%;
      width:300px
      //   .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      //     background: #21da40;
      //   }
    }
    .description {
      padding: 12px 35px;
      background: #F5FAFF;
      p {
        color: #2b2a2a;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 0px;
      }
    }
    .select-location {
      display: flex;
      margin-top: 12px;
      margin-bottom: 24px;
      .select-location-card.selected {
        border: 2px solid #2ecc39;

        .select-header {
          span {
            background: #2ecc39;
          }
          p {
            color: #2ecc39;
            font-size: 8px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.5px;
          }
        }
        p {
          color: #2BC822;
        }
        .anticon {
          path {
            fill: #2BC822;
          }
        }
      }
      .select-location-card {
        cursor: pointer;
        border: 1px solid #707070;
        text-align: center;
        padding: 10px 8px;
        border-radius: 4px;
        margin-right: 8px;
        width: 120px;
        .select-header {
          display: flex;
          align-items: center;
          height: 22px;
          span {
            width: 10px;
            height: 10px;
            background: #efefef;
            border-radius: 20px;
            margin-right: 5px;
          }
        }
        p {
          font-size: 12px;
          color: #707070;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 0px;
        }
        .anticon {
          font-size: 48px;
          path {
            fill: #707070;
          }
        }
      }
    }
  }
}
