.Escalate_NDR {
  * {
    letter-spacing: 0.032em;
  }
  .ant-modal-content {
    padding: 24px 32px;

    .ant-modal-title {
      padding-bottom: 8px;
    }

    .ant-modal-header {
      padding: 0;
    }

    // modal body

    .ant-modal-body {
      padding: 0;

      .ndr-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 12px;
        .details {
          width: 200px;
          p {
            margin-bottom: 4px;
            font-size: 12px;
            color: #898989;
          }
          .id {
            height: 32px;
            background: #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 16px;
            font-weight: 500;
            font-size: 12px;
            color: #1370fc;
          }
        }
      }
      .remarks {
        color: #898989;
        font-size: 12px;
        margin-bottom: 4px;
      }

      textarea {
        resize: none;
        height: 100px;
        padding: 8px 16px;
        border: none;
        background-color: #f5f5f5;
      }
      ::placeholder {
        color: #898989;
        font-size: 12px;
      }

      .upload-text {
        margin-top: 12px;
        margin-bottom: 4px;
        font-size: 12px;
        color: #898989;
      }

      .ant-upload {
        padding: 0px !important;
        padding-left: 8px !important;
        border: none;
        background-color: #f5f5f5;
        border-radius: 2px;
        .ant-upload-drag-container {
          display: flex;
          align-items: center;
          height: 32px;

          .ant-upload-text {
            margin: 0px;
            font-size: 12px;
            color: #898989;
          }
        }

        .ant-upload-drag-icon {
          margin-bottom: 6px;
          margin-right: 8px;

          svg {
            color: #898989;
            font-size: 16px;
          }
        }
      }
    }

    .ant-modal-footer {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 15px;

      .cancel {
        width: 176px;
        height: 40px;

        border-radius: 1px;
        border: 1px solid #898989;
      }
      .cancel:hover {
        width: 176px;
        height: 40px;
        color: #1370fc;
        border-radius: 1px;
        border: 1px solid #1370fc;
      }
      .ant-btn-primary {
        width: 176px;
        height: 40px;
        border-radius: 1px;
      }

      .success_btn {
        border: 1px solid #2bc823;
        background-color: #2bc823;
      }
    }
  }

  .ant-input {
    border-radius: 2px !important;
  }

  .re-Attemptcheckbox {
    margin-top: 32px;
    span {
      font-size: 12px;
      color: #898989;
    }
  }

  .ndr-reattamp_modal {
    border: none;

    input {
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
    }

    .ant-input-number {
      border: none;

      input {
        border: 1px solid #f5f5f5;
        height: 32px;
      }
      input:focus {
        background-color: #fff;
        border: 1px solid #212121;
      }
    }
    .ant-input-number:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .btn_disabled {
    color: #fff;
    background-color: #2bc82371;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    pointer-events: none;
    border: none;
  }

  .remarks {
    span {
      color: red;
    }
  }

  input:focus {
    background-color: #fff;
    border: 1px solid #212121;
  }
  textarea:focus {
    background-color: #ffffff !important;
    border: 1px solid #212121 !important;
  }
}
