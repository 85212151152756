.collapse-item {
  width: 992px;
  margin: auto;
  border-radius: 0px !important;
  letter-spacing: 0.032em;

  .ic-w {
    margin-top: 8px;
    span {
      display: flex !important;
      align-items: center;
      font-size: 11px;
    }
  }

  .ant-collapse-item {
    border-radius: 0px !important;
    .container {
      background: #ffffff !important;
      padding: 8px 24px 8px 24px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .prod-details {
        flex-basis: 25%;
        display: flex;
        align-items: center;
        cursor: auto;

        p {
          font-size: 12px;
        }

        .img {
          height: 40px;
          width: 40px;
          background: gray;
          border: 2px solid #f5f5f5;
          margin-right: 12px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .live-inv {
        flex-basis: 12%;
        text-align: center;
        font-size: 12px;
      }

      .expand {
        flex-direction: column;
      }
      .pricing {
        flex-basis: 16%;
        text-align: center;
        font-size: 12px;

        .price-values {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .pricing {
        span {
          font-size: 9px;
          line-height: 14px;
        }
      }

      .dist {
        flex-basis: 16%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        // display: none;

        p:first-of-type {
          margin-top: 4px;
        }

        p {
          font-size: 9px;
          line-height: 14px;
        }
      }

      .flexible {
        flex-basis: 10%;
        text-align: center;
        .restock-button {
          background-color: rgb(255, 186, 57) !important;
          box-shadow: 0px 2px 8px #002f7225 !important;
          // border: 1px solid #0068ff !important;
          border-radius: 2px !important;
          font-weight: 500 !important;
          font-size: 12px !important;
          color: #fff !important;
          text-shadow: none !important;
          cursor: pointer;
          padding: 6px 8px;
          display: flex;
          align-items: center;
        }
        .restock-button:hover {
          background-color: rgb(238, 158, 7) !important;
          // border: 2px solid #rgb(238, 158, 7) !important;
        }
      }

      .expand {
        flex-basis: 12%;
        text-align: right;

        color: #1370fc;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  .ant-collapse-header {
    border-radius: 0px !important;
    padding: 0 !important;
  }

  .ant-collapse-content {
    background: #f5f5f5;
  }

  .ant-collapse-content-box {
    padding: 0 24px !important;
  }

  .head-inner {
    background-color: #f5f5f5;
    margin: auto;
    justify-content: flex-start;
    display: flex;
    box-shadow: none !important;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 12px;

    .prod-details {
      flex-basis: 33.5%;
      font-weight: 500;
      text-decoration: underline;
    }

    .live-inv {
      flex-basis: 12%;
      text-align: center;
      font-weight: 500;
      text-decoration: underline;
    }

    .pricing {
      flex-basis: 16%;
      text-align: center;
      font-weight: 500;
      text-decoration: underline;
    }

    .dist {
      flex-basis: 16%;
      text-align: center;
      font-weight: 500;
      text-decoration: underline;
      // display: none;
    }

    .expand {
      flex-basis: 12%;
      text-align: right;
      font-weight: 500;
      text-decoration: underline;
      opacity: 0;
    }
  }

  .bottom-head2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px !important;

    .prod-details {
      flex-basis: 33.5%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      cursor: auto;
      font-size: 12px;
      span {
        font-weight: 500;
      }

      p {
        font-size: 12px;
      }

      .img {
        height: 40px;
        width: 40px;
        background: gray;
        border: 2px solid #f5f5f5;
        margin-right: 12px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .live-inv {
      flex-basis: 12%;
      text-align: center;
      font-size: 12px;
    }

    .pricing {
      flex-basis: 16%;
      text-align: center;
      font-size: 12px;
    }

    .dist {
      flex-basis: 16%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      // display: none;
    }

    .expand {
      flex-basis: 12%;
      text-align: right;
      opacity: 0;
      color: #1370fc;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .bottom-head2:last-of-type {
    margin-bottom: 0;
  }

  .product-link-name p a:hover {
    text-decoration: underline !important;
  }

  .fl {
    width: 90% !important;
    span {
      color: #212121;
      font-weight: 500;
    }
  }
}

.ant-collapse.ant-collapse-icon-position-right.collapse-item {
  width: auto;
}
