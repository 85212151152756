.product-list-variants {
  .product-card-switch {
    font-size: 0.75rem;
    margin: 0 0.5rem;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-switch-checked {
    background-color: #36c626;
  }

  table {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    thead {
      tr {
        th {
          font-size: 0.75rem;
          font-weight: 400;
          letter-spacing: 0.3px;
          background: #16273b !important;
          border-radius: 0 !important;
          color: white !important;
          text-align: center;
        }
        th:first-child {
          padding-right: 4px;
          text-align: end;
        }
        th:nth-child(2) {
          padding-left: 4px;
          width: 115px;
        }

        th:nth-child(7) {
          padding-left: 4px;
          width: 139px;
        }
        th:nth-child(6) {
          width: 100px;
        }
        th:nth-child(9) {
          width: 100px;
        }
      }
    }
    tbody {
      background: white;
      tr {
        td {
          font-size: 0.75rem;
          border-bottom-color: #f2f2f2 !important;
          background: #f8f8f8;
          text-align: center;
          input {
            font-size: 0.75rem;
            text-align: center;
          }
        }
        td:first-child {
          padding-right: 4px;
          text-align: end;
        }
        td:nth-child(2) {
          padding-left: 4px;
        }
        td:nth-child(6) {
          width: 100px;
        }
        td:nth-child(9) {
          width: 100px;
        }
      }
    }
  }
}
.editPrice .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-item {
  font-size: 0.75rem;
}

.flex-variants {
  display: flex;
  align-items: center;
  justify-content: center;
  .sp {
    margin-right: 4px;
  }
}
