.review-line-item {
  .ant-modal-content {
    padding: 10px 10px;
    border-radius: 3px;
    .ant-modal-header {
      padding: 16px 37px;
    }
  }

  .order_vfid span {
    color: #898989;
  }
  .modal-title {
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 0px;
      font-size: 16px;
      letter-spacing: 0.032em;
      line-height: 22px;
    }
  }
  .blue-text {
    color: #0068ff;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
    line-height: 16px;
    letter-spacing: 0.032em;
    p {
      font-size: 12px;
      color: black;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
  .line-items-tab {
    padding: 16px 8px 8px 16px;
    background: #fff;
    .line-item {
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid transparent;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-input {
        width: 80px;
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: none;
          width: 14px;
          height: 14px;
        }
      }
      .quantity-field {
        max-width: 65px;
        margin-right: 5px;
      }
      .anticon {
        font-size: 22px;
      }
      .line-item-product-detail {
        display: flex;
        align-items: center;
        .mr {
          margin-right: 8px;
        }
      }
      .line-item-img {
        width: 48px;
        height: 48px;
        margin-right: 0px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        // border: 1px solid #c8c8c8;
      }
      .line-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
      }
      .line-item-sku {
        font-size: 12px;
        color: #000;
        font-weight: 400;
        margin: 0;
        // margin-top: -2px;
      }
    }
    .line-item.selected {
      background: #eeffe5;
      border: 1px solid #73d13d;
    }
    .edit-quantity-btn {
      background: transparent;
      border: 1px solid #ff005e;
      border-radius: 4px;
      color: #ff005e;
    }
    .edit-quantity-btn:hover {
      background: #ff005e;
      border: 1px solid #ff005e;
      box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
      color: white;
    }
  }
  .shipments-tab {
    padding: 12px 13px;
    background: #fff;
    .no-shipment {
      text-align: center;
    }
    .shipment-header {
      background: #f2f2f2;
      padding: 28px 20px;
      margin-bottom: 5px;
      color: black;
    }
    .shipment-items {
      background: #eff5ff;
      padding: 5px 10px;
    }
    .shipment-item {
      background: transparent;
      padding: 16px 8px;
      display: flex;
      align-items: center;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: none;
        }
      }
      .anticon {
        font-size: 22px;
      }
      .shipment-item-product-detail {
        display: flex;
        align-items: center;
      }
      .shipment-item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
      }
      .shipment-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 15px;
      }
      .shipment-item-sku {
        font-size: 14px;
        color: #676666;
        font-weight: 400;
        margin: 0;
        margin-top: -2px;
      }
    }
    .shipment-item:nth-of-type(2n) {
      background: white;
    }
  }
  .quantity {
    width: 250px;
  }
}
