.media-modal-new {
    .ant-modal-body {
      position: relative;
    }
  
    .left,
    .right {
      position: absolute;
  
      svg {
        transform: scale(2);
        cursor: pointer;
      }
    }
  
    .left {
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  
    .right {
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  
    .img-slider {
      > div,
      > img,
      > video {
        object-fit: cover;
        min-height: fit-content;
        max-height: 500px;
        width: 100%;
        border: 1px solid #c4c4c4;
        cursor: pointer;
      }
    }
  
    .ant-modal-body {
      padding: 24px 4rem;
    }
  
    .img-slider {
      display: flex;
      align-items: center;
    }
  }