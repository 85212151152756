.inventory-list {
  .heading {
    .content-page-title {
      margin-bottom: 0;
      span {
        float: right;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }
    p {
      font-size: 16px;
      color: black;
    }
  }
  .inventory-filters {
    display: flex;
    justify-content: space-between;
    .store-filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 4.5px 5px 4.5px 17px;
      width: 290px;
      box-shadow: 0px 2px 8px #00000014;
      letter-spacing: 0 !important;
      background-color: white !important;
      p {
        margin-bottom: 0px;
        font-size: 12px;
        color: black;
        margin-right: 5px;
      }
      .filter_select {
        .ant-select-selection__placeholder {
          display: block !important;
          background-color: white !important;
        }
        .ant-select-selection__choice {
          display: none;
        }
      }
      .filter.ant-select {
        width: auto;
        color: #004eff;
        font-size: 12px;
        // font-weight: 600;
        .ant-select-selection {
          background-color: transparent;
          border: none;
          .ant-select-selection__rendered {
            line-height: 31px;
            .ant-select-selection__placeholder {
              color: #004eff;
            }
            ul {
              .ant-select-selection__choice {
                color: #004eff;
                .ant-select-selection__choice__remove:hover,
                .ant-select-selection__choice__remove {
                  color: rgb(2, 103, 251);
                }
              }
            }
          }
        }
        .ant-select-selection--single {
          height: 25px;
        }
        .ant-select-arrow {
          margin-top: -2px;
          color: #004eff;
        }
      }
      .ant-select-focused .ant-select-selection,
      .ant-select-selection:focus,
      .ant-select-selection:active {
        border-color: none;
        border-right-width: 0px !important;
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .ant-select-selection-selected-value {
    width: 70px;
  }
  .ant-select-arrow {
    margin-left: 15px;
  }

  .ant-collapse {
    border: none;
    box-shadow: 0px 3px 10px #00000010;
  }

  .ant-collapse:last-child {
    border: none;
    box-shadow: 0px 3px 10px #00000010;
    border-bottom-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    .ant-collapse-item {
      border-bottom-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      border-bottom: none !important;

      .ant-collapse-header {
        border-bottom-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        .container {
          border-bottom-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
          border-bottom: none !important;
        }
      }
    }
  }

  .ant-collapse-content-active {
    border: none;
  }

  .head {
    box-shadow: 0px 3px 10px #00000010 !important;
  }
}

.new-inventory-card {
  // display:none;
  letter-spacing: 0.032em;

  .svg-wraper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 4px;
    svg {
      display: block;
      fill: #fff;
      margin-bottom: -4px;
    }
  }

  .svg-wraper.desc {
    svg:nth-of-type(2) {
      fill: #fbc52d;
    }
  }

  .svg-wraper.asc {
    svg:nth-of-type(1) {
      fill: #fbc52d;
    }
  }

  .head {
    height: 40px;
    background: #16273b;
    margin: auto;
    margin-top: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 12px;
    color: white;

    .prod-details {
      flex-basis: 25%;
    }

    .live-inv {
      flex-basis: 12%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .pricing {
      flex-basis: 16%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .dist {
      flex-basis: 16%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .flexible {
      flex-basis: 10%;
      text-align: center;
    }

    .expand {
      flex-basis: 13%;
      text-align: right;
      opacity: 0;
    }

    .expand.custom {
      opacity: 1 !important;
      text-align: center;
    }
  }
}

.new-inventory-card-logs {
  // display:none;

  position: relative;
  letter-spacing: 0.032em;

  .head {
    width: 992px;
    height: 40px;
    background: #16273b;
    margin: auto;
    margin-top: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 12px;
    color: white;

    :nth-child(1) {
      width: 40%;
    }
    :nth-child(2) {
      width: 40%;
    }
    :nth-child(3) {
      width: 20%;
    }
  }
}

.new-inventory-rto-logs {
  // display:none;

  position: relative;
  letter-spacing: 0.032em;

  .head {
    width: 992px;
    height: 40px;
    background: #16273b;
    margin: auto;
    margin-top: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 12px;
    color: white;
    justify-content: space-between;

    :nth-child(1) {
      width: 376px;
    }
    :nth-child(2) {
      width: 120px;
      text-align: center;
    }
    :nth-child(3) {
      width: 56px;
    }
  }
}

.logs-btn {
  display: block;
  height: 40px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  width: 120px;
  font-weight: 500 !important;
  align-items: center;
  justify-content: center;
}

.logs-tab {
  .ant-pagination {
    margin-top: 20px;
  }
}

.logs-tab.rto {
  .ant-pagination {
    margin-top: 20px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
}

.new-inventory-rto-logs {
  .head {
    width: auto !important;
  }
}

.scroller {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

//working
.scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}
.scroller::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #898989;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.scroller::-webkit-scrollbar {
  width: 8px;

  border: 1px solid #898989;
}
// bottom counter
.bottom-fix-counter {
  height: 40px;
  background-color: #d8dade;
  margin: auto;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 12px;
  color: white;

  div {
    color: #212121;
    font-weight: 600;
    font-size: 14px;
  }

  .prod-details {
    flex-basis: 24.7%;
  }

  .live-inv {
    flex-basis: 12%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pricing {
    flex-basis: 16%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .dist {
    flex-basis: 16%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .flexible {
    flex-basis: 10%;
    text-align: center;
  }

  .expand {
    flex-basis: 12%;
    text-align: right;
    // opacity: 0;
  }
}

.bottom-fix-counter.bad {
  .prod-details {
    flex-basis: 25%;
  }

  .live-inv {
    flex-basis: 12%;
  }

  .pricing {
    flex-basis: 16%;
  }

  .dist {
    flex-basis: 16%;
  }

  .flexible {
    display: none;
  }

  .expand {
    flex-basis: 12%;

    // opacity: 0;
  }
}
