.right-container-signup1 {
  flex: 1;
  padding-bottom: 47px !important;
  .ant-tabs-nav-scroll {
    display: none;
  }
  .ant-tabs-bar{
    display: none;
  }
}
