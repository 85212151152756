.rfq-modal {
  top: 32px !important;
  * {
    letter-spacing: 0.032em;

    margin: 0;
  }
  .heading-varient {
    font-size: 12px !important;
    color: #898989 !important;
    line-height: 24px !important;
  }
  // .ant-form .ant-select-selection .landing-select {
  //   .ant-select-selection .ant-select-selection__rendered {
  //     height: 32px !important;
  //   }
  // }
  .product-name-wraper {
    position: relative;
    .external-product-wraper {
      position: absolute;
      background-color: #fff;
      z-index: 99;
      max-height: 200px;
      overflow-y: hidden;

      top: 65px;
      // border: 1px solid #bcbcbc;
      overflow: scroll;
      width: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

      .img-details.external {
        background-color: #f5f5f5;
        border-bottom: 1px solid #fff !important;
      }

      //

      // box-sizing: border-box;
      // font-size: 14px;
      // font-variant: initial;
      // background-color: #fff;
      // border-radius: 4px;
      // outline: none;
      // -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

      // overflow: hidden;
    }
  }

  .scroller {
    max-height: 469px !important;
  }

  .ant-form-explain {
    text-transform: capitalize !important;
  }

  .ant-modal-header {
    padding: 24px 0px 8px 0px !important;
    margin: 0px 24px;

    .ant-modal-title {
      h1 {
        font-size: 16px;
        font-weight: 600;
        color: #212121;
        margin-bottom: 6px;
      }

      h2 {
        font-size: 12px;
        font-weight: 400;
        color: #898989;
      }
    }
  }
  .qty-error {
    color: #f5222d;
    font-size: 11px;
  }
  .ant-modal-body {
    padding-top: 16px !important;
    padding-bottom: 0px !important;

    .img-details-wraper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right_svg {
        display: none;
      }
    }
    .varient-qty-container {
      margin-bottom: 16px !important;
    }
    .red-dot {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 12px;
      font-family: SimSun, sans-serif;
      line-height: 1;
    }
    .img-details-wraper-custom {
      .right_svg {
        display: none;
      }
    }
    // .img-details-wraper-custom:nth-of-type(odd) {
    //   background-color: #d1d9f2;
    // }
    .img-details {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-start;

      img {
        height: 32px !important;
        width: 32px;
        object-fit: contain;
        border: 1px solid #e8e8e8;
        margin-right: 6px;
      }
      .text-wraper {
        font-size: 12px;
        line-height: 16px;
        flex: 1;
        .allsep {
          font-size: 10px;
        }
      }
    }
    .external-product-wraper {
      .img-details.external {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        padding-left: 0px;
        border-bottom: 1px solid #e9e9e9;
        cursor: pointer;
        transition: 0.4s all;
      }

      .img-details.external {
        svg {
          fill: #5c88ff;
        }
      }

      .img-details.external:hover {
        * {
          font-weight: 500;
          transition: 0.4s all;
        }

        background-color: #f5f5f5;
      }
    }

    // uploader

    .ant-upload.ant-upload-btn {
      padding: 0px !important;
    }

    .preview-image-wraper {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll;

      img {
        height: 80px;
      }

      embed {
        height: 80px;
      }

      video {
        height: 80px;
      }

      iframe {
        height: 80px;
      }
    }

    .ant-upload.ant-upload-drag {
      border-radius: 0px;
      border: none;
      background-color: #f5f5f5;
      padding: 0px !important;
      height: 64px;

      .ant-upload-drag-container {
        color: #898989;
        font-size: 12px;
        background-color: #f5f5f5;
      }
    }

    // tabs
    .ant-tabs-bar {
      margin-bottom: 16px !important;
      border-bottom: 0px;
      .ant-tabs-nav-scroll {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > div {
          width: 100%;
        }

        .ant-tabs-nav.ant-tabs-nav-animated {
          > div {
            width: 100%;
          }
        }

        .ant-tabs-tab {
          padding: 8px 16px !important;
          width: 50%;
          margin: 0 !important;
          text-align: center;
          background-color: #f5f5f5 !important;
          font-size: 12px;
          color: #212121 !important;
        }

        .ant-tabs-tab-active.ant-tabs-tab {
          background-color: #5c88ff !important;
          color: #fff !important;
        }

        .ant-tabs-ink-bar {
          background-color: #5c88ff !important;
          transition: 0.3s all linear !important;
          display: none !important;
        }
      }
    }

    .ant-select-selection__choice {
      height: 32px;
      margin-top: 4px;
    }

    .product-select {
      .ant-select-selection.ant-select-selection--single {
        border-radius: 0px !important;
        background-color: #f5f5f5 !important;
        height: 40px;
      }

      .ant-select-selection__placeholder {
        padding-left: 12px !important;
      }
      input {
        padding-left: 12px !important;
      }
    }

    .product-variants {
      .ant-select-selection__placeholder {
        margin-left: 0px;
        padding-left: 12px;
      }
      input {
        margin-left: 6px !important;
      }
    }

    // form
    .ant-form {
      .ant-select-selection__clear {
        top: 35% !important;
        background: transparent !important;
      }

      #expected_landing_date {
        width: 100% !important;
      }

      .ant-calendar-picker-clear {
        top: 25% !important;
        background: #f5f5f5 !important;
        border-radius: 50%;
      }
      .ant-calendar-picker-icon {
        top: 25% !important;
      }

      .ant-form-explain {
        font-size: 11px !important;
      }

      .ant-row.ant-form-item {
        margin-bottom: 16px !important;
      }
      .ant-row.varient-item {
        margin-bottom: 0px !important;
        margin-left: 20px;
        width: 50%;
      }

      .ant-input-group-addon {
        height: 32px !important;
        border-radius: 0px !important;
        background-color: #ededed !important;
        border: none !important;
        font-size: 12px !important;
      }

      input {
        border-radius: 0px !important;
        background-color: #f5f5f5 !important;
        border: none !important;
        font-size: 12px !important;
      }

      input::placeholder {
        color: #898989 !important;
      }

      textarea::placeholder {
        color: #898989 !important;
      }

      .ant-input:placeholder-shown {
        font-size: 12px !important;
      }

      //   ant select

      .ant-select-selection-selected-value {
        padding-left: 12px !important;
        margin-top: 4px;
        font-size: 12px !important;
      }
      .ant-select-selection {
        border: none !important;

        .ant-select-selection.ant-select-selection--single {
          height: 40px !important;
        }

        .ant-select-selection__rendered {
          height: 40px !important;
          border-radius: 0px !important;
          background-color: #f5f5f5 !important;
          border: none !important;
          margin-left: 0px !important;

          svg {
            fill: #5c88ff;
          }

          input {
            background-color: transparent !important;
          }
        }

        .ant-select-selection__placeholder {
          top: 22% !important;
          font-size: 12px !important;
          color: #898989 !important;
          padding-left: 9px;
        }
      }

      textarea {
        border-radius: 0px !important;
        background-color: #f5f5f5 !important;
        border: none !important;
        font-size: 12px;
      }

      .private-label-radio-group {
        display: flex;
        align-items: center;

        label {
          span:nth-of-type(2) {
            font-size: 12px;
            color: #898989;
          }
        }

        .ant-radio-wrapper-checked {
          .ant-radio-inner {
            border-color: #2bc822 !important;
          }

          .ant-radio-inner::after {
            background: #2bc822 !important;
          }
        }
      }

      .footer-button {
        border-top: 1px solid #e8e8e8;
        padding-top: 6px;

        .ant-form-item-children {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-bottom: 12px;

          button {
            padding: 0px 50px;
            box-shadow: 0px 2px 3px #00000016;
          }
          .cancelBtn {
            box-shadow: none;
          }

          button:last-of-type {
            background-color: #2bc822;
            border-color: #2bc822;
          }
        }
      }
      .ant-form-item-label {
        line-height: 24px !important;

        label {
          font-size: 12px !important;
          color: #898989 !important;
        }
      }
    }
  }
}

.product-overlay-wraper {
  li {
    border-bottom: 1px solid #fff;
  }

  .img-details-wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right_svg {
      fill: #5c88ff;
    }
  }

  .img-details {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;

    img {
      height: 32px !important;
      width: 32px;
      object-fit: contain;
      border: 1px solid #e8e8e8;
      margin-right: 6px;
    }

    .text-wraper {
      font-size: 12px !important;
      line-height: 14px;
      flex: 1;
    }
  }
}
.rfq-modal
  .ant-modal-body
  .ant-form
  .landing-select
  .ant-select-selection
  .ant-select-selection__rendered {
  height: 32px !important;
  margin-right: 0px !important;
}
.rfq-modal
  .ant-modal-body
  .ant-form
  .landing-select
  .ant-select-selection
  .ant-select-arrow {
  top: 35%;
}
