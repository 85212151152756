.overdue-modal {
  * {
    letter-spacing: 0.032em;
  }

  .ant-modal-header {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 24px;
    margin-right: 24px;

    .ant-modal-title {
      h1 {
        margin: 0;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
        color: #212121;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }

      p {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #ff3434;
      }
    }
  }

  .ant-modal-body {
    padding-top: 12px !important;
    p {
      font-size: 12px !important;
      color: #212121 !important;
      b {
        font-weight: 500;
      }
    }

    button {
      margin-top: 8px;
      height: 40px !important;
    }

    .ant-btn-primary {
      margin-top: 16px !important;
      background-color: #2c9545 !important;
      border: 1px solid #2c9545;
      span {
        font-weight: 500 !important;
        font-size: 14px;
      }
    }

    .ant-btn-cancel {
      border: 1px solid #212121;
      span {
        color: #212121 !important;
        font-weight: 500 !important;
        font-size: 14px;
      }
    }
  }
}
