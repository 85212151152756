.ndr-modal {
  .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000;
  }

  .no-top-margin {
    margin-top: 0rem;
  }

  input,
  textarea {
    border: 1.5px solid #979797;
    border-radius: 4px;
  }
}
