.product-categories-card {
  margin-top: 26px;
  .ant-card-body {
    padding: 0px;
    .product-categories {
      display: flex;
      max-height: 16rem;
      .selected-niche {
        padding: 1.5rem;
        background: #eef4fb;
        color: black;
        width: 15rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ant-btn.ant-btn-link span + .anticon {
          margin-left: 0.25rem;
        }
        .niche-info {
          // display: flex;
          // align-items: center;
          // justify-content: center !important;
          color: #0068ff;
          margin-bottom: 0.5rem;

          p {
            font-size: 0.875rem;
            color: #0068ff;
            font-weight: 500;
            margin: 0.25rem 0 0 0;
          }
        }
        p {
          font-size: 0.75rem;
          font-weight: 400;
          color: black;
          margin-bottom: 0px;
        }
        label.ant-checkbox-wrapper {
          margin-top: 13px;
          margin-bottom: 13px;
          font-size: 0.75rem;
          color: black;
        }
      }
    }
    .niche-category {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      padding: 1.5rem;
      overflow: auto;
      white-space: nowrap;
      .category-name {
        display: flex;
        width: 16rem;
        margin-left: 0px;
        margin-bottom: 0.625rem;
        font-size: 0.75rem;
        span.ant-checkbox {
          margin-right: 0;
        }
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2ee31b;
    border-color: #2ee31b;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #2ee31b;
  }
}
