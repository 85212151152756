@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import "./global.scss";
* {
  font-family: "Poppins", "Chinese Quote", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

:root {
  --normal-letter-spacing: 0.032em;
  --small-font-size: 12px;
  --extra-small-font-size: 10px;
  --extra-medium-small-font-size: 14px;
  --medium-font-size: 16px;
  --extra-medium-font-size: 20px;
  --max-font-size: 24px;
  --small-line-height: 16px;
  --max-line-height: 24px;
  --medium-line-height: 20px; // it is apply for 16px font size. and more then one line
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-size: unset !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1 {
  font-size: 2em;
}

#root {
  height: 100%;
}

.content-body {
  height: 100%;
  overflow-y: hidden;
}

.bold {
  font-weight: bold;
}

.ant-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important;
}

a {
  text-decoration: none !important;
}

.content-page-title {
  font-size: 1.3125rem;
  font-weight: 400;
  margin-bottom: 0px;
}

.content-page-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0px;
}

.content-page-subtitle-right {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0;
}

.ant-dropdown-menu {
  padding: 0 !important;
  .ant-dropdown-menu-item-divider {
    margin: 0;
  }
}

// @media screen and (min-width: 320px) {
//   html {
//     font-size: calc(12px + 4 * ((100vw - 320px) / 961));
//   }
// }
// @media screen and (min-width: 1281px) {
//   html {
//     font-size: 16px;
//   }
// }
// .copied-id svg g path {
//     fill: #1370fc !important;
// }
.import-product-search {
  .ant-input-group .ant-input {
    height: 400px;
    box-shadow: 0px 2px 8px #00000014;
    border-radius: 2px 0 0 2px;
    border: 0px;

    input {
      height: 40px;
    }
  }
  input {
    height: 40px;
  }
}

// pagination style across all pages 1440px
.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .ant-pagination-total-text {
    min-width: fit-content;
    position: absolute;
    font-size: 12px;
    right: 120px;
    color: #898989;
  }

  .ant-pagination-item {
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #ffffff;
    box-shadow: 0 3px 8px #00000016;
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    a {
      color: #212121;
      font-size: 12px;
    }
  }

  .ant-pagination-jump-next-custom-icon {
    margin-right: 0px;
  }

  .ant-pagination-item-before-jump-next {
    margin-right: 0px;
  }

  .ant-pagination-prev {
    border-radius: 2px;
    border: 2px solid #ffffff;

    .ant-pagination-item-link {
      border-radius: 0px;
      border: 0px;
    }
  }

  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 2px;
      border: 0px;
    }
  }

  .ant-pagination-item:hover {
    background-color: #f5faff;

    border: 1px solid #1268fb;

    a {
      color: #212121;
      border: 0px solid #1268fb;

      border-radius: 0px;
    }
  }

  .ant-pagination-item-active {
    background-color: #1268fb;
    border-radius: 2px;
    border: 1px solid #1268fb;
    a {
      color: #fff;
      border-radius: 0px;
    }
  }
  .ant-pagination-item-active:hover {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px;
    a {
      color: #fff;
    }
  }

  .ant-pagination-item-active:focus {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px;
    a {
      color: #fff;
    }
  }

  .ant-pagination-options {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 48px;
    justify-content: flex-end;
    .ant-pagination-options-size-changer {
      order: 2;
      margin-right: 0px;
      min-width: 103px;

      .ant-select-selection {
        border-radius: 2px;
        border: 0px solid #ffffff;

        .ant-select-selection-selected-value {
          color: #212121;
          font-size: 12px;
          width: 85px;
        }
      }
    }

    .ant-pagination-options-quick-jumper {
      position: absolute;
      left: 0px;
      font-size: 12px;
      color: #898989;
      input {
        border-radius: 2px;
        border: 1px solid #ffffff;
        color: #212121;
        height: 32px;
        width: 36px;
        padding: 0;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1438px) {
  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .ant-pagination-total-text {
      min-width: fit-content;
      position: absolute;
      font-size: 12px;
      right: 120px;
      color: #898989;
    }

    .ant-pagination-item {
      border: 0 !important;
      border-radius: 2px;
      font-size: 12px;
      // border: 1px solid #ffffff;
      display: flex;
      height: 24px;
      width: 24px !important;
      align-items: center;
      min-width: 24px !important;

      a {
        color: #212121;
        font-size: 12px;
        line-height: 24px;
        height: 24px !important;
        width: 24px !important;
        min-width: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-pagination-jump-next-custom-icon {
      margin-right: 0px;
    }

    .ant-pagination-item-before-jump-next {
      margin-right: 0px;
    }

    .ant-pagination-prev {
      border: 0 !important;
      border-radius: 2px;
      font-size: 12px;
      // border: 1px solid #ffffff;
      display: flex;
      height: 24px;
      width: 24px !important;
      align-items: center;
      min-width: 24px !important;

      .ant-pagination-item-link {
        color: #212121;
        font-size: 12px;
        line-height: 24px;
        height: 24px !important;
        width: 24px !important;
        min-width: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-pagination-next {
      border: 0 !important;
      border-radius: 2px;
      font-size: 12px;
      // border: 1px solid #ffffff;
      display: flex;
      height: 24px;
      width: 24px !important;
      align-items: center;
      min-width: 24px !important;
      .ant-pagination-item-link {
        color: #212121;
        font-size: 12px;
        line-height: 24px;
        height: 24px !important;
        width: 24px !important;
        min-width: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ant-pagination-item:hover {
      background-color: #f5faff;
      border-radius: 2px;

      a {
        color: #212121;
        border: 1px solid #4398ed;
      }
    }

    .ant-pagination-item-active {
      background-color: #1268fb;
      border-radius: 2px;
      border: 1px solid #1268fb;
      a {
        color: #fff;
        border-radius: 0px;
      }
    }
    .ant-pagination-item-active:hover {
      background-color: #1268fb;
      border: 1px solid #1268fb;
      border-radius: 2px;
      a {
        color: #fff;
      }
    }

    .ant-pagination-item-active:focus {
      background-color: #1268fb;

      border-radius: 2px;
      a {
        color: #fff;
      }
    }

    .ant-pagination-options {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      margin-left: 48px;
      justify-content: flex-end;
      height: 24px;
      .ant-pagination-options-size-changer {
        order: 2;
        margin-right: 0px;
        min-width: 103px;
        border: 0 !important;
        border-radius: 2px;
        font-size: 12px;
        // border: 1px solid #ffffff;
        display: flex;
        height: 24px;

        align-items: center;

        .ant-select-selection {
          border-radius: 2px;
          border: 0px solid #ffffff;
          height: 24px;
          .ant-select-selection-selected-value {
            color: #212121;
            font-size: 12px;
            height: 24px;
            line-height: 24px;
          }
        }
      }

      .ant-pagination-options-quick-jumper {
        position: absolute;
        left: 0px;
        font-size: 12px;
        color: #898989;
        input {
          border-radius: 2px;
          border: 1px solid #ffffff;
          color: #212121;
          height: 24px;
          width: 32px;
        }
      }
    }
  }
}

// reporting charts
.recharts-surface {
  overflow: visible !important;
}

#fade-in {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alert-wrap-reporting {
  position: relative;
  .ex-ic {
    position: absolute;
    top: 17px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    display: inline-block;
    color: rgb(0, 104, 255);
    left: 25px;
  }
  .anticon-info-circle {
    visibility: hidden;
  }
}

// table column title with icon
.table-title-icon {
  display: flex;
  align-items: center;
  justify-content: center !important;
  position: relative;
  z-index: 9999;
  > span {
    min-width: 24px !important;
  }
}

// top performing products
.table-title-icon.custom {
  span {
    // g {
    //   fill: #212121;
    // }
  }
}
.ant-message {
  z-index: 9999999999999 !important;
}
