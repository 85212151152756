.variant-link-view-right-varaint-image-data {
  width: 50%;
  // display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 2px;

  // height: 64px;
  // margin-bottom: 80px;

  .image-allspec-data {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    img {
      height: 48px;
      width: 48px;
      margin-right: 16px;
      border: 1px solid #979797;
      padding: 2px;
    }
  }

  .variant-link-view-allspec-dropdown {
    // padding: 8px 16px !important;
    letter-spacing: 0.032em;
    background: #f5f5f5;
    margin-bottom: 8px;
  }

  .option-varaint.allspecs-product-name {
    margin-bottom: -5px;
  }

  .varaint-name-spac {
    font-size: 12px;
    color: #1161fb;
    line-height: 16px;
  }

  .allspecs-product-name-id {
    color: #898989;
    .vfsku-id {
      color: #000;
    }
  }
  .image-allspec-data-notlinlk {
    display: flex;
    margin-top: 16px;
    align-items: center;
    background: #fff7f5;
    padding: 8px;
    border: 1px solid #fd4726;
    .redalert-ican {
      margin-right: 8px;
      font-size: 24px;
    }
    .notlink-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
    }
    .notlink-allspecs-product-name {
      color: #212121;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      .sku-txt {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .vfsku-id {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
    }
  }

  .image-allspec-bundle-notlinlk {
    display: flex;
    margin-top: 16px;
    align-items: center;
    background: #fff7f5;
    padding: 8px;
    border: 1px solid #fd4726;
    margin-top: -3px;
    .redalert-ican {
      margin-right: 8px;
      font-size: 24px;
    }
    .notlink-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
    }
    .notlink-allspecs-product-name {
      color: #212121;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      .sku-txt {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .vfsku-id {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
    }
  }
}
