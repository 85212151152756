.switch-store-modal3 {
  .ant-modal-header {
    padding: 16px 8px !important;
    border-bottom: 1px solid #cecece !important;
    display: flex;
    margin: 0 auto;
    width: 580px;
  }
  .title_txt {
    font-size: 14px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 500;
  }
  .ant-modal-close-x {
    display: none;
  }
}
