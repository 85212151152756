.view-remark-tab {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .flex {
    margin-bottom: 5px;
    margin: 0 0px 8px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    font-size: 12px;
    color: #898989;
    .flex-remarks {
      width: 30%;
    }

    .flex-bucket {
      width: 30%;
    }

    .flex-remarkby {
      width: 12%;
    }
    .flex-logged {
      width: 20%;
    }
  }
  .thead {
    display: flex;
    margin-bottom: 8px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    padding: 0 16px;
    justify-content: space-between;
    .flex-remarks {
      width: 30%;
    }

    .flex-bucket {
      width: 30%;
    }

    .flex-remarkby {
      width: 12%;
    }
    .flex-logged {
      width: 20%;
    }
  }
}
