.wallet-transactions {
  margin-top: -32px;
  .ant-cascader-picker {
    width: 300px;
  }

  .ant-cascader-picker-label {
    text-align: left;
  }
  button.ant-btn.download-transaction-btn {
    background: #36c626;
    border-color: #36c626;
    color: white;
  }
  .transaction-rangepicker {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
  .transaction-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-select {
      width: 178px;
    }
    p {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }
  .green-text {
    color: #229d1b;
    font-weight: 500;
  }
  .red-text {
    color: #c70c3c;
    font-weight: 500;
  }
  .orange-text {
    color: #ff9800;
    font-weight: 500;
  }
  .blue-text {
    color: #007fff;
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #0068ff;
    font-size: 12px;
    margin-left: 8px !important;
  }
  .ant-table-thead > tr > th .ant-table-header-column {
    display: inline-block;
    vertical-align: top;
    width: 109px;
  }

  .ant-table-thead > tr > th:nth-child(4) .ant-table-header-column {
    width: 175px !important;
  }
  .ant-table-thead > tr > th:nth-child(7) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-thead > tr > th:nth-child(6) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-thead > tr > th:nth-child(8) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-thead > tr > th:nth-child(9) .ant-table-header-column {
    width: 70px !important;
  }
  .ant-table-layout-fixed table {
    table-layout: unset !important;
  }
  .content-page-text {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 32px;
    margin-bottom: -32px;
    margin-top: 24px;
    color: #000000;
  }
  .Transaction_History {
    position: relative;
    .ant-table-body {
      max-height: 368px !important;
      border-radius: 4px;
      overflow: visible;
    }
    // ::-webkit-scrollbar {
    //   -webkit-appearance: none;
    //   width: 8px;
    //   height: 8px !important;
    // }

    // ::-webkit-scrollbar-thumb {
    //   border-radius: 5px;
    //   background-color: rgba(0, 0, 0, 0.5);
    //   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    // }
    .line {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 10px;
      background: #16273b;
      border-top-right-radius: 4px;
    }
  }
  .transaction-history-length {
    position: relative;
    .ant-table-body {
      height: 368px !important;
      border-radius: 4px;
      overflow: visible;
    }
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .ant-table-pagination.ant-pagination {
    float: right;
    margin: 16px 16px !important;
  }

  .ant-table-tbody > tr > td {
    border: none !important;
    color: #595959;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px;
  }

  .ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
    position: absolute !important;
    top: -205px !important;
    left: 44% !important;
  }

  .gst-deductions-on-deliver {
    .ant-tooltip-inner {
      text-align: center !important;
      letter-spacing: 0.032em;
      line-height: 16px;
      font-size: 12px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000bf;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 99;
  bottom: 146%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  top: -43px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000bf transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.wallet-tabs {
  .ant-pagination .ant-pagination-item {
    min-width: min-content !important;
  }
}

.new-storage-charges {
  .heads {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      flex: 1;

      color: #898989;
      font-weight: 400;
      font-size: 12px;
      padding: 16px;
      white-space: normal;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  .heads.values {
    border: 1px solid #c0c0c0;
    border-right: 0px;
    > div {
      border-right: 2px solid #c0c0c0;
      padding: 16px 16px 16px 16px;
      color: #212121;
      text-transform: capitalize;

      font-size: 16px;
    }
  }
}
.new-ref-cell {
  margin-top: -15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bn {
  justify-content: end !important;
  .mg {
    margin-right: 30px !important;
  }
}
