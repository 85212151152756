.card-footer {
  .ant-divider {
    margin: 0 !important;
    background-color: #898989 !important;
  }

  .total-amount {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px;

    h1 {
      font-size: 14px;
      color: #898989;
    }

    h2 {
      color: #212121;
      font-size: 20px;
      font-weight: 600;

      .custom-price-strike {
        h3 {
          margin: 0;
          color: green;
          font-weight: 600;
          font-size: 18px;
        }
        p {
          text-decoration: line-through;
          color: #898989;
          font-size: 14px;
        }
      }
    }
  }

  .terms {
    text-align: center;
    font-size: 12px;
    color: #898989;
    margin-top: 12px;
    a {
      color: #5171f8;
      text-decoration: underline;
    }
  }
}
