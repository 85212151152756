.global-tooltip-w {
  display: flex;
  align-items: center;
  justify-content: center;
}
.global-tooltip-icon-w {
  font-size: 16px;
  margin: 0px 8px !important;
}
.global-tooltip-w:hover .global-tooltip-icon-w {
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  svg {
    g {
      stroke: #ffffff;
    }

    :last-child {
      g {
        stroke: #26b99a;
      }
    }
  }
}
.global-tooltip-w:hover .global-tooltip-icon-w g text {
  fill: #fff;
}
.global-tooltip-w:hover .global-tooltip-icon-w g > g {
  stroke: #ffffff;
}

.field-value {
  font-size: 12px;
  position: relative;
  top: 5px;
  .iconCantainer {
    margin-left: 2px;
    position: relative;
  }
}
.field-value:hover {
  svg {
    #Ellipse_44 {
      stroke: #1268fb;
      fill: #1268fb;
    }
    #Ellipse_47 {
      stroke: #fff;
      fill: #ffff;
    }
    #Rectangle_2462 {
      stroke: #fff;
      fill: #ffff;
    }
  }
}
.field-value1 {
  font-size: 12px;
  position: relative;
  top: 9px;
}

.edd-test.custom {
  .edd-log-text {
    display: flex !important;
    flex-direction: column !important;
  }
}
