.product-override-modal {
    width: 800px !important;
    max-width: 100%;

    .input-label {
        font-weight: 700;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    .checkbox-container {
        margin-top: 3rem;

        .ant-checkbox-wrapper {
            font-weight: 500;
        }
    }
    .sub-text {
        font-weight: 400;
        font-size: 0.85rem;
    }
}