.steps-modal-let {
  * {
    letter-spacing: 0.03em;
  }

  .ant-modal-content {
    border-radius: 2px;
  }

  .ant-modal-body {
    padding: 36px 36px;
    position: relative;

    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }

    .top-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.56rem;

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
          font-size: 24px;
          font-weight: 600;
          text-transform: capitalize;
          margin-bottom: 0.21rem;

          img {
            max-width: 100%;
            width: 1.786rem;
            height: 1.786rem;
            margin-right: 0.25rem;
          }
        }

        .sub-title {
          font-size: 16px;
          font-weight: 400;
          color: #8c8c8c;
          margin-block-end: 0.71rem;
        }

        .steps-ratio {
          border-radius: 3px;
          background: rgba(54, 198, 38, 0.33);
          height: 3rem;
          width: 16.86rem;
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 16px;
          color: #020429;
          font-weight: 600;
        }
      }

      .right {
        svg {
          width: 12rem;
          height: 8rem;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .list-div {
      .step {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 24px;

        svg {
          width: 1.71rem;
          height: 1.71rem;
          margin-right: 1.43rem;
        }

        .number {
          font-size: 1.143rem;
          color: #9c9c9c;
          border: 2px solid #9c9c9c;
          border-radius: 50%;
          height: 1.71rem;
          width: 1.71rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1.43rem;
        }

        .text {
          .heading {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0.071rem;
            color: #36c626;

            svg {
              width: 1.286rem;
              height: 1.286rem;
              vertical-align: text-bottom;
              margin-right: 0.286rem;
              display: none;
            }
          }

          .content {
            font-size: 12px;
            font-weight: 400;
            color: #8c8c8c;
          }
        }
      }
      .step:last-of-type {
        .text .content {
          margin-bottom: 0;
        }
      }
      .step.incomplete {
        .text .heading {
          color: #8c8c8c;
        }
        .text .heading:hover {
          color: #36c525;

          svg {
            display: inline-block;
          }
        }
      }
      .step.first-incomplete {
        .text .heading,
        .number {
          color: #fff;
          background: #36c525;
          border-color: #36c525;
        }
        .text .heading {
          border-radius: 3px;
          padding: 0.2rem 0.5rem;

          svg * {
            fill: #fff;
          }
        }
        .text .heading:hover {
          svg {
            display: inline-block;
          }
        }
      }

      .first-incomplete {
        .content {
          margin-top: 6px;
        }
      }
    }
  }
}
