.right-container-signup {
  flex: 1;
  display: block;
  width: 100%;
  padding-bottom: 47px !important;
  .ant-tabs-nav-scroll {
    display: none;
  }
  .ant-tabs-bar {
    display: none;
  }
}
