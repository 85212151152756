.rasing-bulk-order-modal {
  * {
    box-sizing: border-box;
    letter-spacing: 0.032em;
    margin: 0;
    padding: 0;
  }
  .ant-modal-close-x {
    display: none;
  }
  p {
    font-size: 12px;
    color: #898989;
  }

  h1 {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 5px;
  }

  .ant-modal-header {
    padding-top: 24px;
    margin: 0 24px;

    p {
      margin-bottom: 12px;
    }
  }

  .ant-modal-footer {
    margin: 0 24px;
    padding-bottom: 26px;
    padding-top: 16px;

    display: flex;

    .note {
      width: 60px;

      p:first-of-type {
        margin-bottom: 5px;
      }
      p {
        text-align: left;
        font-size: 10px;
        font-style: italic;
      }
    }

    .note-desc {
      p:first-of-type {
        margin-bottom: 5px;
      }
      p {
        text-align: left;
        font-size: 10px;
        font-style: italic;
      }
    }
    .btns {
      width: 700px;
      display: flex;
      justify-content: end;
      align-items: center;

      border-radius: 2px;
      button:first-of-type {
        width: 160px;
      }
      button {
        font-size: 12px;
        font-weight: 500;
        width: 208px;
        height: 40px;
        border-radius: 2px !important;
      }
    }
  }

  .ant-modal-body {
    padding: 24px;
    padding-top: 16px;
    font-size: 12px;

    .content-headings {
      padding: 12px 16px;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        font-style: italic;
      }

      .content-head-1 {
        font-style: normal;
        span {
          margin-left: 4px;
          color: #0068ff;
        }
      }

      .content-head-2 {
        p {
          margin-right: 4px;
        }

        i {
          font-size: 16px;
          margin-left: 8px;
        }
      }

      .content-head-3 {
        i {
          font-size: 16px;
          margin-left: 4px;
        }
      }
    }

    // .variants-headings
    .variants-headings {
      padding: 0px 16px 4px 16px;
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .head-1 {
        width: 380px !important;
        justify-content: flex-start;
      }

      .head-2 {
        width: 100px;
      }

      .head-3 {
        width: 100px;
      }
      .head-4 {
        width: 100px;
      }
    }

    // variants-data-row
    .variants-data-row {
      padding: 8px 16px;
      display: flex;
      height: 72px;
      background-color: #f5f5f5;
      margin-bottom: 8px;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .head-1 {
        width: 380px !important;
        justify-content: flex-start;

        .details {
          flex-direction: column;
          align-items: flex-start;
          div {
          }
          p {
            color: #898989;
            margin-right: 4px;
          }
          span {
            color: #212121;
          }
        }

        .img {
          height: 56px;
          width: 56px;
          margin-right: 16px;
          img {
            object-fit: cover;
            height: 100%;
            width: inherit;
          }
        }
      }

      .head-2 {
        width: 100px;
      }

      .head-3 {
        width: 100px;
      }
      .head-4 {
        width: 100px;
      }
    }

    // variants-total-pricing
    .variants-total-pricing {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      height: 48px;
      background-color: #f5f5f5;

      .included {
        font-style: italic;
        color: #898989;
        font-size: 12px;
      }

      .total-price {
        display: flex;
        justify-content: end;
        align-items: center;
        .gst {
          font-size: 12px;
          color: #898989;
        }
        .price {
          width: 100px;
          margin-left: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #212121;
        }
      }
    }
  }

  .product-tooltip:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer;
  }
  .product-tooltip:hover svg.product-tooltip-icon g text {
    fill: #fff;
  }
  .product-tooltip:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb;
  }
}
