.signup-container-new {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#536be8, #3d2fd8, #3d2fd8, #6c48e2);
  position: relative;
  .mainCard-signup {
    z-index: 999;
    width: 900px;
    // min-height: 592px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 6px 8px 48px rgba(0, 0, 0, 0.39);
    display: flex;
  }
  .shadow-container {
    position: absolute;
    top: -247px;
    left: -63px;
  }
}
