.not-found-wraper {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 3px 10px #0000000d;
  * {
    letter-spacing: 0.032em !important;
    color: #898989;
    font-size: 16px;
  }
  background-color: #fff;
  //   height: 264px;
  padding-top: 20px;
  padding-bottom: 52px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    height: 200px;
    width: 200px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 4px;
  }

  p {
    font-size: 12px;
    line-height: 16px;

    span {
      text-decoration: underline;
      font-style: italic;
      font-size: 12px;
    }
  }
}
