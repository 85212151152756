.click-id-badge {
  cursor: pointer;
  height: 24px;
  padding: 0 10px;
  background: #1161fb;
  color: white;
  border-bottom-left-radius: 0;
  font-size: 12px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  .anticon {
    margin-right: 0px !important;
  }

  svg {
    fill: #fff;
    margin-left: 8px;
    transition: all 2s ease-in-out;
    font-size: 12px;
    margin-right: 0px !important;

    g {
      stroke: #fff;
      animation: fillAnimation2 0.5s ease-in-out forwards;
      path {
        stroke: #fff;
      }
    }
  }
}

.click-id-badge:hover {
  svg {
    fill: #fff;
    margin-left: 8px;
    transition: all 2s ease-in-out;
    g {
      stroke: #fff;
      fill: #fff;
      animation: fillAnimation 0.5s ease-in-out forwards;

      path {
        stroke: #fff;
      }
    }
  }
}

@keyframes fillAnimation {
  0% {
    fill: #1161fb;
  }
  100% {
    fill: #fff;
  }
}

@keyframes fillAnimation2 {
  0% {
    fill: #fff;
  }
  100% {
    fill: #1161fb;
  }
}
