.my-orders {
  .mav-filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;
    .mav-filter-wraper {
      margin-right: 15px;
      margin-bottom: 24px;
    }
  }

  .bulk-list-wraper {
    .detailswrp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      > p {
        font-size: 12px;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 500;
          margin: 0px 6px;
        }
      }

      margin-top: 8px;
      .right {
        margin-left: 16px;

        .err {
          border-color: red !important;
          background-color: transparent !important;
          color: red !important;
        }
      }
    }

    .lst-wraper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 12px;

      .left {
        transition: all 1s linear;
        background: #e6eaee;
        width: 100%;
        padding: 8px 12px 0px 12px;
        border-radius: 4px;
        border: 1px solid #b2b2b2;
      }

      .ant-tag {
        margin-bottom: 8px;
        border-radius: 2px;
        padding: 2px 6px;
        border-color: #b5daff;
        background-color: #f5faff;
      }

      .left::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      .left::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #898989;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      .left::-webkit-scrollbar {
        width: 8px;
        border: 1px solid #898989;
      }

      .left {
        display: flex;
        align-items: center;
        min-height: 20px;
        max-height: 80px;
        overflow-y: scroll;
        flex-wrap: wrap;

        p {
          margin-right: 8px;
        }
      }
      .right {
        margin-bottom: 12px;
      }
    }
  }

  .filter {
    .ant-select-selection {
      border-radius: 0;
      padding-left: 10px;
    }
  }
  .pick {
    height: 41px !important;
    .ant-calendar-picker-input {
      height: 40px;
    }
  }
  .fill {
    .active {
      color: #1268fb;
      border-bottom: 2px #1268fb solid;
    }
  }
  .fill2 {
    .active {
      color: #1268fb;
      border-bottom: 2px #1268fb solid;
    }
  }
  // .active {
  //   background-color: blue;
  // }
  .se {
    input {
      height: 40px;
    }
  }
  .ss {
    .ant-select-selection {
      border-radius: 0 !important;
    }
    .ant-select-selection__rendered {
      border-radius: 0;
    }
  }
  .drop {
    background-color: #f8f8f8 !important;

    // padding: 4.5px 5px 4.5px 17px;
    // width: 290px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;

    // margin: auto;
    .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important;
    }
    .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb;
      .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
      }
    }
    .ant-select-selection {
      width: 159px;
      border: none !important;
      background: #f8f8f8 !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    .ant-select-arrow {
      top: 53%;
      left: 159% !important;
    }
  }
  // .ant-checkbox-inner {
  //   // background-color: #fff;
  //   border: 2px solid #e3e3e3;
  //   border-radius: 2px;
  // }
  .ant-checkbox-inner::after {
    left: 20%;
  }
  .ant-btn-group-lg > .ant-btn,
  .ant-btn-group-lg > span > .ant-btn {
    height: 32px !important;
    padding: 0 8px !important;
    font-size: 12px !important;
    border-radius: 0 !important;
    line-height: 22px !important;
    font-weight: 500;
    letter-spacing: 0.032em;
  }
}

.button-row {
  margin-top: 20px;
  span {
    font-size: 12px;
    color: #6e6e6e;
  }
  button {
    background: none;
    border: none;
    color: #6e6e6e;
    margin-left: 30px;
    font-size: 12px;
    cursor: pointer;
  }
}

.ant-select-arrow {
  margin-top: -4px;
}

// .import-product-search .ant-input-group {
//     width: 510px;
// }
// .ant-input:hover {
//     background-color: white !important;
// }
.mo {
  .ant-select-selection {
    .ant-select-selection-selected-value {
      width: 100px;
    }
    background-color: #f8f8f8;
    :hover {
      background-color: white !important;
    }
  }
}

@media screen and (min-width: 180px) and (max-width: 1439px) {
  .drop {
    background-color: #f8f8f8 !important;
    // padding: 4.5px 5px 4.5px 17px;
    // width: 290px;
    // margin-left: 8px !important;

    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;
    // margin: auto;
    .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important;
    }
    .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb;
      .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
      }
    }
    .ant-select-selection {
      width: 159px;
      border: none !important;
      background: #f8f8f8 !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb;
        .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px;
        }
      }
    }
    .ant-select-arrow {
      top: 53%;
      left: 159% !important;
    }
  }
}

// @media screen and (min-width: 1440px) and (max-width: 3200px) {
//     .my-orders {
//         position: absolute;
//         width: calc(100% - 240px);
//         top: 0;
//         // left: 200px;
//         z-index: 99999999;
//     }
// }

.custom-feedback {
  @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@600&display=swap");

  background-color: #ffffff;
  padding: 20px 32px;
  width: 300px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  border-radius: 0px !important;

  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-close {
    right: 24px;
    top: 21px;

    svg {
      path {
        fill: #898989;
      }
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-family: "Poppins", sans-serif;
  }
  .svg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  h1 {
    font-family: "IBM Plex Serif", serif !important;
    font-size: 20px;
    font-weight: 600;
    margin-left: 46px;
    margin-right: 46px;
    text-align: center;
    margin: auto;
    line-height: 26px;
    margin-bottom: 9px;
    letter-spacing: 0 !important;
  }

  h2 {
    color: #383838;
    text-align: center;
    font-size: 12px;
    font-weight: 400;

    a {
      color: #0068ff;
      cursor: pointer;
      text-decoration: none;
    }
    margin-bottom: 9px;
  }

  .line {
    height: 1px;
    background-color: #e3e3e3;
    margin-bottom: 16px;
  }

  h3 {
    color: #212121;
    text-decoration: underline;
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 400;
  }

  .paragraph {
    width: 95%;

    margin: auto;
    margin-bottom: 20px;
    :first-child {
      font-weight: 400;
      margin-bottom: 24px;
      font-size: 12px;
      color: #212121;
    }
    :last-child {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 12px;
      color: #212121;
    }
  }

  .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    :first-child {
      width: 224px;
      height: 32px;
      background-color: #2a9c27;
      color: white;
      outline: none;
      border: none;
      font-size: 12px;
      margin-bottom: 12px;
      font-weight: 400;
      cursor: pointer;
    }

    :last-child {
      width: 224px;
      position: relative;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #898989;
      border: none;
      font-weight: 400;
      cursor: pointer;

      svg {
        position: absolute;
        height: 16px;
        right: -52px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.old-user-custom-feedback {
  @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@600&family=Poppins:wght@400;500&display=swap");

  .ant-notification-notice-message {
    display: none;
  }

  background-color: #ffffff;
  padding: 20px 32px;
  width: 300px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  border-radius: 0px !important;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.032em;
    font-family: "Poppins", sans-serif;
  }

  .svg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  h1 {
    font-family: "IBM Plex Serif", serif !important;
    font-size: 20px;
    font-weight: 600;
    margin-left: 46px;
    margin-right: 46px;
    text-align: center;
    margin: auto;
    margin-bottom: 9px;
    line-height: 26px;
    letter-spacing: 0 !important;
  }

  h2 {
    color: #383838;
    text-align: center;
    font-size: 12px;
    font-weight: 400;

    span {
      color: #0068ff;
      cursor: pointer;
    }
    margin-bottom: 9px;
  }

  .line {
    height: 1px;
    background-color: #e3e3e3;
    margin-bottom: 19px;
  }

  h3 {
    color: #212121;
    text-decoration: underline;
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 400;
  }

  .paragraph {
    margin: auto;
    margin-bottom: 20px;
    line-height: 16px;
    :first-child {
      font-weight: 400;
      margin-bottom: 24px;
      font-size: 12px;
      color: #212121;
    }
    :last-child {
      margin-bottom: 24px;
      font-weight: 400;
      margin: auto;
      font-size: 12px;
      color: #212121;
      width: 80%;
      text-align: center;
    }
  }

  .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    :first-child {
      width: 224px;
      height: 32px;
      font-size: 12px;
      background-color: #2a9c27;
      color: white;
      outline: none;
      border: none;
      margin-bottom: 12px;
      font-weight: 400;
      cursor: pointer;
    }

    :last-child {
      width: 224px;
      position: relative;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      color: #898989;
      border: none;
      font-size: 12px;
      cursor: pointer;

      svg {
        position: absolute;
        height: 16px;
        right: -52px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.content-page-subtitle {
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em;
}
