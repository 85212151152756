.view-steps-card {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    letter-spacing: 0.032em;

    color: #212121;
  }
  transition: all 0.4s linear;
  max-height: 0px;
  overflow: hidden;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  .steps-count {
    width: 260px !important;
    margin-right: 44px;
    border: 1px solid #898989;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 12px;
    margin: 24px 0;
    margin-left: 32px;
    margin-right: 40px;

    h1 {
      font-size: 32px;
    }

    button {
      margin-top: 16px;
      padding: 6px 12px;
      font-size: 16px;
      span {
        color: #fff !important;
      }

      svg {
        fill: #fff;
      }
    }
  }

  .content {
    width: 600px !important;

    h1 {
      font-size: 26px;
      margin-bottom: 8px;
    }
    h2 {
      font-size: 14px;
      color: #898989;
      font-weight: 500;
    }
    button {
      font-size: 14px;
      margin: 8px 0;
      span {
        color: #fff;
      }
      padding: 4px 4px;
      border-radius: 2px;
    }
    h4 {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
    }
    h5 {
      font-size: 16px;
      font-style: italic;
      margin-top: 8px;
      color: #898989;
    }
  }
  .img {
    width: 320px;
    margin-right: 32px !important;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.view-steps-card.clicked {
  max-height: 400px !important;
  transition: all 0.4s linear;

  margin: 24px 0;
}
