.cod-order-content {
  font-size: 12px;
  letter-spacing: 0.032px;
  svg.reachable-message {
    cursor: pointer;
  }
  .contact-center-tooltip:hover svg.reachable-message {
    background: #1268fb;
    border-radius: 50%;
  }
  .contact-center-tooltip:hover svg.reachable-message g#Ellipse_41 {
    stroke: #1268fb;
  }

  .contact-center-tooltip:hover svg.reachable-message text {
    fill: #fff !important;
  }
  .contact-center-tooltip {
    position: relative;
    display: inline-block;
  }
  .contact-center-tooltip .tooltiptext {
    display: none;
    width: 296px;
    font-weight: 400;
    background-color: #212121;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.032em;
    text-align: center;
    border-radius: 2px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    top: -62px;
    right: 5%;
    transition: opacity 0.3s;
  }

  .contact-center-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent;
  }

  .contact-center-tooltip:hover .tooltiptext {
    display: block;
  }
  label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block;
  }
}
