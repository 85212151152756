.bulk-place-modal {
  .anticon-info-circle{
    left:12px
  }
  .ant-alert-info {
    padding-left: 48px;
  }
  svg{
    transform: scale(0.75);
  }
    .heading-cointainer{
      display: flex;
      justify-content: space-between;
    }
      .ant-modal-body {
        p.input-label {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          margin-bottom: 5px;
        }
        input:first-of-type {
          margin-bottom: 8px;
        }
        .bulk-import {
          .icon-container {
            margin-top: 10px;
            padding: 10px;
            border: 2px solid #979797;
            border-radius: 4px;
            color: #979797;
            text-align: center;
            cursor: pointer;
    
            i {
              svg {
                width: 5rem;
                height: 5rem;
              }
            }
          }
          .icon-container:hover {
            border-color: #5e73ff;
            color: #5e73ff;
          }
        }
      }
      .ant-modal-content {
        border-radius: 2px;
      }
      .ant-modal-title {
        margin: 0;
        color: #212121;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.032em;
        margin-top: 8px;
      }
      .ant-modal-close {
        display: none;
      }
      .ant-modal-header {
        margin: 0 auto;
        padding: 16px 0px;
        border-bottom: 1px solid #cecece;
        width: 432px;
      }
      .ant-modal-body {
        padding: 16px 24px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989;
        font-weight: 400;
      }
      .or-txt {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989;
        font-weight: 400;
        margin-bottom: 8px;
      }
    
      .or-txt2 {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989;
        font-weight: 400;
        margin-bottom: 8px;
        margin-top: 8px;
      }
      .ant-input {
        background-image: none !important;
        border-radius: 0px !important;
        border: none !important;
        background-color: #f5f5f5;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-size: 12px;
        padding: 8px;
        height: 120px;
      }
      .ant-input::placeholder {
        font-size: 12px;
        font-weight: 400px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
      }
      .ant-modal-footer {
        padding: 16px 0px;
        text-align: right;
        border-top: 1px solid #cecece;
        width: 432px;
        margin: 0 auto;
      }
      .add-products-bulk-link {
        margin-top: 16px;
        cursor: pointer;
        position: relative;
        a {
          color: #fd104d;
        }
      }
      .add-products-bulk-link:after {
        content: "";
        position: absolute;
        width: 30%;
        transform: scaleX(0);
        height: 1px;
        bottom: -2px;
        left: 2px;
        background-color: #e61047;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
    
      .add-products-bulk-link:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    
      .bulk-search-btn {
        background: #2bc822;
        border: none;
        width: 144px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px;
        border-radius: 2px;
      }
    
      .disabled-bulk-search-btn {
        background: #94e38f;
        border: none;
        width: 144px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px;
        border-radius: 2px;
        pointer-events: none;
      }
    
      .bulk-search-cancel-modal {
        border: 1px solid #898989;
        width: 144px;
        height: 40px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.032em;
        line-height: 16px;
        border-radius: 2px;
        color: #898989;
      }
      .arrow-right {
        margin-left: 5px;
      }
    
      .bulk-search {
        .bulk-search-radio-btn {
          margin-top: -16px;
        }
        .label-text-enabled {
          color: #212121;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
        }
        .label-text-disabled {
          color: #898989;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
        }
    
        .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
          background-color: #36c626;
        }
        .ant-radio-inner::after {
          background-color: #fff;
        }
        .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner {
          border-color: #36c626;
        }
        .ant-radio-checked .ant-radio-inner {
          border-color: #36c626;
        }
      }
      .clear-data {
        float: right;
        margin-top: -12px;
        background: transparent;
        border: none;
        font-size: 12px;
        cursor: pointer;
        margin-top: -5px;
        font-weight: 400;
        color: #898989;
      }
      .bulk-search-txt {
        margin-bottom: 12px;
        letter-spacing: 0.032em;
        line-height: 22px;
      }
      .clear-data:hover {
        float: right;
        margin-top: -12px;
        background: transparent;
        border: none;
        font-size: 12px;
        cursor: pointer;
        margin-top: -5px;
        font-weight: 500;
        color: #212121;
        text-decoration: underline !important;
      }
    }
    