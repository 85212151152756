.shipmentprogressbar_data {
  background: #ffff;
  padding: 8px 16px;
  .shipmentprogressbar_data2 {
    background-color: #f7f7f7;
    height: 112px;
    margin-bottom: 12px;
    display: flex;
    // align-items: center;

    .line-container {
      margin-top: 24px;
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      .progress-line {
        position: relative;
        margin: 0 auto;
        border-radius: 10px;
        width: 60%;
        background: #cecece;
        height: 8px;
        z-index: 90;
        display: flex;
        justify-content: space-between;
        margin-left: 40px;

        .progress {
          position: absolute;
          width: 75%;
          height: 100%;
          background: #2bc822;
          transition: width 1s ease-in-out;
        }
      }

      .status {
        position: relative;
        top: -4px;
        left: -0.7px;

        .dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #cecece;
          // border: 5px solid #cecece;

          cursor: pointer;

          .current {
            background: white;
            border: 5px solid orange;
          }

          &.completed {
            position: relative;
            background: #2bc822;
            border: 5px solid #2bc822;
          }
        }

        .dot.dot-active {
          outline: 4px solid #b8f0b3;
          animation: pulse-black 2s infinite;
        }

        .time-and-status-wraper {
          position: relative;
          display: flex;
          min-width: 75px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          right: 28px;

          .date {
            margin-top: 2px;
            color: #212121;
            font-size: 12px;
            letter-spacing: 0, 032em;
            font-weight: 400;
          }
        }

        .shipment_step_color {
          color: #2bc822;
          font-weight: 500;
          font-size: 12px;
          margin-top: 7px;
        }
        .original_color {
          color: #cecece;
          font-size: 12px;
          margin-top: 7px;
        }
      }
      .status:nth-of-type(4) {
        .time-and-status-wraper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .custom-rto-in-transit {
        left: 56px !important;
      }

      .return-time-and-status-wraper {
        margin-left: -64px;
        position: relative;
        color: rgb(43, 200, 34);
        left: 35px;
        text-align: center;
        .date {
          margin-top: 2px;
          color: #212121;
          font-size: 12px;
          letter-spacing: 0, 032em;
        }
      }
    }

    .rto-dot-active {
      left: 5px !important;
    }

    .Order_tracking_status {
      margin-right: 28px;
      p {
        letter-spacing: 0.032em;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .lost-shipment {
      color: #f12f1a;
      font-weight: 500;
    }
  }

  .shipment_track_status {
    max-height: 187px;
    overflow-x: auto;
    .footer_data {
      font-size: 12px;
      background: #f5fff4;
      padding: 8px;
      margin-bottom: 8px;
      .footer_data_text {
        margin-bottom: 4px;
        position: relative;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        span {
          margin-right: 8px;
        }

        .circle {
          position: absolute;
          transform: translateY(-60%);
          height: 16px;
          width: 16px;
          top: 50%;
          left: 8px;
          border-radius: 50%;
          background: #252625;
          transition: all 1s;
        }
        .circle::after {
          content: "";
          position: absolute;
          transform: translate(-50%, 0);
          height: 56px;
          width: 1px;
          top: 100%;
          left: 50%;

          background: #252625;
          transition: all 1s;
        }
      }
    }
    .shipmen-remark {
      width: 400px;
    }
    .shipment-date {
      width: 240px;
      padding-left: 40px;
      .date-moment {
        font-weight: 600;
        letter-spacing: 0.032em;
        font-size: 12px;
        color: #000;
      }
    }
    .Shipment-location {
      width: 190px;
      text-transform: uppercase;
    }
  }

  .shipment_track {
    .footer_data {
      font-size: 12px;
      background: #f5fff4;
      padding: 8px;
      margin-bottom: 8px;
      .footer_data_text {
        margin-bottom: 4px;
        position: relative;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        span {
          margin-right: 8px;
        }

        .circle {
          position: absolute;
          transform: translateY(-60%);
          height: 16px;
          width: 16px;
          top: 50%;
          left: 8px;
          border-radius: 50%;
          background: #252625;
          transition: all 1s;
        }
        .circle::after {
          content: "";
          position: absolute;
          transform: translate(-50%, 0);
          height: 56px;
          width: 1px;
          top: 100%;
          left: 50%;

          background: #252625;
          transition: all 1s;
        }
      }
    }
    .shipmen-remark {
      width: 405px;
      text-transform: uppercase;
    }
    .shipment-date {
      width: 215px;
      padding-left: 40px;
    }
    .Shipment-location {
      width: 170px;
      text-transform: uppercase;
    }
  }

  .detail_not_available {
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    background: #f5fff4;
    padding: 8px;
  }

  .shipment_track_status::-webkit-scrollbar {
    width: 5px;
  }
  .shipment_track_status::-webkit-scrollbar-thumb {
    background: #888;
  }
  .shipment_track_status::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .lessDetails {
    margin-left: 8px;
  }
  .moredeatil {
    margin-left: 8px;
  }
  .ist-timezone-txt p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    color: #000;
    font-family: ui-monospace;
    font-style: italic;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
}

.footer_data:last-of-type {
  overflow: hidden;
  .circle {
    visibility: hidden;
  }
  .line {
    height: 16px;
    background: #252625;
    width: 16px;
    position: absolute;
    visibility: visible;
    border-radius: 50%;
  }
  .footer_data {
    overflow: hidden;
  }
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

// Packed
@keyframes load-34 {
  0% {
    width: 0;
  }
  100% {
    width: 34%;
  }
}

@keyframes load-48 {
  0% {
    width: 0;
  }
  100% {
    width: 48%;
  }
}

// Shipped
@keyframes load-66 {
  0% {
    width: 0;
  }
  100% {
    width: 66%;
  }
}

@keyframes load-79 {
  0% {
    width: 0;
  }
  100% {
    width: 79%;
  }
}

@keyframes load-100 {
  0% {
    width: 0;
  }
  100% {
    width: 99%;
  }
}
