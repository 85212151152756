.dispute-card {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px;

  .upper-section {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin-top: 24px;
    .vf {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      p {
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .vforderid_tag {
    padding: 5px;
    padding-left: 15px;
    position: absolute;
    left: -10px;
    top: 8px;
    background: #ed7741;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .vforderid_tag:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 100%;
    left: 0px;
    border-bottom: 9px solid #ed7741;
    border-left: 9px solid transparent;
    transform: rotate(270deg);
  }
  .ant-card-body {
    padding: 0px;
    .blue-text {
      color: #0068ff;
    }
    .header-row {
      display: flex;
      align-items: center;
      padding: 46px 52px 20px 52px;
      h3 {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 400;
      }
      .dispute-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        min-width: 66px;
        .anticon {
          font-size: 17px;
          margin-right: 10px;
        }
      }
      .dispute-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px;
      }
    }
    .circle-ican {
      font-size: 10px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: "white";
      padding: 4px 8px;
      padding-right: 5px;
    }
    .circle-ican-status {
      font-size: 12px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px 10px;
      padding-left: 0;
      font-weight: 500;
    }

    .tabs-row {
      background: #16273b;
      padding: 0 24px;
      display: flex;
      align-items: center;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          padding: 12px 15px;
          color: white;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em;
        }
        li.active,
        li:hover {
          background: #244060;
        }
      }
    }
    .tab-container {
      background: #f2f2f2;
      padding: 0 24px 24px 24px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .dispute-info-tab {
        background: white;
        padding: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .dispute-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          p {
            font-size: 14px;
            font-weight: 500;
            color: black;
            margin-bottom: 0px;
            span {
              color: #5a5a5a;
              font-weight: 400;
            }
          }
        }
        .dispute-reason {
          width: 50%;
          margin-right: 8px;
          h6 {
            font-size: 12px;
            line-height: 16px;
            color: #898989;
            margin-bottom: 4px;
            font-weight: 400;
          }
          p {
            font-size: 12px;
            padding: 8px 16px;
            background: #f5f5f5;
            color: #252525;
          }
        }
      }
      .line-items-tab {
        padding: 16px 13px 7px 13px;
        background: #fff;
        .line-item {
          background: #f2f2f2;
          margin-bottom: 10px;
          padding: 14px 12px;
          display: flex;
          align-items: center;
          border: 1px solid transparent;
        }
        .anticon {
          font-size: 17px;
        }
        .line-item-product-detail {
          display: flex;
          align-items: center;
        }
        .line-item-img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: inline-block;
          background-size: cover !important;
          background-position: center !important;
          border: 1px solid #c8c8c8;
        }
        .line-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          font-size: 12px;
        }
        .line-item-sku {
          font-size: 12px;
          color: #676666;
          font-weight: 400;
          margin: 0;
          margin-top: -2px;
          b {
            color: #898989;
            font-weight: 400;
          }
          .detailcommon {
            color: #000;
            letter-spacing: 0.032em;
            font-size: 12px;
            line-height: 16px;
          }
        }
        .delivered-date {
          margin-bottom: 0px;
          text-decoration: none;
        }
      }
      .media-attachments {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        .image-div {
          margin-top: 10px;
          margin-right: 10px;
          border: 1px solid #898989;
          img {
            width: 100px;
            height: 100px;
            cursor: pointer;
            padding: 3px;
          }
        }
        .download-image-data {
          position: absolute;
          bottom: 25px;
          padding: 6px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 93px;
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    .flex-note-log {
      width: 44%;
      color: #000000a6;
    }
    .flex-date {
      width: 30%;
      display: flex;
      align-items: center;
      .circle {
        margin-right: 8px;
      }

      .font-weight {
        font-weight: 600;
        color: #212121;
      }

      span {
        color: #212121;
      }

      .line {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
    .flex-addedby {
      width: 13%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #000000a6;
    }
  }

  .fields {
    display: flex;
    width: 94%;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    .field {
      flex: 1;
      margin-right: 24px;
      .field-label {
        font-size: 12px;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px;
      }
      .field-value {
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5;
        color: black;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          margin-right: 12px;
        }
      }
    }
    .field:last-of-type {
      margin-right: 0;
    }
  }

  .flex-5 {
    flex: 4;
  }
  .flex-6 {
    flex: 3;
  }
  .flex-7 {
    flex: 2;
    font-size: 12px;
  }
  .flex-8 {
    flex: 1;
  }

  .note-logs {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px;
  }

  .notes-logs-txt {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
  }
  .notes-logs-data {
    background: white;
    padding: 12px 16px;
    font-size: 12px;

    .note {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      color: #212121;
      font-style: italic;
      font-size: 12px;
    }
  }

  .tab-count {
    display: inline-block;
    background: #ff9400;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    margin-left: 12px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
  }
  .upper_data {
    display: flex;
    justify-content: space-between;
  }

  .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 20px;
    color: "#000";
    font-weight: 500;
  }
  .video-data {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
  .shipment-status {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .returned-on {
    margin-left: -16px;
  }
  .delivered-on {
    margin-left: -16px;
  }
  .delivered {
    margin-left: -38px;
  }
  .returned {
    margin-left: -38px;
  }
  .shipment-status {
    margin-left: 24px;
  }
  .header-varaint-status {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    font-size: 12px;
  }
  .delivered-on-date {
    color: #73d13d;
    margin-bottom: 0;
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: left;
  }
  .row-ul {
    li {
      font-size: 12px;
    }
  }

  // logs
  .escalation-status-wraper {
    background-color: #f7f7f7;
    height: 112px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    .progress-line {
      position: relative;
      margin: 0 auto;
      bottom: 20px;
      border-radius: 10px;
      width: 60%;
      background: #cecece;
      height: 8px;
      z-index: 90;
      display: flex;
      justify-content: space-between;

      .progress {
        transition: all 1s linear;

        position: absolute;
        width: 0;
        height: 100%;
        background: #2bc822;
      }
    }

    .status {
      position: relative;
      top: -4px;
      left: -1px;

      .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #cecece;
        // border: 5px solid #cecece;

        cursor: pointer;

        .current {
          background: white;
          border: 5px solid orange;
        }

        &.completed {
          position: relative;
          background: #2bc822;
          border: 5px solid #2bc822;
        }
      }

      .dot.dot-active {
        outline: 4px solid #b8f0b3;
        animation: pulse-black 2s infinite;
      }

      .time-and-status-wraper {
        position: relative;
        display: flex;
        min-width: 75px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        right: 28px;

        .date {
          margin-top: 2px;
          color: #212121;
        }
      }

      .shipment_step_color {
        color: #2bc822;
        font-weight: 500;
        font-size: 12px;
        margin-top: 7px;
      }
      .original_color {
        color: #cecece;
        font-size: 12px;
        margin-top: 7px;
      }
    }

    .status:nth-of-type(4) {
      .time-and-status-wraper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        right: -30px;
      }
    }
  }

  .logs-wraper {
    max-height: 160px;
    overflow-y: scroll;
    .status-logs {
      height: 48px !important;
      padding: 0px 16px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5fff4;

      .flex-date {
        .circle {
          height: 16px;
          width: 16px;
          background-color: #212121;
          border-radius: 50%;
          position: relative;
        }
        .circle.single-circle::after {
          content: "";
          height: 0;
          width: 0;
        }
        .circle::after {
          content: "";
          height: 40px;
          width: 2px;
          background-color: #212121;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .status-logs:last-of-type {
      .circle::after {
        content: "";
        height: 0px;
        width: 0px;
      }
    }
  }

  .logs-wraper::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989;
  }
  .logs-wraper::-webkit-scrollbar-thumb {
    background-color: #898989;
  }

  .single-log::-webkit-scrollbar {
    width: 8px;
    border: 0px solid #ffffff;
  }
  .single-log::-webkit-scrollbar-thumb {
    background-color: #ffffff;
  }
  .cancelEscalation_status {
    display: flex;
    .cancel_esc {
      margin-right: 16px;
    }
  }
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes load-25 {
  0% {
    width: 0;
  }
  100% {
    width: 20%;
  }
}

@keyframes load-50 {
  0% {
    width: 0;
  }
  100% {
    width: 54%;
  }
}

@keyframes load-100 {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
