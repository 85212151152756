.loading-container{
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    background-color: #f0f3f7;
    .ant-skeleton.ant-skeleton-active{
        width: 70% !important;
        padding-top: 20px;
    }
}