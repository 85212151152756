.recharts-wrapper {
    margin-top: 50px !important;
    position: relative !important;
    .recharts-legend-wrapper {
        width: 400px !important;
        height: auto !important;
        left: -40px !important;
        bottom: 26rem !important;
        margin-top: 10px !important;
    }
    .ant-tabs-tabpane {
        background-color: white !important;
        padding-top: 0px !important;
    }
    .recharts-default-legend {
        display: none !important;
        margin-bottom: -1rem !important;
        .recharts-surface {
            width: auto !important;
        }
    }
    .recharts-wrapper>.recharts-surface {
        width: 99%;
    }
}

.overall_progress {
    .ant-tabs-nav-scroll {
        border-radius: 4px;
        padding: 10px !important;
        padding-top: 20px !important;
        padding-bottom: 0 !important;
        background: #fafbfc !important;
        padding-left: 2rem !important;
    }
}

.gr {
    .ant-tabs-nav .ant-tabs-tab {
        padding-right: 0 !important;
        padding-left: 0 !important;
        Color: #212121;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #0068FF;
        font-weight: 500;
    }
    .ant-tabs-ink-bar-animated {
        background-color: #0068FF;
    }
}