.options-container {
  .select-wrap {
    i {
      color: #898989;
      font-size: 12px;
    }
  }
  .spn {
    margin-left: 0px;
    font-weight: 400;
    color: #898989;

    span {
      font-weight: 400;
      color: #898989;
    }
  }

  .spn.true {
    margin-left: 0px;
    font-weight: 500;
    color: #212121;

    span {
      font-weight: 500;
      color: #212121;
    }
  }

  .ant-checkbox {
    margin-left: 5px;
  }
  .sub-text {
    margin-left: 29px;
    margin-bottom: 1.5rem;
    color: #898989;
    font-size: 12px;
  }
  .defaultTime {
    color: #898989;
    margin-top: 24px;
  }
  .ant-select-selection {
    background-color: #f5f5f5;
  }
}

.updated {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  span {
    font-size: 14px;
    // margin-top: 8px;
  }
}

.time-zone-tooltip {
  .anticon svg {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  .anticon:hover {
    svg {
      #Ellipse_44 {
        stroke: #1268fb;
        fill: #1268fb;
      }
      #Ellipse_47 {
        stroke: #fff;
        fill: #ffff;
      }
      #Rectangle_2462 {
        stroke: #fff;
        fill: #ffff;
      }
    }
  }
}

.time-zone-dropdown {
  border-radius: 0px;
  height: 40px;
  margin-top: 6px;
  .ant-select-selection.ant-select-selection--single {
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1) !important;
    background-color: #f8f8f8;
  }
}
