// touring css
.__floater.__floater__open {
  z-index: 13000 !important;

  .__floater__arrow {
    span {
      height: 2px;
      left: 5px !important;
      z-index: 9;

      svg {
        polygon {
          fill: #1f9d82 !important;
        }
      }
    }
  }
}

// content
.react-joyride__tooltip {
  border-radius: 4px !important;
  width: 400px !important;
  box-shadow: 0 2px 3px #000 !important;
  background-image: linear-gradient(to right, #1f9d82, #1a8950) !important;
  // background-color: #22ac8f !important;
  > div {
    > div {
      padding: 0 !important;

      .product-tour {
        * {
          letter-spacing: 0.032em !important;
          margin: 0;
          padding: 0;
          font-size: 12px;
          color: #212121;
        }

        padding-bottom: 0px;
      }

      .tour-progress {
        height: 26px;
        border-radius: 12px;
        width: 100%;
        border: 2px solid #fed972;
        margin-bottom: 15px;
        overflow: hidden;

        .bar {
          background-color: #ffd972;
          height: 100%;

          position: relative;
        }

        .bar::before {
          content: "Quick Overview Tour";
          display: block;
          position: absolute;
          height: 100%;
          top: 20%;
          font-size: 10px;
          font-weight: 500;
          left: 16px;
        }

        .bar::after {
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          top: 0;
          right: 0;
          width: 16px;
          background-color: #fff6de;
        }
      }

      .tour-head {
        h2 {
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          opacity: 0.5;
          text-align: left;
          margin-bottom: 3px;
        }
      }

      p {
        text-align: left;

        font-size: 14px;
        font-weight: 500;
        color: #ffffff !important;
        margin-bottom: 30px !important;
      }
    }
  }

  // margin-top: 6px;

  button {
    height: 32px !important;
    border-radius: 2px !important;
  }

  button[data-action="primary"] {
    transition: all 0.3s ease-out;
    background-color: #fff !important;
    color: #1a816b !important;
    border-radius: 2px !important;
    width: 82px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  button[data-action="primary"]:hover {
    transition: all 0.3s ease-in;

    background: #1a816b !important;
    border: 1px solid #fed972 !important;

    color: #fed972 !important;

    border-radius: 2px !important;
    width: 82px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    box-shadow: inset 1px 2px 3px 2px #00000050;
  }

  button[data-action="primary"]:active {
    transition: all 0.3s ease-out;
    background-color: #fff !important;
    color: #1a816b !important;
    border: none !important;
    border-radius: 2px !important;
    width: 82px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    box-shadow: none !important;
  }

  button[data-action="back"] {
    transition: all 0.3s ease-in;

    color: #fff !important;
    font-weight: 500;
    width: 82px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
    background-color: #1f9d82 !important;
  }

  button[data-action="back"]:hover {
    transition: all 0.3s ease-out;
    box-shadow: inset 1px 2px 3px 2px #00000050;

    color: #fff !important;
    font-weight: 500;
    width: 82px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
    background-color: #1f9d82 !important;
  }

  button[data-action="back"]:active {
    transition: all 0.3s ease-out;
    box-shadow: inset 1px 2px 3px 2px #00000050;

    color: #3284c9 !important;
    font-weight: 500;
    width: 82px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
    background-color: #fff !important;
  }

  button[data-action="skip"] {
    transition: all 0.3s ease-in;

    color: #898989 !important;
    font-size: 12px !important;
    border: 1px solid #ffffff90 !important;
    color: #ffffff80 !important;
  }

  button[data-action="skip"]:hover {
    transition: all 0.3s ease-out;

    color: #898989 !important;
    font-size: 12px !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important;
  }

  > button {
    display: none;
  }
}

.react-joyride__overlay {
  z-index: 12999 !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.react-joyride__spotlight {
  border: 3px solid #1f9d82 !important;
  border-radius: 0px !important;
  left: 0px !important;
  width: 240px !important;
  z-index: 99;
}

// tour end modal
.tour-end-modal {
  width: 400px !important;
  .ant-modal-close-x {
    display: none !important;
  }

  .ant-modal-close {
    display: none !important;
  }

  .ant-modal-content {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 4px !important;
  }

  .ant-modal-body {
    padding: 24px 16px !important;
    border-radius: 4px !important;
    width: 400px !important;
    box-shadow: 0 2px 3px #000 !important;
    background-image: linear-gradient(to right, #1f9d82, #1a8950) !important;
  }

  .tour-modal-content {
    .bulb-icon-wraper {
      display: flex;
      align-items: center;
      justify-content: center;
      .bulb-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #fed972;

        svg {
          font-size: 40px;
        }
      }
    }
  }

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.6;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 3px;
  }

  h2 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 0 45px;
    color: #ffffff !important;
    margin-bottom: 24px;
  }

  .btn-wraper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      transition: all 0.3s ease-out;
      background-color: #fff !important;
      color: #1a816b !important;
      border-radius: 2px !important;
      width: 136px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      height: 32px !important;
      border: none !important;
      cursor: pointer !important;
    }

    button:hover {
      transition: all 0.3s ease-in;

      background: #1a816b !important;
      border: 1px solid #fed972 !important;

      color: #fed972 !important;

      border-radius: 2px !important;

      font-size: 12px !important;
      font-weight: 600 !important;
      box-shadow: inset 1px 2px 3px 2px #00000050;
    }

    button:active {
      transition: all 0.3s ease-out;
      background-color: #fff !important;
      color: #1a816b !important;
      border: none !important;
      border-radius: 2px !important;

      font-size: 12px !important;
      font-weight: 500 !important;
      box-shadow: none !important;
    }

    // button {
    //   height: 32px !important;
    //   border-radius: 2px !important;
    //   background-color: #fff !important;
    //   color: #3284c9 !important;
    //   border-radius: 2px !important;
    //   width: 136px !important;
    //   font-size: 12px !important;
    //   border: none;
    //   font-weight: 500 !important;
    //   cursor: pointer;
    // }
  }
}

@media screen and (min-width: 180px) and (max-width: 1439px) {
  .react-joyride__spotlight {
    width: 200px !important;
  }
}
