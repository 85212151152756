.variant-mapping {
  margin-top: 62px;
  .variant-matching {
    .header-txt {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      p {
        color: #898989;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-weight: 400;
        margin-right: 172px;
      }
    }
  }

  // .ant-select-search--inline {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   margin-top: -2px;
  // }

  .save-continue {
    background: #2bc822;
    border: none;
    border-radius: 2px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 500;
    float: right;
    margin-top: 16px;
  }

  .varaint-matching-data {
    display: flex;
    justify-self: space-between;
    width: 100%;
    .left-varaint-bundle-image-data {
      width: 43%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      img {
        margin-left: 16px;
      }
    }
  }

  .ant-select-selection {
    min-height: 64px;
    border: none;
    background: #f5f5f5;
    border-radius: 2px;
    .ant-select-selection__rendered {
      min-height: 48px;
      padding-top: 6px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.032em;
      color: #212121;
    }
    .ant-select-search--inline {
      position: absolute;
      width: 100%;
      height: 100%;
      margin-top: -2px;
    }
  }

  .ali-product-name {
    font-weight: 400;
    margin-top: 72px;
    margin-bottom: 24px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    background: #f5faff;
    padding: 8px;
    border-radius: 4px;
  }
  .ant-select-selection__placeholder {
    top: 72%;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .link-product-name {
    color: #0068ff;
    letter-spacing: 0.032em;
    font-weight: 400;
  }
  .tooltipText {
    margin-right: 8px;
  }
  .aliproduct-length {
    color: #898989;
  }
  .anticon:hover {
    svg {
      #Ellipse_44 {
        stroke: #1268fb;
        fill: #1268fb;
      }
      #Ellipse_47 {
        stroke: #fff;
        fill: #ffff;
      }
      #Rectangle_2462 {
        stroke: #fff;
        fill: #ffff;
      }
    }
  }
}
