.pricing-card {
  background: white;
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  letter-spacing: 0.032em;
  .top-section {
    display: flex;
    padding: 32px 32px 16px 32px;
    .img-container {
      width: 160px;
      margin-right: 24px;
      img {
        width: 160px;
        height: 160px;
        border: 2px solid #f5f5f5;
      }
    }
    .basic-details {
      flex: 1;
      min-width: 0;
      .product-description {
        font-size: 16px;
        color: #212121;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .product-name-detail {
        font-size: 16px;
        margin-top: 3px;
        .product-name-txt {
          font-weight: 500;
          color: #212121;
          line-height: "16px";
          letter-spacing: "0.032em";
          span a:hover {
            text-decoration: underline !important;
          }
        }
      }
      .product-id-copie-data {
        .product-Id {
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500;
          color: "#898989";
        }
        .copied-Id {
          margin-left: 6px;
          color: #898989;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
    .fields {
      display: flex;
      border-top: 1px solid #cecece;
      padding-top: 16px;
      margin-top: 16px;
      width: 100%;
      .field {
        flex: 1;
        margin-right: 16px;
        .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
        }
        .field-value {
          font-size: 12px;
          padding: 8px 16px;
          background: #f5f5f5;
          color: #212121;
        }
      }
      .field:last-of-type {
        margin-right: 0;
      }
    }
  }
  .tabs-header {
    background: #16273b;
    height: 40px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 32px;
      li {
        display: inline-block;
        button {
          border: none;
          background: transparent;
          margin-right: 32px;
          color: white;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 16px;
          cursor: pointer;
          .tab-count {
            display: inline-block;
            background: #ff9400;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            margin-left: 12px;
          }
        }
      }
    }
  }
  .tabs-content {
    background: #f5f5f5;
    padding: 0 32px 32px 32px;
    .tab-content-all-data {
      padding: 16px;
      background: white;
      font-size: 12px;
      border-radius: 0px 0px 4px 4px;
      display: none;
    }
    .block {
      display: block;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    .flex-4 {
      padding: 3px 13px 3px;
      background-color: #0068ff;
      color: #fff;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
  .retail-price-data {
    padding: 16px 24px;
    letter-spacing: 0.032em;
    border-radius: 0px 2px 2px 2px;
    background-color: #f5faff;
    border: 2px solid #1370fc;
  }

  .flex-1 {
    flex: 1;
    .retail-piece {
      font-size: 20px;
      color: #565759;
      font-weight: 600;
      letter-spacing: 0.032em;
      line-height: 20px;
      span {
        font-size: 12px;
      }
    }
    .pices-color {
      color: #f66161;
    }
  }
  .flex-2 {
    flex: 2;
  }

  .flex-3 {
    .right-text {
      font-size: 10px;
      font-style: italic;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #000;
    }
  }

  .anticon {
    svg {
      margin-bottom: 1px;
    }
  }
  .tab-container {
    background: #f5f5f5;
    padding: 0px !important;
    border-radius: 4px !important;
  }
  .view-in-store {
    margin-top: 0px !important;
  }

  .bulk-pricing-table {
    margin-top: 16px;
    .bulk-pricing-table-txt {
      font-size: 12px;
      padding: 4px 12px;
      color: white;
      background: #1370fc;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      display: "inline-block";
    }
    .pricingtabledata {
      background: #f5faff;
      overflow: auto;
      border: 2px solid #1370fc;
      border-radius: 0px 2px 2px 2px;
      .nowrap-Data {
        display: flex;
        flex-wrap: nowrap;
        .quantity-data {
          flex: 1;
          min-width: 22%;
          .quantity-txt {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            color: #898989;
            font-weight: 400;
            border-bottom: 1px solid #b5dafe;
            border-top-left-radius: 2px;
            letter-spacing: "0.032em";
          }
          .quantity-price-txt {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            color: #898989;
            font-weight: 400;
            border-top-left-radius: 2px;
            letter-spacing: "0.032em";
          }
        }
        .range-data {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-width: 20%;
          .range-split-data {
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #b5dafe;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.032em;
            color: #565759;
          }
          .freight-ppq {
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            color: #565759;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.032em;
          }
        }
      }
    }
  }
  // .note_logs {
  //   background: white;
  //   padding: 12px 16px;
  // }

  .line-through {
    color: #565759;
    text-decoration: line-through;
    font-weight: 500;
  }
}
p,
h3,
h2 {
  margin: 0;
}
.top-section {
  position: relative;
  overflow: hidden;
}
.product-label {
  position: absolute;
  padding: 6px;
  background-color: #ff9400;
  width: 187px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  transform: rotate(-40deg);
  left: -36px;
  top: 33px;
}
