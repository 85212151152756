.search-products-container {
  .ant-input-search {
    height: 1.75rem;
    .ant-input {
      font-size: 0.75rem;
      height: 1.75rem;
    }
    .ant-input::placeholder {
      font-size: 0.75rem;
    }
  }
  img {
    max-width: 100%;
  }
  .search-button {
    text-align: end;
  }
  .search-button button {
    width: 275px;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    height: 36px;
  }
  .import-product-btn {
    background: #0068ff;
    color: white;
    border-color: #0068ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }

  .product-category-row {
    .selected-category {
      border-color: #36c626;
      svg * {
        stroke: #36c626;
        fill: #36c626;
      }
      p {
        color: #36c626;
      }
    }
    .product-category-card {
      cursor: pointer;
      display: flex;
      background: white;
      border: 1px solid #d6d6d6;
      align-items: center;
      height: 70px;
      padding: 15px 20px;
      .product-category-img {
        width: 20px;
        svg {
          width: 20px;
        }
      }
      .product-category-name {
        flex: 1;
        flex-direction: column;
        font-size: 14px;
        line-height: 30px;
        align-items: center;
        overflow: hidden;
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .product-category-card:hover,
    .product-category-card.selected {
      background: #e6e5e5;
      border: 1px solid black;
      color: black;
    }
  }

  .list-category-container {
    padding-top: 19px;

    .list-category-card {
      .ant-card-body {
        padding: 0;
      }
      .watch-product {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: 24px;
      }

      .slider-image {
        overflow: scroll;
        margin: 10px;
        width: 118px;
        display: flex;
        margin-bottom: 0px;
        img {
          width: 32px;
          height: 32px;
          border: 1px solid black;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .title {
      font-weight: 500;
    }

    .blue-text {
      color: #0068ff;
    }
    .pink-text {
      color: #ff005e;
    }

    .list-category-column {
      padding: 0.5rem;
      margin: 0 0 15px 0;
      .image-container {
        border: 1px solid black;
        width: 144px;
        max-height: 138px;
      }

      .list-category-card-content {
        width: 98%;
      }

      .landing-cost {
        margin-bottom: 0px;
        padding: 7px;
        background: #f2f7fe;
        text-align: center;
        font-weight: 500;
        color: #383838;
        line-height: 22px;
        margin-top: 3px;
      }

      .product-name {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        display: block;
        color: #1268fb;
        line-height: 21px;
        margin-bottom: 8px;
        min-height: 42px;
      }

      .product-field {
        margin-bottom: 0.2rem;
        font-size: 12px;
        line-height: 22px;
        color: #696969;
        margin: 0;
        b {
          font-weight: 500;
        }
      }

      .anticon-star svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-bottom: 0.7rem;

        path {
          fill: #f2e227;
        }
      }

      .footer {
        // cursor: pointer;
        color: #fff;
        background: #36c626;
        border: none;
        border-radius: 0px;
        box-shadow: unset;
        font-size: 14px;
        text-align: center;
        padding: 12px 24px;
      }

      .list-category-card {
        border: 2px solid transparent;

        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      }
    }
  }
  .filter-container p {
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-bottom: 10px;
  }
  .filter {
    .ant-select-selection {
      border-radius: 0;
    }
  }
  .filter.first {
    .ant-select-selection {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
  .filter.last {
    .ant-select-selection {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .product-sorting {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .other-attributes {
    margin-top: 6px;
    display: flex;
    .anticon {
      font-size: 24px;
      margin-right: 8px;
    }
  }
  .viewAll {
    display: flex;
    .ant-btn {
      background: #f2f7ff;
      color: #0068ff;
      border: none;
      height: 48px;
      flex: 1;
    }
    a {
      display: inline-block;
      background: #f2f7ff;
      color: #0068ff;
      border: none;
      height: 48px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex: 1;
    }
  }
  .product-and-sort {
    p {
      font-size: 16px;
      font-weight: 500;
      color: black;
      margin-bottom: 0px;
    }
  }
}
