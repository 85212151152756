.Transaction-heads-modal {
  width: 644px !important;
  letter-spacing: 0.01em;
  top: 70px;
  .search-input-transactions {
    // margin-top: 10px;
    height: 40px !important;
    box-shadow: 0 3px 10px #0000000d;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #e3e3e3 !important;
    position: relative;
    top: -6px;
    width: 532px;
    z-index: 99999;
    -webkit-transition: all 0.2s ease-in-out !important;
    transition: all 0.2s ease-in-out !important;
  }

  .ant-modal-content {
    border-radius: 2px;
  }

  .ant-modal-close-x {
    svg {
      fill: #fff;
    }
  }
  .ant-modal-header {
    background-color: #192f4a;

    > div {
      color: #fff;
    }
  }

  .ant-modal-header {
    // border-radius: 2px;
    background-color: #fff;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
    padding-bottom: 8px;
    border-color: #cecece;
    margin-bottom: 12px;

    h2 {
      margin: 0px;
      font-size: 16px;
      color: #000000d9;
      font-weight: 500;
    }

    p {
      margin: 0px;
      font-size: 12px;
      color: #898989;
      padding-bottom: 16px;
    }
  }

  .ant-modal-body {
    height: 500px;
    padding-top: 0px;
    overflow: scroll;
    padding-bottom: 12px;
    overflow-x: hidden !important;
    margin-right: 1px;

    .wraper {
      border-bottom: 1px solid #b2b2b2;

      .select-all-wraper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-top: 24px;
        h1 {
          font-size: 14px;
          margin: 0;
          padding: 0;
        }
        .ant-checkbox {
          display: none;
        }

        span {
          color: #0062ff;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          cursor: pointer;
          font-weight: 500;
        }

        .ant-checkbox-wrapper-checked {
          span {
            color: red !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            cursor: pointer;
            font-weight: 500;
          }
        }

        span:hover {
          text-decoration: underline;
        }
      }
      .select-all-wraper:first-of-type {
        margin-top: 24px;
      }

      .ant-checkbox-wrapper-checked {
        .ant-checkbox-inner {
          background-color: #36c626;
          border-color: #36c626;
        }
      }

      .single-select-wraper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 24px;

        .ant-checkbox-wrapper {
          margin-left: 0px;
          // margin-right: 32px;
          border: none;
          width: 48%;
          padding: 8px;
          margin-bottom: 12px;
          font-size: 12px;
          background-color: #f5f5f5;
          color: #212121;
        }

        .ant-checkbox-wrapper:hover {
          .ant-checkbox-inner {
            border-color: #36c626;
          }
        }

        .ant-checkbox-wrapper:nth-child(odd) {
          //   border-right: 0px solid #898989;
        }
      }
    }

    .wraper-first.wraper.search {
      // padding-top: 40px;
    }

    .wraper:first-of-type {
      .select-all-wraper {
        margin-top: 12px;
      }
    }
    .wraper:last-of-type {
      border-bottom: none;

      .single-select-wraper {
        margin-bottom: 0px;
      }
    }
  }

  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color: #cecece;

    .ant-btn.ant-btn-cancel {
      border-radius: 2px;
    }
  }

  .ant-modal-body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  .ant-modal-body::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .ant-modal-body::-webkit-scrollbar {
    width: 8px;

    border: 1px solid #898989;
  }
}
