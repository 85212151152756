.my-disputes {
  letter-spacing: 0.3px !important;
  .content-page-title {
    margin-bottom: 0px;
  }
  .page-subtitle {
    font-size: 16px;
    color: black;
    margin-bottom: 0px;
    span {
      font-size: 14px;
      color: black;
    }
  }
  .dispute-filter {
    margin-bottom: 32px;
    .dispute-status {
      font-size: 14px;
      color: black;
      margin-bottom: 0px;
    }
    .ant-btn {
      background: #1370fc;
      border: none;
      box-shadow: none;
      color: white;
      font-size: 12px;
      font-weight: 500;
      width: 160px;
      height: 40px;
      border-radius: 2px !important;
    }
    .ant-select {
      width: auto !important;
      .ant-select-selection {
        border: none;
        box-shadow: none;
        color: #000000a6;
      }
    }
  }
  .drop {
    background-color: white !important;
    padding: 4.5px 5px 4.5px 17px;
    width: 290px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;
    .ant-select-selection__rendered {
      margin-left: 8px;
      background-color: white !important;
      margin-right: 2px;
      height: 100% !important;
      .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
      }
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important;
    }
    .ant-select-selection-selected-value {
      font-size: 13px;
      line-height: 16px;
      color: #1268fb;
    }
    .sel {
      .ant-select-selection {
        width: 159px;
        .ant-select-selection-selected-value {
          line-height: 30px;
        }
      }
      .ant-select-arrow {
        top: 53%;
      }
    }
  }
  .skeleton {
    margin-bottom: 30px;
  }
  .empty-card {
    margin-bottom: 20px;
    text-align: center;
  }
  .no-escalations-found-txt {
    margin: 0;
  }
}

.import-product {
  .placeholder {
    ::placeholder {
      font-size: 12px;
    }
  }
  .ant-input-group .ant-input {
    height: 40px;
    box-shadow: 0px 2px 8px #00000014;
    border-radius: 2px 0 0 2px;
    border: 0px;
    background: white !important;
  }
}

.pick {
  color: #1268fb !important;
  background-color: white !important;
  margin-left: 8px;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px #00000014;
  :focus {
    outline: none !important;
    border: none !important;
  }
  :active {
    outline: none !important;
    border: none !important;
  }
  .ant-calendar-picker-input {
    color: #1268fb !important;
    background: none;
    border: none;
    outline: none;
    font-size: 12px !important;
    :focus {
      outline: none !important;
      border: none !important;
    }
    :active {
      outline: none !important;
      border: none !important;
    }
    padding-left: 0;
  }
  .ant-calendar-range-picker-separator {
    color: #1268fb !important;
    vertical-align: baseline;
  }
}
