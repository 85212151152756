.product-link-modal {
  .ant-modal-content {
    border-radius: 0px;
  }
  .ant-radio-group {
    display: block;

    .ant-radio-wrapper {
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
      font-size: 12px;
      border-radius: 0px;
      padding: 10px;
      display: block;
      margin-bottom: 1rem;

      p {
        font-size: 12px;
      }
      .label-text {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
      }
    }
    .ant-radio-wrapper:hover:hover {
      background-color: #f0ffef;
      border: 1px solid #2bc822;
    }
  }
  .next-button {
    background-color: #2bc822;
    outline: none;
    border: none;
    width: 104px;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
  }
  .cancle-button {
    width: 104px;
    border-radius: 2px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-radio-inner::after {
    background-color: #fff;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
    background-color: #36c626;
  }

  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #8bd987;
    border-color: #8bd987;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    width: 104px;
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
  }

  .link-product-txt {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.032em;
    color: #212121;
    line-height: 16px;
  }
  .ant-modal-body {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .ant-modal-footer {
    padding: 16px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    margin-top: -16px;
  }
  .link-product-note-txt {
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    font-style: italic;
    line-height: 16px;
    margin-bottom: 16px;
  }
  .ant-modal-close-x {
    display: none;
  }
}
