@import url("../../../Commonscss/constant.scss");

.Transactions_head_fs {
  .Transactions-order_place_fs {
    min-height: 55px;
    max-height: 214px;
    overflow: scroll;
  }
  .modal_headerdata {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 24px;
    align-items: center;
  }
  .Vforderid_txt {
    padding: 0px 24px;
    span {
      padding: 5px;
      padding-left: 8px;
      background: #3385ff;
      color: white;
      border-radius: 2px;
      border-bottom-left-radius: 0;
      font-size: var(--small-font-size);
      font-weight: 500;
      letter-spacing: 0.032em;
    }
  }

  .Header_name {
    font-size: var(--small-font-size);
    padding: 0px 24px;
  }

  .Transactions-table_fs {
    width: 100%;
    font-size: 12px;
    transition-duration: 1s;

    overflow-x: scroll;
    padding: 16px 24px;
    display: inline-block;

    th {
      color: #898989;
      font-weight: 400;
      padding: 16px;
      white-space: normal;
      letter-spacing: 0.032em;
      line-height: 16px;
    }

    td {
      padding: 16px 16px 16px 16px;
      color: #212121;
      text-transform: capitalize;
      border: 2px solid #e3e3e3;
      font-size: 16px;
    }
    .table_parrent {
      transform: translate(4%, 0px) !important;
    }
    .table_parrent.tr {
      transform: translate(0%, 0px) !important;
    }

    .pr-details {
      position: relative;
      .qty-header {
        position: absolute;
        z-index: 97;
        top: 50%;

        transform: translateY(-50%);
        right: 0px;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
      }
    }
  }

  .shipping-fs {
  }

  .transaction_images {
    padding: 8px 8px 8px 16px;
    width: 235px;
    span {
      white-space: nowrap;
      display: inline-block;
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .transaction_images.gb {
    padding: 8px 8px 8px 16px;
    width: 350px;
    span {
      white-space: nowrap;
      display: inline-block;
    }
    img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }

  .transaction_images {
    position: relative;
    .wraper {
      img {
        height: 32px !important;
        width: 32px !important;
      }
      display: flex;
      align-items: center;
    }

    .qty {
      position: absolute;
      z-index: 97;
      top: 50%;
      font-size: 16px;
      transform: translateY(-50%);
      right: 0px;
      border-left: 1px solid #b2b2b2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 107%;
    }
  }

  .transaction_images {
    border-bottom: 1px #b2b2b2 solid;
  }

  .ant-modal-body table thead th:nth-child(3) {
    text-align: none !important;
  }
  .Transactions-table_Total {
    width: 100%;

    transition-duration: 1s;
    margin-bottom: 20px;
    overflow-x: scroll;
    padding: 16px 24px;
    display: inline-block;
    margin: -56px 0 0 38px;

    td {
      padding: 16px 16px;
      color: #212121;
      text-transform: capitalize;
      // border: 2px solid #e3e3e3;
      width: 200px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.032em;
      line-height: 16px;
    }
  }
  tr.no-border {
    border: none !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.032em;
    line-height: 16px;
  }
  .no-border td {
    border: none !important;
    background: white !important;
  }
  .red_text {
    color: #e14643;
    font-weight: 600;
    letter-spacing: var(--normal-letter-spacing);
    line-height: var(--small-line-height);
  }
  .green_text {
    color: #36c626;
    font-weight: 600;
    letter-spacing: var(--normal-letter-spacing);
    line-height: var(--small-line-height);
  }

  .shipping-qty {
    display: none;
  }
}

.Transactions_head .Transactions-table th:first-child {
  padding-left: 0;
}
.Transactions_head .Transactions-table td:first-child {
  background: #f5f5f5;
}
.Transactions-order_place_fs tr:last-child {
  .transaction_images {
    border-bottom: none !important;
  }
}

.fs-16-m {
  font-size: 16px;
  font-weight: 500;
}
